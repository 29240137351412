import {Autocomplete, Box, Button, MenuItem, Select, TextField, Typography} from '@mui/material';
import React, {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CacheContext} from 'src/context/cache/cache.store';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {NetworkContext} from 'src/context/network/network.store';
import {DistrictDto, LanguageDto, LocalizedTextDto, StationDto} from 'src/services/models';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';
import {PresenceOrigin, SearchPrecensesQuery} from './AssignModal';
import { handleFilterStations } from 'src/scenes/change-route/NewRoutePlanner/StationAutoComplete';

export const AssignBtp: React.FC<{handleSearch: (props: SearchPrecensesQuery) => void; loading: boolean, isDisabled: boolean}> = ({
  handleSearch,
  loading,
  isDisabled
}) => {
  const {districts, stations} = useContext(CacheContext);
  const {i18n, t} = useTranslation();

  const [selectedDistrict, setSelectedDistrict] = useState<string>((districts as DistrictDto[])[0].id || '');
  const [selectedStation, setSelectedStation] = useState<string>('undefined');

  const handleDistrictChange = (val: string) => {
    const districtId = val;
    setSelectedDistrict(districtId || '');
    setSelectedStation('undefined'); // Reset station when district changes
  };

  const handleStationChange = (val: string) => {
    setSelectedStation(val);
  };

  const stationOptions = [
    {
      id: 'undefined',
      name: [
        {language: LanguageDto.Dutch, text: 'Volledig District'},
        {language: LanguageDto.French, text: 'District Complète'},
        {language: LanguageDto.English, text: 'Full District'},
      ] as LocalizedTextDto[],
      districtId: selectedDistrict,
    },
    ...(selectedDistrict
      ? (stations as StationDto[])
          .filter(el => el.districtId === selectedDistrict)
          .sort((a, b) =>
            handleTranslationObject(i18n, a.name as LocalizedTextDto[]).localeCompare(
              handleTranslationObject(i18n, b.name as LocalizedTextDto[]),
            ),
          )
      : []),
  ];

  const doSearch = () => {
    if (selectedStation === 'undefined') {
      const stationList = (stations as StationDto[]).filter(el => el.districtId === selectedDistrict).map(el => el.id);
      if (stationList.length > 0) {
        handleSearch({origin: PresenceOrigin.BTP, payload: stationList as string[]});
        return;
      }
    } else {
      handleSearch({origin: PresenceOrigin.BTP, payload: [selectedStation]});
    }
  };

  return (
    <Box sx={{border: 'solid 1px lightgrey', padding: '25px'}}>
      <Typography sx={{marginBottom: '25px', marginTop: '15px'}} variant="h5">
        {t('pmr-mission.select-district')}
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '20% 1fr 20%',
          gap: '15px',
          marginBottom: '15px',
        }}
      >
        <Select value={selectedDistrict} onChange={e => handleDistrictChange(e.target.value)} disabled={isDisabled}>
          {(districts as DistrictDto[]).map(el => (
            <MenuItem key={el.id} value={el.id || ''}>
              {handleTranslationObject(i18n, el.name as LocalizedTextDto[])}
            </MenuItem>
          ))}
        </Select>
        <Autocomplete
          value={stationOptions.find(el => el.id === selectedStation)}
          onChange={(_e, newValue) =>
            handleStationChange(newValue?.id === 'undefined' ? 'undefined' : (newValue?.id as string))
          }
          options={stationOptions}
          getOptionLabel={option => handleTranslationObject(i18n, option.name as LocalizedTextDto[])}
          renderInput={params => <TextField {...params} label={t('select.station')} />}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          disableClearable // This prop prevents the user from clearing the selection
          disabled={isDisabled}
          filterOptions={(el, state) => {
            return handleFilterStations(el, state.inputValue)}}
        />
        <Button variant="outlined" disabled={selectedDistrict.length === 0 || loading || isDisabled} onClick={doSearch}>
          {t('pmr.search.search')}
        </Button>
      </Box>
    </Box>
  );
};
