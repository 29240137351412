// @ts-nocheck
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export function UserLeft(props) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em">
        <g id="Calque_2" data-name="Calque 2">
          <g id="all">
            <g id="ic_change">
              <rect fill='white' class="cls-1" width="24" height="24" />
              <path
                class="cls-2"
                d="M14,23.22H3V19.93a4.25,4.25,0,0,1,.34-1.71,4.55,4.55,0,0,1,.94-1.39,4.66,4.66,0,0,1,1.4-.94,4.45,4.45,0,0,1,1.7-.34H8.51a3.26,3.26,0,0,1-3.28-3.28A3.26,3.26,0,0,1,8.51,9a3.28,3.28,0,0,1,3.29,3.29,3.28,3.28,0,0,1-3.29,3.28h1.1a4.36,4.36,0,0,1,1.59.29,4.47,4.47,0,0,1,1.32.81,3.23,3.23,0,0,1,.37.37,4.47,4.47,0,0,1,.81,1.32A4.36,4.36,0,0,1,14,19.93Zm-1.1-1.1V19.93a3.24,3.24,0,0,0-3.28-3.28H7.42a3.14,3.14,0,0,0-1.25.25,3.49,3.49,0,0,0-1.06.7,3.17,3.17,0,0,0-.72,1,3.25,3.25,0,0,0-.26,1.28v2.19ZM6.32,12.27a2.22,2.22,0,0,0,2.19,2.19,2.1,2.1,0,0,0,1.56-.65,2.15,2.15,0,0,0,.63-1.54,2.17,2.17,0,0,0-2.19-2.2A2.15,2.15,0,0,0,7,10.71,2.1,2.1,0,0,0,6.32,12.27Zm6.57.85V11.79a4.1,4.1,0,0,1,2.19-.62h.55a2.75,2.75,0,0,1-2.74-2.74A2.61,2.61,0,0,1,13.7,6.5a2.73,2.73,0,0,1,4.67,1.93,2.67,2.67,0,0,1-.79,1.94,2.62,2.62,0,0,1-1.95.8h.55a4.18,4.18,0,0,1,1.71.34,4.42,4.42,0,0,1,2.33,2.33,4.18,4.18,0,0,1,.34,1.71v2.19H15.12V16.65h4.35v-1.1a3.26,3.26,0,0,0-3.29-3.28h-1.1A3,3,0,0,0,12.89,13.12ZM14,8.43a1.59,1.59,0,0,0,.48,1.17,1.56,1.56,0,0,0,1.16.47,1.62,1.62,0,0,0,1.65-1.64,1.57,1.57,0,0,0-.48-1.16,1.59,1.59,0,0,0-1.17-.48A1.64,1.64,0,0,0,14,8.43Z"
              />
              <path
                class="cls-2"
                d="M10,2.22a4.77,4.77,0,0,1,1.66-1.1,5,5,0,0,1,1.9-.37,5.1,5.1,0,0,1,3.58,1.49l.44.45a.61.61,0,0,1,.19.44.57.57,0,0,1-.18.45.65.65,0,0,1-.9,0l-.44-.45A4,4,0,0,0,15,2.29,3.74,3.74,0,0,0,13.57,2a3.94,3.94,0,0,0-1.43.27,3.82,3.82,0,0,0-1.25.84L9.74,4.27h2a.65.65,0,0,1,.44.19.63.63,0,0,1,.19.44.62.62,0,0,1-.18.46.65.65,0,0,1-.46.18H8.21a.6.6,0,0,1-.44-.18.66.66,0,0,1-.19-.45V1.31A.62.62,0,0,1,7.76.85.57.57,0,0,1,8.21.68a.56.56,0,0,1,.44.17.6.6,0,0,1,.19.46V3.38Z"
              />
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
