import {AddressDetailsDto} from 'src/services/models/address-details-dto';
import {PtcarDetailsDto} from 'src/services/models/ptcar-details-dto';
import {TrainDetailsDto} from 'src/services/models/train-details-dto';
import {MenuItem} from '@mui/material';
import {LocationDetailsDto} from 'src/services/models/location-details-dto';

export const isAddressDetails = (loc: any): loc is AddressDetailsDto => {
  return (loc as AddressDetailsDto).address !== undefined;
};

export const isPtcarDetails = (loc: any): loc is PtcarDetailsDto => {
  return (loc as PtcarDetailsDto).uicCode !== undefined;
};

export const isTrainDetails = (loc: any): loc is TrainDetailsDto => {
  return (loc as TrainDetailsDto).trainNumber !== undefined;
};

export const mapLocationsToMenuItems = (locations: LocationDetailsDto[]) => {
  return locations.map(loc => {
    if (isAddressDetails(loc)) {
      return (
        <MenuItem value={loc.id || ''} key={loc.id || ''}>
          {loc.address}
        </MenuItem>
      );
    } else if (isPtcarDetails(loc)) {
      return (
        <MenuItem value={loc.id || ''} key={loc.id || ''}>
          {loc.name}
        </MenuItem>
      );
    } else if (isTrainDetails(loc)) {
      return (
        <MenuItem value={loc.id || ''} key={loc.id || ''}>
          {loc.trainNumber} - {loc.fromStation} - {loc.toStation}
        </MenuItem>
      );
    } else {
      return null;
    }
  });
};
