import {ReservationTypeDto} from 'src/services/models';
import {MissionSummaryDto} from 'src/services/models/mission-summary-dto';
import {findHeaviestAssistanceType, highestAmountOfTravelers} from './util';
import FullAssistanceIcon from 'src/icons/FullAssistanceIcon';
import LightAssistanceIcon from 'src/icons/LightAssistanceIcon';
import {ContentItem} from '../MissionItem.styles';
import AdultIcon from 'src/icons/AdultIcon';
import ChildIcon from 'src/icons/ChildIcon';
import SeniorIcon from 'src/icons/SeniorIcon';
import {assistanceIconStyles} from './constants';

export const AssistanceType: React.FC<{mission: MissionSummaryDto}> = ({mission}) => {
  if (
    mission.reservationType === ReservationTypeDto.Disabled ||
    (mission.traveler && mission.traveler.disableds && mission.traveler.disableds > 0)
  ) {
    return (
      <>
        {findHeaviestAssistanceType(mission.traveler?.fullAssistances, mission.traveler?.lightAssistances).variant ===
        'FULL' ? (
          <FullAssistanceIcon style={assistanceIconStyles} />
        ) : (
          <LightAssistanceIcon style={assistanceIconStyles} />
        )}
        <ContentItem sx={{fontSize: '11.2px'}} color="#333333">
          {findHeaviestAssistanceType(mission.traveler?.fullAssistances, mission.traveler?.lightAssistances).amount}
        </ContentItem>
      </>
    );
  }

  const highestAmountType = highestAmountOfTravelers(
    mission.traveler?.adults,
    mission.traveler?.children,
    mission.traveler?.seniors,
  )[0].variant;

  return (
    <>
      {highestAmountType === 'ADULTS' ? (
        <AdultIcon />
      ) : highestAmountType === 'CHILDREN' ? (
        <ChildIcon />
      ) : (
        <SeniorIcon />
      )}
      <ContentItem sx={{fontSize: '11.2px'}} color="#333333">
        {
          highestAmountOfTravelers(mission.traveler?.adults, mission.traveler?.children, mission.traveler?.seniors)[0]
            .amount
        }
      </ContentItem>
    </>
  );
};
