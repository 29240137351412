import React from 'react';

const AssistIcon: React.FC<{disabled: boolean}> = ({disabled}) => (
  <svg id="ic_assist" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      id="Path_5879"
      data-name="Path 5879"
      d="M7,12a3.9,3.9,0,0,1,.31-1.55,4.18,4.18,0,0,1,.86-1.28,4.25,4.25,0,0,1,1.27-.86A3.85,3.85,0,0,1,11,8h1a2.89,2.89,0,0,1-2.12-.87A2.9,2.9,0,0,1,9,5a2.86,2.86,0,0,1,.88-2.12A2.86,2.86,0,0,1,12,2a3,3,0,0,1,0,6h1a3.9,3.9,0,0,1,1.55.31,4,4,0,0,1,2.14,2.14A3.9,3.9,0,0,1,17,12H16a3,3,0,0,0-3-3H11a3,3,0,0,0-3,3Zm3-7a2,2,0,0,0,3.41,1.42A1.92,1.92,0,0,0,14,5a2,2,0,0,0-2-2,1.92,1.92,0,0,0-1.42.59A2,2,0,0,0,10,5Z"
      fill={disabled ? '#a8a8a8' : '#333333'}
    />
    <path
      id="Path_5262"
      data-name="Path 5262"
      d="M2.32,14.45h0A.46.46,0,0,0,2,15a.44.44,0,0,0,.56.29c.68-.2,1.36-.37,2.05-.5a7.61,7.61,0,0,1,1.65-.14l.35,5.74-2.22.71h0a.451.451,0,1,0,.27.86l2.56-.82a.45.45,0,0,0,.31-.46V20.6a41.64,41.64,0,0,0,7.57-.31h.05a4.28,4.28,0,0,0,1.23-.78c.49-.38,1.09-.88,1.72-1.42,1.26-1.1,2.67-2.38,3.73-3.29a.46.46,0,0,0,.14-.48,1.55,1.55,0,0,0-.69-.88,2.16,2.16,0,0,0-.9-.25,3.37,3.37,0,0,0-1.05.08h0a9.55,9.55,0,0,0-2.8,1.57,1.56,1.56,0,0,0-1-.94,3.8,3.8,0,0,0-1.34-.16l-3.46.16h-.12a.44.44,0,0,0-.31.45.43.43,0,0,0,.47.42l3.47-.15a2.56,2.56,0,0,1,1,.11.61.61,0,0,1,.46.39l.08.27c-.33.25-.66.49-1,.71l-4.41.48h0a.45.45,0,1,0,.14.89L15,17a.37.37,0,0,0,.2-.07c1.5-1,2.91-2.34,4.38-2.79a3.17,3.17,0,0,1,.75-.06,1.34,1.34,0,0,1,.52.14.36.36,0,0,1,.11.14c-1,.91-2.28,2-3.43,3-.62.55-1.2,1-1.68,1.41a7,7,0,0,1-.92.61,42.08,42.08,0,0,1-7.44.3l-.25-4.13a.17.17,0,0,1,0-.14,1.28,1.28,0,0,1,.37-.29A2.26,2.26,0,0,1,8.07,15l.85-.05a.451.451,0,0,0-.05-.9l-1,.06h0a4.23,4.23,0,0,0-.62.25H7.18v-.19a.46.46,0,0,0-.35-.42,6.52,6.52,0,0,0-2.33.13,19.279,19.279,0,0,0-2.15.53ZM4.4,15.8v.93a.07.07,0,0,0,0,.05.444.444,0,1,0,.88-.11V15.7a.443.443,0,1,0-.88.1Z"
      fill={disabled ? '#a8a8a8' : '#333333'}
    />
    <rect id="Rectangle_2075" data-name="Rectangle 2075" width="24" height="24" fill="none" />
  </svg>
);

export default AssistIcon;
