// @ts-nocheck
import React, {useEffect, useState} from 'react';
import i18next from 'i18next';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import greenTick from '../../components/images/ic_tick-green.svg';
import setTranslationLanguage from 'src/components/MissionList/LocaleUtils';
import {CacheContext} from 'src/context/cache/cache.store';
import {DistrictDto} from 'src/services/models';
import {Check} from '@mui/icons-material';

const groupBy = (list, key) =>
  list.reduce((hash, obj) => ({...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj)}), {});

const StationList = ({data, stationRowClicked, selectIds, selectedDistrictId, setSelectedDistrictId}) => {
  const stationData = data;
  const [selectedStations, setStations] = useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState('');

  const {districts} = React.useContext(CacheContext);

  const languageTranslation = localStorage.getItem('language') ? localStorage.getItem('language') : 'En';

  const handleClick = index => {
    if (selectedIndex === index) {
      setSelectedIndex('');
    } else {
      setSelectedIndex(index);
    }
  };

  useEffect(() => {
    setStations(selectIds);
  }, [selectIds]);

  const regionData = groupBy(
    stationData,
    i18next.language === 'En' ? 'districtNameEn' : i18next.language === 'Fr' ? 'districtNameFr' : 'districtNameNl',
  );

  const onStationHandler = (statId, isDistrict) => {
    if (isDistrict) {
      const region = regionData[isDistrict];
      //Handle local district set fo rindicator
      const nameWithId = (districts as DistrictDto[]).map(el => ({id: el.id, name: el.name[0].text}));
      const idFromName = nameWithId.find(el => el.name === isDistrict);
      setSelectedDistrictId(idFromName?.id);
      const regionIds = region.map(e => e.uicCode);
      setStations(regionIds);
      stationRowClicked(regionIds);
    } else {
      const mockStation = [...selectedStations];
      if (mockStation.indexOf(statId) !== -1) {
        mockStation.splice(mockStation.indexOf(statId), 1);
      } else {
        mockStation.push(statId);
      }
      setStations(mockStation);
      stationRowClicked(mockStation);
    }
  };

  return (
    <div className="panel-station">
      <List sx={{width: '100%', bgcolor: 'background.paper'}} component="nav" aria-labelledby="nested-list-subheader">
        {Object.keys(regionData).map((key, idx) => (
          <div key={idx}>
            <ListItemButton className="panel-header-station">
              {(districts as DistrictDto[])
                .find(el => el.id === selectedDistrictId)
                ?.name?.some(el => el.text === key) && <Check color="#25336C" />}
              <ListItemText
                primary={key}
                style={{
                  color: (districts as DistrictDto[])
                    .find(el => el.id === selectedDistrictId)
                    ?.name?.some(el => el.text === key)
                    ? '#25336C'
                    : 'black',
                }}
                onClick={() => onStationHandler(undefined, key)}
              />
              {idx === selectedIndex ? (
                <ExpandLess onClick={() => handleClick(idx)} />
              ) : (
                <ExpandMore onClick={() => handleClick(idx)} />
              )}
            </ListItemButton>

            <Collapse in={idx === selectedIndex} timeout="auto" unmountOnExit>
              {regionData[key]
                .sort((a, b) => {
                  const v1 = setTranslationLanguage(a, 'name', languageTranslation);
                  const v2 = setTranslationLanguage(b, 'name', languageTranslation);
                  return v1.localeCompare(v2);
                })
                .map(station => (
                  <List key={station.uicCode} component="div" disablePadding>
                    <ListItemButton sx={{pl: 2}} onClick={() => onStationHandler(station.uicCode)}>
                      {selectedStations.includes(station.uicCode) && (
                        <img src={greenTick} alt="img-tick" className="img-style" />
                      )}
                      <ListItemText primary={setTranslationLanguage(station, 'name', languageTranslation)} />
                    </ListItemButton>
                  </List>
                ))}
            </Collapse>
          </div>
        ))}
      </List>
    </div>
  );
};

export default StationList;
