import type {i18n} from 'i18next';
import {LanguageDto, StationDto} from 'src/services/models';

export const getStationFromId = (i18n: i18n, id: string, stations: StationDto[]) => {
  let activeLanguage = LanguageDto.English;

  if (i18n.language.toLocaleUpperCase() === 'NL') activeLanguage = LanguageDto.Dutch;
  if (i18n.language.toLocaleUpperCase() === 'FR') activeLanguage = LanguageDto.French;
  if (i18n.language.toLocaleUpperCase() === 'EN') activeLanguage = LanguageDto.English;

  const station = stations.find(el => el.id === id);
  const trans = station?.name?.find(el => el.language === activeLanguage);

  return trans?.text;
};
