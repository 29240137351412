import moment from 'moment';
import {Reducer} from 'react';
import {CreateClientDto, CreateGroupDto, LanguageDto, StationDto} from 'src/services/models';
import {GeoDurationRequestDto} from 'src/services/models/geo-duration-request-dto';
import {GeoDurationResponseDto} from 'src/services/models/geo-duration-response-dto';
import {GroupDto} from 'src/services/models/group-dto';
import {TaxiDto} from 'src/services/models/taxi-dto';

/*
---------------------------------
        Reducer for CreateTaxiMission
        includes:
            -Company (list)
            -Phone number (reference by list)
            -Departure station
            -Arrival Station
            -Departure pickup
            -Arrival pickup
            -note
            -Time (estimated travel time)

---------------------------------
*/

export interface TaxiCreateTaxiMission {
  company: TaxiDto | undefined;
  phoneNumber: string | undefined | null;
  departureStation: StationDto | undefined;
  arrivalStation: StationDto | undefined;
  departurePickup: string;
  arrivalPickup: string;
  note: string;
  travelTime: GeoDurationResponseDto | undefined;
  client: CreateClientDto;
  departureTime: moment.Moment;
  arrivalTime: moment.Moment;
  assistances: Array<CreateGroupDto>;
}

export const intialTaxiCreateMission: TaxiCreateTaxiMission = {
  company: undefined,
  phoneNumber: undefined,
  departureStation: undefined,
  arrivalStation: undefined,
  departurePickup: '',
  arrivalPickup: '',
  note: '',
  travelTime: undefined,
  departureTime: moment(),
  arrivalTime: moment(),
  client: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    language: LanguageDto.Dutch,
  },
  assistances: [
    {
      quantity: 1,
      typeId: 'pmr-to',
    },
  ],
};

export enum TaxiCreateTaxiMissionActionType {
  COMPANY = 'COMPANY',
  DEPARTURE_STATION = 'DEPARTURE_STATION',
  ARRIVAL_STATION = 'ARRIVAL_STATION',
  DEPARTURE_PICKUP = 'DEPARTURE_PICK',
  ARRIVAL_PICKUP = 'ARRIVAL_PICK',
  NOTE = 'NOTE',
  DURATION = 'DURATION',
  NAME = 'NAME',
  LASTNAME = 'LASTNAME',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  LANGUAGE = 'LANGUAGE',
  DEP_TIME = 'DEP_TIME',
  ADD_ASSISTANCES = 'ADD_ASSISTANCES',
  DEL_ASSISTANCES = 'DEL_ASSISTANCES',
  ASSISTANCE_TYPE = 'ASSISTANCE_TYPE',
  ASSISTANCE_QUANT = 'ASSISTANCE_QUANT',
}

export type TaxiCreateTaxiMissionActions =
  | {
      type: TaxiCreateTaxiMissionActionType.COMPANY;
      payload: TaxiDto | undefined;
    }
  | {type: TaxiCreateTaxiMissionActionType.DEPARTURE_STATION; payload: StationDto | undefined}
  | {type: TaxiCreateTaxiMissionActionType.ARRIVAL_STATION; payload: StationDto | undefined}
  | {type: TaxiCreateTaxiMissionActionType.DEPARTURE_PICKUP; payload: string}
  | {type: TaxiCreateTaxiMissionActionType.ARRIVAL_PICKUP; payload: string}
  | {type: TaxiCreateTaxiMissionActionType.NOTE; payload: string}
  | {type: TaxiCreateTaxiMissionActionType.DURATION; payload: undefined | GeoDurationResponseDto}
  | {type: TaxiCreateTaxiMissionActionType.NAME; payload: string}
  | {type: TaxiCreateTaxiMissionActionType.LASTNAME; payload: string}
  | {type: TaxiCreateTaxiMissionActionType.PHONE; payload: string}
  | {type: TaxiCreateTaxiMissionActionType.EMAIL; payload: string}
  | {type: TaxiCreateTaxiMissionActionType.LANGUAGE; payload: LanguageDto}
  | {type: TaxiCreateTaxiMissionActionType.DEP_TIME; payload: {dep: moment.Moment; arr: moment.Moment}}
  | {type: TaxiCreateTaxiMissionActionType.ADD_ASSISTANCES; payload: GroupDto}
  | {type: TaxiCreateTaxiMissionActionType.DEL_ASSISTANCES; payload: number}
  | {type: TaxiCreateTaxiMissionActionType.ASSISTANCE_TYPE; payload: {id: string; index: number}}
  | {type: TaxiCreateTaxiMissionActionType.ASSISTANCE_QUANT; payload: {amount: number; index: number}};

export const CreateTaxiMissionReducer: Reducer<TaxiCreateTaxiMission, TaxiCreateTaxiMissionActions> = (
  state,
  action,
) => {
  switch (action.type) {
    case TaxiCreateTaxiMissionActionType.COMPANY:
      if (action.payload) {
        return {
          ...state,
          company: action.payload,
          phoneNumber: action.payload.phoneNumber,
        };
      }
      //No taxi company
      return {...state, company: undefined, phoneNumber: undefined, travelTime: undefined};
    case TaxiCreateTaxiMissionActionType.DEPARTURE_STATION:
      if (action.payload) {
        return {
          ...state,
          departureStation: action.payload,
        };
      }
      //No dep
      return {...state, departureStation: undefined};
    case TaxiCreateTaxiMissionActionType.ARRIVAL_STATION:
      if (action.payload) {
        return {
          ...state,
          arrivalStation: action.payload,
        };
      }
      //No dep
      return {...state, arrivalStation: undefined};
    //CALCULATE TRAVEL DURATION
    case TaxiCreateTaxiMissionActionType.DEPARTURE_PICKUP:
      if (action.payload) {
        return {
          ...state,
          departurePickup: action.payload,
        };
      }
      //No dep
      return {...state, departurePickup: ''};
    case TaxiCreateTaxiMissionActionType.ARRIVAL_PICKUP:
      if (action.payload) {
        return {
          ...state,
          arrivalPickup: action.payload,
        };
      }
      //No dep
      return {...state, arrivalPickup: ''};
    case TaxiCreateTaxiMissionActionType.NOTE:
      return {...state, note: action.payload};
    case TaxiCreateTaxiMissionActionType.DURATION:
      return {...state, travelTime: action.payload};
    case TaxiCreateTaxiMissionActionType.NAME:
      if (action.payload) {
        return {...state, client: {...state.client, firstName: action.payload}};
      }
      return {...state, client: {...state.client, firstName: ''}};
    case TaxiCreateTaxiMissionActionType.LASTNAME:
      if (action.payload) {
        return {...state, client: {...state.client, lastName: action.payload}};
      }
      return {...state, client: {...state.client, lastName: ''}};
    case TaxiCreateTaxiMissionActionType.PHONE:
      if (action.payload) {
        return {...state, client: {...state.client, phoneNumber: action.payload}};
      }
      return {...state, client: {...state.client, phoneNumber: ''}};
    case TaxiCreateTaxiMissionActionType.EMAIL:
      if (action.payload) {
        return {...state, client: {...state.client, email: action.payload}};
      }
      return {...state, client: {...state.client, email: ''}};
    case TaxiCreateTaxiMissionActionType.LANGUAGE:
      if (action.payload) {
        return {...state, client: {...state.client, language: action.payload}};
      }
      return {...state, client: {...state.client, language: LanguageDto.Dutch}};
    case TaxiCreateTaxiMissionActionType.DEP_TIME:
      if (action.payload) {
        return {...state, departureTime: action.payload.dep, arrivalTime: action.payload.arr};
      }
      return {...state, departureTime: moment(), arrivalTime: moment()};
    case TaxiCreateTaxiMissionActionType.ADD_ASSISTANCES:
      if (action.payload) {
        const newAssistances = state.assistances;
        newAssistances.push(action.payload);
        return {...state, assistances: [...newAssistances]};
      }
      return {...state};
    case TaxiCreateTaxiMissionActionType.DEL_ASSISTANCES:
      if (action.payload) {
        const newAssistances = state.assistances;
        const filtered = newAssistances.filter((_el, ind) => ind !== action.payload);
        return {...state, assistances: [...filtered]};
      }
      return {...state};
    case TaxiCreateTaxiMissionActionType.ASSISTANCE_TYPE:
      if (action.payload) {
        const newAssistances = state.assistances;
        newAssistances[action.payload.index] = {
          quantity: newAssistances[action.payload.index].quantity,
          typeId: action.payload.id,
        };
        return {...state, assistances: [...newAssistances]};
      }
      return {...state};
    case TaxiCreateTaxiMissionActionType.ASSISTANCE_QUANT:
      if (action.payload) {
        const newAssistances = state.assistances;
        newAssistances[action.payload.index] = {
          typeId: newAssistances[action.payload.index].typeId,
          quantity: action.payload.amount,
        };
        return {...state, assistances: [...newAssistances]};
      }
      return {...state};
    default:
      return {...state};
  }
};
