import {Box} from '@mui/material';
import React from 'react';
import {ChangeRouteContext} from '../ChangeRoute.store';
import {calculateDurationOfTime} from '../util/CalculateDurationOfRoute';
import CloseIcon from '@mui/icons-material/Close';
import {OriginalRouteStepMap, originalRouteStepMapper} from '../util/OriginalRouteStepMap';
import {OriginalRouteStep} from './OriginalRouteStep';
import {useTranslation} from 'react-i18next';

export const OriginalRouteView = () => {
  const {
    selectedOriginalRoute,
    setIsIndividualSelected,
    fetchSingleProposal,
    isIndividualSelected,
    fetchAlternativeRoutes,
    selectedIndividualProposal,
    setSelectedIndividualProposal,
  } = React.useContext(ChangeRouteContext);

  if (selectedOriginalRoute == null || selectedOriginalRoute.length == 0) {
    return (
      <Box sx={{backgroundColor: 'white', padding: '25px', display: 'grid', placeItems: 'center'}}>
        <Box>
          <CloseIcon sx={{color: 'grey', fontSize: '68px'}} />
        </Box>
      </Box>
    );
  }

  const handleSelectionofIndividualProposal = (ind: number, proposal: OriginalRouteStepMap) => {
    //Deselect
    if (selectedIndividualProposal === ind) {
      setIsIndividualSelected(false);
      setSelectedIndividualProposal(null);
      fetchAlternativeRoutes(selectedOriginalRoute);
    } else {
      setIsIndividualSelected(true);
      setSelectedIndividualProposal(ind);
      fetchSingleProposal(proposal);
    }
  };

  const {t} = useTranslation();

  return (
    <div
      style={{
        overflowY: 'scroll',
        minHeight: 'calc(100vh - 64px)',
        maxHeight: 'calc(100vh - 64px)',
        backgroundColor: 'white',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          padding: '25px',
          paddingBottom: '0px',
          backgroundColor: 'white',
        }}
      >
        <h4>{t('cr.original-proposition')}</h4>
        <p style={{textAlign: 'right'}}>
          {calculateDurationOfTime(
            selectedOriginalRoute[0].departureTime,
            selectedOriginalRoute.slice(-1)[0].arrivalTime,
          )}
          , {selectedOriginalRoute.length > 1 ? selectedOriginalRoute.length - 1 : ''}{' '}
          {selectedOriginalRoute.length === 1
            ? t('cr.no-transfers')
            : selectedOriginalRoute.length === 2
            ? t('cr.transfer')
            : t('cr.transfers')}
        </p>
      </Box>

      {selectedOriginalRoute.map((el, ind, arr) => (
        <Box
          key={`${el.id}+${ind}`}
          sx={{
            backgroundColor: 'white',
            padding: '25px',
            paddingTop: '0px',
            paddingBottom: '10px',
            marginBottom: ind === arr.length - 1 ? '10px' : '0px',
            position: 'relative',
            zIndex: 0,
          }}
        >
          <OriginalRouteStep
            withHover={false}
            route={originalRouteStepMapper(el)}
            isSelected={ind === selectedIndividualProposal && isIndividualSelected}
            handleClick={handleSelectionofIndividualProposal}
            ind={ind}
            withDelete={undefined}
          />
        </Box>
      ))}
    </div>
  );
};
