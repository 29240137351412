// @ts-nocheck
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function Inventory(props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
        <g id="IC_Inventory" transform="translate(12 12)">
          <g id="Group_8958" data-name="Group 8958" transform="translate(-12 -12)">
            <rect id="Rectangle_2006" data-name="Rectangle 2006" width="24" height="24" fill="none" />
            <path
              id="Path_5715"
              data-name="Path 5715"
              d="M18.25,4.5A1.2,1.2,0,0,1,19.5,5.75v15a1.232,1.232,0,0,1-.352.879,1.182,1.182,0,0,1-.9.371H5.75A1.267,1.267,0,0,1,4.5,20.75v-15a1.182,1.182,0,0,1,.371-.9A1.232,1.232,0,0,1,5.75,4.5H8.367A1.835,1.835,0,0,1,9.852,3.25a2.514,2.514,0,0,1,.878-.9A2.355,2.355,0,0,1,12,2a2.54,2.54,0,0,1,2.187,1.25,1.78,1.78,0,0,1,.9.41,1.758,1.758,0,0,1,.547.84Zm0,1.25H15.633c-.052.13-.1.254-.156.371a1.779,1.779,0,0,1-.235.371A1.221,1.221,0,0,1,14.227,7H9.773A1.186,1.186,0,0,1,8.8,6.492a1.307,1.307,0,0,1-.274-.371c-.052-.117-.1-.241-.156-.371H5.75v15h12.5ZM8.875,9.5a.552.552,0,0,1,.625.625.633.633,0,0,1-.176.43.614.614,0,0,1-.9,0,.633.633,0,0,1-.176-.43A.552.552,0,0,1,8.875,9.5Zm0,2.5a.552.552,0,0,1,.625.625.633.633,0,0,1-.176.43.614.614,0,0,1-.9,0,.633.633,0,0,1-.176-.43A.552.552,0,0,1,8.875,12Zm0,2.5a.552.552,0,0,1,.625.625.633.633,0,0,1-.176.43.614.614,0,0,1-.9,0,.633.633,0,0,1-.176-.43A.552.552,0,0,1,8.875,14.5Zm1.25-10a.552.552,0,0,0-.625.625.633.633,0,0,0,.176.43.578.578,0,0,0,.449.2h3.75a.578.578,0,0,0,.449-.2.633.633,0,0,0,.176-.43.552.552,0,0,0-.625-.625H13.25A1.2,1.2,0,0,0,12,3.25a1.232,1.232,0,0,0-.879.352,1.182,1.182,0,0,0-.371.9Zm5,5a.552.552,0,0,1,.625.625.633.633,0,0,1-.176.43.578.578,0,0,1-.449.195h-3.75a.671.671,0,0,1-.625-.625.578.578,0,0,1,.195-.449.633.633,0,0,1,.43-.176Zm0,2.5a.552.552,0,0,1,.625.625.633.633,0,0,1-.176.43.578.578,0,0,1-.449.195h-3.75a.671.671,0,0,1-.625-.625.578.578,0,0,1,.195-.449.633.633,0,0,1,.43-.176Zm0,2.5a.552.552,0,0,1,.625.625.633.633,0,0,1-.176.43.578.578,0,0,1-.449.195h-3.75a.671.671,0,0,1-.625-.625.578.578,0,0,1,.195-.449.633.633,0,0,1,.43-.176Z"
              fill={props.color ? '#25336C' : '#262626'}
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
