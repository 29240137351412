import {
  timelineItemClasses,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
  Timeline,
  TimelineOppositeContent,
  timelineOppositeContentClasses,
} from '@mui/lab';
import {Box, Button, Chip, Dialog, Divider} from '@mui/material';
import moment from 'moment';
import {CarIcon} from './NewRoutePlanner';
import {GeoDurationResponseDto} from 'src/services/models/geo-duration-response-dto';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import React from 'react';
import {JourneySteppers} from '../steppers/steppers';
import {useTranslation} from 'react-i18next';

export const TaxiProposal = ({
  departureName,
  taxiCompany,
  travelTime,
  departureComment,
  arrivalComment,
  arrival,
  onProposal = false,
  departureTime,
  arrivalTime,
  withDelete,
  ind,
}: {
  departureName: string;
  taxiCompany: string;
  travelTime: GeoDurationResponseDto;
  departureComment: string;
  arrival: string;
  arrivalComment: string;
  onProposal: boolean;
  departureTime?: string;
  arrivalTime?: string;
  withDelete: undefined | ((ind: number) => void);
  ind: number;
}) => {
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  const {t} = useTranslation();

  return (
    <Box
      sx={{
        border: 'solid 1px #EBEBEB',
        borderRadius: '5px',
        width: onProposal ? '100%' : '400px',
      }}
    >
      {withDelete !== undefined && (
        <Button
          variant="text"
          startIcon={<DeleteForeverIcon sx={{color: 'red'}} />}
          onClick={() => setShowDeleteDialog(true)}
          sx={{position: 'absolute', top: '10px', right: '5px', zIndex: 99}}
        />
      )}
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: onProposal ? 0.05 : 0,
          },
        }}
      >
        <TimelineItem>
          {onProposal && (
            <TimelineOppositeContent>
              {' '}
              <Chip
                sx={{
                  backgroundColor: '#C67E1E',
                  color: 'white',
                  borderRadius: '5px',
                }}
                label={departureTime}
              />
            </TimelineOppositeContent>
          )}
          <TimelineSeparator>
            <TimelineDot color="warning" />
            <TimelineConnector color="warning" />
          </TimelineSeparator>
          <TimelineContent>
            <Box>
              <p style={{fontWeight: 'bold', fontSize: '14px'}}>{departureName}</p>
              <Box
                sx={{
                  display: 'grid',
                  padding: '10px',
                  gap: '20px',
                  gridTemplateColumns: '40px 1fr',
                  backgroundColor: '#F9F1E7',
                  height: '72px',
                  borderRadius: '8px',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'grid',
                    placeItems: 'center',
                    backgroundColor: 'white',
                    width: '40px',
                    height: '40px',
                  }}
                >
                  <CarIcon />
                </Box>
                <Box>
                  <p style={{margin: '0px'}}>
                    <span style={{color: '#C67E1E', fontWeight: 'bold'}}>{t('cr.taxi-requested')}</span>
                  </p>
                  <p style={{margin: '0px'}}>{taxiCompany}</p>
                </Box>
              </Box>
              <Box>
                <p style={{marginTop: '10px'}}>
                  <b>
                    {t('cr.estimate')}:{' '}
                    {travelTime.timeInSeconds === 0 || travelTime.timeInSeconds === undefined
                      ? '--:--'
                      : moment.utc((travelTime.timeInSeconds || 0) * 1000).format('HH:mm:ss')}
                  </b>
                </p>
                <Divider orientation="horizontal" sx={{width: '100%', marginBottom: '10px'}} />
                <p>
                  {t('cr.meeting')}: {departureComment}
                </p>
              </Box>
            </Box>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          {onProposal && (
            <TimelineOppositeContent>
              <Chip
                sx={{
                  backgroundColor: '#C67E1E',
                  color: 'white',
                  borderRadius: '5px',
                }}
                label={arrivalTime}
              />
            </TimelineOppositeContent>
          )}
          <TimelineSeparator>
            <TimelineDot color="warning" />
          </TimelineSeparator>
          <TimelineContent>
            <Box>
              <p style={{fontWeight: 'bold', fontSize: '14px'}}>{arrival}</p>
              <p>
                {t('cr.meeting')}: {arrivalComment}
              </p>
            </Box>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
      <Dialog open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
        <Box sx={{textAlign: 'center', padding: '20px 40px', width: '328px'}}>
          <p>
            <b>{t('cr.remove-proposal')}</b>
          </p>
          <p>
            {t('cr.sure')} <br /> {t('cr.delete')}
          </p>
          <Box
            sx={{
              border: 'solid 1px black',
              borderRadius: '8px',
              maxWidth: '200px',
              margin: '0px auto',
              textAlign: 'left',
              padding: '15px 0px 15px 15px',
            }}
          >
            <JourneySteppers start={departureName || ''} end={arrival || ''} />
          </Box>
          <Box sx={{display: 'grid', gridTemplateColumns: '1fr 1fr', width: '200px', margin: '15px auto'}}>
            <Button variant="text" onClick={() => setShowDeleteDialog(false)}>
              {t('back-btn')}
            </Button>
            <Button variant="contained" onClick={() => withDelete && withDelete(ind)}>
              {t('check-in.btn-yes')}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};
