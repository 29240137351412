import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';

type CardBoxProps = {
  isAssigned?: boolean;
  isPMR?: boolean;
  isSelected?: boolean;
};
export const CardBox = styled(Paper)((props: CardBoxProps) => ({
  minHeight: 140,
  height: `${props.isAssigned ? 184 : 140}`,
  borderTop: `6px solid ${props.isPMR ? '#FFCF00' : '#00AAAB'}`,
  borderBottom: `${props.isSelected ? `4px solid #25336C` : null}`,
  borderRadius: `${props.isSelected ? `4px;` : null}`,
  flexGrow: 1,
  margin: '6px 0',
}));

export const CardContent = styled(Grid)({
  width: '100%',
  height: '100%',
  padding: '10px 0',
  minHeight: 140,
});

export const CardItem = styled(Grid)({
  paddingTop: 14,
});

export const CardItemCentered = styled(Stack)({
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: 0,
});

export const CardItemContent = styled(Stack)(
  (props: {itemCentered?: boolean; contentCentered?: boolean; margin?: number; spacing?: number; grow?: boolean}) => ({
    justifyContent: 'flex-start',
    alignItems: `${props.itemCentered ? 'center' : 'flex-start'}`,
    alignContent: `${props.contentCentered ? 'center' : null}`,
    margin: `10px 0 10px ${props.margin}px`,
    flexGrow: `${props.grow ? 1 : null}`,
  }),
);

export const ContentItem = styled(Typography)(
  (props: {
    size?: number;
    weight?: number;
    color?: string;
    grow?: boolean;
    radius?: number;
    backgroud?: string;
    padding?: string;
    border?: string;
  }) => ({
    fontSize: `${props.size ? `${props.size}rem` : '0.81rem'}`,
    fontWeight: `${props.weight ? props.weight : 600}`,
    color: `${props.color ? props.color : 'black'}`,
    flex: `${props.grow ? 1 : null}`,
    borderRadius: `${props.radius ? `${props.radius}px` : 0}`,
    backgroundColor: `${props.backgroud ? props.backgroud : 'white'}`,
    padding: `${props.padding ? props.padding : 0}`,
    border: `${props.border ? props.border : null}`,
  }),
);

export const leftPanel = {
  paddingLeft: 8,
  paddingRight: 0,
  paddingTop: 0,
};

export const rightPanel = {
  paddingLeft: 0,
  paddingRight: 8,
  paddingTop: 0,
};
