// @ts-nocheck

import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function CheckinOut(props) {
  return (
    <SvgIcon {...props}>
      <svg id="ic_checkin-out" width="24" height="24" viewBox="0 0 24 24" {...props}>
        <g id="Group_8139" data-name="Group 8139">
          <rect id="Rectangle_1732" data-name="Rectangle 1732" width="24" height="24" fill="none" />
        </g>
        <g id="Group_8142" data-name="Group 8142" transform="translate(0 1)">
          <g id="Group_8141" data-name="Group 8141" transform="translate(3 1)">
            <g id="Group_8140" data-name="Group 8140">
              <path
                id="Path_5409"
                data-name="Path 5409"
                d="M14.429,1H8.714V2.9h5.714Zm-3.81,12.381h1.9V7.667h-1.9Zm7.648-6.3,1.352-1.352A10.522,10.522,0,0,0,18.276,4.39L16.924,5.743a8.571,8.571,0,1,0,1.343,1.343Zm-6.7,12.01a6.667,6.667,0,1,1,6.667-6.667A6.662,6.662,0,0,1,11.571,19.1Z"
                transform="translate(-3 -1)"
              />
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
