import React from 'react';

const TrainManagerIcon = () => (
  <svg id="vector" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="#000000"
      d="M9 11.42a2.5 2.5 0 1 0 2.5 2.5A2.5 2.5 0 0 0 9 11.42Zm0 4.22a1.72 1.72 0 1 1 1.223 -0.5A1.73 1.73 0 0 1 9 15.64Z"
      id="path_0"
    />
    <path
      fill="#000000"
      d="M22.38 8.43A0.52 0.52 0 0 0 22 8.26H15.2a0.5 0.5 0 0 0 -0.5 0.5V10H13.55V8.76a0.5 0.5 0 0 0 -0.5 -0.5H9a5.66 5.66 0 0 0 -5.34 3.82H3.4a1.87 1.87 0 1 0 0 3.73h0.26a5.65 5.65 0 0 0 11 -1.47l7.25 -2.8a0.47 0.47 0 0 0 0.31 -0.39l0.31 -2.26A0.51 0.51 0 0 0 22.38 8.43Zm-19 6.35a0.84 0.84 0 0 1 -0.88 -0.86 0.87 0.87 0 0 1 0.87 -0.87h0v0.09a4.93 4.93 0 0 0 -0.06 0.78 5.12 5.12 0 0 0 0.06 0.78Zm17.85 -4.12L14 13.46a0.51 0.51 0 0 0 -0.32 0.47 4.65 4.65 0 0 1 -9.2 1v-0.17a4.58 4.58 0 0 1 -0.08 -0.83 4.8 4.8 0 0 1 0.08 -0.85v-0.15A4.68 4.68 0 0 1 9 9.26h3.53v1.22a0.51 0.51 0 0 0 0.5 0.5H15.2a0.5 0.5 0 0 0 0.5 -0.5V9.26h5.73Z"
      id="path_1"
    />
    <path
      fill="#000000"
      d="M14.16 7.07a0.5 0.5 0 0 0 0.5 -0.5V4.93a0.5 0.5 0 0 0 -1 0V6.57A0.5 0.5 0 0 0 14.16 7.07Z"
      id="path_2"
    />
    <path
      fill="#000000"
      d="M15.22 7.83a0.45 0.45 0 0 0 0.27 0.08 0.49 0.49 0 0 0 0.42 -0.23L16.8 6.3a0.5 0.5 0 0 0 -0.8 -0.54l-0.88 1.38A0.5 0.5 0 0 0 15.22 7.83Z"
      id="path_3"
    />
    <path
      fill="#000000"
      d="M12.36 7.68a0.51 0.51 0 0 0 0.42 0.23 0.43 0.43 0 0 0 0.27 -0.08 0.49 0.49 0 0 0 0.15 -0.69l-0.88 -1.38a0.5 0.5 0 1 0 -0.84 0.54Z"
      id="path_4"
    />
  </svg>
);

export default TrainManagerIcon;
