import {Grid} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ReservationTypeDto} from 'src/services/models';
import {MissionTypeDto} from 'src/services/models/mission-type-dto';

export const TravelHeader: React.FC = () => {
  const {t} = useTranslation();

  return (
    <Grid container sx={{padding: '0px 8px', margin: '15px 0px 5px 0px'}}>
      <Grid item xs={3.2} sx={{marginRight: '110px'}}>
        <h6>{t('mission-details.category-itinerary')}</h6>
      </Grid>
      <Grid item xs={1.5}>
        <h6>{t('reservation.form.train.label')}</h6>
      </Grid>
      <Grid item xs={1.5}>
        <h6>{t('mission-details-v2.journey.assist')}</h6>
      </Grid>
      <Grid item xs={1.5}>
        <h6>{t('trip-details-v2.details.wheelchair')}</h6>
      </Grid>
    </Grid>
  );
};
