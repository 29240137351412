const NoteIconNew: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24.575" viewBox="0 0 26 24.575" {...props}>
    <g id="ic_note_new" transform="translate(-1304 -141)">
      <path
        id="comment_FILL0_wght300_GRAD0_opsz24"
        d="M103.948,207.843h12.105v-1.579H103.948Zm0-3.158h12.105v-1.579H103.948Zm0-3.158h12.105v-1.579H103.948ZM120,215.515l-3.725-3.725H101.9a1.887,1.887,0,0,1-1.9-1.9V197.9a1.887,1.887,0,0,1,1.9-1.9H118.1a1.887,1.887,0,0,1,1.9,1.9ZM101.58,197.9v12.308h15.352l1.49,1.49V197.9a.312.312,0,0,0-.324-.324H101.9a.312.312,0,0,0-.324.324Zm0,0v0Z"
        transform="translate(1203.999 -49.94)"
        fill="#333"
      />
      <g id="Small_Badges" data-name="Small Badges" transform="translate(716 -433)">
        <circle
          id="Ellipse_1"
          data-name="Ellipse 1"
          cx="3"
          cy="3"
          r="3"
          transform="translate(608 574)"
          fill="#c27710"
        />
      </g>
    </g>
  </svg>
);

export default NoteIconNew;
