import React from 'react';
import {Box, Collapse, Divider, Grid, IconButton, Stack} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {JourneyItem} from './JourneyItem';
import {generateKey} from 'src/utils/generateKey';
import moment from 'moment';
import {CircularProgressWithLabel} from 'src/components/progress/CircularProgressWithLabel';
import {isStationPartOfDistrict} from '../util';
import {localStorageKeys} from 'src/utils/localStorageKeys';
import {CacheContext} from 'src/context/cache/cache.store';
import {ReservationsContext} from 'src/context/reservations/reservations.store';
import {DisabledTravelDto} from 'src/services/models/disabled-travel-dto';
import {SubPmrRoutes} from '../Pmr';

//Styles
import {travelItemStyles} from './TravelItem.styles';

type FirstAndLast = {
  station: string;
  train: string;
  time: string;
  uic: string;
  proposal: string;
  isOk: boolean | null | undefined;
};

export const TravelItem: React.FC<{
  travel: DisabledTravelDto;
  selectedIndex: number;
  variant: SubPmrRoutes;
  position: number;
  formattedCommonDate: string;
  isChangeRoute: boolean;
}> = ({travel, selectedIndex, variant, position, formattedCommonDate, isChangeRoute}) => {
  const {focusedReservation} = React.useContext(ReservationsContext);

  const [open, setOpen] = React.useState(false);

  const selectedStations: string = localStorage.getItem(localStorageKeys.SELECED_STATIONS) || '';

  const firstDeparture: FirstAndLast = travel.journeys
    ? {
        station: travel.journeys[0].departureName || '',
        train: travel.journeys[0].trainNumber?.toString() || '',
        time: travel.journeys[0].departureTime?.toString() || '',
        uic: travel.journeys[0].departureUicCode || '',
        proposal: travel.journeys[0].proposalId || '',
        isOk: travel.journeys[0].isDepartureOk,
      }
    : {station: '', train: '', time: '', uic: '', proposal: '', isOk: null};
  const lastArrival: FirstAndLast = travel.journeys
    ? {
        station: travel.journeys[travel.journeys.length - 1].arrivalName || '',
        train: travel.journeys[travel.journeys.length - 1].trainNumber?.toString() || '',
        time: travel.journeys[travel.journeys.length - 1].arrivalTime?.toString() || '',
        uic: travel.journeys[travel.journeys.length - 1].arrivalUicCode || '',
        proposal: travel.journeys[travel.journeys.length - 1].proposalId || '',
        isOk: travel.journeys[travel.journeys.length - 1].isArrivalOk,
      }
    : {station: '', train: '', time: '', uic: '', proposal: '', isOk: null};

  const doProgressCalculation = () => {
    let amountOfConfirmations = 0;
    let totalAmount = 0;
    travel.journeys?.forEach(j => {
      if (j.isArrivalOk || !j.withArrivalAssistance) {
        amountOfConfirmations++;
      }

      if (j.isDepartureOk || !j.withDepartureAssistance) {
        amountOfConfirmations++;
      }

      totalAmount = totalAmount + 2;
    });

    return 100 * (amountOfConfirmations / totalAmount);
  };

  const isFirstDisabled = firstDeparture.isOk !== null && !firstDeparture.isOk;
  const isLastDisabled = lastArrival.isOk !== null && !lastArrival.isOk;

  return (
    <Grid
      container
      sx={{
        padding: '8px 20px',
        height: '100%',
        backgroundColor: '#F9FBFD',
        border: 'solid 1px #ECECEC',
        borderRadius: '5px',
        alignItems: 'center',
      }}
    >
      <FirstDepartureItem
        firstDeparture={firstDeparture}
        isFirstDisabled={isFirstDisabled}
        selectedStations={selectedStations}
        commonDate={formattedCommonDate}
      />
      <Grid item xs sx={{display: 'grid', placeItems: 'center'}}>
        <CircularProgressWithLabel
          value={doProgressCalculation()}
          sx={{
            color: 'green',
            width: '35px',
            height: '35px',
            lineHeight: '44px',
            textAlign: 'center',
          }}
        />
      </Grid>
      <LastArrivalItem isLastDisabled={isLastDisabled} lastArrival={lastArrival} selectedStations={selectedStations} />
      <Grid item sx={{display: 'grid', placeItems: 'center', width: '45px'}}>
        <IconButton
          size="medium"
          onClick={() =>
            setOpen(prev => {
              return focusedReservation !== selectedIndex ? !prev : prev;
            })
          }
        >
          {open || focusedReservation === selectedIndex ? (
            <ArrowDropUpIcon sx={{color: 'black', transform: 'scale(1.8)'}} />
          ) : (
            <ArrowDropDownIcon sx={{color: 'black', transform: 'scale(1.8)'}} />
          )}
        </IconButton>
      </Grid>
      <Collapse in={open || focusedReservation === selectedIndex} sx={{width: '100%'}}>
        <Divider sx={{margin: '15px 0px', border: 'dashed 1px black'}} />
        <Box sx={{padding: '0px 0px 15px 0px'}}>
          <Stack spacing={1} sx={{width: '100%'}}>
            {travel.journeys?.map((el, ind) => (
              <JourneyItem
                journey={el}
                key={generateKey(ind.toString())}
                isInRefusalMode={focusedReservation === selectedIndex && !isChangeRoute}
              />
            ))}
          </Stack>
        </Box>
      </Collapse>
    </Grid>
  );
};

const FirstDepartureItem: React.FC<{
  selectedStations: string;
  firstDeparture: FirstAndLast;
  isFirstDisabled: boolean;
  commonDate: string;
}> = ({firstDeparture, isFirstDisabled, selectedStations, commonDate}) => {
  return (
    <Grid item xs={5} display={'flex'}>
      <span
        style={{
          ...travelItemStyles.itemTime,
          marginRight: '4px',
          textDecoration: isFirstDisabled ? 'line-through' : '',
          border:
            moment(firstDeparture.time).format('DD.MM.YYYY') === commonDate ? 'solid 2px black' : 'solid 2px #0069B4',
          color: moment(firstDeparture.time).format('DD.MM.YYYY') === commonDate ? 'black' : '#0069B4',
        }}
      >
        {moment(firstDeparture.time).format('DD.MM.YYYY')}
      </span>
      <span
        style={{
          ...travelItemStyles.itemTime,
          marginRight: '4px',
          textDecoration: isFirstDisabled ? 'line-through' : '',
          border: 'none',
        }}
      >
        {moment(firstDeparture.time).format('HH:mm')}
      </span>
      <b style={{...travelItemStyles.seperator, marginRight: '8px'}}>|</b>
      {isStationPartOfDistrict(selectedStations, firstDeparture.uic) ? (
        <b style={{display: 'flex', alignItems: 'center', textDecoration: isFirstDisabled ? 'line-through' : ''}}>
          {firstDeparture.station}
        </b>
      ) : (
        <p
          style={{
            margin: '0',
            display: 'flex',
            alignItems: 'center',
            textDecoration: isFirstDisabled ? 'line-through' : '',
          }}
        >
          {firstDeparture.station}
        </p>
      )}
    </Grid>
  );
};

const LastArrivalItem: React.FC<{
  selectedStations: string;
  lastArrival: FirstAndLast;
  isLastDisabled: boolean;
}> = ({lastArrival, selectedStations, isLastDisabled}) => {
  return (
    <Grid item xs={5} sx={{textAlign: 'right'}} display={'flex'} justifyContent={'flex-end'}>
      {isStationPartOfDistrict(selectedStations, lastArrival.uic) ? (
        <b style={{display: 'flex', alignItems: 'center', textDecoration: isLastDisabled ? 'line-through' : ''}}>
          {lastArrival.station}
        </b>
      ) : (
        <p
          style={{
            margin: '0',
            display: 'flex',
            alignItems: 'center',
            textDecoration: isLastDisabled ? 'line-through' : '',
          }}
        >
          {lastArrival.station}
        </p>
      )}
      <b style={{...travelItemStyles.seperator, marginLeft: '8px'}}>|</b>
      <span
        style={{
          ...travelItemStyles.itemTime,
          marginLeft: '4px',
          textDecoration: isLastDisabled ? 'line-through' : '',
          border: 'none',
        }}
      >
        {moment(lastArrival.time).format('HH:mm')}
      </span>
    </Grid>
  );
};
