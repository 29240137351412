import { FC } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  Box,
  Select,
  MenuItem,
  TextField,
  Typography,
  InputLabel,
  FormControl,
} from "@mui/material";
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';

import { EvacuationDto } from "src/services/models/evacuation-dto";
import { EvacuationVehicleTypeDto } from "src/services/models/evacuation-vehicle-type-dto";

interface CoiEvacuationFormTrainProps {
  values: EvacuationDto;
  handleChangeInput: (name: string, value: string | number) => void;
  defaultValues?: EvacuationDto;
  errors: string[];
  setErrors: (errors: string[]) => void;
}

export const CoiEvacuationFormTrain: FC<CoiEvacuationFormTrainProps> = ({ values, handleChangeInput, defaultValues, errors, setErrors }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <FormControl fullWidth>
        <InputLabel id="vehicle-type-label">{t("coi.incident-emergency-vehicle")}</InputLabel>
        <Select
          disabled
          labelId="vehicle-type-label"
          sx={{ marginBottom: '10px' }}
          value={values.type}
          defaultValue={defaultValues?.type}
          name="type"
          id="vehicle-type"
          label={t("coi.incident-emergency-vehicle")}
          onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
        >
          {Object.values(EvacuationVehicleTypeDto).map(type => (
            <MenuItem value={type} key={type}>{type}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        id="transportId"
        name="transportId"
        defaultValue={defaultValues?.transportId}
        label={`${values.type || defaultValues?.type} ID`}
        value={values.transportId}
        onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
      />
      <Typography sx={{ fontWeight: 'bold' }}>{t('coi.arrival')}</Typography>
      <DesktopTimePicker
        renderInput={(props) => <TextField helperText={errors?.includes('arrivalTime') ? t('coi.evacuation-method-errors.arrivalTime') : ''} {...props} />}
        value={values.arrivalTime || defaultValues?.arrivalTime}
        label={t("coi.arrival-time")}
        maxTime={moment(values?.departureTime || defaultValues?.departureTime)}
        onError={(newError) => {
          if (newError) {
            setErrors([...errors, 'arrivalTime'])
          } else {
            setErrors(errors.filter(error => error !== 'arrivalTime'))
          }
        }}
        onChange={(e) => {
          if (e) {
            handleChangeInput("arrivalTime", e.toISOString())}
          }
        }
      />
      <Typography sx={{ fontWeight: 'bold' }}>{t('coi.departure')}</Typography>
      <DesktopTimePicker
        renderInput={(props) =>
          <TextField helperText={errors?.includes('departureTime') ? t('coi.evacuation-method-errors.departurTime') : ''} {...props} />}
        value={values.departureTime || defaultValues?.departureTime}
        label={t("coi.departure-time")}
        minTime={moment(values?.arrivalTime || defaultValues?.arrivalTime)}
        onChange={(e) => {
          if (e) {
            handleChangeInput("departureTime", e.toISOString())}
          }
        }
        onError={(newError) => {
          if (newError) {
            setErrors([...errors, 'departureTime'])
          } else {
            setErrors(errors.filter(error => error !== 'departureTime'))
          }
        }}
      />
      <Typography sx={{ fontWeight: 'bold' }}>{t('coi.incident-evacuation')}</Typography>
      <Box sx={{ display: "flex", gap: 2 }}>
        <DesktopTimePicker
          renderInput={(props) => <TextField sx={{ width: '100%' }} helperText={errors?.includes('startTime') ? t('coi.evacuation-method-errors.startTime') : ''} {...props} />}
          value={values.startTime || defaultValues?.startTime}
          label={t("coi.start-time")}
          maxTime={moment(values?.endTime || defaultValues?.endTime)}
          onChange={(e) => {
            if (e) {
              handleChangeInput("startTime", e.toISOString())}
            }
          }
          onError={(newError) => {
            if (newError) {
              setErrors([...errors, 'startTime'])
            } else {
              setErrors(errors.filter(error => error !== 'startTime'))
            }
          }}
        />
        <DesktopTimePicker
          renderInput={(props) => <TextField sx={{ width: '100%' }} helperText={errors?.includes('endTime') ? t('coi.evacuation-method-errors.endTime') : ''} {...props} />}
          value={values.endTime || defaultValues?.endTime}
          label={t("coi.end-time")}
          minTime={moment(values?.startTime || defaultValues?.startTime)}
          onChange={(e) => {
            if (e) {
              handleChangeInput("endTime", e.toISOString())}
            }
          }
          onError={(newError) => {
            if (newError) {
              setErrors([...errors, 'endTime'])
            } else {
              setErrors(errors.filter(error => error !== 'endTime'))
            }
          }}
        />
      </Box>
      <TextField
        sx={{ marginTop: '10px' }}
        id="numberOfPassengers"
        type="number"
        name="numberOfPassengers"
        defaultValue={defaultValues?.numberOfPassengers}
        value={values.numberOfPassengers}
        label={t("coi.number-of-passengers")}
        onChange={(e) => handleChangeInput(e.target.name, parseInt(e.target.value))}
      />
    </Box>
  )
}
