import {Box, Snackbar, Stack} from '@mui/material';
import React from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

export const PmrSnackBar: React.FC<{success: boolean; title: string; message: string; open: boolean}> = ({
  success,
  title,
  message,
  open,
}) => {
  const [showSnackbar, setShowSnackbar] = React.useState(false);

  React.useEffect(() => {
    if (open) {
      setShowSnackbar(true);
    }
  }, [open]);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  };

  return (
    <Snackbar
      open={showSnackbar}
      autoHideDuration={2000}
      onClose={handleClose}
      anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      sx={{
        backgroundColor: 'white',
        borderRadius: '8px',
        minWidth: '500px',
        boxShadow: ' 10px 10px 5px 0px rgba(0,0,0,0.05)',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '60px 0.8fr',
          width: '100%',
          padding: '15px',
          height: '80px',
          gap: '15px',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            placeItems: 'center',
            backgroundColor: success ? '#E9F2E9' : '#FAEBEB',
            borderRadius: '8px',
          }}
        >
          {success ? (
            <CheckOutlinedIcon scale={2} sx={{color: 'green'}} />
          ) : (
            <CloseOutlinedIcon scale={2} sx={{color: 'red'}} />
          )}
        </Box>
        <Box>
          <Stack spacing={1} justifyContent={'space-evenly'} sx={{height: '100%'}}>
            <p style={{margin: '0px', fontWeight: 'bold', color: success ? 'green' : 'red'}}>{title}</p>
            <p style={{margin: '0px', display: 'inline-block', whiteSpace: 'nowrap'}}>{message}</p>
          </Stack>
        </Box>
      </Box>
    </Snackbar>
  );
};
