// @ts-nocheck

import React from 'react';
import Icon from '@ant-design/icons';

function LogoSvg(props: any) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 45 45" {...props}>
      <defs>
        <clipPath id="clipPath">
          <rect id="Rectangle_1600" data-name="Rectangle 1600" width="45" height="45" fill="#fff" />
        </clipPath>
      </defs>
      <g id="svgexport-1" clipPath="url(#clipPath)">
        <path
          id="Tracé_5104"
          data-name="Tracé 5104"
          d="M26.131,118.792c-10.1,0-18.329-5.814-18.329-12.936s8.229-12.936,18.329-12.936S44.46,98.733,44.46,105.856s-8.229,12.936-18.329,12.936m0-27.392C13.889,91.4,4,97.829,4,105.856s9.889,14.456,22.131,14.456,22.131-6.5,22.131-14.456S38.374,91.4,26.131,91.4"
          transform="translate(-3.649 -83.373)"
          fill="#fff"
        />
        <path
          id="Tracé_5105"
          data-name="Tracé 5105"
          d="M153.8,160.983h-2.424c-.764,0-1.177-.342-1.177-.966v-5.533c0-.342.141-.483.483-.483H153.8a3.6,3.6,0,0,1,3.592,3.53,3.415,3.415,0,0,1-3.372,3.46,1.9,1.9,0,0,1-.22-.009m-3.592-14.1c0-.624.413-.966,1.177-.966h1.59a2.983,2.983,0,0,1,3.109,2.845v.132a3.083,3.083,0,0,1-3.109,3.039h-2.283c-.342,0-.483-.141-.483-.483Zm9.748,6.156c-.483-.211-.483-.281,0-.553a4.077,4.077,0,0,0,1.932-3.592c0-2.7-3.592-5.392-9.336-5.392a15.207,15.207,0,0,0-9.2,3.039c-.483.413-.413.624-.272.764l.834.966c.272.281.413.211.553.07.624-.483.694-.211.694.343v9.546c0,.553-.07.834-.694.343-.141-.141-.272-.211-.553.07l-.9,1.036c-.141.211-.272.413.281.764a17.052,17.052,0,0,0,9.406,2.977c6.429,0,10.442-2.7,10.442-6.3.053-2.424-1.95-3.6-3.2-4.084"
          transform="translate(-130.352 -130.895)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export const LogoIcon = (props: any) => <Icon component={LogoSvg} {...props} />;
