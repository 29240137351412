import React from 'react';
import {PmrView} from './views/PmrView';
import {SelectStationComponent, TargetScreen} from 'src/utils/selectStation';
import {useOnDistrictsOrStationsChange} from 'src/hooks/useDistrictChange';
import {useDistrictContext} from 'src/context/district/district.store';

export enum SubPmrRoutes {
  OVERVIEW = 'OVERVIEW',
  VALIDATION = 'VALIDATION',
  SEARCH = 'SEARCH',
}

type Props = {
  subRoute: SubPmrRoutes;
};

const Pmr: React.FC<Props> = ({subRoute}) => {
  const {selectedStations} = useDistrictContext();

  const [showTrain, setShowTrain] = React.useState(selectedStations.length === 0);

  const handleDistrictChange = () => {
    selectedStations.length === 0 ? setShowTrain(true) : setShowTrain(false);
  };

  useOnDistrictsOrStationsChange(handleDistrictChange);

  if (showTrain && subRoute !== SubPmrRoutes.SEARCH) {
    return <SelectStationComponent target={TargetScreen.RSERVATIONS} />;
  }
  return <PmrView variant={subRoute} />;
};

export default Pmr;
