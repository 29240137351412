import {useEffect, useState} from 'react';

import adultIcon from 'src/components/images/ic_adult-blue.svg';
import wheelChairIcon from 'src/components/images/ic_wheelchairFix_blue.svg';

const AgeIcons = ({groupCount, handicapDetails}: {groupCount: number; handicapDetails: object}) => {
  const [disabledCount, setDisabledCount] = useState(0);

  useEffect(() => {
    if (handicapDetails) {
      const countRaw = Object.values(handicapDetails)
        .filter(item => item)
        .reduce((total, number) => total + number, 0);
      setDisabledCount(countRaw);
    }
  }, [handicapDetails]);

  return (
    <>
      {groupCount && (
        <span className="main-img-box">
          <img src={adultIcon} className="img-chair-style" alt="img" />
          <span className="img-text">{groupCount}</span>
        </span>
      )}
      {handicapDetails && (
        <span className="main-img-box">
          <img src={wheelChairIcon} className="img-chair-style" alt="img" />
          <span className="img-text">{disabledCount}</span>
        </span>
      )}
    </>
  );
};

export default AgeIcons;
