import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  timelineOppositeContentClasses,
} from '@mui/lab';
import {StepButton} from '../common/index';
import moment from 'moment';
import React from 'react';
import {AlternativeTravelDto} from 'src/services/models/alternative-travel-dto';
import {JourneyDto} from 'src/services/models/journey-dto';
import {Box, Button, Chip} from '@mui/material';
import {calculateDurationOfTime} from '../util/CalculateDurationOfRoute';
import {CacheContext} from 'src/context/cache/cache.store';
import {StationDto} from 'src/services/models';
import {
  AssistsGreen,
  CloseIntermediateStopIcon,
  IntermediateStopIcon,
  ShortArrow,
  TransferStopIcon,
} from './AlternativeRouteIcons';
import {useTranslation} from 'react-i18next';

type AlternativeRouteProposalStepperProps = {
  travel: AlternativeTravelDto;
};

function AlternativeRouteProposalStepper(props: AlternativeRouteProposalStepperProps) {
  const {travel} = props;
  const {stations} = React.useContext(CacheContext);

  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.05,
          paddingLeft: 0,
        },
        paddingLeft: 0,
      }}
    >
      {travel.journeys &&
        travel.journeys.map((el, ind, arr) => (
          <AlternativeRouteProposalStepperTrip
            route={el}
            key={el.departureUicCode}
            isLastInSequence={ind === arr.length - 1}
            ind={ind}
            proposal={arr}
            stations={stations as StationDto[]}
          />
        ))}
    </Timeline>
  );
}

type AlternativeRouteProposalStepperTripProps = {
  route: JourneyDto;
  isLastInSequence: boolean;
  ind: number;
  proposal: JourneyDto[];
  stations: StationDto[];
};
const AlternativeRouteProposalStepperTrip = (props: AlternativeRouteProposalStepperTripProps) => {
  const {route, ind, proposal} = props;
  const [showIntermediate, setShowIntermediate] = React.useState(false);
  const {t} = useTranslation();

  return (
    <>
      <TimelineItem>
        <TimelineOppositeContent>
          <Chip
            sx={{backgroundColor: '#25336C', color: 'white', borderRadius: '5px'}}
            label={moment(route.departureTime).format('HH:mm')}
          />
        </TimelineOppositeContent>
        <TimelineSeparator>
          <Box sx={{display: 'grid', placeItems: 'center', paddingTop: '8px', paddingBottom: '8px'}}>
            <StepButton alternate />
          </Box>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Box>
            <p>
              <b>{route.departureName}</b>
            </p>
            <p>
              <Chip
                sx={{
                  backgroundColor: 'white',
                  fontWeight: 'bold',
                  borderRadius: '5px',
                  border: 'solid 1px black',
                  height: '20px',
                  marginRight: '8px',
                }}
                label={route.transportId}
              />{' '}
              {route.departureTrackName && (
                <Chip
                  sx={{
                    backgroundColor: '#25336C',
                    color: 'white',
                    borderRadius: '5px',
                    height: '20px',
                  }}
                  label={route.departureTrackName}
                />
              )}{' '}
              {props.stations.find(el => el.id === route.departureUicCode)?.assistanceType === 'Full' && (
                <AssistsGreen />
              )}
            </p>
            <p>
              {t('cr.towards')} <b>{route.direction}</b>
            </p>
          </Box>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent sx={{color: 'white', minWidth: '73.11px'}}>.</TimelineOppositeContent>
        <TimelineSeparator>
          {showIntermediate ? <CloseIntermediateStopIcon /> : <IntermediateStopIcon />}
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Button
            onClick={() => setShowIntermediate(prev => !prev)}
            variant="text"
            sx={{backgroundColor: '#F2F2F2F2', color: 'black', fontWeight: 'normal', textTransform: 'none'}}
          >
            {showIntermediate ? t('hide') : t('show')} {route.intermediateStations?.length} {t('inter-stops')} -{' '}
            {calculateDurationOfTime(route.departureTime || undefined, route.arrivalTime || undefined)}
          </Button>
        </TimelineContent>
      </TimelineItem>
      {showIntermediate &&
        route.intermediateStations &&
        route.intermediateStations.map(el => (
          <TimelineItem>
            <TimelineOppositeContent sx={{color: 'white', minWidth: '73.11px'}}>.</TimelineOppositeContent>
            <TimelineSeparator>
              {' '}
              <Box sx={{display: 'grid', placeItems: 'center', paddingTop: '8px', paddingBottom: '8px'}}>
                <StepButton alternate={false} />
              </Box>{' '}
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>{el}</TimelineContent>
          </TimelineItem>
        ))}
      <TimelineItem>
        <TimelineOppositeContent>
          {' '}
          <Chip
            sx={{backgroundColor: '#25336C', color: 'white', borderRadius: '5px'}}
            label={moment(route.arrivalTime).format('HH:mm')}
          />
        </TimelineOppositeContent>
        <TimelineSeparator>
          <Box sx={{display: 'grid', placeItems: 'center', paddingTop: '8px', paddingBottom: '8px'}}>
            <StepButton alternate={props.isLastInSequence} />
          </Box>
          {!props.isLastInSequence && (
            <TimelineConnector
              sx={{
                backgroundImage: 'repeating-linear-gradient(to bottom, #BDBDBD 0%, #BDBDBD 4px, white 4px, white 8px)',
              }}
            />
          )}
        </TimelineSeparator>
        <TimelineContent>
          <Box>
            <p>
              <b>{route.arrivalName}</b>
            </p>
            {props.isLastInSequence && (
              <p>
                {route.arrivalTrackName && (
                  <Chip
                    sx={{
                      backgroundColor: '#25336C',
                      color: 'white',
                      borderRadius: '5px',
                      height: '20px',
                    }}
                    label={route.arrivalTrackName}
                  />
                )}
                {props.stations.find(el => el.id === route.arrivalUicCode)?.assistanceType === 'Full' && (
                  <AssistsGreen />
                )}
              </p>
            )}
          </Box>
        </TimelineContent>
      </TimelineItem>
      {!props.isLastInSequence && (
        <TimelineItem>
          <TimelineOppositeContent sx={{color: 'white', minWidth: '73.11px'}}>.</TimelineOppositeContent>
          <TimelineSeparator>
            <Box sx={{display: 'grid', placeItems: 'center', paddingTop: '8px', paddingBottom: '8px'}}>
              <TransferStopIcon />
            </Box>
            <TimelineConnector
              sx={{
                backgroundImage: 'repeating-linear-gradient(to bottom, #BDBDBD 0%, #BDBDBD 4px, white 4px, white 8px)',
              }}
            />
          </TimelineSeparator>
          <TimelineContent sx={{paddingTop: '0px'}}>
            <p style={{border: 'solid 1px black', display: 'inline', padding: '8px 15px', borderRadius: '5px'}}>
              {t('cr.transfer-from')}{' '}
              <b>
                {t('icons.track')} {route.arrivalTrackName}
              </b>{' '}
              <ShortArrow />{' '}
              <b>
                {t('icons.track')} {proposal[ind + 1].departureTrackName}
              </b>
            </p>
          </TimelineContent>
        </TimelineItem>
      )}
    </>
  );
};

export default AlternativeRouteProposalStepper;
