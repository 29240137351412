import React, {useState} from 'react';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {Box, Paper, Grid, TextField} from '@mui/material';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import 'moment/min/locales';

import {FormAction, FormState} from '../../formState';
import {FormActionKind} from '../../formState';

export const DateRange: React.FC<{state: FormState; dispatch: React.Dispatch<FormAction>}> = ({state, dispatch}) => {
  const {t, i18n} = useTranslation();
  const [departureError, setDepartureError] = useState(false);
  const [arrivalError, setArrivalError] = useState(false);

  moment.locale(i18n.language);

  const handleDepartureChange = (value: any) => {
    const isValid = moment(value, 'DD-MM-yyyy', true).isValid();
    if (isValid) {
      if (moment(value).isBefore(state.arrivalDate) || moment(value).isSame(state.arrivalDate)) {
        dispatch({payload: moment(value), type: FormActionKind.DEPARTURE_DATE});
        setDepartureError(false);
      } else {
        dispatch({payload: null, type: FormActionKind.DEPARTURE_DATE});
        setDepartureError(true);
      }
    } else {
      dispatch({payload: null, type: FormActionKind.DEPARTURE_DATE});
      setDepartureError(true);
    }
  };

  const handleArrivalChange = (value: any) => {
    const isValid = moment(value, 'DD-MM-yyyy', true).isValid();
    if (isValid) {
      if (moment(value).isAfter(state.departureDate) || moment(value).isSame(state.departureDate)) {
        dispatch({payload: moment(value), type: FormActionKind.ARRIVAL_DATE});
        setArrivalError(false);
      } else {
        setArrivalError(true);
        dispatch({payload: moment(value), type: FormActionKind.ARRIVAL_DATE});
      }
    } else {
      setArrivalError(true);
      dispatch({payload: moment(value), type: FormActionKind.ARRIVAL_DATE});
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={i18n.language}>
      <Box sx={{padding: '25px 25px 0px 25px'}}>
        <Paper elevation={0} sx={{marginBottom: '15px'}}>
          <b>{t('pmr.search.date-range')}</b>
        </Paper>
        <Grid container gap={2}>
          <Grid item xs={3}>
            <DatePicker
              inputFormat="DD-MM-yyyy"
              //@ts-ignore
              slotProps={{
                field: {
                  clearable: true,
                  onClear: () => dispatch({payload: null, type: FormActionKind.DEPARTURE_DATE}),
                },
              }}
              key="departure"
              maxDate={state.arrivalDate}
              value={state.departureDate}
              onChange={handleDepartureChange}
              renderInput={props => (
                <TextField
                  sx={{width: '100%'}}
                  {...props}
                  error={departureError}
                  helperText={departureError ? 'Invalid date format' : ''}
                  label={`${t('pmr.search.departure')} ${t('pmr.search.date')}`}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <DatePicker
              inputFormat="DD-MM-yyyy"
              key="arrival"
              minDate={state.departureDate}
              value={state.arrivalDate}
              onChange={handleArrivalChange}
              renderInput={props => (
                <TextField
                  sx={{width: '100%'}}
                  {...props}
                  error={arrivalError}
                  helperText={arrivalError ? 'Invalid date format' : ''}
                  label={`${t('pmr.search.arrival')} ${t('pmr.search.date')}`}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
};
