import axios, {AxiosError} from 'axios';

export const updatePersonel = async (
  baseUrl: string,
  incidentId: string,
  locationId: string,
  body: {employeeId: string; assignments: string[]},
) => {
  try {
    await axios.put(`${baseUrl}/api/incidents/${incidentId}/locations/${locationId}/missions`, body);
    return true;
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};
