import {styled} from '@mui/system';
import {Button} from '@mui/material';

export const InlineText = styled('p')({
  margin: 0,
});

export const FullDetailsButton = styled(Button)({
  color: 'black',
  borderColor: 'black',
  borderRadius: '8px',
  padding: '2px 8px',
  fontWeight: 'bold',
});
