import {CreateReservationDto} from 'src/services/models';
import {TransportTypeDto} from 'src/services/models/transport-type-dto';

export const createValidReservation = (reservation: CreateReservationDto): CreateReservationDto => {
  const fitleredJourneys = reservation?.travel?.journeys?.filter(val => Object.keys(val).length !== 0);
  const fitleredAges = reservation.travel.traveler?.ageCategories?.filter(val => Object.keys(val).length !== 0);
  const fitleredDisableds = reservation.travel.traveler?.disableds?.filter(val => Object.keys(val).length !== 0);

  reservation.travel.journeys = fitleredJourneys;

  if (reservation.travel.traveler?.ageCategories) {
    reservation.travel.traveler.ageCategories = fitleredAges;
  }

  if (reservation.travel.traveler?.disableds) {
    reservation.travel.traveler.disableds = fitleredDisableds;
  }

  return reservation;
};
