// src/hooks/useHandleCall.ts
import {AxiosError} from 'axios';
import React from 'react';
import {NetworkContext} from 'src/context/network/network.store';
import {ModelError} from 'src/services/models';

type HandleCall<T> = {
  executeCall: (fn: Promise<T>) => Promise<T | null>;
  loadingCall: boolean;
  errorCall: ModelError | null;
};

const useHandleCall = <T,>(): HandleCall<T> => {
  const {validateNetworkCall} = React.useContext(NetworkContext);
  const [loadingCall, setLoading] = React.useState(false);
  const [errorCall, setError] = React.useState<ModelError | null>(null);

  const executeCall = async (fn: Promise<T>): Promise<T | null> => {
    setLoading(true);
    setError(null);

    const res = await fn
      .then(res => res)
      .catch((error: AxiosError<ModelError>) => {
        if (error.response) {
          validateNetworkCall(error.response.status, error.response.data);
          setError(error.response.data);
        }
        return null;
      });

    setLoading(false);
    return res;
  };

  return {executeCall, loadingCall, errorCall};
};

export default useHandleCall;
