import {Box, Button, CircularProgress, List, ListItem, Stack, Tab, Tabs, Tooltip, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {AssignButton, IOSSwitch} from 'src/scenes/coi/coiComponents/common';
import {MissionDetailDto} from 'src/services/models/mission-detail-dto';
import SaveIcon from 'src/icons/SaveIcon';
import {AssignMissionsDto} from 'src/services/models/assign-missions-dto';
import useHandleCall from 'src/hooks/useHandleCall';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import assignMission from 'src/scenes/Pages/Coordinator/components/Modal/Assign/assignMission.service';
import {LocalizedTextDto, ReservationTypeDto} from 'src/services/models';
import {AssignBtp} from './AssignBtp';
import {getPresences, getSapPresences, getUnits} from './data';
import {StationPresenceDto} from 'src/services/models/station-presence-dto';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';
import {AssignSap} from './AssignSap';
import {OrganizationUnitDto} from 'src/services/models/organization-unit-dto';
import {MissionContext} from 'src/scenes/coordinator/MissionOverview/missions.store';
import { SettingsContext } from 'src/context/settings/settings.store';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export enum PresenceOrigin {
  BTP = 'BTP',
  SAP = 'SAP',
}

export type SearchPrecensesQuery =
  | {origin: PresenceOrigin.BTP; payload: string[]}
  | {origin: PresenceOrigin.SAP; payload: OrganizationUnitDto};

type AssignModalProps = {
  onClose: () => void;
  mission: MissionDetailDto;
};

export const AssignModal: React.FC<AssignModalProps> = ({onClose, mission}) => {
  const {t} = useTranslation();

  const {refetch} = React.useContext(MissionContext);
  const {RESERVATIONS_FEATURE_FLAG_PMR_DiTA} = React.useContext(SettingsContext);

  const {loadingCall: loadingAssignCall, executeCall: executeAssignCall} = useHandleCall();
  const {loadingCall: loadingPrecensesCall, executeCall: executePrecensesCall} = useHandleCall<StationPresenceDto[]>();
  const {loadingCall: loadingUnitsCall, executeCall: executeUnitsCall} = useHandleCall<OrganizationUnitDto[]>();

  const {variables} = React.useContext(EnvironmentContext);

  const [selectedTab, setSelectedTab] = React.useState<0 | 1>(0);

  const [prescenses, setPresences] = React.useState<StationPresenceDto[]>([]);

  const [assignedAgent, setAssignedAgent] = React.useState<string | undefined>(mission.owner?.id || undefined);

  const handleChange = (_event: React.SyntheticEvent, newValue: 0 | 1) => {
    setSelectedTab(newValue);
  };

  const [assignTrainManager, setAssignTrainManager] = React.useState(() => {
    if (mission.owner && mission.owner.id && mission.trainManager && mission.trainManager.employeeId) {
      return mission.owner.id === mission.trainManager.employeeId;
    }
    return false;
  });

  const [units, setUnits] = React.useState<OrganizationUnitDto[] | undefined>(undefined);

  const init = async () => {
    //Fetch units
    const unitsFromBe = (await executeUnitsCall(getUnits(variables.BASE_ENDPOINT))) || undefined;
    setUnits(unitsFromBe);

    if (mission.owner && mission.trainManager && mission.owner.id === mission.trainManager.employeeId) {
      setAssignTrainManager(true);
    }
  };

  React.useEffect(() => {
    init();
  }, []);

  React.useEffect(() => {
    setPresences([]);
  }, [selectedTab]);

  //Handle save
  const handleSave = async () => {
    const owner = assignTrainManager ? mission.trainManager?.employeeId : assignedAgent;

    if (owner) {
      const missionToAssign: AssignMissionsDto = {
        missions: [{missionId: mission.id, reservationType: ReservationTypeDto.Disabled}],
        owner,
      };

      await executeAssignCall(assignMission(variables.BASE_ENDPOINT, missionToAssign));
      refetch();
      onClose();
    }
  };

  const handleFindPrecenses = async (props: SearchPrecensesQuery) => {
    if (props.origin === PresenceOrigin.BTP) {
      const result = await executePrecensesCall(getPresences(variables.BASE_ENDPOINT, props.payload));
      if (result) {
        setPresences(result);
      }
    } else if (props.origin === PresenceOrigin.SAP) {
      const result = await executePrecensesCall(getSapPresences(variables.BASE_ENDPOINT, props.payload));
      if (result) {
        setPresences(result);
      }
    }
  };

  if (loadingAssignCall) {
    return (
      <Box sx={{display: 'grid', placeItems: 'center'}}>
        <CircularProgress />
      </Box>
    );
  }

  const handleSelectionOfAgent = (employeeId: string | undefined) => {
    setAssignTrainManager(false);
    setAssignedAgent(prev => (prev === employeeId ? undefined : employeeId));
  };

  const toggleSelectionOfTrainManager = () => {
    setAssignedAgent(undefined);
    setAssignTrainManager(prev => !prev);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          sx={{
            border: 'none',
            '& .MuiTabs-indicator': {
              height: '1px',
              backgroundColor: 'white',
              top: '-1px',
              color: 'grey',
            },
            '& .Mui-selected': {
              color: 'grey !important',
            },
            '& .MuiTab-root': {
              borderBottom: 'none',
            }, // Remove the border on the full Tabs component
          }}
        >
          <Tab
            disabled={loadingPrecensesCall || assignTrainManager}
            sx={{
              color: selectedTab === 0 ? 'grey' : 'lightblue', // Grey text if selected, lightblue otherwise
              border: selectedTab === 0 ? '1px solid lightgrey' : 'none', // Apply border if selected
              borderBottom: 'none', // Remove bottom border to avoid overlap
              borderRadius: '4px 4px 0 0',
              // Optional: Slight rounding for a polished look
            }}
            label={t('pmr-mission.btp-tab')}
            {...a11yProps(0)}
          />
          <Tab
            disabled={loadingPrecensesCall || loadingUnitsCall || assignTrainManager}
            sx={{
              color: selectedTab === 1 ? 'grey' : 'lightblue', // Grey text if selected, lightblue otherwise
              border: selectedTab === 1 ? '1px solid lightgrey' : 'none', // Apply border if selected
              borderBottom: 'none', // Remove bottom border to avoid overlap
              borderRadius: '4px 4px 0 0', // Optional: Slight rounding for a polished look
            }}
            label={t('pmr-mission.sap-tab')}
            {...a11yProps(1)}
          />
        </Tabs>
        {RESERVATIONS_FEATURE_FLAG_PMR_DiTA ? (
          <Stack direction={'row'} spacing={1} sx={{alignItems: 'center'}}>
            <Typography sx={{fontWeight: 'bold'}}>{t('pmr-mission.assign-trm')}</Typography>
            <Tooltip
              title={
                mission.trainManager !== undefined &&
                mission.trainManager !== null &&
                mission.trainManager.employeeId !== null
                  ? ''
                  : mission.trainManager === undefined || mission.trainManager === null
                  ? t('pmr-mission.no-manager')
                  : t('pmr-mission.no-manager-id')
              }
            >
              <span key={mission.id}>
                <IOSSwitch
                  sx={{
                    zIndex: 9999, // Ensure it is on top of other elements
                    visibility: 'visible', // Ensure visibility
                  }}
                  disabled={!mission.trainManager || mission.trainManager.employeeId === null}
                  checked={assignTrainManager}
                  onChange={toggleSelectionOfTrainManager}
                />
              </span>
            </Tooltip>
          </Stack>
        ) : null}
      </Box>
      <AssignTab value={selectedTab} index={0}>
        <AssignBtp handleSearch={handleFindPrecenses} loading={loadingPrecensesCall} isDisabled={assignTrainManager} />
      </AssignTab>
      <AssignTab value={selectedTab} index={1}>
        {units && units.length > 0 && !loadingUnitsCall && (
          <AssignSap handleSearch={handleFindPrecenses} loading={loadingPrecensesCall} units={units} isDisabled={assignTrainManager} />
        )}
      </AssignTab>
      <Box sx={{marginTop: '25px', marginBottom: '25px'}}>
        <Typography variant="h5" sx={{fontWeight: 'bold', marginBottom: '16px'}}>
          {t('pmr-mission.select-agent')}
        </Typography>
        {prescenses.length > 0 && (
          <PresencesList
            selectedTab={selectedTab}
            presences={prescenses}
            assignAgent={handleSelectionOfAgent}
            assignedAgent={assignedAgent}
          />
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 2,
        }}
      >
        <Button variant="text" onClick={onClose}>
          {t('station-section.btn-cancel')}
        </Button>
        <Button disabled={!(assignedAgent || assignTrainManager)} variant="contained" onClick={handleSave} startIcon={<SaveIcon />}>
          {t('settings.changelanguage.save')}
        </Button>
      </Box>
    </>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function AssignTab(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
const PresencesList: React.FC<{
  selectedTab: number;
  presences: StationPresenceDto[];
  assignAgent: (employeeId: string) => void;
  assignedAgent: string | undefined;
}> = ({presences, assignAgent, assignedAgent, selectedTab}) => {
  const {i18n} = useTranslation();

  return (
    <List
      sx={{
        width: '100%',
        height: 'calc(5 * 62px)', // Assuming each ListItem height is about 56px
        overflowY: 'auto',
        gap: '16px',
        display: 'grid',
        gridAutoRows: 'min-content',
      }}
    >
      {selectedTab === 0 &&
        presences.map((presence, index) => (
          <ListItem
            key={index}
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr auto',
              alignItems: 'center',
              padding: '8px 16px',
              backgroundColor: '#eff4f9',
              gap: '12px',
            }}
          >
            <Typography sx={{fontWeight: 'bold'}}>{presence.name}</Typography>
            <Typography>{handleTranslationObject(i18n, presence.role as LocalizedTextDto[])}</Typography>
            <Typography>
              {' '}
              <span style={{display: 'flex', gap: '5px'}}>
                <LocalPhoneOutlinedIcon color={'info'} />
                <a
                  href={`dica:${presence.phoneNumber?.replace('+', '000')}`}
                  style={{justifySelf: 'end', textDecoration: 'none'}}
                >
                  {presence.phoneNumber}
                </a>
              </span>
            </Typography>
            <AssignButton
              onClick={() => assignAgent(presence.employeeId || '')}
              selected={presence.employeeId === assignedAgent}
            >
              Assign Agent
            </AssignButton>
          </ListItem>
        ))}
      {selectedTab === 1 &&
        presences.map((presence, index) => (
          <ListItem
            key={index}
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr auto',
              alignItems: 'center',
              padding: '8px 16px',
              backgroundColor: '#eff4f9',
              gap: '12px',
            }}
          >
            <Typography sx={{fontWeight: 'bold'}}>
              {/*@ts-ignore*/}
              {presence.firstName} {presence.lastName}
            </Typography>
            <AssignButton
              onClick={() => assignAgent(presence.employeeId || '')}
              selected={presence.employeeId === assignedAgent}
            >
              Assign Agent
            </AssignButton>
          </ListItem>
        ))}
    </List>
  );
};
