import React from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import moment from 'moment';
import {Box, CircularProgress, Typography} from '@mui/material';

import useHandleCall from 'src/hooks/useHandleCall';
import {IncidentDtoPaginatedListResponseDto} from 'src/services/models/incident-dto-paginated-list-response-dto';
import {getIncidentList} from './data';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {localStorageKeys} from 'src/utils/localStorageKeys';
import {CoiIncidentList} from './coiComponents/coiOverviewComponents/coiIncidentList/coiIncidentList';
import {CoiIncidentDetail} from './coiComponents/coiIncidentDetail/coiIncidentDetail';
import {DistrictContext} from 'src/context/district/district.store';
import {useCoiIncidentsContext} from 'src/context/coi/coi.incidents.store';
import coiBg from './COI_BG.svg';
import {SelectStationComponent, TargetScreen} from 'src/utils/selectStation';
import {useOnDistrictsOrStationsChange} from 'src/hooks/useDistrictChange';

export type CoiOverviewStore = {
  incidents?: IncidentDtoPaginatedListResponseDto;
  incidentListController: {
    changeDate: (direction: 'forwards' | 'backwards') => string;
    refetchData: (date: string, district: string, moreResultsToken: string | null | undefined) => Promise<void>;
    isToday: () => boolean;
  };
  date: string;
};

export default function CoiOverview({isFollowing}: {isFollowing: boolean}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [incidents, setIncidents] = React.useState<undefined | IncidentDtoPaginatedListResponseDto>(undefined);
  const {executeCall, loadingCall} = useHandleCall<IncidentDtoPaginatedListResponseDto>();
  const [selectedIncident, setSelectedIncident] = React.useState<string | null>(null);
  const {selectedDistrict} = React.useContext(DistrictContext);
  const {setFollowingIncidentsCounter} = useCoiIncidentsContext();

  const districtId = localStorage.getItem(localStorageKeys.SELECTED_DISTRICT);
  //ENV
  const {variables} = React.useContext(EnvironmentContext);

  useOnDistrictsOrStationsChange(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('id');
  });

  //Incident list keys
  const [incidentListDate, setIncidentListDate] = React.useState<string>(moment().format('YYYY-MM-DD'));
  const district = localStorage.getItem(localStorageKeys.SELECTED_DISTRICT) || '';

  const handleSelectIncident = (id: string) => {
    setSelectedIncident(id);
    updateUrlParams(id, incidentListDate);
  };

  const updateUrlParams = (id: string | null, date: string) => {
    const searchParams = new URLSearchParams(location.search);
    if (id !== null) searchParams.set('id', id);
    if (id === null) searchParams.delete('id');
    searchParams.set('district', districtId || '');
    searchParams.set('date', date);
    navigate(`${location.pathname}?${searchParams.toString()}`, {replace: true});
  };

  //Controller for all incidentlist functions
  const incidentListController = {
    changeDate: (direction: 'forwards' | 'backwards') => {
      setSelectedIncident(null);
      const newDate =
        direction === 'backwards'
          ? moment(incidentListDate).subtract(1, 'days').format('YYYY-MM-DD')
          : moment(incidentListDate)
              .add(incidentListController.isToday() ? 0 : 1, 'days')
              .format('YYYY-MM-DD');
      setIncidentListDate(newDate);
      updateUrlParams(null, newDate);
      return newDate;
    },
    isToday: () => {
      return moment(incidentListDate).isSame(moment(), 'day');
    },
    refetchData: async (date: string, district: string, moreResultsToken: string | null | undefined) => {
      const result = await executeCall(
        getIncidentList(variables.BASE_ENDPOINT, {
          district: !isFollowing ? district : '',
          followingOnly: isFollowing,
          moreResultsToken: moreResultsToken,
          ...(!isFollowing ? {date: date} : {}),
        }),
      );

      if (result?.totalItems === 0) {
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete('id');
      }

      if (result) {
        setIncidents(result);
      }
    },
    getFollowUpCounter: async () => {
      const result = await executeCall(
        getIncidentList(variables.BASE_ENDPOINT, {
          moreResultsToken: null,
          followingOnly: true,
        }),
      );

      if (result) {
        setFollowingIncidentsCounter(result?.totalItems || 0);
      }

      if (result && district.length === 0) {
        setIncidents(result);
      }
    },
  };

  const initialize = async () => {
    if (district.length !== 0) {
      incidentListController.refetchData(incidentListDate, district, null);
    }
  };

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const dateParam = params.get('date');
    const idParam = params.get('id');
    const districtParam = params.get('district');

    if (districtId !== districtParam) {
      setSelectedIncident(null);
    }

    if (dateParam) {
      setIncidentListDate(dateParam);
    }
    if (idParam && districtId === districtParam) {
      setSelectedIncident(idParam);
    }

    initialize();
    incidentListController.getFollowUpCounter();
  }, [selectedDistrict, isFollowing]);

  React.useEffect(() => {
    if (selectedIncident) {
      setSelectedIncident(null);
    }
  }, [location.pathname]);

  React.useEffect(() => {
    if (isFollowing) {
      setFollowingIncidentsCounter(incidents?.totalItems || 0);
    }
  }, [incidents]);

  React.useEffect(() => {
    if (!isFollowing && incidents?.items) {
      // incidentListController.getFollowUpCounter();
    }
  }, []);

  if (district.length === 0 && !isFollowing) {
    return <SelectStationComponent target={TargetScreen.INCIDENTS} />;
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 4fr',
        minHeight: 'calc(100vh - 64px)',
        maxHeight: 'calc(100vh - 64px)',
        overflow: 'hidden',
      }}
    >
      <CoiIncidentList
        incidents={incidents}
        incidentListController={incidentListController}
        date={incidentListDate}
        selectedIncident={selectedIncident}
        handleSelectIncident={handleSelectIncident}
        isFollowing={isFollowing}
      />
      {loadingCall && <LoadingIncidents />}
      {incidents === undefined || (incidents.totalItems === 0 && <NoSelectedIncident />)}
      {!!selectedIncident ? (
        <CoiIncidentDetail
          incidentId={selectedIncident}
          handleCloseIncidentCallback={() => initialize()}
          handleFollowIncidentCallback={() => incidentListController.getFollowUpCounter()}
        />
      ) : (
        <NoSelectedIncident />
      )}
    </Box>
  );
}

const LoadingIncidents = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: 'calc(100vh - 64px)',
        maxHeight: 'calc(100vh - 64px)',
        overflow: 'hidden',
        placeItems: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
};

const NoSelectedIncident = () => {
  const {t} = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: 'calc(100vh - 64px)',
        maxHeight: 'calc(100vh - 64px)',
        overflow: 'hidden',
        placeItems: 'center',
      }}
    >
      <img src={coiBg} style={{width: '120px', height: '80px', marginBottom: '30px'}} alt="no incident selected" />
      <Typography sx={{fontWeight: 400, fontSize: '20px', fontFamily: 'Arial', marginBottom: '10px'}}>
        {t('coi.incident-select-incident')}
      </Typography>
      <Typography sx={{color: 'rgba(0, 0, 0, 0.6)', fontSize: '16px', fontWeight: 400, fontFamily: 'Arial'}}>
        {t('coi.incident-no-incident-selected')}
      </Typography>
    </Box>
  );
};
