//@ts-nocheck
import React, {useContext} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import Box, {BoxProps} from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import List from '@mui/material/List';
import {alpha, styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {useEffect, useState} from 'react';
import EmptyListIcon from 'src/components/icons/EmptyList';
import {colors} from 'src/utils/hexCode';
import MissionItem from 'src/components/coordinator/MissionItem/MissionItem';
import {SettingsContext} from 'src/context/settings/settings.store';
import {Navigate, useLocation} from 'react-router-dom';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import {PlusIcon} from 'src/components/icons';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';

import {emptyList, LeftScreenContainer, loaderScreen, RightScreenContainer} from './MissionOverview.styles';
import '../ReservationFilter/ReservationFilter.scss';

import {useTranslation} from 'react-i18next';

import DateRange from '../../../components/DateRangeSection/DateRange';
import ReservationFilter from '../ReservationFilter';
import {MissionDetailDto} from 'src/services/models/mission-detail-dto';
import {ReservationTypeDto} from 'src/services/models';
import {MissionTypeDto} from 'src/services/models/mission-type-dto';
import {
  DateDirection,
  IMissionFilter,
  MissionContext,
  defaultMissionFilter,
} from 'src/scenes/coordinator/MissionOverview/missions.store';
import ReloadIcon from 'src/components/images/ic_refresh.svg';
import {mapDataToAssignment} from './missions.util';
import MissionDetailsNew from '../MissionDetailsNew/MissionDetailsNew';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {Button, Fab, Paper, Popover, IconButton} from '@mui/material';
import {SelectStationComponent, TargetScreen} from 'src/utils/selectStation';
import ReservationFilterModal from '../ReservationFilter/ReservationFilterModel';
import {StyledComponent} from '@emotion/styled';

const Search = styled('div')(({theme}) => ({
  position: 'absolute',
  borderRadius: 8,
  marginLeft: 0,
  width: '100%',
  padding: '8px 20px',
  zIndex: 2,
  left: 25,
  bottom: 40,
  display: 'flex',
  alignItems: 'center',
  backgroundColor: alpha(colors.black_opaque, 0.85),
  '&:hover': {
    backgroundColor: alpha(colors.black_opaque, 1),
  },
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
  color: '#fff',
  width: '100%',
  marginLeft: 15,
  '& .MuiInputBase-input': {
    width: '100%',
  },
}));

const fabStyle = {
  position: 'absolute',
  bottom: 8,
  zIndex: 1,
  width: '350px',
};

function useScrollbarWidth(ref: React.RefObject<HTMLElement>): number | undefined {
  const [scrollbarWidth, setScrollbarWidth] = useState<number>();

  useEffect(() => {
    if (ref.current) {
      const container = ref.current;

      // Measure total width including scrollbar
      const totalWidth = container.offsetWidth;

      // Measure inner width (without scrollbar)
      const innerWidth = container.clientWidth;

      // Scrollbar width is the difference between the total width and the inner width
      setScrollbarWidth(totalWidth - innerWidth);
    }
  }, [ref]);

  return scrollbarWidth;
}

export default function MissionOverview({
  isAssignScreen,
  isNotAssignScreen,
}: {
  isAssignScreen: boolean;
  isNotAssignScreen: boolean;
}) {
  const [selectedIndex, setSelectedIndex] = useState<null | string>(null);
  const [mappedData, setMappedData] = useState([] as MissionDetailDto[]);
  const {t} = useTranslation();
  const [valueToSearch, setValueToSearch] = useState('');
  const {variables} = useContext(EnvironmentContext);
  const [missionsToBeDisplayed, setMissionsToBeDisplayed] = useState([] as MissionDetailDto[]);
  const [isReservationModalOpen, setIsReservationModalOpen] = useState(false);

  //Resize value
  const [resizeValue, setResizeValue] = React.useState(() => {
    const hasLocalEntry = localStorage.getItem('missionListPanelSize');
    if (hasLocalEntry) {
      return parseInt(hasLocalEntry);
    }
    return 400;
  });
  //360 + width
  const containerRef = React.useRef(null);
  const scrollBarWidth = useScrollbarWidth(containerRef);

  const {handleDateChange, loadingData} = React.useContext(MissionContext);

  const onLeftClickHandle = () => {
    handleDateChange(DateDirection.PREV, 1);
  };

  const onRightClickHandle = () => {
    handleDateChange(DateDirection.NEXT, 1);
  };

  const {
    filteredMissions,
    missions,
    handleSelectMission,
    selectedMissionDetails,
    loadingDetailData,
    missionFilter,
    handleFilterChange,
    handleFilterReset,
    clearMissions,
    refetch,
    handleExportMissionsToJson,
  } = React.useContext(MissionContext);

  const location = useLocation();

  const setActive = (index: string) => {
    setSelectedIndex(index);
    handleSelectMission(index);
  };

  const [show, setShow] = useState(true);

  useEffect(() => {
    let timer = setTimeout(() => setShow(false), 1 * 100);

    return () => {
      clearTimeout(timer);
    };
  }, [show, setShow, loadingData]);

  useEffect(() => {
    clearMissions();
    setShow(true);
    const data = mapDataToAssignment(filteredMissions, isAssignScreen ? isAssignScreen : false);
    setMappedData(data);
  }, [isAssignScreen, isNotAssignScreen, location, missionFilter, missions]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValueToSearch(event.target.value);
  };

  const toggleModal = () => {
    setIsReservationModalOpen(prev => !prev);
  };

  const handleResetClick = () => {
    handleFilterReset();
    toggleModal();
  };

  const handleChangeFilterVariables = (filter: IMissionFilter) => {
    handleFilterChange(filter);
    toggleModal();
  };

  const showDetailDataScreen = () => {
    if (loadingDetailData && selectedMissionDetails === undefined) {
      return (
        <Box style={{height: '85%'}} alignItems="center" justifyContent="center" display="flex">
          <Box style={{color: colors.grey_7}}>
            <Box alignItems="center" justifyContent="center" display="flex">
              <CircularProgress style={{color: colors.nbms_dark_blue}} />
            </Box>
          </Box>
        </Box>
      );
    }
    if (selectedMissionDetails !== undefined && loadingDetailData === false) {
      return <MissionDetailsNew isAssigned={isAssignScreen} />;
    }

    if (selectedMissionDetails === undefined && loadingDetailData === false) {
      return (
        <Box style={{height: '85%'}} alignItems="center" justifyContent="center" display="flex">
          <Box style={{color: colors.grey_7}}>
            <Box alignItems="center" justifyContent="center" display="flex">
              <EmptyListIcon style={emptyList} />
            </Box>
            <Typography paragraph>{t('mission-overview.box-select-mission')}</Typography>
          </Box>
        </Box>
      );
    }
  };

  const handleClickExportToJson = () => {
    handleExportMissionsToJson(missionsToBeDisplayed);
  };

  const filterAndMapMissions = (data: MissionDetailDto[]) => {
    const filteredData = data
      .filter(el =>
        valueToSearch.length > 0
          ? el.journey?.stationName?.toLocaleUpperCase().includes(valueToSearch.toLocaleUpperCase())
          : true,
      )
      .sort((prev, next) => {
        if (new Date(prev.journey?.time).getTime() === new Date(next.journey?.time).getTime()) {
          return prev.status?.localeCompare(next.status);
        }
        return new Date(prev.journey?.time).getTime() > new Date(next.journey?.time).getTime() ? 1 : -1;
      });
    setMissionsToBeDisplayed(filteredData);
  };

  useEffect(() => {
    filterAndMapMissions(mappedData);
  }, [mappedData, valueToSearch]);

  if (localStorage.getItem('selectedStations')?.length === 0) {
    return <SelectStationComponent target={TargetScreen.MISSIONS} />;
  }

  const handleMouseDown = e => {
    e.preventDefault();
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = e => {
    if (e.clientX > 760 && resizeValue <= 600) {
      const newResizeValue = e.clientX - 360;
      if (newResizeValue <= 600) {
        setResizeValue(newResizeValue);
        localStorage.setItem('missionListPanelSize', newResizeValue);
      }
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: `${variables.USE_PMR_3 ? `${resizeValue}px 32px 1fr` : `${resizeValue}px 32px 1fr`}`,
        minHeight: 'calc(100vh - 64px)',
        maxHeight: 'calc(100vh - 64px)',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <LeftScreenContainer ref={containerRef}>
        <Box sx={{padding: '10px 10px 5px 10px'}}>
          <Paper
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: '56px',
              padding: '0 8px',
            }}
          >
            <DateRange onRightClicked={onRightClickHandle} onLeftClicked={onLeftClickHandle} />
            <Box sx={{display: 'flex', alignItems: 'center', marginLeft: '8px'}}>
              <IconButton onClick={() => toggleModal()}>
                <Box sx={{position: 'relative', display: 'flex', alignItems: 'center'}}>
                  <FilterAltOutlinedIcon sx={{color: '#333'}} />
                  {JSON.stringify(missionFilter) !== defaultMissionFilter ? <span className="orange-dot"></span> : null}
                </Box>
              </IconButton>
              {/* <ReservationFilter /> */}
              <IconButton onClick={refetch}>
                <CachedOutlinedIcon sx={{color: '#333'}} />
              </IconButton>
              <IconButton onClick={handleClickExportToJson}>
                <IosShareOutlinedIcon sx={{color: '#333'}} />
              </IconButton>
            </Box>
          </Paper>
        </Box>
        <List style={show || loadingData ? loaderScreen : {paddingBottom: 120, paddingTop: 0}} autoFocus={true}>
          {show || loadingData ? (
            <Box>
              <CircularProgress style={{color: colors.nbms_dark_blue}} />
            </Box>
          ) : (
            <>
              {missionsToBeDisplayed?.map(item => (
                <MissionItem
                  id={item.id}
                  dataItem={item}
                  isPMR={item.reservationType === ReservationTypeDto.Disabled}
                  isAtDeparture={item.missionType === MissionTypeDto.Departure}
                  isAtArrival={item.missionType === MissionTypeDto.Arrival}
                  handleClick={setActive}
                  selectedindex={selectedIndex}
                  isAssigned={isAssignScreen}
                />
              ))}
            </>
          )}
        </List>
      </LeftScreenContainer>
      <Box
        onMouseDown={handleMouseDown}
        sx={{
          borderLeft: 'solid 2px grey',
          height: '100%',
          background: 'white',
          '&:hover': {borderLeft: 'solid 2px #0069b4', cursor: 'ew-resize'},
        }}
      />
      <RightScreenContainer>{showDetailDataScreen()}</RightScreenContainer>
      <Search
        resizeValue={resizeValue}
        sx={{
          maxWidth: `${resizeValue - (scrollBarWidth ? scrollBarWidth : 0) - 50}px`,
        }}
      >
        <SearchIcon sx={{fill: '#fff'}} />
        <StyledInputBase
          placeholder={t('create-grobo-mission.search')}
          inputProps={{'aria-label': 'search'}}
          onChange={handleChange}
        />
      </Search>
      <ReservationFilterModal
        isOpen={isReservationModalOpen}
        handleClose={toggleModal}
        selectedTab={1}
        onResetFilter={handleResetClick}
        handleChangeFilter={handleChangeFilterVariables}
        activeFilter={missionFilter}
      />
    </Box>
  );
}
