import {useTranslation} from 'react-i18next';

export enum TargetScreen {
  MISSIONS = 'MISSIONS',
  RSERVATIONS = 'RESERVATIONS',
  INCIDENTS = 'INCIDENTS',
}

export const SelectStationComponent = ({target}: {target: TargetScreen}) => {
  const {t} = useTranslation();

  const message = () => {
    switch (target) {
      case TargetScreen.MISSIONS:
        return t('home.select-station-msg-missions');
      case TargetScreen.RSERVATIONS:
        return t('home.select-station-msg-reservations');
      case TargetScreen.INCIDENTS:
        return t('home.select-station-msg-incidents');
    }
  };
  return (
    <div style={{display: 'grid', placeItems: 'center', height: 'calc(100vh - 64px)'}}>
      <p className="no-station-msg" style={{fontWeight: 'bold'}}>
        {message()}
      </p>
    </div>
  );
};
