import {SvgIcon, Grid} from '@mui/material';
import AdultIcon from 'src/icons/AdultIcon';
import ChildIcon from 'src/icons/ChildIcon';
import ColleagueIcon from 'src/icons/ColleagueIcon';
import SeniorIcon from 'src/icons/SeniorIcon';
import {MissionDetailDto} from 'src/services/models/mission-detail-dto';
import {getTotalAmountOfTravelers} from './util';

export const TravelerCombinationInformation: React.FC<{
  mission: MissionDetailDto;
  t: (arg0: string) => string;
}> = ({mission, t}) => (
  <Grid item xs={12} sx={{border: 'solid 1px #E8EAED', borderRadius: '5px'}} padding={'12px 16px'} mb={'12px'}>
    <Grid container>
      <Grid item xs={5}>
        <p>
          <b>{t('icons.amount-of-travelers')}</b>
        </p>
        <div>
          <SvgIcon>
            <ColleagueIcon disabled={false} />
          </SvgIcon>
          <b style={{paddingLeft: '5px'}}>{getTotalAmountOfTravelers(mission.traveler)}</b>
        </div>
      </Grid>
      <Grid item xs={7}>
        <p>
          <b>{t('mission-details-v2.traveler.type-of-travelers')}</b>
        </p>
        {mission.traveler?.seniors && mission.traveler.seniors > 0 ? (
          <p>
            <SvgIcon>
              <SeniorIcon />
            </SvgIcon>
            <b style={{marginRight: '5px'}}>{mission.traveler.seniors}</b>
            {t('mission.mission-details.type-of-voyagers.seniors')}
          </p>
        ) : null}
        {mission.traveler?.adults && mission.traveler.adults > 0 ? (
          <p>
            <SvgIcon>
              <AdultIcon />
            </SvgIcon>
            <b style={{marginRight: '5px'}}>{mission.traveler.adults}</b>
            {t('mission.mission-details.type-of-voyagers.adults')}
          </p>
        ) : null}
        {mission.traveler?.children && mission.traveler.children > 0 ? (
          <p>
            <SvgIcon>
              <ChildIcon />
            </SvgIcon>
            <b style={{marginRight: '5px'}}>{mission.traveler.children}</b>
            {t('mission.mission-details.type-of-voyagers.children')}
          </p>
        ) : null}
      </Grid>
    </Grid>
  </Grid>
);
