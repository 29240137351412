import {DisabledOriginalTravelsDto} from 'src/services/models/disabled-original-travels-dto';

export const formatOutboundInbound = (variant: 'out' | 'in', reservation: DisabledOriginalTravelsDto) => {
  const baseData = variant === 'out' ? reservation.outboundJourneys : reservation.returnJourneys;
  if (baseData?.length === 0 || baseData === undefined || baseData === null) {
    return undefined;
  } else {
    const amount = baseData.length;
    const departureOfFirstTrip = baseData[0].departureName || '';
    const arrivalOfLastTrip = baseData[amount - 1].arrivalName || '';
    const departureDate = baseData[0].departureTime;
    return {
      departureOfFirstTrip,
      departureDate,
      arrivalOfLastTrip,
    };
  }
};
