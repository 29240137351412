import axios, {AxiosError} from 'axios';
import {ReservationDto} from 'src/services/models/reservation-dto';
import {ChangeAssistance} from './GroupReservation.store';
import {ReservationTypeDto} from 'src/services/models';

const getGroupMissions = async (
  baseUrl: string,
  params: {
    date: string;
    arrivalStation?: string;
    departureStation?: string;
    trainNumber?: number;
    description?: string;
  },
) => {
  let fullUrl = `${baseUrl}/api/reservations?date=${params.date}`;
  if (params.arrivalStation) fullUrl = fullUrl.concat(`&arrivalStation=${params.arrivalStation}`);
  if (params.departureStation) fullUrl = fullUrl.concat(`&departureStation=${params.departureStation}`);
  if (params.trainNumber) fullUrl = fullUrl.concat(`&trainNumber=${params.trainNumber}`);
  if (params.description) fullUrl = fullUrl.concat(`&description=${params.description}`);

  try {
    const res = await axios.get<ReservationDto[]>(fullUrl);
    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};

export const postAssistance = async (baseUrl: string, assistances: ChangeAssistance[]) => {
  const fullUrl = `${baseUrl}/api/reservations/assistance`;

  const body = assistances;

  try {
    const res = await axios(fullUrl, {
      data: body,
      method: 'POST',
    });
    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};

const getMission = async (baseUrl: string, id: string, type: ReservationTypeDto | null) => {
  const fullUrl = `${baseUrl}/api/reservations/${id}${type && `?reservationType=${type}`}`;

  try {
    const res = await axios.get<ReservationDto>(fullUrl);
    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};

export {getGroupMissions, getMission};
