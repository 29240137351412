import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Menu from './Menu/Menu';
import {EnvironmentContext} from 'src/context/environment/environment.store';

export default function SidebarMenu() {
  const {variables} = React.useContext(EnvironmentContext);

  return (
    <React.Fragment>
      <Box sx={{gridColumn: '1/2', gridRow: '2/3', height: 'calc(100vh - 64px)'}}>
        <Drawer
          open={true}
          variant="persistent"
          sx={{
            width: variables.USE_PMR_3 ? 360 : 240,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: variables.USE_PMR_3 ? 360 : 240,
              position: 'fixed',
              top: '64px',
              height: 'calc(100vh - 64px)',
              boxSizing: 'border-box',
            },
          }}
        >
          <Menu />
        </Drawer>
      </Box>
    </React.Fragment>
  );
}
