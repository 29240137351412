import axios, {AxiosError} from 'axios';
import {ReservationTypeDto} from '../models';
import {MissionDetailDto} from '../models/mission-detail-dto';

const getMissionDetails = async (baseUrl: string, id: string, reservationType: ReservationTypeDto) => {
  const fullUrl = `${baseUrl}/api/missions/${id}?reservationType=${reservationType}`;
  try {
    const res = await axios.get<MissionDetailDto>(fullUrl);
    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};

export default getMissionDetails;
