import {Button} from 'antd';
import './ButtonOutlined.scss';
import React from 'react';

type Props = {
  label?: string;
  htmlType?: 'submit' | 'reset' | 'button';
  icon?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  dangerType?: boolean;
  disabled?: boolean;
};

export const ButtonOutlined = ({label, icon, dangerType, onClick, htmlType = 'button', disabled}: Props) => {
  return (
    <Button
      icon={icon}
      className={dangerType ? 'outlined-red-button' : 'outlined-button'}
      onClick={onClick}
      htmlType={htmlType}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};
