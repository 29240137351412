// @ts-nocheck
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function ReducedMobility(props) {
  return (
    <SvgIcon {...props}>
      <svg
        id="ic_reduced_mobility"
        data-name="ic_reduced mobility"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        {...props}
      >
        <path id="Path_5203" data-name="Path 5203" d="M0,0H24V24H0Z" fill="none" />
        <path
          id="Path_5204"
          data-name="Path 5204"
          d="M19.14,21V14.42A3.15,3.15,0,0,0,16.28,11a2.76,2.76,0,0,0-.6-.39l-2.77-.94L12,5.87a12.49,12.49,0,0,0-1.53-1.4,4.38,4.38,0,0,0-.68-.15,2.14,2.14,0,0,0-1.45.56L4.87,8.32a2.79,2.79,0,0,0-.79,2.16V20.64a.45.45,0,0,0,0,.13V21a1.5,1.5,0,0,0,3,0V16l.3.58L7.31,20.8a1.53,1.53,0,0,0,1.45,1.59h0a1.54,1.54,0,0,0,1.49-1.54l.09-4.46a1.51,1.51,0,0,0-.14-.67,2.33,2.33,0,0,0-.11-.28L8.24,11.76l1.64-1.63.56,1.27.06.1.12.17.08.09.17.14L11,12a1.53,1.53,0,0,0,.29.14l2.27.77a4.14,4.14,0,0,0-.29,1.54V21.6a.44.44,0,0,0,.44.45.45.45,0,0,0,.44-.45V17.08H18.3V21.5a.58.58,0,0,0,1.16,0A.54.54,0,0,0,19.14,21Zm-.88-6v1.2H14.11V15Zm-4.14-.88a3.05,3.05,0,0,1,.26-1l.5.16a1.45,1.45,0,0,0,1.8-.86,1.26,1.26,0,0,0,.08-.52,2.36,2.36,0,0,1,1.49,2.19Zm1.53-2.05a.34.34,0,0,1-.42.2l-.2-.07a1.86,1.86,0,0,1,.63-.29h0a.25.25,0,0,1-.01.19ZM9.14,15.94a1.13,1.13,0,0,1,.06.16h0a.53.53,0,0,1,.06.23L9.17,20.8a.44.44,0,0,1-.39.46.43.43,0,0,1-.38-.47l.09-4.53h0l-2.4-4.71L6,11.36V21a.4.4,0,0,1-.8,0V10.49a1.74,1.74,0,0,1,.44-1.37l3.5-3.48a1.05,1.05,0,0,1,.61-.22h0a1.2,1.2,0,0,1,1.14.87L12,10.48h0l2.7.92a4,4,0,0,0-.58.49L11.56,11,10.24,8.23,6.92,11.51h0Z"
        />
        <path
          id="Path_5205"
          data-name="Path 5205"
          d="M12.77,6a2.59,2.59,0,1,1,2.58-2.58A2.59,2.59,0,0,1,12.77,6Zm0-4.16a1.58,1.58,0,1,0,1.57,1.58,1.58,1.58,0,0,0-1.57-1.58Z"
        />
      </svg>
    </SvgIcon>
  );
}
