import React from 'react';
import {SubPmrRoutes} from '../Pmr';
import {Container, Pagination, Paper, Box, Button, Alert} from '@mui/material';
import {getCorrectTitle, finalizedHideIfCompliedToSelection} from '../util';
import {ReservationsContext} from 'src/context/reservations/reservations.store';
import {PmrLoader} from '../components/PmrLoader';
import {ReservationItem} from '../components/ReservationItem';
import {Search} from './Search';
import Refusal from '../components/refusal';
import {DisabledReservationDto} from 'src/services/models/disabled-reservation-dto';
import CloseIcon from '@mui/icons-material/Close';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {NoReservationsIcon} from '../icons';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

type Props = {
  variant: SubPmrRoutes;
};

const PmrView: React.FC<Props> = ({variant}) => {
  const {
    reservations,
    reservationsInProgress,
    isFetchingProposalReservations,
    isFetchingPendingReservations,
    cancelRefusalModal,
    setCancelRefusalModal,
    searchedReservations,
    initReservationsStore,
    setFocusedReservation,
    setIsDeclineViewVisible,
    handleLengthCalc,
  } = React.useContext(ReservationsContext);

  const {variables} = React.useContext(EnvironmentContext);
  const {t} = useTranslation();

  const getCorrectData = () => {
    if (variant === SubPmrRoutes.OVERVIEW) {
      return reservations;
    }
    if (variant === SubPmrRoutes.VALIDATION) {
      return reservationsInProgress;
    }

    if (variant === SubPmrRoutes.SEARCH) {
      return searchedReservations;
    }

    return [];
  };

  const [pagination, setPagination] = React.useState(0);
  const [reservationsToShow, setReservationsToShow] = React.useState<DisabledReservationDto[]>([]);
  const [hasBeenRefreshed, setHasBeenRefreshed] = React.useState(true);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setHasBeenRefreshed(false);
    }, variables.PMR_REFRESH_RATE * 60000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  const itemsToRender = React.useMemo(() => {
    // Step 1: Sort reservations
    const sortedReservations = [...reservationsToShow].sort((a, b) => {
      //@ts-ignore
      const dateA = moment(a.travels[0].journeys[0].departureTime);
      //@ts-ignore
      const dateB = moment(b.travels[0].journeys[0].departureTime);
      return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
    });

    // Step 2: Filter and collect items
    const resultsToBeSliced: Array<JSX.Element | null> = sortedReservations
      .map((res, ind) => {
        const item = <ReservationItem key={res.id} reservation={res} ind={pagination * 10 + ind} variant={variant} />;

        if (variant === SubPmrRoutes.SEARCH) {
          return item;
        } else {
          return finalizedHideIfCompliedToSelection(item, res);
        }
      })
      .filter(item => item !== null); // Remove null items

    // Step 3: Slice for pagination
    return resultsToBeSliced.slice(pagination * 10, pagination * 10 + 10);
  }, [pagination, reservationsToShow, variant]);

  React.useEffect(() => {
    variables.USE_PMR_3 && initReservationsStore();
  }, []);

  React.useEffect(() => {
    setIsDeclineViewVisible(false);
    setFocusedReservation(null);
    setPagination(0);
    setReservationsToShow(getCorrectData());
  }, [reservations, reservationsInProgress, searchedReservations, variant]);

  if (window.location.pathname.includes('overview')) {
    if (isFetchingProposalReservations && variant !== SubPmrRoutes.SEARCH) {
      return <PmrLoader />;
    }
  } else if (window.location.pathname.includes('validation-in-progress')) {
    if (isFetchingPendingReservations && variant !== SubPmrRoutes.SEARCH) {
      return <PmrLoader />;
    }
  }

  const getPagination = () => {
    const affectedData = getCorrectData(); // Make sure this function returns the correct filtered data
    const removedIfAccepted: Array<JSX.Element> = [];

    affectedData.forEach((res, ind) => {
      if (variant === SubPmrRoutes.SEARCH) {
        removedIfAccepted.push(<ReservationItem key={res.id} reservation={res} ind={ind} variant={variant} />);
      } else {
        const item = finalizedHideIfCompliedToSelection(
          <ReservationItem key={res.id} reservation={res} ind={ind} variant={variant} />,
          res,
        );
        if (item) {
          removedIfAccepted.push(item);
        }
      }
    });

    const length = removedIfAccepted.length;
    return Math.ceil(length / 10);
  };

  const reservationVariant = () => {
    if (window.location.pathname.includes('overview')) {
      return handleLengthCalc.revLength;
    } else if (window.location.pathname.includes('validation-in-progress')) {
      return handleLengthCalc.revProgLength;
    }
    return 0;
  };

  if (reservationVariant() === 0 && variant !== SubPmrRoutes.SEARCH) {
    return (
      <Container
        maxWidth={false}
        sx={{
          overflowY: 'scroll !important',
          maxHeight: '100vh',
          minHeight: '100vh',
          paddingBottom: '120px',
          paddingLeft: '0px !important',
          paddingRight: '0px !important',
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <Box sx={{textAlign: 'center'}}>
          <NoReservationsIcon />
          <h4 style={{marginTop: '25px'}}>
            {variant === SubPmrRoutes.VALIDATION ? t('pmr.none-to-validate') : t('pmr.none-new-res')}
          </h4>
          <p style={{color: 'grey', fontWeight: 'bold'}}>{t('pmr.no-res-warning')}</p>
        </Box>
      </Container>
    );
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        overflowY: 'scroll !important',
        maxHeight: '100vh',
        minHeight: '100vh',
        paddingBottom: '120px',
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
      }}
    >
      <Paper sx={{padding: '28px 28px'}} elevation={0}>
        <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '25px'}}>
          <h3>{getCorrectTitle(variant, t)}</h3>
          {variant !== SubPmrRoutes.SEARCH && (
            <Button
              variant="contained"
              onClick={() => {
                initReservationsStore();
                setHasBeenRefreshed(true);
              }}
            >
              {t('pmr.refresh')}
            </Button>
          )}
        </Box>
        {!hasBeenRefreshed && variant !== SubPmrRoutes.SEARCH && (
          <Alert
            sx={{marginBottom: '15px', textAlign: 'center', width: '100%', display: 'grid', placeItems: 'center'}}
            severity="error"
            icon={false}
          >
            <p style={{width: '100%', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', marginBottom: 0}}>
              {t('pmr.refresh-warning-one')} {variables.PMR_REFRESH_RATE} {t('pmr.refresh-warning-two')}{' '}
              <span
                style={{textDecoration: 'underline', cursor: 'pointer'}}
                onClick={() => {
                  initReservationsStore();
                  setHasBeenRefreshed(true);
                }}
              >
                {t('pmr.refresh-now')}
              </span>
            </p>
          </Alert>
        )}
        {variant === SubPmrRoutes.SEARCH && <Search />}
        {reservationsToShow.length > 10 && (
          <Pagination
            page={pagination + 1}
            onChange={(_e, value) => {
              setPagination(value - 1);
              setIsDeclineViewVisible(false);
              setFocusedReservation(null);
            }}
            count={getPagination()}
          />
        )}
        {itemsToRender}
        {reservationsToShow.length > 10 && (
          <Pagination
            page={pagination + 1}
            onChange={(_e, value) => {
              setPagination(value - 1);
              setIsDeclineViewVisible(false);
              setFocusedReservation(null);
            }}
            count={getPagination()}
          />
        )}
        {variant !== SubPmrRoutes.SEARCH && reservationsToShow.length === 0 ? (
          <Box sx={{backgroundColor: 'white', padding: '25px', display: 'grid', placeItems: 'center', height: '80vh'}}>
            <Box>
              <CloseIcon sx={{color: 'grey', fontSize: '68px'}} />
            </Box>
          </Box>
        ) : null}
      </Paper>
      {cancelRefusalModal && <Refusal.Modal open={cancelRefusalModal} onClose={setCancelRefusalModal} />}
    </Container>
  );
};

export {PmrView};
