import React from 'react';

const DeleteIcon = () => {
  return (
    <svg id="ic_delete" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        id="Path_5734"
        data-name="Path 5734"
        d="M3.25,4.5V3.25H8.91a1.43,1.43,0,0,1,.28-.62,1.84,1.84,0,0,1,.56-.43,2.16,2.16,0,0,1,1-.2h2.54a2.55,2.55,0,0,1,1.15.29,1.29,1.29,0,0,1,.69,1h5.62V4.5Zm17.5,1.25V7H19.5L18.25,20.75A1.11,1.11,0,0,1,17,22H7a1.11,1.11,0,0,1-1.25-1.25L4.5,7H3.25V5.75ZM7,20.75H17L18.25,7H5.75ZM7.86,9.54l1.21-.12.78,8.75-1.21.12Zm4.77-.08v8.79H11.3V9.46Zm1.44,8.71.78-8.75,1.29.12-.78,8.75Z"
        fill="#333"
      />
      <rect id="Rectangle_1989" data-name="Rectangle 1989" width="24" height="24" fill="none" />
    </svg>
  );
};

export default DeleteIcon;
