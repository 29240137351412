// @ts-nocheck
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function Folder(props) {
  return (
    <SvgIcon {...props}>
      <svg id="ic_folder" width="24" height="24" viewBox="0 0 24 24" {...props}>
        <defs>
          <clipPath id="clipPath">
            <rect
              id="Rectangle_1958"
              data-name="Rectangle 1958"
              width="24"
              height="24"
              fill={props.disabled ? '#a8a8a8' : '#333333'}
            />
          </clipPath>
        </defs>
        <g id="Group_8896" data-name="Group 8896" clipPath="url(#clipPath)">
          <path
            id="Path_5686"
            data-name="Path 5686"
            d="M20.75,4.462a1.176,1.176,0,0,1,.7.2,1.218,1.218,0,0,1,.391.431A1.265,1.265,0,0,1,22,5.719v13.82a1.175,1.175,0,0,1-.2.726,1.263,1.263,0,0,1-.43.373,1.226,1.226,0,0,1-.624.157H3.25a1.227,1.227,0,0,1-.625-.157,1.272,1.272,0,0,1-.43-.373A1.168,1.168,0,0,1,2,19.539V4.462A1.317,1.317,0,0,1,2.527,3.4a1.159,1.159,0,0,1,.723-.2h7.3L12,4.462Zm0,2.513a1.63,1.63,0,0,0-.195-.628,1.281,1.281,0,0,0-.371-.432,1.1,1.1,0,0,0-.685-.2H10.75L9.5,4.462h-5a1.159,1.159,0,0,0-.723.2,1.313,1.313,0,0,0-.527,1.06V18.282a1.161,1.161,0,0,0,.195.746,1.349,1.349,0,0,0,.43.354,1.237,1.237,0,0,0,.625.157h15a1.243,1.243,0,0,0,.626-.157,1.371,1.371,0,0,0,.43-.354,1.161,1.161,0,0,0,.195-.746Z"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
