import {Box, MenuItem, TextField, TextareaAutosize} from '@mui/material';
import moment from 'moment';
import React from 'react';
import {CacheContext} from 'src/context/cache/cache.store';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {GeoDurationResponseDto} from 'src/services/models/geo-duration-response-dto';
import {TaxiDto} from 'src/services/models/taxi-dto';
import {getDrivingDuration} from '../data';
import {ChangeRouteContext} from '../ChangeRoute.store';
import {AxiosError} from 'axios';
import {NetworkContext} from 'src/context/network/network.store';
import {ModelError} from 'src/services/models';
import {ChangeRouteActionTypes} from '../reducer';
import StationAutoComplete from './StationAutoComplete';
import {useTranslation} from 'react-i18next';

export const AlternativeRaxiRoute = ({
  selectedTaxiCompany,
  setSelectedTaxiCompany,
  travelTime,
  setTravelTime,
  departureComment,
  setDepartureComment,
  arrivalComment,
  setArrivalComment,
  comment,
  setComment,
}: {
  selectedTaxiCompany: TaxiDto;
  setSelectedTaxiCompany: React.Dispatch<TaxiDto>;
  travelTime: GeoDurationResponseDto;
  setTravelTime: React.Dispatch<GeoDurationResponseDto>;
  departureComment: string;
  setDepartureComment: React.Dispatch<string>;
  arrivalComment: string;
  setArrivalComment: React.Dispatch<string>;
  comment: string;
  setComment: React.Dispatch<string>;
}) => {
  const {taxiCompanies} = React.useContext(CacheContext);
  const {variables} = React.useContext(EnvironmentContext);
  const {manualRouteCreation, manualRouteCreationDispatch} = React.useContext(ChangeRouteContext);
  const {validateNetworkCall} = React.useContext(NetworkContext);
  const {t} = useTranslation();

  const calculateTaxiTime = async () => {
    if (manualRouteCreation.departureName && manualRouteCreation.arrivalName) {
      await getDrivingDuration(variables.BASE_ENDPOINT, {
        fromStationUicCode: manualRouteCreation.departureUic,
        toStationUicCode: manualRouteCreation.arrivalUic,
      })
        .then(res => {
          if (res) {
            setTravelTime(res);
          }
        })
        .catch((err: AxiosError) => {
          if (err.response) {
            validateNetworkCall(err.response?.status, err.response?.data as ModelError);
          }
        });
    }
  };

  React.useEffect(() => {
    calculateTaxiTime();
  }, [manualRouteCreation.arrivalUic]);

  const handleArrivalChange = (val: string, name: string) => {
    manualRouteCreationDispatch({type: ChangeRouteActionTypes.ARRIVAL, payload: {name: name, uic: val}});
  };

  return (
    <Box
      sx={{
        backgroundColor: '#F7F7F7',
        padding: '15px',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '15px',
      }}
    >
      <TextField
        fullWidth
        select
        defaultValue={selectedTaxiCompany.id}
        label={t('select-taxi-company')}
        sx={{backgroundColor: 'white'}}
        onChange={e => setSelectedTaxiCompany(taxiCompanies.find(el => el.id === e.target.value) || taxiCompanies[0])}
      >
        {taxiCompanies
          .filter(el => {
            const today = moment();
            if (today.isAfter(moment(el.validUntill))) {
              return false;
            }
            return true;
          })
          .map(el => {
            return (
              <MenuItem key={el.id} value={el.id || ''}>
                {el.company}
              </MenuItem>
            );
          })}
      </TextField>
      <TextField
        disabled
        sx={{backgroundColor: 'white'}}
        label={t('placeholders.phone-number')}
        value={selectedTaxiCompany.phoneNumber}
      />
      <TextField
        sx={{backgroundColor: 'white'}}
        placeholder={t('dep.pickup-point')}
        value={departureComment}
        label={t('dep.pickup-point')}
        onChange={e => setDepartureComment(e.target.value)}
      />
      <StationAutoComplete handleArrivalChange={handleArrivalChange} />
      <TextField
        sx={{backgroundColor: 'white'}}
        placeholder={t('arr-dropoff-point')}
        value={arrivalComment}
        label={t('arr-dropoff-point')}
        onChange={e => setArrivalComment(e.target.value)}
      />
      <TextareaAutosize
        style={{minHeight: '100px', borderRadius: '5px', padding: '5px'}}
        placeholder={t('placeholders.add-note')}
        value={comment}
        onChange={e => setComment(e.target.value)}
      />
      <TextField
        sx={{backGroundColor: 'white'}}
        label={t('estimated-driving-time')}
        value={
          travelTime.timeInSeconds === 0 || travelTime.timeInSeconds === undefined
            ? '--:--'
            : moment((travelTime.timeInSeconds || 0) * 1000)
                .subtract({hours: 1})
                .format('HH:mm:ss')
        }
      />
    </Box>
  );
};
