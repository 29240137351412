import {StationDto} from 'src/services/models';
import {localStorageKeys} from './localStorageKeys';

export const isFullDistrictSelected = () => {
  const stations = JSON.parse(localStorage.getItem(localStorageKeys.CACHE_STATIONS) || JSON.stringify(''))
    .data as StationDto[];
  const districtId = localStorage.getItem(localStorageKeys.SELECTED_DISTRICT) || '';
  const selectedStations = (localStorage.getItem(localStorageKeys.SELECED_STATIONS) || '').split(',');

  if (
    stations &&
    stations.length > 0 &&
    districtId &&
    districtId.length > 0 &&
    selectedStations &&
    selectedStations.length > 0
  ) {
    const stationsRelatedToDistrict = stations.filter(s => s.districtId === districtId);
    return stationsRelatedToDistrict.length === selectedStations.length;
  }

  return false;
};
