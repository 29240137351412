import {Paper} from '@mui/material';
import React from 'react';
import {MissionDetailDto} from 'src/services/models/mission-detail-dto';
import {GroupNameChip} from './Chips';
import {useTranslation} from 'react-i18next';
import {LanguageDto} from 'src/services/models';

const getCorrectLanguageTranslation = (lang: LanguageDto | undefined) => {
  switch (lang) {
    case LanguageDto.Dutch:
      return 'choose-language.language-nl';
    case LanguageDto.English:
      return 'choose-language.language-en';
    case LanguageDto.French:
      return 'choose-language.language-fr';
    case LanguageDto.German:
      return 'choose-language.language-ge';
    default:
      return 'choose-language.language-nl';
  }
};

const ClientInformation: React.FC<{mission: MissionDetailDto}> = ({mission}) => {
  const {t} = useTranslation();

  const [translation, setTranslations] = React.useState('choose-language.language-nl');

  return (
    <Paper
      elevation={1}
      sx={{
        borderRadius: '12px',
        padding: '24px',
        border: 'solid 1px #D7D7D7',
      }}
    >
      <h4>{t('mission-details-v2.client-information.title')}</h4>
      <h5
        style={{
          color: '#0069B4',
          marginTop: '24px',
        }}
      >
        {mission.client?.firstName} {mission.client?.lastName}
      </h5>
      <p>
        {t('mission-details-v2.client-information.language')}:{' '}
        {t(getCorrectLanguageTranslation(mission.client?.language))}
      </p>
      <hr />
      <p style={{marginBottom: '0px'}}>{mission.client?.email}</p>
      <a style={{display: 'block', marginBottom: '16px'}} href={`tel:${mission.client?.phoneNumber}` || ''}>
        {mission.client?.phoneNumber}
      </a>
      {mission.client?.description && <GroupNameChip groupName={mission.client?.description || ''} />}
    </Paper>
  );
};

export {ClientInformation};
