function secondsToHms(d: number) {
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + 'h ' : '';
  var mDisplay = m > 0 ? m + 'min ' : '';
  var sDisplay = s > 0 ? s + 'sec ' : '';
  return hDisplay + mDisplay + sDisplay;
}

export const calculateDurationOfTime = (
  departureTime: Date | undefined | null,
  arrivalTime: Date | undefined | null,
): string => {
  const time = new Date(arrivalTime as Date).getTime() - new Date(departureTime as Date).getTime();
  const formatted = secondsToHms(time / 1000);
  return formatted;
};
