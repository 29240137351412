import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {BottomNavigation, Form, ListView, ReservationModalView} from 'src/components';
import {GroupReservationContext} from './GroupReservation.store';
import {GroupReservationBox} from 'src/components/ReservationBox/GroupReservationBox';
import {MissionContext} from '../MissionOverview/missions.store';
import {SelectStationComponent, TargetScreen} from 'src/utils/selectStation';

export function Reservation() {
  const {t} = useTranslation();

  const {clearMissions} = React.useContext(MissionContext);

  const {
    loadingMissions,
    groupMissions,
    groupMissionDate,
    groupMissionFilterState,
    groupMissionFilterReducer,
    handleGroupMissionSearch,
    handleDateChange,
    searchOnReservation,
    setSearchOnReservation,
    activeMissions,
    activeMissionAssistance,
    handleActiveMissionAssistance,
    handleChangeAssistance,
    assistanceFilter,
    missionModal,
    setMissionModal,
  } = React.useContext(GroupReservationContext);

  const loading = false;
  const postCreateMission = () => {
    handleChangeAssistance();
  };

  React.useEffect(() => {
    clearMissions();
  }, []);

  if (localStorage.getItem('selectedStations')?.length === 0) {
    return <SelectStationComponent target={TargetScreen.RSERVATIONS} />;
  }

  return (
    <Box
      sx={{
        maxHeight: '100vh',
        paddingLeft: '0px',
        paddingRight: '0px',
        overflowY: 'scroll !important',
      }}
    >
      <Box sx={{padding: '20px', overflowY: 'auto', overflowX: 'hidden', marginBottom: '100px'}}>
        <Typography variant="h5"> {t('reservation.title')} </Typography>
        <Form
          searchOnReservation={searchOnReservation}
          toggleSearchOnReservation={setSearchOnReservation}
          isReservation
          missionDates={{
            startDate: groupMissionDate,
            endDate: '',
          }}
          //@ts-ignore
          handleMissionDates={date => handleDateChange(date)}
          filterState={groupMissionFilterState}
          filterReducer={groupMissionFilterReducer}
          handleSearch={handleGroupMissionSearch}
        />
        <ListView
          title={t('reservation.title-list')}
          data={groupMissions}
          loading={loadingMissions}
          isReservationFilter={true}
          Item={GroupReservationBox}
          filterState={groupMissionFilterState}
          filterReducer={groupMissionFilterReducer}
          assistanceFilter={assistanceFilter}
        />
      </Box>
      <BottomNavigation
        isReservation
        isOpen={missionModal}
        loading={loading}
        isCreateMissionModalOpen={missionModal}
        setMissionModal={setMissionModal}
        onSubmit={postCreateMission}
        activeReservation={activeMissions.length > 0}
        ModalViewComponent={
          <ReservationModalView
            assistances={activeMissionAssistance}
            reservation={activeMissions[0]}
            handleActiveMissionAssistance={handleActiveMissionAssistance}
          />
        }
      />
    </Box>
  );
}
