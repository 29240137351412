import * as React from 'react';

import {MissionContext} from '../MissionOverview/missions.store';
import {PrintSickers} from './PrintStickers';
import {SelectStationComponent, TargetScreen} from 'src/utils/selectStation';

const PrintStickerView = () => {
  const {clearMissions} = React.useContext(MissionContext);

  React.useEffect(() => {
    clearMissions();
  }, []);

  if (localStorage.getItem('selectedStations')?.length === 0) {
    return <SelectStationComponent target={TargetScreen.MISSIONS} />;
  }
  return <PrintSickers />;
};

export default PrintStickerView;
