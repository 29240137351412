import {Box, Grid, Stack} from '@mui/material';
import JourneyIcon from 'src/components/images/ico_journey.svg';

export const JourneySteppers: React.FC<{start: string; end: string}> = ({start, end}) => {
  return (
    <Grid container spacing={1} wrap="nowrap">
      <Grid item xs={2} sx={{display: 'grid', placeItems: 'center'}}>
        <img src={JourneyIcon} alt="Select Journey" />
      </Grid>
      <Grid item xs={10}>
        <Stack>
          <Box sx={{fontSize: '11px', fontWeight: 'bold', marginBottom: '15px'}}>{start}</Box>
          <Box sx={{fontSize: '11px', fontWeight: 'bold'}}>{end}</Box>
        </Stack>
      </Grid>
    </Grid>
  );
};
