import {Box} from '@mui/material';
import React from 'react';

import PersonIcon from '@mui/icons-material/Person';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';

export const AssignedCoi: React.FC<{id: string; name: string; phone: string}> = props => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(1,15%) auto 1fr',
        padding: '10px',
        paddingLeft: '15px',
        gap: '10px',
        minHeight: '48px',
      }}
    >
      <Box></Box>
      <Box>
        <PersonIcon /> <span style={{fontWeight: 'bold'}}>{props.name}</span>
      </Box>
      <div style={{display: 'flex', justifyContent: 'flex-end', gap: '24px'}}>
        <span style={{display: 'flex', gap: '5px'}}>
          <LocalPhoneOutlinedIcon color={'info'} />
          <a
            href={`dica:${props.phone?.replace('+', '000')}`}
            style={{justifySelf: 'end', textDecoration: 'none', fontWeight: 'bold'}}
          >
            {props.phone}
          </a>
        </span>
      </div>
    </Box>
  );
};
