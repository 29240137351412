import {Chip} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';

export const TrainNumberChip: React.FC<{trainNumber: string}> = ({trainNumber}) => {
  return (
    <Chip
      variant="outlined"
      sx={{
        borderRadius: '5px',
        fontWeight: 'bold',
        fontSize: '16px',
        maxHeight: '28px',
        marginBottom: '2px',
        backgroundColor: 'white',
      }}
      label={trainNumber}
    />
  );
};

export const TaxiChip: React.FC = () => {
  return (
    <Chip
      variant="outlined"
      sx={{
        borderRadius: '5px',
        fontWeight: 'bold',
        fontSize: '16px',
        maxHeight: '28px',
        marginBottom: '2px',
        backgroundColor: '#F9F1E7',
        color: '#C67E1E',
        border: 'none',
      }}
      label={'TAXI'}
    />
  );
};

export const AssistanceNeededChip = () => {
  const {t} = useTranslation();
  return (
    <Chip
      sx={{color: 'green', fontWeight: 'bold', backgroundColor: '#EAF2EA'}}
      label={t('mission-details-v2.traveler.assistance-needed')}
    />
  );
};

export const GroupNameChip: React.FC<{groupName: string}> = ({groupName}) => {
  return <Chip sx={{color: '#BA6C11', fontWeight: 'bold', backgroundColor: '#F9F1E7'}} label={groupName} />;
};
