// src/components/CoiCreateIncidentProvider.tsx
import React from 'react';
import {getCOIReferenceData} from 'src/scenes/coi/data';
import {EnvironmentContext} from '../environment/environment.store';
import {ExternalCatalogDto} from 'src/services/models/external-catalog-dto';
import {SettingsContext} from '../settings/settings.store';
import useHandleCall from 'src/hooks/useHandleCall';
import {Box, CircularProgress} from '@mui/material';

export type COIStore = {
  referenceData: ExternalCatalogDto[];
};

const CoiCreateIncidentContext = React.createContext<COIStore>({} as COIStore);

const CoiCreateIncidentProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const {variables} = React.useContext(EnvironmentContext);
  const {COI_FEATURE_FLAG} = React.useContext(SettingsContext);
  const {executeCall} = useHandleCall<ExternalCatalogDto[]>();

  // ReferenceData
  const [referenceData, setReferenceData] = React.useState<ExternalCatalogDto[]>([]);

  const init = async () => {
    const refData = await executeCall(getCOIReferenceData(variables.BASE_ENDPOINT));
    if (refData) {
      setReferenceData(refData);
    }
  };

  React.useEffect(() => {
    if (COI_FEATURE_FLAG) {
      init();
    }
  }, [COI_FEATURE_FLAG]);

  return <CoiCreateIncidentContext.Provider value={{referenceData}}>{children}</CoiCreateIncidentContext.Provider>;
};

export const useCoiCreateIncidentContext = () => {
  const coiStore = React.useContext(CoiCreateIncidentContext);
  return coiStore;
};

export default CoiCreateIncidentProvider;
