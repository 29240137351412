import {Box, Stack} from '@mui/system';
import React from 'react';
import {OriginalRouteStepMap} from '../util/OriginalRouteStepMap';
import {Backdrop, Button, Chip, Dialog} from '@mui/material';
import {HorizontalLine, StepButton} from '../common';
import {ChangeRouteContext} from '../ChangeRoute.store';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {JourneySteppers} from '../steppers/steppers';

export const OriginalRouteStep = ({
  route,
  isSelected,
  handleClick,
  ind,
  withHover,
  withDelete,
}: {
  route: OriginalRouteStepMap;
  isSelected: boolean;
  handleClick: (ind: number, proposal: OriginalRouteStepMap) => void;
  ind: number;
  withHover: boolean;
  withDelete: undefined | ((ind: number) => void);
}) => {
  const trainContainerRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [lineHeight, setLineHeight] = React.useState('0px');
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  React.useEffect(() => {
    if (trainContainerRef && trainContainerRef.current) {
      const h = trainContainerRef.current.clientHeight;
      const lastAssistance = route.arrivalStation.requiresAssistance;
      setLineHeight((h - (lastAssistance ? 60 : 30)).toString() + 'px');
    }
  }, [trainContainerRef, route]);

  const {selectedOriginalRoute} = React.useContext(ChangeRouteContext);
  const {t} = useTranslation();

  const originalDepartureDate = moment(
    (selectedOriginalRoute && selectedOriginalRoute[0] && selectedOriginalRoute[0].departureTime) || '',
  ).format('DD.MM.YYYY');
  return (
    <>
      <Backdrop
        open={openBackdrop}
        sx={{
          backgroundColor: '#80808099',
          zIndex: theme => theme.zIndex.drawer + 1,
          position: 'absolute',
          width: (containerRef.current?.clientWidth as number) + 2,
          height: (containerRef.current?.clientHeight as number) + 2,
          left: '25px',
          borderRadius: '8px',
          border: isSelected ? 'solid 1px #ACCEE6' : 'solid 1px #DFDFDF',
        }}
        onMouseLeave={() => {
          setOpenBackdrop(false);
        }}
      >
        <Button onClick={() => handleClick(ind, route)} variant="contained" sx={{textTransform: 'none'}}>
          {isSelected ? t('cr.deselect-proposal') : t('cr.select-proposal')}
        </Button>
      </Backdrop>
      <Box
        ref={containerRef}
        sx={{
          marginTop: '25px',
          border: isSelected ? 'solid 1px #ACCEE6' : 'solid 1px #DDDDDD',
          borderRadius: '8px',
          display: 'grid',
          gridTemplateRows: '65px 1fr',
          paddingBottom: '25px',
          gridTemplateColumns: '70px 1fr',
          backgroundColor: isSelected ? '#F5F9FC' : 'white',
          position: 'relative',
        }}
        onMouseEnter={() => {
          withHover && setOpenBackdrop(true);
        }}
      >
        {withDelete !== undefined && (
          <Button
            variant="text"
            startIcon={<DeleteForeverIcon sx={{color: 'red'}} />}
            onClick={() => setShowDeleteDialog(true)}
            sx={{position: 'absolute', top: '10px', right: '5px', zIndex: 99}}
          />
        )}
        <Box sx={{gridColumn: '1/3', position: 'relative'}}>
          <Chip
            variant="outlined"
            sx={{position: 'absolute', bottom: '15px', left: '58px', borderRadius: '5px', fontWeight: 'bold'}}
            label={route.trainNumber}
          />
          {withDelete == undefined && (
            <span
              style={{
                fontWeight: 'bold',
                borderRadius: '4px',
                padding: '0px 8px',
                marginRight: '4px',
                border: route.arrivalDate === originalDepartureDate ? 'solid 2px black' : 'solid 2px #0069B4',
                color: route.arrivalDate === originalDepartureDate ? 'black' : '#0069B4',
                position: 'absolute',
                bottom: '15px',
                right: '10px',
              }}
            >
              {route.arrivalDate}
            </span>
          )}
        </Box>
        <Box sx={{gridColumn: '1/2', gridRow: '2/3', position: 'relative'}}>
          <Chip
            sx={{
              position: 'absolute',
              top: '0px',
              right: '0px',
              backgroundColor: '#25336C',
              color: 'white',
              borderRadius: '5px',
            }}
            label={route.departureTime}
          />
          <Chip
            sx={{
              position: 'absolute',
              bottom: `${route.arrivalStation.requiresAssistance ? '35px' : '-5px'}`,
              right: '0px',
              backgroundColor: '#25336C',
              color: 'white',
              borderRadius: '5px',
            }}
            label={route.arrivalTime}
          />
        </Box>
        <Box
          ref={trainContainerRef}
          sx={{gridColumn: '2/3', gridRow: '2/3', paddingLeft: '8px', paddingTop: '5px', position: 'relative'}}
        >
          <HorizontalLine height={lineHeight} left="17px" top="17px" />
          <Stack spacing={3}>
            <Box>
              <Box sx={{display: 'grid', gridTemplateColumns: '20px 1fr'}}>
                {' '}
                <StepButton alternate={true} /> <b style={{paddingLeft: '8px'}}>{route.departureStation.name}</b>
              </Box>
              {route.departureStation.requiresAssistance && (
                <p style={{paddingLeft: '28px', marginBottom: '0px'}}>{t('cr.requires-assistance')}</p>
              )}
            </Box>
            {route.itineraries.map((el, ind) => (
              <Box
                key={el.toString() + ind.toString()}
                sx={{display: 'grid', gridTemplateColumns: '20px 1fr', alignItems: 'center', marginBottom: '15px'}}
              >
                <StepButton alternate={false} />
                <p style={{paddingLeft: '8px', marginBottom: '0px'}}>{el}</p>
              </Box>
            ))}
            <Box>
              <Box sx={{display: 'grid', gridTemplateColumns: '20px 1fr'}}>
                {' '}
                <StepButton alternate={true} /> <b style={{paddingLeft: '8px'}}>{route.arrivalStation.name}</b>
              </Box>
              {route.arrivalStation.requiresAssistance && (
                <p style={{paddingLeft: '28px'}}>{t('cr.requires-assistance')}</p>
              )}
            </Box>
          </Stack>
        </Box>
        <Dialog open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
          <Box sx={{textAlign: 'center', padding: '20px 40px', width: '328px'}}>
            <p>
              <b>{t('cr.remove-proposal')}</b>
            </p>
            <p>
              {t('cr.sure')} <br /> {t('cr.delete')}
            </p>
            <Box
              sx={{
                border: 'solid 1px black',
                borderRadius: '8px',
                maxWidth: '200px',
                margin: '0px auto',
                textAlign: 'left',
                padding: '15px 0px 15px 15px',
              }}
            >
              <JourneySteppers start={route.departureStation.name || ''} end={route.arrivalStation.name || ''} />
            </Box>
            <Box sx={{display: 'grid', gridTemplateColumns: '1fr 1fr', width: '200px', margin: '15px auto'}}>
              <Button variant="text" onClick={() => setShowDeleteDialog(false)}>
                {t('back-btn')}
              </Button>
              <Button variant="contained" onClick={() => withDelete && withDelete(ind)}>
                {t('check-in.btn-yes')}
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>
    </>
  );
};
