import { FC } from 'react';
import { Box } from '@mui/material';

export const Circular: FC<{ color: string }> = ({ color }) => {
  return (
    <span
      style={{
        display: 'inline-block',
        width: '12px',
        height: '12px',
        backgroundColor: color,
        border: 'solid 3px white',
        borderRadius: '15px',
        boxShadow: 'rgb(144 143 143) 0px 1px 3px 0px',
      }}
    />
  );
};

export const StatusItem: FC<{ label: string, color: string }> = ({ label, color }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Circular color={color} />
      <span
        style={{
          color: color,
          fontSize: '12px',
          fontWeight: 'bold',
          marginLeft: '5px',
        }}
      >
        {label ? label : null}
      </span>
    </Box>
  );
};
