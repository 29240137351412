// @ts-nocheck

import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function Blind(props) {
  return (
    <SvgIcon {...props}>
      <svg id="ic_blind" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
        <path id="Path_5218" data-name="Path 5218" d="M0,0H24V24H0Z" fill="none" />
        <path
          id="Path_5219"
          data-name="Path 5219"
          d="M20,20.72l-5-8.06a1.24,1.24,0,0,0,.26-.59,1.66,1.66,0,0,0-.46-1.23c-.2-.2-2-2-2.75-2.93L10.88,6.63a2.41,2.41,0,0,0-.82-.58A2.367,2.367,0,0,0,8.75,1.52a2.32,2.32,0,0,0-1.51,1,2.31,2.31,0,0,0-.37,1.77,2.36,2.36,0,0,0,1,1.52A3.78,3.78,0,0,0,8.22,6a2.62,2.62,0,0,0-.56.27L4.46,8.38a2.39,2.39,0,0,0-1,2.43L3.87,13a1.45,1.45,0,0,0,.61.92,1.43,1.43,0,0,0,1.08.23,1.48,1.48,0,0,0,.93-.61,1.4,1.4,0,0,0,.22-1.09l-.34-1.82.4-.25v6.4L4.78,20.85v.21a1.43,1.43,0,0,0,.51,1.12,1.45,1.45,0,0,0,.94.34,1.32,1.32,0,0,0,.34,0c.62-.11.9-.65,1.13-1.08l2-3.61.23-.86,1,1,.83,3.45a1.46,1.46,0,0,0,1.43,1.17,1.24,1.24,0,0,0,.27,0,1.45,1.45,0,0,0,1.14-1.71l-1-4.27a5.21,5.21,0,0,0-.78-1.19L11.5,13.86V11.37l.19.19h0c.88.86,1.77,1.73,2.55,1.62l5,8.05a.49.49,0,0,0,.41.23.59.59,0,0,0,.26-.07.49.49,0,0,0,.09-.67ZM10.54,3.88ZM8.1,3.11A1.27,1.27,0,0,1,9,2.55a1,1,0,0,1,.25,0,1.32,1.32,0,0,1,.74.23,1.34,1.34,0,0,1,.35,1.84,1.3,1.3,0,0,1-.85.55,1.32,1.32,0,0,1-1.54-1A1.31,1.31,0,0,1,8.1,3.11Zm2.05,1.68Zm-.43.3Zm.72-.72ZM9.18,2.5ZM8.25,4.8ZM8,4.37Zm2.42,9.76h0l1.43,1.68.12.15a5,5,0,0,1,.6.86l1,4.17a.4.4,0,0,1-.62.4.39.39,0,0,1-.17-.28l-.9-3.74h0L9.35,14.91h0L8.7,17.35,6.82,20.72l-.1.19c-.12.23-.27.52-.47.56a.39.39,0,0,1-.33-.09.4.4,0,0,1-.13-.21l1.94-4.08.09-8.61h0L5.21,10.07h0l.48,2.53a.4.4,0,0,1-.06.3.38.38,0,0,1-.25.16.4.4,0,0,1-.3-.06.39.39,0,0,1-.17-.25L4.5,10.6a1.33,1.33,0,0,1,.56-1.35L8.24,7.12a1.45,1.45,0,0,1,1.87.22L11.28,8.6c.83,1,2.77,3,2.79,3a.75.75,0,0,1,.17.37c0,.06-.09.13-.19.19h0c-.31,0-1.25-.92-1.66-1.32h0a12.424,12.424,0,0,0-1.12-1l-.8-.63h0Z"
        />
      </svg>
    </SvgIcon>
  );
}
