// @ts-nocheck
import React from 'react';
import 'antd/dist/antd.css';
import {Select} from 'antd';
import './SelectComponent.scss';

const {Option} = Select;

const SelectComponent = ({
  defaultValue = "",
  value,
  options,
  onSelectedChange,
  isAvoid,
  isItalic,
  disabled,
  loading = false,
  isSearch = false,
  placeholder,
  size = "large",
  allowClear = false,
  searchOnLabel = false
}) => {
  const renderedOptions = options.map(option => (
    <Option
      key={option.id}
      value={option.value}
      disabled={isAvoid ? false : option.disabled}
      className={!isItalic && 'selectContainerOptions'}
      name={option.name}
    >
      {option.text}
    </Option>
  ));

  const handleFilterStations = (names: any[], value: string) => {
    return (
      names[0]?.text.toLowerCase().includes(value.toLowerCase()) ||
      names[1]?.text.toLowerCase().includes(value.toLowerCase()) ||
      names[2]?.text.toLowerCase().includes(value.toLowerCase())
    );
  };

  return (
    <Select
      size={size}
      allowClear={allowClear}
      loading={loading}
      value={value}
      placeholder={placeholder}
      showSearch={isSearch}
      disabled={disabled || false}
      onChange={(event, obj) => {
        onSelectedChange(event, obj)
      }}
      className={`selectContainer ${!isItalic && 'selectContainerItalic'}`}
      filterOption={(input, option) => {
        return handleFilterStations(option.name, input)
        // return (searchOnLabel ? option.children : option.value).toString().toLocaleUpperCase().includes(input.toLocaleUpperCase())
        }}
      filterSort={(optionA, optionB) => optionA.children?.toLowerCase().localeCompare(optionB.children?.toLowerCase())}
    >
      {renderedOptions}
    </Select>
  );
};
export default SelectComponent;
