import {Box, CircularProgress} from '@mui/material';
import React, {useState, useContext, useEffect, useRef} from 'react';
import {IncidentDetailsDto} from 'src/services/models/incident-details-dto';
import useDebounce from 'src/hooks/useDebounce';
import useHandleCall from 'src/hooks/useHandleCall';
import {ChatMessageDtoPaginatedListResponseDto} from 'src/services/models/chat-message-dto-paginated-list-response-dto';
import {getChats} from '../../data';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {ChatMessagesList} from './chatMessageList';
import {ChatSearch} from './coiChatSearch';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

export enum MessageFilter {
  ALL = 'Show all messages',
  AUTO = 'Only automatic messages',
  MANUAL = 'Only manual messages'
}

export const CoiChatView: React.FC<{incident: IncidentDetailsDto | undefined}> = ({incident}) => {
  if (!incident) {
    return <p>No Incident</p>;
  }

  const {variables} = useContext(EnvironmentContext);
  const location = useLocation();

  // Fetch
  const {executeCall, errorCall, loadingCall} = useHandleCall<ChatMessageDtoPaginatedListResponseDto>();

  // Search Input
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 300);

  // locationId
  const [locationId, setLocationId] = useState<string | null>(null);

  const handleSelectLocation = (locationId: string) => {
    setLocationId(locationId);
  };

  // Track whether initial fetch has been completed
  const isInitialFetch = useRef(false);

  // Filter
  const [selectedOption, setSelectedOption] = useState<MessageFilter>(MessageFilter.ALL);

  const [chats, setChats] = useState<ChatMessageDtoPaginatedListResponseDto | null>(null);

  const handleSelectAutogeneratedValue = (opt: string): null | boolean => {
    if (opt === MessageFilter.AUTO) return true;
    if (opt === MessageFilter.MANUAL) return false;
    return null;
  }

  const fetchChatMessages = async (useMoreResults: boolean, filter: string, option: string) => {
    const params = new URLSearchParams(location.search);
    const idParam = params.get('id');
    if (incident.id) {
      const token = useMoreResults && chats?.moreResultsToken;
      const messages = await executeCall(
        getChats(variables.BASE_ENDPOINT, {
          autoGenerated: handleSelectAutogeneratedValue(option),
          incidentId: idParam || '',
          moreResultsToken: token ? token : null,
          searchQuery: filter,
          locationId: locationId && locationId !== 'all' ? locationId : null
        }),
      );
      if (messages && messages.totalItems === chats?.totalItems) {
        return;
      }
      if (useMoreResults) {
        // Append more results
        return messages;
      } else {
        // Set first results
        setChats(messages);
      }
    }
  };

  const handleFilterChange = async (option: MessageFilter) => {
    setSelectedOption(option);
    await fetchChatMessages(false, debouncedSearchValue, option);
  };

  const handleNewSearch = async () => {
    await fetchChatMessages(false, debouncedSearchValue, selectedOption);
  };

  const appendMoreResults = async () => {
    if (!chats?.moreResultsToken) return; // Stop if there's no token for more results

    const newChats = await fetchChatMessages(true, debouncedSearchValue, selectedOption);

    if (newChats && newChats.items && chats?.items) {
      setChats(prevChats => ({
        ...prevChats,
        items: [...(newChats.items || []), ...(prevChats?.items || [])], // Prepend new items
        moreResultsToken: newChats?.moreResultsToken || null,
        totalItems: prevChats?.totalItems || 0 + (newChats?.totalItems || 0),
      }));
    }
  };

  // Effect to handle the initial fetch
  useEffect(() => {
    if (!isInitialFetch.current) {
      fetchChatMessages(false, '', selectedOption); // Fetch without a search value on initial load
      isInitialFetch.current = true;
    }
  }, [incident.id, selectedOption, location.pathname]); // Runs once when component mounts or dependencies change

  // Effect to handle the debounced search
  useEffect(() => {
    if (isInitialFetch.current) {
      handleNewSearch(); // Trigger the fetch when the debounced search value changes
    }
  }, [debouncedSearchValue, selectedOption, locationId]); // Only runs when debounced search value or filter changes

  // Polling for new messages every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      handleNewSearch(); // Fetch new data every 10 seconds
    }, 10000);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [debouncedSearchValue, selectedOption, locationId]);

  useEffect(() => {
    setChats(null);
  }, [location.pathname]);

  return (
    <Box sx={{display: 'grid', gridTemplateRows: '40px 1fr', padding: 0, height: '100%', gap: '20px'}}>
      <Box
        sx={{
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          borderTop: 'solid 1px #a7cae4',
          borderBottom: 'solid 1px #a7cae4',
          borderRadius: '5px',
        }}
      >
        <ChatSearch
          searchValue={searchValue}
          onSearchChange={setSearchValue}
          selectedOption={selectedOption}
          onFilterChange={handleFilterChange}
        />
      </Box>
      {loadingCall && chats?.items?.length === 0 && (
        <Box
          sx={{
            border: 'solid 1px #a7cae4',
            display: 'grid',
            placeItems: 'center',
            height: '100%',
            maxHeight: '70vh',
            borderRadius: '5px',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {errorCall && (
        <Box
          sx={{
            border: 'solid 1px #a7cae4',
            display: 'grid',
            placeItems: 'center',
            height: '100%',
            maxHeight: '70vh',
            borderRadius: '5px',
          }}
        >
          <b>No chats found</b>
        </Box>
      )}
      {chats && (
        <ChatMessagesList
          chats={chats}
          appendMoreResults={appendMoreResults}
          incident={incident}
          handleNewSearch={handleNewSearch}
          handleSelectLocation={handleSelectLocation}
        />
      )}
    </Box>
  );
};
