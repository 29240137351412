//@ts-nocheck
import React from 'react';

import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {getTime} from './util';

import {AssistanceNeededChip} from '../Chips';
import {JourneyItem} from './JourneyItem';
import {Grid, Paper} from '@mui/material';

import {MissionTypeDto} from 'src/services/models/mission-type-dto';
import {MissionStatusDto} from 'src/services/models/mission-status-dto';
import {JourneyDto} from 'src/services/models/journey-dto';
import {ReservationTypeDto} from 'src/services/models';
import StickerOfIcon from 'src/icons/StickerOfIcon';
import {ReservationJourneyDto} from 'src/services/models/reservation-journey-dto';
import {DisabledJourneyDto} from 'src/services/models/disabled-journey-dto';
import {TransportTypeDto} from 'src/services/models/transport-type-dto';

export const Journey: React.FC<{
  journey: ReservationJourneyDto | DisabledJourneyDto | JourneyDto | undefined;
  status: MissionStatusDto | undefined;
  type: MissionTypeDto | undefined;
  variant: ReservationTypeDto | undefined;
}> = ({journey, status, type, variant}) => {
  const {t} = useTranslation();
  if (!journey) {
    return null;
  }

  const isDepartureMission = () => {
    return type === MissionTypeDto.Departure || type === MissionTypeDto.Stickering;
  };

  const isTaxi = journey?.transportType === TransportTypeDto.Taxi;

  return (
    <Paper
      elevation={1}
      sx={{
        borderRadius: '12px',
        padding: '24px',
        border: `${
          journey?.withArrivalAssistance === true || journey?.withDepartureAssistance === true
            ? 'solid 3px green'
            : 'none'
        }`,
      }}
    >
      <Grid container>
        <Grid container spacing={2}>
          <Grid item width="auto">
            <h4 style={{display: 'inline'}}>{t('mission-details-v2.journey.journey-title')}</h4>
          </Grid>
          <Grid item xs>
            {(journey?.withArrivalAssistance || journey?.withDepartureAssistance) && <AssistanceNeededChip />}
          </Grid>
        </Grid>
        <Grid container sx={{padding: '0px 8px', margin: '15px 0px 5px 0px'}}>
          <Grid item xs={2.8} sx={{marginRight: '110px'}}>
            <h6>{t('mission-details.category-itinerary')}</h6>
          </Grid>
          <Grid item xs={2}>
            <h6>{t('reservation.form.train.label')}</h6>
          </Grid>

          <Grid item xs={2}>
            <h6>{t('mission-details-v2.journey.assist')}</h6>
          </Grid>

          <Grid item xs={2}>
            <h6>{t('mission.mission-details.location.title')}</h6>
          </Grid>
        </Grid>
        {type === MissionTypeDto.Stickering && (
          <JourneyItem
            isExtended={false}
            isFullStop={false}
            isEndOfLine={false}
            location={journey?.coachNumber?.split(',').join(' ') || ''}
            requiresAssistance={false}
            stationName={journey?.originStationName || ''}
            time={getTime(journey?.originTime)}
            trainNumber={''}
            isSticker={true}
            hasStickerFinished={status === MissionStatusDto.Completed}
            isCompleteOverview={false}
            isGrobo={variant === ReservationTypeDto.Group || type === MissionTypeDto.Stickering}
            door={journey?.doorNumber}
            isTaxi={isTaxi}
            wheelchair={journey.isWheelchairOnDepartureNeeded}
          />
        )}
        <JourneyItem
          isExtended={false}
          isFullStop={true}
          isEndOfLine={false}
          location={journey?.coachNumber?.split(',').join(' ') || ''}
          requiresAssistance={journey?.withDepartureAssistance || false}
          stationName={(isDepartureMission() ? journey?.stationName : journey?.otherStationName) || ''}
          time={isDepartureMission() ? getTime(journey?.time) : getTime(journey?.otherTime)}
          trainNumber={journey?.trainNumber || ''}
          isSticker={false}
          hasStickerFinished={false}
          isCompleteOverview={false}
          isGrobo={variant === ReservationTypeDto.Group || type === MissionTypeDto.Stickering}
          door={journey?.doorNumber}
          isTaxi={isTaxi}
          wheelchair={journey.isWheelchairOnDepartureNeeded}
        />
        <JourneyItem
          isExtended={false}
          isFullStop={true}
          isEndOfLine={true}
          location={journey?.coachNumber?.split(',').join(' ') || ''}
          requiresAssistance={journey?.withArrivalAssistance || false}
          stationName={(!isDepartureMission() ? journey?.stationName : journey?.otherStationName) || ''}
          time={!isDepartureMission() ? getTime(journey?.time) : getTime(journey?.otherTime)}
          trainNumber={''}
          isSticker={false}
          hasStickerFinished={false}
          isCompleteOverview={false}
          isGrobo={variant === ReservationTypeDto.Group || type === MissionTypeDto.Stickering}
          door={journey?.doorNumber}
          isTaxi={isTaxi}
          wheelchair={journey.isWheelchairOnArrivalNeeded}
        />
      </Grid>
    </Paper>
  );
};
