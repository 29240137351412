import {styled} from '@mui/material';

const Circle = styled('span')({
  display: 'inline-block',
  height: '14px',
  width: '14px',
  borderRadius: '8px',
});

export const FullCircle = styled(Circle)({
  backgroundColor: 'black',
});
export const SemiCirlce = styled(Circle)({
  border: 'solid 2px black',
});

const VerticalLine = styled('span')({
  display: 'block',
  width: '28px',
  position: 'absolute',
  left: '21px',
  bottom: '-8px',
  transform: 'rotate(90deg)',
});

export const FullVerticalLine = styled(VerticalLine)({
  border: 'solid 1px black',
});

export const ExtendedFullVerticalLine = styled(FullVerticalLine)({
  width: '95px',
  left: '-12px',
  bottom: '-48px',
});

export const SemiVerticalLine = styled(VerticalLine)({
  border: 'dashed 1px black',
});

export const ExtendedSemiVerticalLine = styled(SemiVerticalLine)({
  width: '95px',
  left: '-12px',
  bottom: '-48px',
});
