import React from 'react';

const ExpandIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g id="ic_expand" transform="translate(-10 -10)">
      <path
        id="expand_content_FILL0_wght300_GRAD0_opsz24"
        d="M220,336v-8.462h2.308v6.154h6.154V336Zm17.692-11.538v-6.154h-6.154V316H240v8.462Z"
        transform="translate(-210.001 -306.001)"
      />
    </g>
  </svg>
);

export default ExpandIcon;
