import React from 'react';

const DoorIcon = () => (
  <svg id="ic_door" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <rect id="Rectangle_1992" data-name="Rectangle 1992" width="24" height="24" fill="none" />
    <path
      id="Path_5738"
      data-name="Path 5738"
      d="M19.88,2.81a1.26,1.26,0,0,1,.92.39,1.27,1.27,0,0,1,.39.93V19.88a1.24,1.24,0,0,1-.39.94,1.33,1.33,0,0,1-.92.37H4.13a1.27,1.27,0,0,1-1.32-1.31V4.13a1.33,1.33,0,0,1,.37-.93,1.25,1.25,0,0,1,.95-.39ZM11.34,4.13H4.13V19.88h7.21Zm8.54,0H12.66V19.88h7.22Z"
      fill="#333"
    />
  </svg>
);

export default DoorIcon;
