export const cachePaths = {
  reasons: {
    url: '/api/reference/reasons',
    path: 'cache_reasons',
  },
  districts: {
    url: '/api/reference/districts',
    path: 'cache_districts',
  },
  stations: {
    url: '/api/reference/stations',
    path: 'cache_stations',
  },
  disabilities: {
    url: '/api/reference/disabilities',
    path: 'cache_disabilities',
  },
  age_categories: {
    url: '/api/reference/age-categories',
    path: 'cache_age_categories',
  },
  reasons_reservations: {
    url: `/api/reference/reasons?ActionType=Reservation`,
    path: 'cache_reasons_reservations',
  },
  taxi_companies: {
    url: `/api/reference/taxis`,
    path: 'cache_taxi_companies',
  },
};
