import {Box, Button, Divider, Paper, Skeleton} from '@mui/material';
import React from 'react';
import {ChangeRouteContext} from '../ChangeRoute.store';
import {JourneySteppers} from '../steppers/steppers';
import {ArrowBack} from '@mui/icons-material';
import {SettingsContext} from 'src/context/settings/settings.store';
import {Navigate, useLocation, redirect, useNavigate} from 'react-router-dom';
import {DisabledOriginalTravelsDto} from 'src/services/models/disabled-original-travels-dto';
import moment from 'moment';
import CalendarIcon from 'src/icons/Calendericon';
import {formatOutboundInbound} from '../util/formatOutboundInbound';
import {useTranslation} from 'react-i18next';

export type TravelStatus = 'NO_CHANGES' | 'ROUTE_COMPLETED' | 'ROUTE_INCOMPLETE';

export const JourneyView = ({id}: {id: string}) => {
  const {originalRouteData, variantSelected, saveRoute, tripStatus} = React.useContext(ChangeRouteContext);
  const {prevRoute, setIsMenuOpen} = React.useContext(SettingsContext);
  const [doRedirect, setDoRedirect] = React.useState(false);

  const {t} = useTranslation();
  const nav = useNavigate();

  const verifyTheNewProposal = () => {
    if (!variantSelected) {
      return true;
    } else {
      if (tripStatus.inbound === 'NO_CHANGES' && tripStatus.outbound === 'NO_CHANGES') {
        return true;
      }

      if (tripStatus.inbound === 'ROUTE_INCOMPLETE' || tripStatus.outbound === 'ROUTE_INCOMPLETE') {
        return true;
      }

      return false;
    }
  };

  return (
    <Box sx={{backgroundColor: 'white', zIndex: 3}}>
      <Paper
        sx={{
          height: 'calc(100vh - 64px)',
          maxHeight: 'calc(100vh - 64px)',
          boxShadow: '7px 0px 23px 4px #e0e0e0be',
          border: 'solid 1px #E0E0E0',
          display: 'grid',
          placeItems: 'center',
          gridTemplateRows: '50px 1fr 130px',
          paddingBottom: '30px',
        }}
        elevation={1}
      >
        <div style={{width: '100%', height: '100%'}}>
          <p
            style={{height: '100%', lineHeight: '50px', paddingLeft: '15px'}}
            onClick={() => {
              setIsMenuOpen(true);
              nav(prevRoute);
            }}
          >
            <ArrowBack sx={{cursor: 'pointer'}} />
            <span style={{cursor: 'pointer', marginLeft: '5px'}}>{t('back-btn')}</span>
          </p>
        </div>
        <Box>
          <Box minWidth={'200px'} maxWidth={'180px'}>
            <h5 style={{fontWeight: 'bold'}}>{t('cr.reservation')}</h5>
            <p style={{marginBottom: '15px'}}>{id}</p>
            <Divider sx={{backgroundColor: 'black'}} />
            <sub style={{display: 'block', width: '100%', marginBottom: '25px', marginTop: '15px'}}>
              {t('cr.select-the-travel')}
            </sub>
            <TravelSelect reservation={originalRouteData} />
          </Box>
        </Box>
        <Box
          sx={{
            width: '200px',
            height: '100px',
            display: 'grid',
            gridTemplateRows: '42px 60px',
            gap: '5px',
            margin: '0px auto',
          }}
        >
          <Button
            onClick={() => saveRoute(true)}
            disabled={verifyTheNewProposal()}
            variant="outlined"
            sx={{width: '200px', textDecoration: 'none'}}
          >
            {t('cr.save-draft')}
          </Button>
          <Button
            onClick={() => saveRoute(false)}
            disabled={verifyTheNewProposal()}
            variant="contained"
            sx={{width: '200px', textDecoration: 'none'}}
          >
            {t('cr.submit')}
          </Button>
        </Box>
      </Paper>

      {doRedirect && <Navigate to={`/${prevRoute}`} />}
    </Box>
  );
};

const TravelSelect: React.FC<{reservation: DisabledOriginalTravelsDto | null}> = ({reservation}) => {
  const {tripStatus} = React.useContext(ChangeRouteContext);
  if (reservation == undefined) {
    return (
      <Box sx={{display: 'grid', placeItems: 'center', width: '100%', marginTop: '15px'}}>
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={210}
          height={123}
          sx={{borderRadius: '15px', marginBottom: '15px'}}
        />
        <Skeleton animation="wave" variant="rectangular" width={210} height={123} sx={{borderRadius: '15px'}} />
      </Box>
    );
  }

  const inbound = formatOutboundInbound('in', reservation);
  const outbound = formatOutboundInbound('out', reservation);
  const inboundStatus = tripStatus.inbound;
  const outboundStatus = tripStatus.outbound;

  return (
    <Box>
      {outbound && <TripItem trip={outbound} variant="outbound" status={outboundStatus} />}
      {inbound && <TripItem trip={inbound} variant="return" status={inboundStatus} />}
    </Box>
  );
};

const TripItem: React.FC<{
  trip: {departureOfFirstTrip: string; departureDate: Date | undefined; arrivalOfLastTrip: string};
  variant: 'outbound' | 'return';
  status: TravelStatus;
}> = ({trip, variant, status}) => {
  const {showCorrectTrips, selectedOriginalRoute, tripStatus, selectedNewProposal} =
    React.useContext(ChangeRouteContext);
  const {t} = useTranslation();

  const hasIncompleteRoute = tripStatus.inbound === 'ROUTE_INCOMPLETE' || tripStatus.outbound === 'ROUTE_INCOMPLETE';

  const departureStation = trip.departureOfFirstTrip;
  const arrivalStation = trip.arrivalOfLastTrip;
  const handleClick = () => {
    showCorrectTrips(variant);
  };

  const getStatusBox = () => {
    if (status === 'NO_CHANGES') {
      return (
        <Box
          sx={{textAlign: 'center', borderRadius: '0px 0px 15px 15px', backgroundColor: '#E0E0E0', color: '#424242'}}
        >
          <b style={{fontSize: '13px', paddingBottom: '4px'}}>{t('cr.no-changes')}</b>
        </Box>
      );
    } else if (status === 'ROUTE_COMPLETED') {
      return (
        <Box
          sx={{textAlign: 'center', borderRadius: '0px 0px 15px 15px', backgroundColor: '#C8E6C9', color: '#1B5E20'}}
        >
          <b style={{fontSize: '13px', paddingBottom: '4px'}}>{t('cr.route-completed')}</b>
        </Box>
      );
    } else {
      return (
        <Box
          sx={{textAlign: 'center', borderRadius: '0px 0px 15px 15px', backgroundColor: '#FEEBEE', color: '#D6221A'}}
        >
          <b style={{fontSize: '13px', paddingBottom: '4px'}}>{t('cr.route-incomplete')}</b>
        </Box>
      );
    }
  };

  return (
    <Box
      onClick={() => !hasIncompleteRoute && handleClick()}
      sx={{
        marginBottom: '15px',
        cursor: hasIncompleteRoute ? 'inherit' : 'pointer',
        backgroundColor:
          selectedOriginalRoute && selectedOriginalRoute[0].departureName === departureStation ? '#EFF4F9' : 'white',
        '&:hover': !(tripStatus.active === 'inbound'
          ? selectedNewProposal?.inboundProposal
          : selectedNewProposal?.outBoundProposal)
          ? {backgroundColor: '#EFF4F9'}
          : {},
        borderRadius: '15px',
        border: 'solid 1px rgba(230, 230, 230, 1)',
      }}
    >
      <Box sx={{padding: '15px'}}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '16px 1fr',
            alignContent: 'center',
            gap: '5px',
            marginBottom: '10px',
            height: '40px',
            borderBottom: 'solid 1px #e7e7e7',
          }}
        >
          <CalendarIcon style={{marginTop: '5px'}} />
          <p style={{fontWeight: 'bold', marginBottom: '5px'}}>{moment(trip.departureDate).format('DD.MM.YYYY')}</p>
        </div>
        <JourneySteppers start={departureStation || ''} end={arrivalStation || ''} />
      </Box>
      {getStatusBox()}
    </Box>
  );
};
