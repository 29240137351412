//@ts-nocheck
import CssBaseline from '@mui/material/CssBaseline';
import {createTheme, StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {Suspense} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './i18n';
import './index.css';

import {AdapterMoment as DateAdapter} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

import 'src/bootstrap.min.css';
import * as serviceWorker from './serviceWorker';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import AuthProvider from './context/authentication/store.auth';
import CacheProvider from './context/cache/cache.store';
import EnvironmentProvider from './context/environment/environment.store';
import LanguageProvider from './context/i18n/store.i18n';
import NetworkProvider from './context/network/network.store';
import ReservationsProvider from './context/reservations/reservations.store';
import SettingsProvider from './context/settings/settings.store';
import MissionProvider from './scenes/coordinator/MissionOverview/missions.store';
import PrintStickerProvider from './scenes/coordinator/PrintStickers/PrintStickers.store';
import GroupReservationProvider from './scenes/coordinator/Reservation/GroupReservation.store';

//Fix format bug by overriding momentjs normal behaviour based on year
import moment from 'moment';
import CoiProvider from './context/coi/coi.createIncident.store';
import CoiIncidentProvider from './context/coi/coi.incidents.store';
import CoiCreateIncidentProvider from './context/coi/coi.createIncident.store';
import {DistrictProvider} from './context/district/district.store';

const orignalFormat = moment.fn.format;
moment.fn.format = function (formatString: moment.Moment) {
  if (this.year() === 1) return '--:--';
  return orignalFormat.call(this, formatString);
};

const theme = createTheme();

ReactDOM.render(
  <LocalizationProvider dateAdapter={DateAdapter}>
    <EnvironmentProvider>
      <LanguageProvider>
        <AuthProvider>
          <NetworkProvider>
            <CacheProvider>
              <DistrictProvider>
                <SettingsProvider>
                  <CoiCreateIncidentProvider>
                    <CoiIncidentProvider>
                      <ReservationsProvider>
                        <MissionProvider>
                          <StyledEngineProvider injectFirst>
                            <ThemeProvider theme={theme}>
                              <Suspense fallback={<div>Loading</div>}>
                                <PrintStickerProvider>
                                  <GroupReservationProvider>
                                    <CssBaseline />
                                    <App />
                                  </GroupReservationProvider>
                                </PrintStickerProvider>
                              </Suspense>
                            </ThemeProvider>
                          </StyledEngineProvider>
                        </MissionProvider>
                      </ReservationsProvider>
                    </CoiIncidentProvider>
                  </CoiCreateIncidentProvider>
                </SettingsProvider>
              </DistrictProvider>
            </CacheProvider>
          </NetworkProvider>
        </AuthProvider>
      </LanguageProvider>
    </EnvironmentProvider>
  </LocalizationProvider>,

  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
