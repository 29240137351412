import React from 'react';

const AssignIcon = () => (
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.75 15.75C1.3375 15.75 0.984375 15.6031 0.690625 15.3094C0.396875 15.0156 0.25 14.6625 0.25 14.25V3.75C0.25 3.3375 0.396875 2.98438 0.690625 2.69063C0.984375 2.39688 1.3375 2.25 1.75 2.25H4.9C5.075 1.8 5.35 1.4375 5.725 1.1625C6.1 0.8875 6.525 0.75 7 0.75C7.475 0.75 7.9 0.8875 8.275 1.1625C8.65 1.4375 8.925 1.8 9.1 2.25H12.25C12.6625 2.25 13.0156 2.39688 13.3094 2.69063C13.6031 2.98438 13.75 3.3375 13.75 3.75V14.25C13.75 14.6625 13.6031 15.0156 13.3094 15.3094C13.0156 15.6031 12.6625 15.75 12.25 15.75H1.75ZM7 3.1875C7.1625 3.1875 7.29688 3.13438 7.40313 3.02813C7.50938 2.92188 7.5625 2.7875 7.5625 2.625C7.5625 2.4625 7.50938 2.32812 7.40313 2.22187C7.29688 2.11563 7.1625 2.0625 7 2.0625C6.8375 2.0625 6.70313 2.11563 6.59688 2.22187C6.49063 2.32812 6.4375 2.4625 6.4375 2.625C6.4375 2.7875 6.49063 2.92188 6.59688 3.02813C6.70313 3.13438 6.8375 3.1875 7 3.1875ZM1.75 13.3875C2.425 12.725 3.20938 12.2031 4.10313 11.8219C4.99688 11.4406 5.9625 11.25 7 11.25C8.0375 11.25 9.00313 11.4406 9.89688 11.8219C10.7906 12.2031 11.575 12.725 12.25 13.3875V3.75H1.75V13.3875ZM7 9.75C7.725 9.75 8.34375 9.49375 8.85625 8.98125C9.36875 8.46875 9.625 7.85 9.625 7.125C9.625 6.4 9.36875 5.78125 8.85625 5.26875C8.34375 4.75625 7.725 4.5 7 4.5C6.275 4.5 5.65625 4.75625 5.14375 5.26875C4.63125 5.78125 4.375 6.4 4.375 7.125C4.375 7.85 4.63125 8.46875 5.14375 8.98125C5.65625 9.49375 6.275 9.75 7 9.75ZM3.25 14.25H10.75V14.0625C10.225 13.625 9.64375 13.2969 9.00625 13.0781C8.36875 12.8594 7.7 12.75 7 12.75C6.3 12.75 5.63125 12.8594 4.99375 13.0781C4.35625 13.2969 3.775 13.625 3.25 14.0625V14.25ZM7 8.25C6.6875 8.25 6.42188 8.14062 6.20312 7.92188C5.98438 7.70312 5.875 7.4375 5.875 7.125C5.875 6.8125 5.98438 6.54688 6.20312 6.32812C6.42188 6.10938 6.6875 6 7 6C7.3125 6 7.57812 6.10938 7.79688 6.32812C8.01562 6.54688 8.125 6.8125 8.125 7.125C8.125 7.4375 8.01562 7.70312 7.79688 7.92188C7.57812 8.14062 7.3125 8.25 7 8.25Z"
      fill="#1C1B1F"
    />
  </svg>
);

export default AssignIcon;
