// @ts-nocheck
import {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import './StationSearch.scss';
import searchIcon from '../../components/images/ic_search.svg';

const StationSearch = ({setData, cleredInput, masterData, inputValue, setValueInputSearch}) => {
  const inputEl = useRef(null);
  const {t} = useTranslation();

  const filterStation = event => {
    const textInput = event.target.value;
    setValueInputSearch(textInput);
    if (textInput.length >= 2) {
      const copyStationData = [...masterData];
      const filterData = copyStationData.filter(item => {
        if (item.nameEn.toLowerCase().includes(textInput.toLowerCase())) {
          return true;
        }

        if (item.nameFr.toLowerCase().includes(textInput.toLowerCase())) {
          return true;
        }

        if (item.nameNl.toLowerCase().includes(textInput.toLowerCase())) {
          return true;
        }
        return false;
      });
      setData(filterData);
    } else if (textInput.length < 1) {
      cleredInput(inputEl);
    }
  };

  return (
    <>
      <div className="search-input-div">
        <input
          type="text"
          ref={inputEl}
          placeholder={t('station.modal.search')}
          onChange={filterStation}
          value={inputValue}
        />
        <img src={searchIcon} alt="img-search" className="img-style" />
      </div>
    </>
  );
};

export default StationSearch;
