// @ts-nocheck
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function Return(props) {
  return (
    <SvgIcon {...props}>
      <svg
        id="ic_more_steps"
        data-name="ic_more steps"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        {...props}
      >
        <path
          id="Path_5130"
          data-name="Path 5130"
          d="M13.84,15.88a1.46,1.46,0,0,1,0-2l3.35-3.3h-8a4,4,0,0,0-1.9.42,4.56,4.56,0,0,0-1.45,1.11A4.2,4.2,0,0,0,5,13.74a3.93,3.93,0,0,0,0,2,4,4,0,0,0,1.56,2.5,4.56,4.56,0,0,0,2.81.93h11.2A1.45,1.45,0,0,1,22,20.56a1.33,1.33,0,0,1-.42,1,1.43,1.43,0,0,1-1,.41H9.16a7,7,0,0,1-2.94-.63,7.08,7.08,0,0,1-2.36-1.69,6.92,6.92,0,0,1-1.47-2.5,7.28,7.28,0,0,1-.36-3,6.45,6.45,0,0,1,.8-2.56,7.35,7.35,0,0,1,1.67-2A7.85,7.85,0,0,1,6.8,8.21a7.39,7.39,0,0,1,2.67-.49h7.67l-3.3-3.25a1.4,1.4,0,0,1-.44-1,1.25,1.25,0,0,1,.44-1,1.28,1.28,0,0,1,1-.45,1.41,1.41,0,0,1,1,.45l5.76,5.7a1.43,1.43,0,0,1,.36,1,1.44,1.44,0,0,1-.36,1l-5.71,5.71a1.38,1.38,0,0,1-1,.44,1.4,1.4,0,0,1-1.05-.44Z"
        />
        <g id="Group_7648" data-name="Group 7648">
          <rect id="Rectangle_1612" data-name="Rectangle 1612" width="24" height="24" fill="none" />
        </g>
      </svg>
    </SvgIcon>
  );
}
