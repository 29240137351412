import {TravelerDetailDto} from 'src/services/models/traveler-detail-dto';

export const getTotalAmountOfTravelers = (traveler: TravelerDetailDto | undefined) => {
  if (!traveler) {
    return '';
  }

  const a = traveler.adults ? traveler.adults : 0;
  const c = traveler.children ? traveler.children : 0;
  const s = traveler.seniors ? traveler.seniors : 0;

  return (a + c + s).toString();
};
