import {ReservationJourneyDto} from 'src/services/models/reservation-journey-dto';
import moment from 'moment';
import {DisabledJourneyDto} from 'src/services/models/disabled-journey-dto';
import {DisabledOriginalJourneyDto} from 'src/services/models/disabled-original-journey-dto';

export type OriginalRouteStepMap = {
  trainNumber: string | number;
  departureStation: {
    name: string;
    requiresAssistance: boolean;
    assistanceAvailable: boolean;
    uic: string;
  };
  arrivalStation: {
    name: string;
    requiresAssistance: boolean;
    assistanceAvailable: boolean;
    uic: string;
  };
  itineraries: Array<string>;
  departureTime: string;
  arrivalTime: string;
  arrivalDate: string;
  departureDate: Date | '';
};

export const originalRouteStepMapper = (origin: DisabledOriginalJourneyDto): OriginalRouteStepMap => {
  const ORS: OriginalRouteStepMap = {} as OriginalRouteStepMap;

  //@ts-ignore
  ORS.trainNumber = origin.transportId || origin.transportId || '';
  ORS.departureStation = {
    name: origin.departureName || '',
    requiresAssistance: origin.withDepartureAssistance || false,
    assistanceAvailable: origin.isDepartureAssistancePossible ? origin.isDepartureAssistancePossible : false,
    uic: origin.departureUicCode || '',
  };

  ORS.arrivalStation = {
    name: origin.arrivalName || '',
    requiresAssistance: origin.withArrivalAssistance || false,
    assistanceAvailable: origin.isArrivalAssistancePossible ? origin.isArrivalAssistancePossible : false,
    uic: origin.arrivalUicCode || '',
  };

  const stations =
    origin.intermediateStations && origin.intermediateStations.length > 0 ? origin.intermediateStations : [];
  ORS.itineraries = stations;

  ORS.departureTime = moment(origin.departureTime || '').format('HH:mm');
  ORS.arrivalTime = moment(origin.arrivalTime || '').format('HH:mm');
  ORS.arrivalDate = moment(origin.arrivalTime || '').format('DD.MM.YYYY');
  ORS.departureDate = origin.departureTime || '';

  return ORS;
};
