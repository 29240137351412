import Box from '@mui/material/Box';
import {useTranslation} from 'react-i18next';
import {GroupReservationModalBox} from './GroupReservationModalBox';
import {ReservationDto} from 'src/services/models/reservation-dto';
import {ChangeAssistance} from 'src/scenes/coordinator/Reservation/GroupReservation.store';

export type HandleFunc = (value: boolean, index: number, place: 'departure' | 'arrival') => void;

export const ReservationModalView = ({
  reservation,
  assistances,
  handleActiveMissionAssistance,
}: {
  reservation: ReservationDto;
  assistances: ChangeAssistance[];
  handleActiveMissionAssistance: HandleFunc;
}) => {
  const {t} = useTranslation();

  return (
    <Box>
      <Box>{t('reservation.title-main')}</Box>
      <GroupReservationModalBox
        handleActiveMissionAssistance={handleActiveMissionAssistance}
        reservation={reservation}
        assistances={assistances}
      />
      <Box sx={{borderTop: '1px solid #d7d7d7'}} />
    </Box>
  );
};
