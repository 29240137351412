import React from 'react';
import {useState} from 'react';
import {MissionContext} from 'src/scenes/coordinator/MissionOverview/missions.store';
import trainIconWhite from '../../components/images/ic_train-white.svg';
import ModalStation from './StationModal';
import './StationSectionStyle.scss';
import {getDistrictFromName} from 'src/utils/getDistrictIdFromName';
import {CacheContext} from 'src/context/cache/cache.store';
import {DistrictDto} from 'src/services/models';
import {useTranslation} from 'react-i18next';
import {ReservationsContext} from 'src/context/reservations/reservations.store';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {customEvents} from '../coordinator/MissionItem/AssistanceDetails/constants';
import {useDistrictContext} from 'src/context/district/district.store';

function StationSection({
  clickedStation,
  className,
  isCoordinator,
}: {
  clickedStation: any;
  className: string;
  isCoordinator: boolean;
}) {
  const [stationModal, setStationModal] = useState(false);

  const {handleStationChange, resetMissionStore} = React.useContext(MissionContext);
  const {initReservationsStore, resetReservationsStore} = React.useContext(ReservationsContext);
  const {districts} = React.useContext(CacheContext);
  const {t, i18n} = useTranslation();
  const {variables} = React.useContext(EnvironmentContext);
  const {handleMutateDistricts} = useDistrictContext();

  let stationSectionStyle = `station-section station-section-coordinator ${className}`;
  let trainIcon = trainIconWhite;

  const modalOpen = () => {
    setStationModal(!stationModal);
  };

  const modalClose = () => {
    setStationModal(!stationModal);
  };

  const handleChoose = (statIds: string, regionName: string) => {
    const stationNameCount = `${regionName} (${statIds.length})`;
    localStorage.setItem('districtId', getDistrictFromName(i18n, regionName, districts as DistrictDto[]));
    localStorage.setItem('savedStations', stationNameCount);
    localStorage.setItem('selectedStations', statIds);
    window.dispatchEvent(new Event(customEvents.noTrains));
    //@ts-ignore
    handleMutateDistricts(statIds);
    handleStationChange(getDistrictFromName(i18n, regionName, districts as DistrictDto[]), statIds);
    if (statIds.length === 0) {
      variables.USE_PMR_3 && resetReservationsStore();
      resetMissionStore();
    } else {
      variables.USE_PMR_3 && initReservationsStore();
    }
    clickedStation(statIds);
    modalClose();
  };

  const getText = () => {
    const savedStations = localStorage.getItem('savedStations');

    if (!savedStations) {
      return t('station-select.no-station');
    }

    if (savedStations.includes('No Station')) {
      return t('station-select.no-station');
    }

    if (savedStations.includes('Multiple')) {
      return t('station-select.multi-station');
    }

    return savedStations;
  };

  return (
    <>
      <div className={stationSectionStyle}>
        <div className="row-station">
          <div className={`${isCoordinator ? 'rc2-coordinator' : 'rc2'}`}>
            <span className="train-list-count" onClick={modalOpen}>
              <img src={trainIcon} className="img-style" alt="img" />
              <span className="vert-mid">{getText()}</span>
            </span>
          </div>
          <ModalStation
            isCoordinator={isCoordinator}
            show={stationModal}
            clickedChoose={handleChoose}
            handleClose={modalClose}
          />
        </div>
      </div>
    </>
  );
}

export default StationSection;
