import {Container, List, ListItem, ListItemIcon, ListItemText, Box} from '@mui/material';

import {Icons} from './list';
import {useTranslation} from 'react-i18next';

export const Legend = () => {
  const {t} = useTranslation();

  return (
    <Box
      sx={{
        maxHeight: '100vh',
        paddingLeft: '0px',
        paddingRight: '0px',
        overflow: 'scroll !important',
      }}
    >
      <Container maxWidth={false} sx={{marginBottom: '60px'}}>
        <List>
          {Icons.map(Icon => {
            const translation = t(Icon.name);
            const {icon} = Icon;
            return (
              <ListItem
                sx={{
                  borderBottom: 'solid 1px #d6d6d6',
                }}
              >
                <ListItemIcon>
                  {typeof icon === 'string' ? (
                    <img
                      style={{
                        width: '25px',
                        height: '25px',
                        filter: `${
                          Icon.name === 'icons.train-sticker'
                            ? ' brightness(0) saturate(100%) invert(51%) sepia(88%) saturate(730%) hue-rotate(62deg) brightness(112%) contrast(104%)'
                            : 'brightness(0) saturate(100%) invert(0%) sepia(95%) saturate(7346%) hue-rotate(208deg) brightness(82%) contrast(95%)'
                        }`,
                      }}
                      src={icon as string}
                    />
                  ) : (
                    //@ts-ignore
                    <Icon.icon color="green" />
                  )}
                </ListItemIcon>
                <ListItemText primary={`${translation.charAt(0).toLocaleUpperCase() + translation.slice(1)}`} />
              </ListItem>
            );
          })}
        </List>
      </Container>
    </Box>
  );
};
