import React from 'react';
import {getTimeDifferenceFromEpochValues} from 'src/utils/getTimeDifferenceFromEpochValues';
import {EnvironmentContext} from '../environment/environment.store';
import {getReferenceData} from './cache.service';

// Network error handling
import {NetworkContext} from '../network/network.store';

// Loader
import {CircularProgress} from '@mui/material';
import {AgeCategoryDto, DisabilityDto, DistrictDto, ReasonDto, StationDto} from 'src/services/models';
import {getCachedData, setToLocal} from './cache.util';
import {AuthContext} from '../authentication/store.auth';
import {TaxiDto} from 'src/services/models/taxi-dto';
import {RefuseDto} from 'src/services/models/refuse-dto';

const DAY_IN_MILLI = 86400000; // 24 hours in milliseconds

interface ICacheContext {
  reasons: ReasonDto[];
  districts: DistrictDto[];
  stations: StationDto[];
  disabilities: DisabilityDto[];
  ageCategories: AgeCategoryDto[];
  isCachePopulated: boolean;
  reservationRefusalReasons: ReasonDto[];
  taxiCompanies: TaxiDto[];
}

const CacheContext = React.createContext<ICacheContext>({
  reasons: [],
  districts: [],
  stations: [],
  disabilities: [],
  ageCategories: [],
  isCachePopulated: false,
  reservationRefusalReasons: [],
  taxiCompanies: [],
});

const CacheProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [reasons, setReasons] = React.useState<ReasonDto[]>([]);
  const [districts, setDistricts] = React.useState<DistrictDto[]>([]);
  const [stations, setStations] = React.useState<StationDto[]>([]);
  const [disabilities, setDisabilities] = React.useState<DisabilityDto[]>([]);
  const [ageCategories, setAgeCategories] = React.useState<AgeCategoryDto[]>([]);
  const [isCachePopulated, setIsCachePopulated] = React.useState(false);
  const [reservationRefusalReasons, setReservationRefusalReasons] = React.useState<ReasonDto[]>([]);
  const [taxiCompanies, setTaxiCompanies] = React.useState<TaxiDto[]>([]);

  const {validateNetworkCall} = React.useContext(NetworkContext);
  const {variables} = React.useContext(EnvironmentContext);
  const {validUser} = React.useContext(AuthContext);

  const CACHE_STATE = {
    reasons,
    districts,
    stations,
    disabilities,
    ageCategories,
    isCachePopulated,
    reservationRefusalReasons,
    taxiCompanies,
  };

  const getAndCacheData = async () => {
    await getReferenceData(variables?.BASE_ENDPOINT).then(res => {
      if (res.length > 0) {
        setToLocal(res);
        getCachedData(
          setReasons,
          setDistricts,
          setStations,
          setDisabilities,
          setAgeCategories,
          setReservationRefusalReasons,
          setTaxiCompanies,
        );

        setIsCachePopulated(true);
      } else {
        setToLocal(Array.from(Array(5)).map(_el => ({data: _el.data})));
        //@ts-ignore
        validateNetworkCall(res.response.code, res.response.data);
        setIsCachePopulated(true);
        throw new Error('Not able to get endpoints');
      }
    });
  };

  const intializer = (fromLogin?: boolean) => {
    const cachedTimeInEpoch = parseInt(localStorage.getItem('LAST_USER_VISIT') || '0');
    const currentTimeInEpoch = new Date().getTime();

    // If there is no last visit timestamp, treat it as first visit
    if (cachedTimeInEpoch === 0) {
      // Collect data as there will be no cache
      getAndCacheData();
      setIsCachePopulated(false);
      localStorage.setItem('LAST_USER_VISIT', currentTimeInEpoch.toString());
      return;
    }

    // Check if the last visit was the previous day or earlier
    const timeDifference = getTimeDifferenceFromEpochValues({time1: currentTimeInEpoch, time2: cachedTimeInEpoch});

    // If last visit was on a previous day or more, refresh the cache
    const lastVisitDate = new Date(cachedTimeInEpoch);
    const currentDate = new Date();

    // Compare just the date (ignoring time)
    const lastVisitWasYesterdayOrEarlier =
      lastVisitDate.getDate() !== currentDate.getDate() ||
      lastVisitDate.getMonth() !== currentDate.getMonth() ||
      lastVisitDate.getFullYear() !== currentDate.getFullYear();

    if (fromLogin || lastVisitWasYesterdayOrEarlier) {
      getAndCacheData();
      setIsCachePopulated(false);
      // Update the last visit timestamp
      localStorage.setItem('LAST_USER_VISIT', currentTimeInEpoch.toString());
      return;
    }

    // If it's still the same day, use cached data
    getCachedData(
      setReasons,
      setDistricts,
      setStations,
      setDisabilities,
      setAgeCategories,
      setReservationRefusalReasons,
      setTaxiCompanies,
    );
    setIsCachePopulated(true);
  };

  React.useEffect(() => {
    if (validUser) {
      intializer(true);
    }
  }, [validUser]);

  return (
    <CacheContext.Provider value={CACHE_STATE}>
      {isCachePopulated ? (
        children
      ) : (
        <div style={{display: 'grid', width: '100vw', height: '100vh', placeItems: 'center'}}>
          <CircularProgress data-testid={'store_loader'} variant="indeterminate" />
        </div>
      )}
    </CacheContext.Provider>
  );
};

export default CacheProvider;
export {CacheContext};
