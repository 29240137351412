import {Button} from 'antd';
import './ButtonFilled.scss';
import React from 'react';

type Props = {
  label?: string;
  icon?: React.ReactNode;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
};

export const ButtonFilled = ({label, icon, onClick, loading, disabled}: Props) => {
  return (
    <Button
      disabled={disabled}
      type="primary"
      loading={loading}
      icon={icon}
      className="filled-button"
      onClick={onClick}
    >
      {label}
    </Button>
  );
};
