import React from 'react';

const StickerOfIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24.141" height="24" viewBox="0 0 24.141 24">
      <g id="ic_sticker_off" transform="translate(-628.859 -128)">
        <g id="ic_sticker_at_the_back" data-name="ic_sticker at the back" transform="translate(628.859 128)">
          <rect id="Rectangle_1108_1_" width="24" height="24" fill="none" />
          <g id="Group_7669" data-name="Group 7669" transform="translate(2 3.2)">
            <path
              id="Path_5245"
              data-name="Path 5245"
              d="M16.073,11.056a1.03,1.03,0,0,1-1.009,1.135H7.5l.756-1.009C10.4,8.535,11.912,7.4,13.173,7.4h1.765a1.13,1.13,0,0,1,1.135,1.135v2.521Zm-1.135-2.4H13.173c-.756,0-1.765.756-3.152,2.4h4.791v-2.4Z"
              transform="translate(-2.773 -3.2)"
              fill="#2b7c2b"
            />
            <g id="Group_7668" data-name="Group 7668" transform="translate(11 8.192)">
              <path
                id="Path_5246"
                data-name="Path 5246"
                d="M19.376,12.532a.531.531,0,0,1,0,.638l-2.019,2.019a.452.452,0,0,1-.638-.638L18.1,13.169H13.425a.427.427,0,1,1,0-.85H18.1l-1.275-1.275a.452.452,0,0,1,.638-.638Z"
                transform="translate(-12 -9.686)"
                fill="#2b7c2b"
              />
            </g>
            <path
              id="Path_5247"
              data-name="Path 5247"
              d="M21.7,21.443a2.49,2.49,0,0,1-4.136,0,2.534,2.534,0,0,1-4.412-.414h-.414c-4.274,0-6.618-.827-7.446-2.344-1.1-2.206.827-5.1,2.9-8.273A9.148,9.148,0,0,1,15.905,6h6.8V7.379H15.767A7.777,7.777,0,0,0,9.149,11.1c-1.792,2.62-3.585,5.377-2.9,6.894.414.827,1.792,1.655,6.2,1.655h1.517l.138.552a1.208,1.208,0,0,0,1.241.965,1.324,1.324,0,0,0,1.241-.965l.138-.552h1.655l.138.552a1.208,1.208,0,0,0,1.241.965,1.324,1.324,0,0,0,1.241-.965l.138-.552h.827"
              transform="translate(-4.448 -5.2)"
              fill="#2b7c2b"
            />
            <path
              id="Path_5248"
              data-name="Path 5248"
              d="M18.8,3.2H4.88A2.924,2.924,0,0,0,2,6.088V24.095a2.924,2.924,0,0,0,2.88,2.888H23.12A2.924,2.924,0,0,0,26,24.095V10.662Zm6,20.895a1.643,1.643,0,0,1-1.68,1.685H4.88A1.643,1.643,0,0,1,3.2,24.095V6.088A1.643,1.643,0,0,1,4.88,4.4H18.8V8.977a1.643,1.643,0,0,0,1.68,1.685H24.8Z"
              transform="translate(-3.859 -6.203)"
              fill="#2b7c2b"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default StickerOfIcon;
