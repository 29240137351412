import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import Badge from '@mui/material/Badge';

import {styled} from '@mui/material/styles';

export const ListIcon = styled(ListItemIcon)({
  minWidth: 30,
  color: '#777777',
});

export const CollapseItem = styled(ListItemButton)({
  '& .MuiTouchRipple-root': {marginLeft: 24, paddingTop: 1, borderLeft: `2px solid #D7D7D7`},
});

export const BadgeItem = styled(Badge)({
  '& .MuiBadge-badge': {
    padding: '0 2px',
    color: '#0069B4',
    border: '1px solid #0069B4',
    backgroundColor: '#fff',
    fontSize: '0.6rem',
    fontWeight: 700,
    right: 10,
  },
});

export const BadgeItemSelected = styled(Badge)({
  '& .MuiBadge-badge': {
    padding: '0 2px',
    color: '#fff',
    backgroundColor: '#0069B4',
    fontSize: '0.6rem',
    fontWeight: 700,
    right: 10,
  },
});
