import {Box, Button, Fab} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {MissionContext} from '../../MissionOverview/missions.store';
import {MissionStatusDto} from 'src/services/models/mission-status-dto';
import DeleteIcon from 'src/icons/DeleteIcon';
import AgentIcon from 'src/icons/AgentIcon';
import {SettingsContext} from 'src/context/settings/settings.store';
import {EnvironmentContext} from 'src/context/environment/environment.store';

const fabStyle = {
  position: 'fixed',
  bottom: 0,
  right: 16,
  display: 'flex',
  flexDirection: 'column',
  gap: '25px',
  marginRight: '25px',
};

type Props = {
  handleAssignConfirmation: () => void;
  handleDeleteConfirmation: () => void;
  isAssigned: boolean;
};

const BottomActionButtons: React.FC<Props> = ({handleAssignConfirmation, isAssigned, handleDeleteConfirmation}) => {
  const {t} = useTranslation();
  const {selectedMissionDetails, selectedMission} = React.useContext(MissionContext);

  return (
    <Box sx={fabStyle}>
      <Box sx={{display: 'flex', gap: '5px'}}>
        <Button
          aria-label="delete"
          style={{borderRadius: 16}}
          onClick={handleDeleteConfirmation}
          disabled={selectedMission.id === undefined}
        >
          <div style={{display: 'grid', gridTemplateRows: '1fr auto', placeItems: 'center'}}>
            <DeleteIcon />
            <span style={{color: 'black', fontSize: '12px'}}>{t('mission-details.box-delete')}</span>
          </div>
        </Button>
        <Fab
          variant="extended"
          color="primary"
          aria-label="add"
          style={{borderRadius: 16}}
          onClick={handleAssignConfirmation}
          disabled={
            selectedMissionDetails?.status === MissionStatusDto.Completed ||
            selectedMissionDetails?.status === MissionStatusDto.Deleted
          }
        >
          <AgentIcon fill="white" />
          <Box sx={{mr: 1, ml: 1}}>
            {' '}
            {isAssigned ? t('mission-details.box-reassign-agent') : t('mission-details.box-assign-agent')}{' '}
          </Box>
        </Fab>
      </Box>
    </Box>
  );
};

export const DeleteAssignBar: React.FC<Props> = ({handleAssignConfirmation, handleDeleteConfirmation, isAssigned}) => {
  const {isMenuOpen} = React.useContext(SettingsContext);
  const {variables} = React.useContext(EnvironmentContext);

  const getWidth = () => {
    if (variables.USE_PMR_3) {
      return isMenuOpen ? 'calc(100vw - 360px)' : 'calc(100vw - 118px)';
    }

    return 'calc(100vw - 240px)';
  };
  return (
    <Box
      position={'fixed'}
      bottom={'0'}
      height={'72px'}
      border={'solid 1px #EAEAEA'}
      width={getWidth()}
      right={'0px'}
      sx={{
        backgroundColor: 'white',
        zIndex: 0,
      }}
    >
      <BottomActionButtons
        handleAssignConfirmation={handleAssignConfirmation}
        isAssigned={isAssigned}
        handleDeleteConfirmation={handleDeleteConfirmation}
      />
    </Box>
  );
};
