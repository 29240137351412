import { Box, Button, Dialog, DialogTitle } from '@mui/material';
import * as React from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import '../CreateMission.scss'
import { CreateReservationFormModal } from '../types';
import CircularProgress from '@mui/material/CircularProgress';

export const FormDialog: React.FC<CreateReservationFormModal> = (
    {
        handleClose,
        handleSubmit,
        isModalVisible,
        isSubmitting
    }    
) => {


    const {t} = useTranslation()

    return(
        <Dialog 

            fullWidth={true}
            onClose={() => {handleClose()}} 
            open={isModalVisible}
        >
            <DialogTitle><HelpOutlineIcon /> {t('settings.modal.confirmation-header')}</DialogTitle>
            <hr/>
            {!isSubmitting ? (<Typography
                align='center'
                paddingBottom={6}
                paddingTop={4}
            >
                {t('create-grobo-mission.modal-info')}
            </Typography> ) : 
            <Box
                sx={{
                    width: '100%',
                    display: 'grid',
                    placeItems: 'center',
                    height: '150px'
                }}
            >
                <CircularProgress/>
            </Box>}
            <div className='bottom-nav-modal'>
                <Button disabled={isSubmitting} variant="contained" onClick={() => {
                    handleSubmit()
                }}>{t('create-prm-mission.modal.btn-create')}</Button>
                <Button disabled={isSubmitting} onClick={() => {handleClose()}} variant='text' sx={{color: 'black'}}>{t('create-prm-mission.modal.btn-cancel')}</Button>
            </div>
        </Dialog>
    )
}