import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';

import {LogoIcon} from 'src/components/icons/LogoIcon';
import StationFilterModal from 'src/scenes/coordinator/StationFilterModal/StationFilterModal';
import {Navigate} from 'react-router-dom';
import {useEffect, useState} from 'react';

type TopBarProps = {
  fetchData: (...args: any) => void;
  date: any;
  dateTo: any;
};

export default function TopBar({fetchData, date, dateTo}: TopBarProps) {
  const [doRedirect, setDoRedirect] = useState(false);

  useEffect(() => {
    setDoRedirect(false);
  }, [doRedirect]);

  const stationFilter = (ids: any) => {
    const stations = ids.length > 0 ? {stationIds: ids} : {};
    fetchData({...stations, date, dateTo});
  };

  return (
    <AppBar position="static" sx={{backgroundColor: '#25336C', gridColumn: '1/3', gridRow: '1/2'}}>
      <Toolbar>
        <IconButton
          onClick={() => {
            setDoRedirect(true);
          }}
          size="large"
          edge="start"
          color="inherit"
          aria-label="Logo"
        >
          <LogoIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
          DiCOS
        </Typography>
        <Box sx={{flexGrow: 1}} />
        <Box sx={{width: 'fit-content'}}>
          <StationFilterModal clickedStation={stationFilter} isCoordinator />
        </Box>
      </Toolbar>
      {doRedirect && <Navigate to="/missions/mission-overview" />}
    </AppBar>
  );
}
