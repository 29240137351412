import {Button, styled} from '@mui/material';
import {useTranslation} from 'react-i18next';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import {Close} from '@mui/icons-material';
import React from 'react';

export const DeclineButton: React.FC<React.JSX.IntrinsicElements['button']> = props => {
  return (
    <Button
      style={{
        maxWidth: '32px',
        minWidth: '32px',
        height: '32px',
        color: '#BB0000',
        border: 'solid 1px #BB0000',
        borderRadius: '8px',
        backgroundColor: 'white',
        fontWeight: 'bold',
        justifySelf: 'flex-end',
      }}
      onClick={e => props.onClick && props.onClick(e)}
    >
      <Close style={{}} />
    </Button>
  );
};

export const AcceptButton: React.FC<React.JSX.IntrinsicElements['button']> = props => {
  const {t} = useTranslation();

  return (
    <AcceptButtonStyle variant="contained" disabled={props.disabled} onClick={e => props.onClick && props.onClick(e)}>
      <DoneOutlinedIcon
        fontSize="small"
        sx={{marginRight: '8px', marginLeft: '8px', marginTop: '8px', marginBottom: '8px', display: 'block'}}
      />{' '}
      <span style={{marginRight: '8px', marginTop: '4px', display: 'block'}}>{t('footer.accepter')}</span>
    </AcceptButtonStyle>
  );
};

export const ValidateButton: React.FC<React.JSX.IntrinsicElements['button']> = props => {
  const {t} = useTranslation();

  return (
    <AcceptButtonStyle variant="contained" onClick={e => props.onClick && props.onClick(e)}>
      <DoneOutlinedIcon
        fontSize="small"
        sx={{marginRight: '8px', marginLeft: '8px', marginTop: '8px', marginBottom: '8px', display: 'block'}}
      />{' '}
      <span style={{marginRight: '8px', marginTop: '4px', display: 'inline-block', textTransform: 'uppercase'}}>
        {t('pmr.confirm-draft')}
      </span>
    </AcceptButtonStyle>
  );
};

export const RejectButton: React.FC<React.JSX.IntrinsicElements['button']> = props => {
  const {t} = useTranslation();

  return (
    <RejectButtonStyle onClick={e => props.onClick && props.onClick(e)}>
      <span style={{textAlign: 'center', textTransform: 'uppercase', paddingTop: '4px'}}>{t('pmr.discard-draft')}</span>
    </RejectButtonStyle>
  );
};

const AcceptButtonStyle = styled(Button)({
  width: 'fit-content',
  whiteSpace: 'nowrap',
  minWidth: '120px',
  height: '32px',
  backgroundColor: '#2B7C2B',
  color: 'white',
  fontSize: '14px',
  borderRadius: '8px',
  textTransform: 'none',
  paddingLeft: '8px',
  paddingRight: '14px',
  '&:hover': {
    backgroundColor: '#2B7C2B',
  },
});

const RejectButtonStyle = styled(Button)({
  width: 'fit-content',
  whiteSpace: 'nowrap',
  minWidth: '120px',
  height: '32px',
  backgroundColor: 'white',
  color: '#BB0000',
  fontSize: '14px',
  borderRadius: '8px',
  textTransform: 'none',
  textAlign: 'center',
  border: 'solid 1px #BB0000',
  '&:hover': {
    backgroundColor: '#BB0000',
    color: 'white',
  },
});
