import {DatePicker, Space, Typography} from 'antd';
import moment from 'moment';

const {Text} = Typography;

type Props = {
  label?: string;
  name?: string;
  change?: () => void;
  value?: string | moment.Moment;
  isTime?: boolean;
  disabled: boolean;
};

export const DateAndTimeInput = ({label, value, isTime = false, disabled = false}: Props) => {
  const handleOnChange = () => {};

  return (
    <Space direction="vertical" style={{width: '100%'}}>
      {label && <Text style={{fontSize: 14, color: '#666666'}}>{label}</Text>}
      <DatePicker
        disabled={disabled}
        style={{width: '100%'}}
        showTime={isTime}
        format=""
        value={moment(value)}
        onChange={handleOnChange}
      />
    </Space>
  );
};
