import {AxiosError} from 'axios';
import moment from 'moment';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {CacheContext} from 'src/context/cache/cache.store';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {NetworkContext} from 'src/context/network/network.store';
import {ModelError, ReservationTypeDto, StationDto} from 'src/services/models';
import {ReservationDto} from 'src/services/models/reservation-dto';
import {
  intitialFilterState,
  MissionFilterActions,
  missionFilterReducer,
} from '../PrintStickers/PrintMissionFilterReducer';
import {IMissionFilter} from '../PrintStickers/PrintStickers.store';
import {getGroupMissions, getMission, postAssistance} from './GroupReservations.service';
import {groupMissionsFilter} from './reservation.filter';
import {ReservationJourneyDto} from 'src/services/models/reservation-journey-dto';
import {ReservationTravelDto} from 'src/services/models/reservation-travel-dto';

enum IPrintAction {
  ALL = 'ALL',
  TO_PRINT = 'TO PRINT',
  PRINTED = 'PRINTED',
}
export interface ChangeAssistance {
  journeyId: string;
  hasDeparture: boolean;
  hasArrival: boolean;
}
interface IGroupReservationStore {
  groupMissions: ReservationDto[];
  groupMissionDetail: ReservationDto | undefined;
  groupMissionDate: string;
  loadingMissions: boolean;
  loadingMissionDetails: boolean;
  groupMissionFilterState: IMissionFilter;
  groupMissionFilterReducer: React.Dispatch<MissionFilterActions>;
  searchOnReservation: boolean;
  setSearchOnReservation: React.Dispatch<boolean>;
  activeMissions: ReservationDto[];
  activeMissionAssistance: ChangeAssistance[];
  missionModal: boolean;
  setMissionModal: React.Dispatch<boolean>;
  handleSelectedGroupReservationDetail: (id: string) => void;
  handleGroupMissionSearch: () => void;
  handleDateChange: (date: moment.Moment) => void;
  handleActiveMission: (missionId: string) => void;
  handleActiveMissionAssistance: (value: boolean, index: number, place: 'departure' | 'arrival') => void;
  handleChangeAssistance: () => void;
  assistanceFilter: () => void;
}

export const GroupReservationContext = React.createContext<IGroupReservationStore>({
  groupMissionFilterState: {
    printAction: IPrintAction.ALL,
  },
} as IGroupReservationStore);

const GroupReservationProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const {variables} = React.useContext(EnvironmentContext);
  const {districts, stations} = React.useContext(CacheContext);
  const {validateNetworkCall} = React.useContext(NetworkContext);

  //Feedback
  const [loadingMissions, setLoadingMissions] = React.useState(false);
  const [loadingMissionDetails, setLoadingMissionDetails] = React.useState(false);
  const [missionModal, setMissionModal] = React.useState(false);

  //State
  const [groupMissions, setGroupMissions] = React.useState<ReservationDto[]>([]);
  const [groupMissionDetail, setGroupMissionDetail] = React.useState<ReservationDto | undefined>(undefined);
  const [activeMissions, setActiveMissions] = React.useState<ReservationDto[]>([]);
  const [activeMissionAssistance, setActiveMissionAssistance] = React.useState<ChangeAssistance[]>([
    {},
  ] as ChangeAssistance[]);

  //Filter
  const [groupMissionDate, setGroupMissionDate] = React.useState(moment().format('YYYY-MM-DD'));
  const [searchOnReservation, setSearchOnReservation] = React.useState(false);
  const [groupMissionFilterState, groupMissionFilterReducer] = React.useReducer(
    missionFilterReducer,
    intitialFilterState(false),
  );

  const SERVICES = {
    fetchGroupMissions: () => {
      setLoadingMissions(true);
      getGroupMissions(variables.BASE_ENDPOINT, {
        date: groupMissionDate,
        arrivalStation: groupMissionFilterState.arrivalStation,
        departureStation: groupMissionFilterState.departureStation,
        trainNumber: groupMissionFilterState.trainNumber,
        description: groupMissionFilterState.groupName,
      })
        .then(res => {
          if (res) {
            setGroupMissions(
              groupMissionsFilter(groupMissionFilterState, searchOnReservation, res, stations as StationDto[]),
            );
          }
          setLoadingMissions(false);
        })
        .catch((err: AxiosError) => {
          if (err.response) {
            validateNetworkCall(err.response.status, err.response.data as ModelError);
          }
          setLoadingMissions(false);
        });
    },
    fetchMissionDetails: (id: string, isFetchById: boolean = false) => {
      setLoadingMissions(true);
      setLoadingMissionDetails(true);
      getMission(variables.BASE_ENDPOINT, id, ReservationTypeDto.Group)
        .then(res => {
          if (res) {
            if (isFetchById) {
              setGroupMissions([res]);
            } else {
              setGroupMissionDetail(res);
            }
          }
          setLoadingMissions(false);
          setLoadingMissionDetails(false);
        })
        .catch((err: AxiosError) => {
          if (err.response && err.response.status !== 404) {
            validateNetworkCall(err.response.status, err.response.data as ModelError);
          }
          setGroupMissions([]);
          setGroupMissionDetail(undefined);
          setLoadingMissions(false);
          setLoadingMissionDetails(false);
        });
    },
    setAssistance: (assistances: ChangeAssistance[]) => {
      setLoadingMissions(true);
      postAssistance(variables.BASE_ENDPOINT, assistances)
        .then(res => {
          manipulateLocalMissions(activeMissions[0].id || '', assistances);
          setMissionModal(false);
          setLoadingMissions(false);
        })
        .catch((err: AxiosError) => {
          if (err.response) {
            validateNetworkCall(err.response.status, err.response.data as ModelError);
          }
          setLoadingMissions(false);
        });
    },
  };

  const handleSelectedGroupReservationDetail = (id: string) => {
    const reservation = groupMissions.find(el => el.id === id);
    if (reservation && reservation.id) {
      SERVICES.fetchMissionDetails(reservation.id);
    }
  };

  const manipulateLocalMissions = (missionsId: string, assistances: ChangeAssistance[]) => {
    let missionToManipulate = groupMissions.find(el => el.id === missionsId);
    let ind = groupMissions.findIndex(el => el.id === missionsId);
    let counter = 0;

    if (missionToManipulate?.travels) {
      const newTravels: ReservationTravelDto[] | undefined = missionToManipulate.travels.map(travel => {
        const newJourneys: ReservationJourneyDto[] | undefined = travel.journeys?.map(journ => {
          const t = {
            ...journ,
            withArrivalAssistance: assistances[counter].hasArrival,
            withDepartureAssistance: assistances[counter].hasDeparture,
          };
          counter = counter + 1;
          return t;
        });

        return {
          ...travel,
          journeys: newJourneys,
        };
      });

      missionToManipulate.travels = newTravels;
      const newGroupMissions = groupMissions;
      newGroupMissions[ind] = missionToManipulate;
      setGroupMissions(newGroupMissions);
    }
  };

  const handleDateChange = (date: moment.Moment) => {
    setGroupMissionDate(date.format('YYYY-MM-DD'));
  };

  const handleGroupMissionSearch = () => {
    if (searchOnReservation) {
      SERVICES.fetchMissionDetails(groupMissionFilterState.reservationNumber, true);
    } else {
      SERVICES.fetchGroupMissions();
    }
  };

  const handleActiveMission = (missionId: string) => {
    const isActive = activeMissions.find(el => el.id === missionId);
    if (isActive) {
      const index = activeMissions.findIndex(el => el.id === missionId);
      setActiveMissions(prev => {
        prev.splice(index, 1);
        return [...prev];
      });
    } else {
      const mission = groupMissions.find(el => el.id === missionId);
      setActiveMissions(mission ? [mission] : ([{}] as ReservationDto[]));
      let allAssistances: ChangeAssistance[] = [];
      mission?.travels?.forEach(travel => {
        const res: ChangeAssistance[] = [];
        travel.journeys?.forEach(journey => {
          res.push({
            //@ts-ignore
            journeyId: journey.id || '',
            hasArrival: journey.withArrivalAssistance || false,
            hasDeparture: journey.withDepartureAssistance || false,
          });
        });
        allAssistances = [...allAssistances, ...res];
      });
      setActiveMissionAssistance(allAssistances);
    }
  };

  const handleActiveMissionAssistance = (value: boolean, index: number, place: 'departure' | 'arrival') => {
    setActiveMissionAssistance(prev => {
      const newState = prev;
      place === 'arrival' ? (newState[index].hasArrival = value) : (newState[index].hasDeparture = value);
      return [...newState];
    });
  };

  const handleChangeAssistance = () => {
    SERVICES.setAssistance(activeMissionAssistance);
  };

  const assistanceFilter = () => {
    SERVICES.fetchGroupMissions();
  };

  React.useEffect(() => {
    SERVICES.fetchGroupMissions();
  }, []);

  return (
    <GroupReservationContext.Provider
      value={{
        groupMissions,
        groupMissionDetail,
        loadingMissionDetails,
        loadingMissions,
        groupMissionDate,
        groupMissionFilterState,
        groupMissionFilterReducer,
        searchOnReservation,
        setSearchOnReservation,
        handleSelectedGroupReservationDetail,
        handleGroupMissionSearch,
        handleDateChange,
        handleActiveMission,
        activeMissions,
        activeMissionAssistance,
        handleActiveMissionAssistance,
        handleChangeAssistance,
        assistanceFilter,
        missionModal,
        setMissionModal,
      }}
    >
      {children}
    </GroupReservationContext.Provider>
  );
};

export default GroupReservationProvider;
