import {Paper} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';

export const Banner: React.FC = () => {
  const {t} = useTranslation();
  return (
    <Paper
      sx={{
        backgroundColor: '#FEEBEE',
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 0px 10px 15px',
      }}
      elevation={0}
    >
      <h5 style={{color: '#BB0000', display: 'block', width: '100%', margin: 0}}>{t('pmr.refuse-mission')}</h5>
      <p style={{margin: 0}}>{t('pmr.impact')}</p>
    </Paper>
  );
};
