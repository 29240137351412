import {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Typography, Paper} from '@mui/material';

import {IncidentDto} from 'src/services/models/incident-dto';
import Train from 'src/components/icons/Train';
import PtcarIcon from 'src/components/images/ic_steps-grey.svg';
import locationIcon from 'src/components/images/ic_location.svg';
import {StatusItem} from 'src/components/StatusItem/StatusItem';
import {IncidentStatusDto} from 'src/services/models/incident-status-dto';
import {IncidentTypeDto} from 'src/services/models/incident-type-dto';
import {useCoiCreateIncidentContext} from 'src/context/coi/coi.createIncident.store';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';

type CoiIncidentListItemCardProps = {
  incident: IncidentDto;
  onSelectIncident: () => void;
  isSelected?: boolean;
};

export const CoiIncidentListItemCard: FC<CoiIncidentListItemCardProps> = ({incident, onSelectIncident, isSelected }) => {
  const {t, i18n} = useTranslation();
  const coiStore = useCoiCreateIncidentContext();

  const trains = incident.locations?.filter(location => location.type === 'Train') || [];
  const ptcars = incident.locations?.filter(location => location.type === 'Ptcar') || [];
  const addresses = incident.locations?.filter(location => location.type === 'Address') || [];

  const getColor = (status: IncidentStatusDto): string => {
    switch (status) {
      case IncidentStatusDto.New:
        return '#003B8C';
      case IncidentStatusDto.OnGoing:
        return '#C27710';
      case IncidentStatusDto.Finalized:
        return '#2B7C2B';
      default:
        return '';
    }
  };

  const getBackgroundColor = (status: IncidentStatusDto): string => {
    switch (status) {
      case IncidentStatusDto.New:
        return 'rgba(194, 119, 16, 0.2)';
      case IncidentStatusDto.OnGoing:
        return 'white';
      case IncidentStatusDto.Finalized:
        return 'rgba(43, 124, 43, 0.2)';
      default:
        return '';
    }
  };

  const translationByCatalogAndSubCatalog = useMemo(() => {
    const catalog = coiStore.referenceData.find(d => d.id === incident.catalogId);
    if (catalog) {
      const subCatalog = catalog.subCatalogs.find(d => d.id === incident.subCatalogId);
      if (subCatalog) {
        return `${handleTranslationObject(i18n, catalog.names)} ${handleTranslationObject(i18n, subCatalog.names)}`;
      } else {
        return `${handleTranslationObject(i18n, catalog.names)}`;
      }
    }
    return '';
  }, [coiStore.referenceData, incident.catalogId, incident.subCatalogId, i18n]);

  const getIncidentTime = (incident: IncidentDto): string => {
    if (!incident.createdAt) {
      return '';
    }
    const date = new Date(incident.createdAt);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  return (
    <Box sx={{
      padding: '0 10px',
      backgroundColor: isSelected ? 'rgba(25, 118, 210, 0.08)' : '',
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    }}>
      <Paper
        onClick={onSelectIncident}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          maxWidth: '343px',
          borderTop: `4px solid ${incident.type === IncidentTypeDto.Intervention ? '#BB0000' : '#C27710'}`,
          borderRadius: '4px',
          backgroundColor: incident.status ? getBackgroundColor(incident.status) : 'white',
          marginBottom: '10px',
          cursor: 'pointer',
          width: '100%',
          borderBottom: `${isSelected ? `4px solid #25336C` : null}`
        }}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: '1px solid #D7D7D7',
              padding: '12px 16px 8px 16px',
            }}
          >
            <Typography sx={{fontWeight: 'bold'}}>
              {incident.type} {translationByCatalogAndSubCatalog}
            </Typography>
            <Typography sx={{fontWeight: 'bold', color: '#C27710'}}>{getIncidentTime(incident)}</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', padding: '0 16px', minHeight: '88px'}}>
            {trains?.length > 0 ? (
              <Box
                sx={{
                  display: 'flex',
                  marginTop: '8px',
                  width: '100%',
                  alignItems: 'flex-start',
                }}
              >
                <Train />
                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '5px', marginLeft: '6px'}}>
                  {trains.map(train =>
                    'trainNumber' in train ? (
                      <Box
                        key={`train-${train.id}`}
                        sx={{
                          border: '1px solid #707070',
                          borderRadius: '4px',
                          padding: '0 3px',
                          backgroundColor: 'transparent',
                        }}
                      >
                        <Typography sx={{fontWeight: 'bold'}}>{train.trainNumber}</Typography>
                      </Box>
                    ) : null,
                  )}
                </Box>
              </Box>
            ) : null}
            {ptcars?.length > 0 ? (
              <Box
                sx={{
                  display: 'flex',
                  marginTop: '8px',
                  width: '100%',
                  alignItems: 'flex-start',
                }}
              >
                <img src={PtcarIcon} alt="img" style={{width: '20px', height: '20px'}} />
                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '5px', marginLeft: '6px'}}>
                  {ptcars.map(ptcar =>
                    'name' in ptcar ? (
                      <Box
                        key={`ptcar-${ptcar.id}`}
                        sx={{
                          border: '1px solid #E0EDF6',
                          borderRadius: '4px',
                          padding: '2px 4px',
                          backgroundColor: 'transparent',
                        }}
                      >
                        <Typography>{ptcar.name}</Typography>
                      </Box>
                    ) : null,
                  )}
                </Box>
              </Box>
            ) : null}
            {addresses.length > 0 ? (
              <Box
                sx={{
                  display: 'flex',
                  marginTop: '8px',
                  width: '100%',
                  alignItems: 'flex-start',
                }}
              >
                <img src={locationIcon} alt="img" style={{width: '24px', height: '24px'}} />
                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '5px', marginLeft: '6px'}}>
                  {addresses.map(address =>
                    'address' in address ? (
                      <Box
                        key={`address-${address.id}`}
                        sx={{
                          border: '1px solid #E0EDF6',
                          borderRadius: '4px',
                          padding: '2px 4px',
                          backgroundColor: 'transparent',
                        }}
                      >
                        <Typography>{address.address}</Typography>
                      </Box>
                    ) : null,
                  )}
                </Box>
              </Box>
            ) : null}
          </Box>
        </Box>
        <Box
          sx={{
            padding: '8px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTop: '1px solid #D7D7D7',
            marginTop: '8px',
          }}
        >
          {incident.status ? (
            <StatusItem label={t(`coi.incident_status.${incident.status}`)} color={getColor(incident.status)} />
          ) : null}
          <Typography sx={{fontWeight: 'bold', fontSize: '12px'}}>{incident.displayId}</Typography>
        </Box>
      </Paper>
      </Box>
    );
};
