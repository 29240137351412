/* tslint:disable */
/* eslint-disable */
/**
 * Assist Dashboard BFF
 * The Assist Dashboard BFF API specification.
 *
 * OpenAPI spec version: 2.0.402
 * Contact: 95ypto.DITA@belgiantrain.be
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @enum {string}
 */
export enum AssistanceType {
    Light = 'Light',
    Full = 'Full'
}

