import {Box, CircularProgress} from '@mui/material';
import React from 'react';
import {ChangeRouteContext} from '../ChangeRoute.store';
import {AlternativeRouteStepper} from './AlternativeRouteStepper';
import {AlternativeRouteContent} from './AlternativeRouteContent';
import NewProposal from '../NewProposedTravel/NewProposal';
import {useTranslation} from 'react-i18next';

export const AlternativeRouteView = () => {
  const {
    alternativeRoutes,
    selectedOriginalRoute,
    isFetchingAlternativeRoutes,
    fetchNextOrPrevious,
    selectedNewProposal,
    tripStatus,
  } = React.useContext(ChangeRouteContext);

  const {t} = useTranslation();

  if (isFetchingAlternativeRoutes) {
    return (
      <Box sx={{backgroundColor: 'white', padding: '25px', display: 'grid', placeItems: 'center'}}>
        <Box>
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  if (alternativeRoutes === null || selectedOriginalRoute === null) {
    return (
      <Box sx={{backgroundColor: 'white', padding: '25px', display: 'grid', placeItems: 'center'}}>
        <Box>
          <b>{t('cr.select-trip')}</b>
        </Box>
      </Box>
    );
  }

  if (
    selectedNewProposal !== undefined &&
    (tripStatus.active === 'inbound' ? selectedNewProposal.inboundProposal : selectedNewProposal.outBoundProposal)
  ) {
    return <NewProposal />;
  }

  return (
    <Box sx={{backgroundColor: 'white', padding: '25px', overflowY: 'scroll', maxHeight: 'calc(100vh - 64px)'}}>
      <Box sx={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
        <h4>{t('cr.alternative-route')}</h4>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '25px 1fr',
          width: '100%',
          minHeight: '400px',
        }}
      >
        <AlternativeRouteStepper />
        <AlternativeRouteContent
          alt={alternativeRoutes}
          sel={selectedOriginalRoute}
          fetchNextOrPrevious={fetchNextOrPrevious}
        />
      </Box>
    </Box>
  );
};
