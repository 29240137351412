import React from 'react'

const OrientationProblemsIcon = () => (
    <svg id="ic-pmr" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <rect id="Rectangle_1966" data-name="Rectangle 1966" width="24" height="24" fill="none"/>
        <path id="Path_5702" data-name="Path 5702" d="M12.86,239.9a3.42,3.42,0,0,0-1.07-.72,3.35,3.35,0,0,0-1.3-.26h-.3l.26-.14a3.38,3.38,0,0,0,.8-.59,3.68,3.68,0,0,0,.72-1.06,3.33,3.33,0,0,0,.26-1.31,3.39,3.39,0,0,0-.26-1.3,3.6,3.6,0,0,0-.72-1.07,3.541,3.541,0,0,0-1.07-.72,3.42,3.42,0,0,0-2.61,0,3.32,3.32,0,0,0-1.06.72,3.6,3.6,0,0,0-.72,1.07,3.39,3.39,0,0,0-.26,1.3,3.33,3.33,0,0,0,.26,1.31,3.68,3.68,0,0,0,.72,1.06,3.78,3.78,0,0,0,.79.59l.27.14h-.3a3.33,3.33,0,0,0-1.31.26,3.371,3.371,0,0,0-1.06.72,3.26,3.26,0,0,0-.72,1.1,3.39,3.39,0,0,0-.26,1.3v3.35h9.92V242.3a3.33,3.33,0,0,0-1-2.37Zm-5.58-2.43h0a2.22,2.22,0,0,1-.66-1.62,2.29,2.29,0,1,1,4.58,0,2.29,2.29,0,0,1-2.29,2.29,2.25,2.25,0,0,1-1.63-.67Zm5.51,7.09H5v-2.29A2.27,2.27,0,0,1,7.27,240h3.22a2.24,2.24,0,0,1,1.63.66,2.27,2.27,0,0,1,.67,1.63Z" transform="translate(0 -223.49)" fill="#333"/>
        <path id="Path_5703" data-name="Path 5703" d="M15.17,227.53a2,2,0,0,0-1.4.51,1.54,1.54,0,0,0-.55,1.08v.2h1.2v-.16a.67.67,0,0,1,.75-.61.74.74,0,0,1,.49.13.33.33,0,0,1,.13.26.64.64,0,0,1-.14.33,2.14,2.14,0,0,1-.44.47,3.4,3.4,0,0,0-.38.37,1.07,1.07,0,0,0-.24.37,1.75,1.75,0,0,0-.08.41c0,.13,0,.28,0,.47v.2h1.23v-.2c0-.49.08-.6.1-.61l.41-.5c.14-.15.28-.32.42-.49a1.1,1.1,0,0,0,.25-.73,1.38,1.38,0,0,0-.5-1.1A2,2,0,0,0,15.17,227.53Z" transform="translate(0 -223.49)" fill="#333"/>
        <path id="Path_5704" data-name="Path 5704" d="M15.08,231.91a.731.731,0,0,0-.54,1.26.68.68,0,0,0,.54.23.69.69,0,0,0,.54-.23.76.76,0,0,0,.2-.53.72.72,0,0,0-.21-.53.76.76,0,0,0-.53-.2Z" transform="translate(0 -223.49)" fill="#333"/>
        <path id="Path_5705" data-name="Path 5705" d="M15.24,225.37a4.85,4.85,0,1,0,4.84,4.84,4.85,4.85,0,0,0-4.84-4.84Zm0,8.79a4,4,0,1,1,4-3.95,4,4,0,0,1-4,3.95Z" transform="translate(0 -223.49)" fill="#333"/>
        <path id="Path_5706" data-name="Path 5706" d="M15.75,235.61a1.1,1.1,0,0,0-.79-.14,1.05,1.05,0,0,0-.79,1.25,1,1,0,0,0,1,.82.85.85,0,0,0,.23,0,1,1,0,0,0,.79-1.24,1.06,1.06,0,0,0-.44-.69Z" transform="translate(0 -223.49)" fill="#333"/>
        <path id="Path_5707" data-name="Path 5707" d="M13.23,236.54a.8.8,0,1,0,.8.8.8.8,0,0,0-.8-.8Z" transform="translate(0 -223.49)" fill="#333"/>
    </svg>

)

export default OrientationProblemsIcon
