// @ts-nocheck
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function EmptyList(props) {
  return (
    <SvgIcon {...props}>
      <svg width="1em" height="1em" viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path data-name="Path 5410" d="M0 0h116v116H0Z" fill="none" />
        <path
          data-name="Path 5411"
          d="M104.167 48.333V29a9.664 9.664 0 0 0-9.667-9.667H17.167A9.623 9.623 0 0 0 7.549 29v19.333A9.667 9.667 0 0 1 7.5 67.666V87a9.7 9.7 0 0 0 9.667 9.667H94.5A9.7 9.7 0 0 0 104.167 87V67.666a9.667 9.667 0 0 1 0-19.333ZM94.5 41.276a19.3 19.3 0 0 0 0 33.447V87H17.167V74.723A19.4 19.4 0 0 0 26.834 58a19.294 19.294 0 0 0-9.618-16.723L17.168 29H94.5ZM51 72.5h9.667v9.667H51Zm0-19.333h9.667v9.666H51Zm0-19.333h9.667v9.667H51Z"
          stroke="#fff"
          strokeWidth={3}
        />
      </svg>
    </SvgIcon>
  );
}
