// @ts-nocheck
import React from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FowardArrow from 'src/components/icons/FowardArrow';
import PrintStickerIcon from 'src/components/icons/PrintStickerIcon';

import Train from 'src/components/icons/Train';

import TrainSticker from 'src/components/icons/TrainSticker';
import moment from 'moment';
import {UserRight, UserLeft, UserChecked} from 'src/components/icons';

import AgeIcons from '../AgeList';

import {useNavigate} from 'react-router-dom';

import IconEdit from '../../components/images/IC_edit.svg';
import './PrintStickerBox.scss';

import {PrintMissionDto} from 'src/services/models/print-mission-dto';
import {PrintStickerContext} from 'src/scenes/coordinator/PrintStickers/PrintStickers.store';
import {MissionStatusDto} from 'src/services/models/mission-status-dto';
import {useTranslation} from 'react-i18next';
import {SettingsContext} from 'src/context/settings/settings.store';

export const PrintStickerBox = (props: PrintMissionDto) => {
  const {selectForPrint, handleSelectForPrint, handleOriginStation} = React.useContext(PrintStickerContext);
  const {setPrevRoute} = React.useContext(SettingsContext);

  const navigate = useNavigate();

  const {t} = useTranslation();

  const handleRedirect = async () => {
    setPrevRoute(history.location);
    navigate(`/trip-details/${props.reservationId}/Group`);
  };

  return (
    <Box
      sx={{
        borderTop: '1px solid #d7d7d7',
        width: '100%',
      }}
    >
      <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '4px'}}>
        <Box sx={{display: 'flex', alignItems: 'center', gap: '5px'}}>
          <Checkbox
            checked={selectForPrint.includes(props.id)}
            sx={{padding: 0}}
            size="small"
            onChange={() => {
              if (selectForPrint.includes(props.id)) {
                handleSelectForPrint(props.id, 'DESELECT');
              } else {
                handleSelectForPrint(props.id, 'SELECT');
              }
            }}
          />
          <a
            style={{cursor: 'pointer', textDecoration: 'underline', color: 'black'}}
            onClick={() => {
              handleRedirect();
            }}
          >
            {props.reservationId}
          </a>
          <span>-</span>
          <span> {props.groupName} </span>
          <span> {moment(props.journey?.time).format('yyyy-MM-DD')} </span>
        </Box>
        <Box>
          {props.status === MissionStatusDto.Completed && (
            <>
              <span style={{marginRight: '5px'}}>
                {props.ocsCoordinator?.id} - {props.ocsCoordinator?.name}
              </span>
              <span>
                <PrintStickerIcon />
              </span>
            </>
          )}
          <span> {moment(props.journey?.otherTime).format('yyyy-MM-DD')} </span>
        </Box>
      </Box>
      <Box sx={{display: 'flex', justifyContent: 'space-between', padding: '4px'}}>
        <span>
          {t('mission.mission-print-sticker-first-mission')} : {props.journey?.originStationName}{' '}
        </span>
        <img
          src={IconEdit}
          alt="Icon"
          className="button-edit-printStricker-img"
          onClick={() => handleOriginStation(props.id)}
        />
        <span style={{flex: 2, textAlign: 'center'}}> {props.journey?.stationName} </span>
        <span>
          <FowardArrow color="#25336c" />
        </span>
        <span style={{flex: 2, textAlign: 'center'}}> {props.journey?.otherStationName} </span>
        <Box>
          <AgeIcons groupCount={props.quantity} />
        </Box>
      </Box>
      <Box sx={{display: 'flex', justifyContent: 'space-between', padding: '4px'}}>
        <Box sx={{display: 'flex', gap: '12px'}}>
          <Box>
            <span>
              <Train color="#25336c" />
            </span>
            <span style={{fontWeight: '700', fontFamily: 'sans-serif'}}>{props.journey?.trainNumber}</span>
          </Box>
          <Box>
            <span>
              <TrainSticker color="#25336c" />
            </span>
            <span style={{fontWeight: '700', paddingLeft: '5px', fontFamily: 'sans-serif'}}>
              {Math.ceil(props.quantity / 4)}
            </span>
          </Box>
        </Box>
        <span>
          <Box>
            <span>
              <UserLeft />
            </span>
            <span style={{fontWeight: '700', fontFamily: 'sans-serif'}}>
              {' '}
              {moment(props.journey?.time).format('HH:mm')}{' '}
            </span>
          </Box>
        </span>
        <span />
        <span>
          <Box>
            <span style={{fontWeight: '700', fontFamily: 'sans-serif'}}>
              {moment(props.journey?.otherTime).format('HH:mm')}
            </span>
            <span>
              <UserRight />
            </span>
          </Box>
        </span>
        <span>
          <Box>
            {props.owner && (
              <>
                <span>
                  <UserChecked color="#25336c" />
                </span>
                <span style={{fontWeight: '700', fontFamily: 'sans-serif'}}> {props.owner.id}</span>
              </>
            )}
          </Box>
        </span>
      </Box>
    </Box>
  );
};
