import {Box, Button, Container, Divider, FormControlLabel, IconButton, Radio, RadioGroup} from '@mui/material';
import * as React from 'react';
import {CreateJourneyDto, CreateReservationDto, ReservationTypeDto} from 'src/services/models';
import {TrainNumber} from './components/trainNumber';
import {reservationsProps} from './types';
import 'src/scenes/Pages/CreateMissionNew/CreateMission.scss';
import {Close} from '@mui/icons-material';
import {t} from 'i18next';
import {TransportTypeDto} from 'src/services/models/transport-type-dto';
import {CreateTaxiMissionFormComponents} from 'src/scenes/taxi/form';
import moment from 'moment';
import {SettingsContext} from 'src/context/settings/settings.store';

export enum JourneyVariant {
  TRAIN = 'TRAIN',
  TAXI = 'TAXI',
}

export const Reservations: React.FC<reservationsProps> = ({reservation, setReservation, variant}) => {
  const [enableAddJourney, setEnableAddJourney] = React.useState(false);
  const {RESERVATIONS_FEATURE_FLAG_NEW_PMR} = React.useContext(SettingsContext);

  const handleAddSubsequentJourney = () => {
    const prevState = reservation;
    prevState.travel.journeys.push({
      withDepartureAssistance: false,
      withArrivalAssistance: false,
      transportType: TransportTypeDto.Train,
      departureUicCode:
        reservation.travel.journeys.length > 1
          ? reservation.travel.journeys[reservation.travel.journeys.length - 1].arrivalUicCode
          : undefined,
    } as CreateJourneyDto);
    setReservation(_prev => {
      return {...prevState};
    });
  };

  const handleRemoveJourneyFromIndex = (ind: number) => {
    const prevState = reservation;
    prevState.travel.journeys.splice(ind, 1);
    setReservation(_prev => {
      return {...prevState};
    });
  };

  const allowAddingOfJourney = () => {
    const prevJourney = reservation.travel.journeys[reservation.travel.journeys.length - 1];
    if (prevJourney.transportType === TransportTypeDto.Taxi) {
      const isValidDate = moment(prevJourney.departureTime).isBefore(moment().startOf('day'));
      if (
        prevJourney.transportId &&
        prevJourney.departureUicCode &&
        prevJourney.arrivalUicCode &&
        !isValidDate &&
        prevJourney.departureInfo &&
        prevJourney.arrivalInfo
      ) {
        return setEnableAddJourney(true);
      } else {
        return setEnableAddJourney(false);
      }
    }

    if (
      reservation.travel.journeys
        .filter(el => el.transportType !== TransportTypeDto.Taxi)
        .every(el => {
          if (el === undefined) {
            return false;
          }
          return (
            el.arrivalUicCode &&
            el.departureUicCode &&
            el.trainNumber &&
            el.arrivalUicCode.length > 0 &&
            el.departureUicCode.length > 0 &&
            el.trainNumber.toString().length > 0
          );
        })
    ) {
      setEnableAddJourney(true);
    } else {
      setEnableAddJourney(false);
    }
  };

  const toggleTaxiReservaton = (pos: number) => {
    const prevState = reservation;
    prevState.travel.journeys[pos] = {
      withDepartureAssistance: pos === 0 ? true : false,
      withArrivalAssistance: false,
      //@ts-ignore
      departureTime:
        pos === 0
          ? moment(new Date()).format()
          : moment(reservation.travel.journeys[pos - 1].arrivalTime)
              .add(1, 'minute')
              .format(),
      transportType:
        prevState.travel.journeys[pos].transportType === TransportTypeDto.Taxi
          ? TransportTypeDto.Train
          : TransportTypeDto.Taxi,
    };

    setReservation(_prev => {
      return {...prevState};
    });
  };

  React.useEffect(() => {
    allowAddingOfJourney();
  }, [reservation]);

  return (
    <Container maxWidth={false}>
      {reservation.travel.journeys.map((el, index, originalArray) => {
        return (
          <div key={reservation.type + index.toString()}>
            <div>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr auto auto',
                }}
              >
                <p className="mission-title">
                  {t('mission.mission-details.trip.title')} {index + 1}
                </p>
                {variant === ReservationTypeDto.Disabled && RESERVATIONS_FEATURE_FLAG_NEW_PMR && (
                  <RadioGroup value={el.transportType} onChange={() => toggleTaxiReservaton(index)} row>
                    <FormControlLabel
                      disabled={index < originalArray.length - 1}
                      label={t('pmr.search.train')}
                      value={TransportTypeDto.Train}
                      control={<Radio />}
                    ></FormControlLabel>
                    <FormControlLabel
                      disabled={index < originalArray.length - 1}
                      label={t('report.taxi')}
                      value={TransportTypeDto.Taxi}
                      control={<Radio />}
                    ></FormControlLabel>
                  </RadioGroup>
                )}
                {index === originalArray.length - 1 && index !== 0 && (
                  <IconButton onClick={() => handleRemoveJourneyFromIndex(index)}>
                    <Close />
                  </IconButton>
                )}
              </Box>
              {el.transportType === TransportTypeDto.Train && (
                <TrainNumber
                  variant={variant}
                  reservation={reservation}
                  setReservation={setReservation}
                  index={index}
                />
              )}
              {el.transportType === TransportTypeDto.Taxi && (
                <>
                  <Divider sx={{margin: '25px 0px'}} textAlign="left">
                    Taxi Company
                  </Divider>
                  <CreateTaxiMissionFormComponents.Company
                    reservation={reservation}
                    setReservation={setReservation}
                    index={index}
                  />
                  <Divider sx={{margin: '25px 0px'}} textAlign="left">
                    Stations
                  </Divider>
                  <CreateTaxiMissionFormComponents.DepartureArrival
                    reservation={reservation}
                    setReservation={setReservation}
                    index={index}
                  />
                </>
              )}
            </div>
          </div>
        );
      })}
      <Button
        onClick={() => handleAddSubsequentJourney()}
        disabled={reservation.travel.journeys.length === 6 || !enableAddJourney}
        variant="contained"
        sx={{marginTop: '25px'}}
      >
        {t('create-mission.add-trip')}
      </Button>
    </Container>
  );
};
