/* tslint:disable */
/* eslint-disable */
//DTO
/**
 * 
 * @export
 * @enum {string}
 */
export enum TrainStatus {
    Scheduled = 'Scheduled',
    Canceled = 'Canceled'
}

