import {Box, Button, Grid, IconButton, Tooltip, styled} from '@mui/material';
import React from 'react';
import NoteIcon from 'src/icons/NoteIcon';
import {AcceptButton, DeclineButton, RejectButton, ValidateButton} from './Buttons';
import FullAssistanceIcon from 'src/icons/FullAssistanceIcon';
import LightAssistanceIcon from 'src/icons/LightAssistanceIcon';
import {ReservationsContext} from 'src/context/reservations/reservations.store';
import NoteIconNew from 'src/icons/NoteIconNew';
import {RemarkDialog} from 'src/scenes/coordinator/MissionDetailsNew/components/OverviewOfTravelers/RemarkDialog';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {ReservationActionDetailDto} from 'src/services/models/reservation-action-detail-dto';
import {ReservationTypeDto} from 'src/services/models';
import {AffectedStation} from 'src/services/models/affected-station';
import {isStationPartOfDistrict} from '../util';
import {DisabledReservationDto} from 'src/services/models/disabled-reservation-dto';
import {acceptReservations} from 'src/context/reservations/data';
import {PmrSnackBar} from './SnackBar';
import {localStorageKeys} from 'src/utils/localStorageKeys';
import {AssistanceType} from 'src/services/models/assistance-type';
import {useLocation, useNavigate} from 'react-router-dom';
import {SubPmrRoutes} from '../Pmr';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import {SettingsContext} from 'src/context/settings/settings.store';
import {useTranslation} from 'react-i18next';
import {ReservationStatusDto} from 'src/services/models/reservation-status-dto';
import {Link} from 'react-router-dom';
import {AuthContext, AuthPermissions} from 'src/context/authentication/store.auth';

const BannerType = (res: DisabledReservationDto) => {
  //Check if a full asistance is required
  const allDisbaledAssistanceTypes =
    res.travels && res.travels.map(el => el.traveler?.disableds?.map(d => d.assistanceType));
  return allDisbaledAssistanceTypes?.flat().includes(AssistanceType.Full) ? (
    <FullAssistanceBanner
      type={res.type || ReservationTypeDto.Disabled}
      id={res.displayId || ''}
      name={res.client?.firstName + ' ' + res.client?.lastName || ''}
    />
  ) : (
    <LightAssistanceBanner
      type={res.type || ReservationTypeDto.Disabled}
      id={res.displayId || ''}
      name={res.client?.firstName + ' ' + res.client?.lastName || ''}
    />
  );
};

export const ReservationItemTitel: React.FC<{
  reservation: DisabledReservationDto;
  isDeclineViewVisible: boolean;
  setIsDeclineViewVisible: React.Dispatch<boolean>;
  ind: number;
  variant: SubPmrRoutes;
  focusedReservation: number | null;
  handleChangeRoute: () => void;
  isChangeRoutView: boolean;
  setIsChangeRouteViewVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleValidateRoute: () => void;
}> = ({
  reservation,
  isDeclineViewVisible,
  setIsDeclineViewVisible,
  ind,
  handleChangeRoute,
  isChangeRoutView,
  setIsChangeRouteViewVisible,
}) => {
  const {
    setFocusedReservation,
    searchReservations,
    fetchInProgressReservations,
    fetchProposalReservations,
    setIsFetchingProposalReservations,
    setIsFetchingPendingReservations,
    discardDraft,
  } = React.useContext(ReservationsContext);
  const {permissions} = React.useContext(AuthContext);
  const {variables} = React.useContext(EnvironmentContext);
  const {setPrevRoute} = React.useContext(SettingsContext);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const {t} = useTranslation();

  const [openSuccesSnackbar, setOpenSuccesSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);

  const selectedStations: string = localStorage.getItem(localStorageKeys.SELECED_STATIONS) || '';
  const hasRemarks = reservation.remarks && reservation.remarks?.length > 0;
  const location = useLocation();
  const redirect = useNavigate();

  const handleAcceptReservation = async () => {
    if (window.location.pathname.includes('overview')) {
      setIsFetchingProposalReservations(true);
    } else if (window.location.pathname.includes('validation-in-progress')) {
      setIsFetchingPendingReservations(true);
    }

    setFocusedReservation(null);
    const baseUrl = variables.BASE_ENDPOINT;
    const affectedStations: AffectedStation[] = [];
    reservation.travels?.forEach(trav => {
      trav.journeys?.forEach(journ => {
        if (
          isStationPartOfDistrict(selectedStations, journ.departureUicCode || 0) &&
          (journ.isDepartureOk !== true || journ.isDepartureOk === null)
        ) {
          affectedStations.push({proposalId: journ.proposalId, stationUicCode: journ.departureUicCode});
        }
        if (
          isStationPartOfDistrict(selectedStations, journ.arrivalUicCode || 0) &&
          (journ.isArrivalOk !== true || journ.isArrivalOk === null)
        ) {
          affectedStations.push({proposalId: journ.proposalId, stationUicCode: journ.arrivalUicCode});
        }
      });
    });
    const data: ReservationActionDetailDto = {
      reservationId: reservation.id,
      reservationType: ReservationTypeDto.Disabled,
      affectedStations: affectedStations.length > 0 ? affectedStations : null,
    };

    if (data.affectedStations?.length === 0 || data.affectedStations === null) {
      return;
    }
    await acceptReservations(baseUrl, data).catch(() => {
      setOpenErrorSnackbar(true);
      setTimeout(() => setOpenErrorSnackbar(false), 2000);
    });

    const district = localStorage.getItem(localStorageKeys.SELECTED_DISTRICT) || '';
    if (window.location.pathname.includes('overview')) {
      await fetchProposalReservations(district).then(_res => {
        setFocusedReservation(null);
        setOpenSuccesSnackbar(true);
        setTimeout(() => setOpenSuccesSnackbar(false), 2000);
      });
    } else if (window.location.pathname.includes('validation-in-progress')) {
      await fetchInProgressReservations(district).then(_res => {
        setFocusedReservation(null);
        setOpenSuccesSnackbar(true);
        setTimeout(() => setOpenSuccesSnackbar(false), 2000);
      });
    }

    await searchReservations(undefined);
    setIsFetchingPendingReservations(false);
    setIsFetchingProposalReservations(false);
  };

  const handleChangeRouteOfReservation = () => {
    handleChangeRoute();
  };

  const showRefusalButton = (status: ReservationStatusDto | undefined) => {
    if (permissions?.includes(AuthPermissions.ApprovalReservations) && status) {
      return status === ReservationStatusDto.Proposal || status === ReservationStatusDto.Pending;
    }

    return false;
  };

  const showAcceptButton = (status: ReservationStatusDto | undefined) => {
    if (permissions?.includes(AuthPermissions.ApprovalReservations) && status) {
      return status === ReservationStatusDto.Proposal;
    }

    return false;
  };

  const showChangeRouteButton = (status: ReservationStatusDto | undefined) => {
    if (permissions?.includes(AuthPermissions.ApprovalReservations) && status) {
      return status === ReservationStatusDto.Proposal || status === ReservationStatusDto.Approved;
    }

    return false;
  };

  const handleDraftDiscard = async () => {
    if (reservation.id) {
      await discardDraft(reservation.id);
    }
    const district = localStorage.getItem(localStorageKeys.SELECTED_DISTRICT) || '';
    await fetchInProgressReservations(district);
    await fetchProposalReservations(district);
  };

  const showAcceptButtonTooltipText = (): string => {
    if (selectedStations && selectedStations.length > 0) {
      return '';
    } else {
      return t('pmr.select-district');
    }
  };

  return (
    <Grid
      container
      spacing={0}
      sx={{border: 'solid 1px #ECECEC', padding: '0px', marginLeft: '0px', marginRight: '0px', borderRadius: '5px'}}
    >
      <Grid item xs={8} sx={{padding: '0px !important', display: 'flex', flexDirection: 'row', alignItems: 'stretch'}}>
        {BannerType(reservation)}
        <Box sx={{display: 'grid', placeItems: 'center'}}>
          <IconButton
            disabled={!reservation.remarks || reservation.remarks.length === 0}
            onClick={() => setIsDialogOpen(reservation.remarks && reservation.remarks.length > 0 ? true : false)}
            sx={{width: '40px', height: '32px'}}
          >
            {hasRemarks ? (
              <NoteIconNew width={'26px'} height={'24px'} />
            ) : (
              <NoteIcon width={'18px'} height={'18px'} style={{marginTop: '4px'}} />
            )}
          </IconButton>
        </Box>
      </Grid>
      {(showRefusalButton(reservation.status) || showChangeRouteButton(reservation.status)) && (
        <Grid item xs={4} sx={{padding: '0px !important', flexGrow: 1}}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              borderRadius: '0px 5px 5px 0px',
              padding: '8px 15px 8px 15px',
              height: '100%',
              gap: '8px',
            }}
          >
            {!isDeclineViewVisible && !isChangeRoutView ? (
              <>
                {showRefusalButton(reservation.status) && (
                  <DeclineButton
                    onClick={() => {
                      if (!isDeclineViewVisible) {
                        setFocusedReservation(ind);
                        setIsDeclineViewVisible(true);
                      } else {
                        setIsDeclineViewVisible(false);
                        setFocusedReservation(null);
                      }
                    }}
                  />
                )}
                {reservation.status === ReservationStatusDto.Proposal ||
                reservation.status === ReservationStatusDto.Approved ? (
                  <>
                    {showAcceptButton(reservation.status) && (
                      <Tooltip title={showAcceptButtonTooltipText()} arrow>
                        <span>
                          <AcceptButton
                            disabled={showAcceptButtonTooltipText().length > 0}
                            onClick={() => handleAcceptReservation()}
                          />
                        </span>
                      </Tooltip>
                    )}
                    <Tooltip title={reservation.isInternational ? t('pmr.international') : ''} arrow>
                      <span>
                        {showChangeRouteButton(reservation.status) && (
                          <Button
                            fullWidth
                            variant="outlined"
                            sx={{
                              color: 'black',
                              borderColor: 'black',
                              height: '32px',
                              minWidth: '138px',
                              width: 'auto',
                              fontSize: '14px',
                              textTransform: 'none',
                              whiteSpace: 'nowrap',
                            }}
                            disabled={reservation.isInternational}
                            startIcon={<AltRouteIcon />}
                            onClick={() => {
                              handleChangeRouteOfReservation();
                            }}
                          >
                            {t('pmr.change-route')}
                          </Button>
                        )}
                      </span>
                    </Tooltip>
                  </>
                ) : (
                  reservation.status === ReservationStatusDto.Pending && (
                    <>
                      <Tooltip title={reservation.isInternational ? t('pmr.international') : ''}>
                        <span>
                          <ValidateButton
                            disabled={reservation.isInternational}
                            onClick={() => {
                              setPrevRoute(location.pathname);
                              redirect(`/pmr/change-route/${reservation.id}`);
                            }}
                          />
                        </span>
                      </Tooltip>
                      <span style={{margin: 0, textAlign: 'center'}}>
                        <RejectButton
                          onClick={() => {
                            handleDraftDiscard();
                          }}
                        />
                      </span>
                    </>
                  )
                )}
              </>
            ) : (
              <Button
                onClick={() => {
                  setIsDeclineViewVisible(false);
                  setFocusedReservation(null);
                  setIsChangeRouteViewVisible(false);
                }}
                variant="outlined"
              >
                {t('filter-section.filter-cancel')}
              </Button>
            )}
          </Box>
        </Grid>
      )}
      <RemarkDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        remarks={reservation.remarks || []}
        fullWidth={true}
        maxWidth={'lg'}
        close={() => setIsDialogOpen(false)}
      />
      <PmrSnackBar
        success={true}
        title={'Accepted'}
        message={`Reservation with ID: ${reservation.id} was succesfully accepted`}
        open={openSuccesSnackbar}
      />
      <PmrSnackBar
        success={false}
        title={'Refused'}
        message={`Reservation with ID: ${reservation.id} was refused`}
        open={openErrorSnackbar}
      />
    </Grid>
  );
};

const FullAssistanceBanner: React.FC<{id: string; name: string; type: ReservationTypeDto}> = ({id, type}) => {
  const {setPrevRoute} = React.useContext(SettingsContext);
  const location = useLocation();
  return (
    <SharedStyles>
      <Box sx={{width: '34px', height: '34px', backgroundColor: '#FFF4E5', borderRadius: '4px', marginRight: '10px'}}>
        <FullAssistanceIcon style={{color: '#C37913', scale: '1', width: '34px', height: '34px'}} />
      </Box>
      <Link
        onClick={() => {
          setPrevRoute(location.pathname);
        }}
        to={`/trip-details/${id}/${type}`}
        style={IdNo}
      >
        N° {id} <span style={{color: 'gray', lineHeight: '18px', marginLeft: '5px'}}>|</span>
      </Link>
    </SharedStyles>
  );
};

const LightAssistanceBanner: React.FC<{id: string; name: string; type: ReservationTypeDto}> = ({id, type}) => {
  const {setPrevRoute} = React.useContext(SettingsContext);
  const location = useLocation();

  return (
    <SharedStyles>
      <Box
        sx={{
          width: '34px',
          height: '34px',
          backgroundColor: '#EAF2EA',
          borderRadius: '4px',
          marginRight: '10px',
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <LightAssistanceIcon style={{color: '#2B7C2B', scale: '1'}} />
      </Box>
      <Link
        onClick={() => {
          setPrevRoute(location.pathname);
        }}
        to={`/trip-details/${id}/${type}`}
        style={IdNo}
      >
        N° {id} <span style={{color: 'gray', lineHeight: '18px', marginLeft: '5px'}}>|</span>
      </Link>
    </SharedStyles>
  );
};

const SharedStyles = styled(Box)({
  padding: '8px 5px 8px 5px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: '5px 0px 0px 5px',
  height: '100%',
});

const IdNo = {
  color: 'black',
  fontWeight: 'bold',
  textDecoration: 'none',
};
