import React from 'react';

const NoteIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19.514" viewBox="0 0 20 19.514" {...props}>
    <g id="ic_note" transform="translate(-1304 -146.061)">
      <path
        id="comment_FILL0_wght300_GRAD0_opsz24"
        d="M103.948,207.843h12.105v-1.579H103.948Zm0-3.158h12.105v-1.579H103.948Zm0-3.158h12.105v-1.579H103.948ZM120,215.515l-3.725-3.725H101.9a1.887,1.887,0,0,1-1.9-1.9V197.9a1.887,1.887,0,0,1,1.9-1.9H118.1a1.887,1.887,0,0,1,1.9,1.9ZM101.58,197.9v12.308h15.352l1.49,1.49V197.9a.312.312,0,0,0-.324-.324H101.9a.312.312,0,0,0-.324.324Zm0,0v0Z"
        transform="translate(1203.999 -49.94)"
        fill="#333"
      />
    </g>
  </svg>
);

export default NoteIcon;
