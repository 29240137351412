import PmrIcon from 'src/icons/PmrIcon';
import ManagerIcon from 'src/components/icons/Manager';
import {ReservationTypeDto} from 'src/services/models';
import {MissionSummaryDto} from 'src/services/models/mission-summary-dto';
import {ContentItem} from '../MissionItem.styles';
import {getSumOfAgeCategories, getSumOfAssistances} from './util';
import {colors} from 'src/utils/hexCode';

export const MissionType: React.FC<{mission: MissionSummaryDto}> = ({mission}) => {
  if (mission.reservationType === ReservationTypeDto.Disabled) {
    return (
      <>
        <PmrIcon style={{width: '24px', height: '24px'}} />
        <ContentItem sx={{fontSize: '11.2px'}} color="#333333">
          {getSumOfAssistances(mission.traveler?.fullAssistances, mission.traveler?.lightAssistances)}
        </ContentItem>
      </>
    );
  }

  return (
    <>
      <ManagerIcon color={colors.nbms_dark_blue} />
      <ContentItem sx={{fontSize: '11.2px'}} color="#333333">
        {getSumOfAgeCategories(mission.traveler)}
      </ContentItem>
    </>
  );
};
