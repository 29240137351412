import {Box, Checkbox, Chip} from '@mui/material';
import React from 'react';
import moment from 'moment';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import BlockIcon from '@mui/icons-material/Block';
import {isStationPartOfDistrict} from '../util';
import RequiresAssistanceOnIcon from 'src/components/images/ic_pmr_on.svg';
import RequiresAssistanceOfIcon from 'src/components/images/ic_pmr_of.svg';
import {localStorageKeys} from 'src/utils/localStorageKeys';
import {DisabledJourneyDto} from 'src/services/models/disabled-journey-dto';
import {SubPmrRoutes} from '../Pmr';
import {ReservationsContext} from 'src/context/reservations/reservations.store';

const TrainNumberChip: React.FC<{trainNumber: number | undefined}> = ({trainNumber}) => {
  return (
    <Chip
      variant="outlined"
      label={trainNumber}
      sx={{
        marginRight: '8px',
        marginTop: '0px',
        backgroundColor: 'white',
        borderRadius: '8px',
        fontWeight: 'bold',
        width: '100%',
      }}
    />
  );
};

const TrainBox: React.FC<{children: React.ReactNode}> = ({children}) => {
  return <Box sx={{minWidth: '65px', marginTop: '0px', marginRight: '8px'}}>{children}</Box>;
};

export const JourneyItem: React.FC<{journey: DisabledJourneyDto; isInRefusalMode: boolean}> = ({
  journey,
  isInRefusalMode,
}) => {
  const {refusalData, setRefusalData} = React.useContext(ReservationsContext);

  const selectedStations: string = localStorage.getItem(localStorageKeys.SELECED_STATIONS) || '';
  const correctColors = (isOk: boolean | null | undefined, isBackground: boolean) => {
    if (isBackground) {
      return isOk ? '#BBD5BE' : '#AEB0B1';
    }

    return isOk ? '#2B7C2B' : '#000000DE';
  };

  const verifyIfStationIsAffected = (
    proposal: string | null | undefined,
    stationUicCode: string | null | undefined,
  ) => {
    if (proposal === (null || undefined) || stationUicCode === (null || undefined)) {
      return false;
    }

    const doesAffectedExist = refusalData.affectedStations?.find(
      el => el.proposalId === proposal && el.stationUicCode === stationUicCode,
    );
    if (doesAffectedExist === undefined) {
      return false;
    }

    return true;
  };

  const handleAffectedStations = (variant: 'dep' | 'arr', removeFromAffected: boolean) => {
    //Add station to refusal
    if (!removeFromAffected) {
      const newlyAffectedStations = refusalData.affectedStations;
      newlyAffectedStations?.push({
        proposalId: journey.proposalId,
        stationUicCode: variant === 'dep' ? journey.departureUicCode : journey.arrivalUicCode,
      });

      setRefusalData(prevData => {
        return {
          ...prevData,
          affectedStations: newlyAffectedStations,
        };
      });
    } else {
      //Remove from refusal
      let indexToBeRemoved: number | undefined = undefined;
      refusalData.affectedStations?.forEach((el, index) => {
        if (
          el.proposalId === journey.proposalId &&
          (variant === 'dep'
            ? el.stationUicCode === journey.departureUicCode
            : el.stationUicCode === journey.arrivalUicCode)
        ) {
          indexToBeRemoved = index;
        }
      });

      if (indexToBeRemoved !== undefined) {
        const newlyAffectedStations = refusalData.affectedStations;
        newlyAffectedStations?.splice(indexToBeRemoved, 1);
        setRefusalData(prevData => {
          return {
            ...prevData,
            affectedStations: newlyAffectedStations,
          };
        });
      }
    }
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 50px 1fr',
        alignItems: 'center',
        padding: '8px 0px',
        width: '100%',
        flexDirection: 'row',
        lineHeight: '40px',
      }}
    >
      <Box
        sx={{
          backgroundColor: correctColors(journey.isDepartureOk, true),
          padding: '5px 8px',
          borderRadius: '5px'
        }}
      >
        <Box sx={{display: 'grid', gridTemplateColumns: '1fr 50px'}}>
          <Box sx={{display: 'flex', flexDirection: 'row'}}>
            {isInRefusalMode && !journey.isDepartureOk && (
              <Checkbox
                sx={{
                  width: '42px',
                  height: '42px',
                }}
                onChange={() =>
                  handleAffectedStations('dep', verifyIfStationIsAffected(journey.proposalId, journey.departureUicCode))
                }
                checked={verifyIfStationIsAffected(journey.proposalId, journey.departureUicCode)}
                icon={
                  <span
                    style={{
                      backgroundColor: 'white',
                      width: '18px',
                      border: 'solid 1px black',
                      height: '18px',
                      borderRadius: '3px',
                    }}
                  />
                }
              />
            )}
            <TrainBox>
              <TrainNumberChip trainNumber={journey.trainNumber} />
            </TrainBox>
            <span style={{marginRight: '8px', fontWeight: 'bold', display: 'inline-block'}}>
              {moment(journey.departureTime).format('HH:mm')}
            </span>
            <b style={{color: 'darkgray'}}>|</b>
            <span
              style={{
                marginLeft: '8px',
                display: 'inline-block',
                fontWeight: isStationPartOfDistrict(selectedStations, journey.departureUicCode || 0)
                  ? 'bold'
                  : 'normal',
              }}
            >
              {journey.departureName}
            </span>
          </Box>
          <Box>
            {journey.withDepartureAssistance ? (
              <img
                style={{
                  width: '25px',
                  height: '25px',
                }}
                src={RequiresAssistanceOnIcon}
              />
            ) : (
              <BlockIcon style={{color: correctColors(journey.isDepartureOk, false)}} />
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={{display: 'grid', placeItems: 'center'}}>
        <ArrowRightAltIcon />
      </Box>
      <Box
        sx={{
          backgroundColor: correctColors(journey.isArrivalOk, true),
          padding: '5px 8px',
          borderRadius: '5px'
        }}
      >
        <Box sx={{display: 'grid', gridTemplateColumns: '1fr 50px'}}>
          <Box sx={{display: 'flex', flexDirection: 'row'}}>
            {isInRefusalMode && !journey.isArrivalOk && (
              <Checkbox
                sx={{
                  width: '42px',
                  height: '42px',
                }}
                onChange={() =>
                  handleAffectedStations('arr', verifyIfStationIsAffected(journey.proposalId, journey.arrivalUicCode))
                }
                checked={verifyIfStationIsAffected(journey.proposalId, journey.arrivalUicCode)}
                icon={
                  <span
                    style={{
                      backgroundColor: 'white',
                      width: '18px',
                      border: 'solid 1px black',
                      height: '18px',
                      borderRadius: '3px',
                    }}
                  />
                }
              />
            )}
            <TrainBox>
              <TrainNumberChip trainNumber={journey.trainNumber} />
            </TrainBox>
            <span style={{marginRight: '8px', fontWeight: 'bold', display: 'inline-block'}}>
              {moment(journey.arrivalTime).format('HH:mm')}
            </span>
            <b style={{color: 'darkgray'}}>|</b>
            <span
              style={{
                marginLeft: '8px',
                display: 'inline-block',
                fontWeight: isStationPartOfDistrict(selectedStations, journey.arrivalUicCode || 0) ? 'bold' : 'normal',
              }}
            >
              {journey.arrivalName}
            </span>
          </Box>
          <Box>
            {journey.withArrivalAssistance ? (
              <img
                style={{
                  width: '25px',
                  height: '25px',
                }}
                src={RequiresAssistanceOfIcon}
              />
            ) : (
              <BlockIcon style={{color: correctColors(journey.isArrivalOk, false)}} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
