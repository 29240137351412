// @ts-nocheck
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function Train(props) {
  return (
    <SvgIcon {...props}>
      <svg width="1em" height="1em" {...props}>
        <g data-name="Calque 2">
          <path d="M7.2 18.25L4.7 22H3.25L6 18a2.38 2.38 0 001 .23zM7 17a1.19 1.19 0 01-.9-.37 1.26 1.26 0 01-.35-.88V7a4.85 4.85 0 01.39-1.95A5 5 0 018.8 2.39 4.85 4.85 0 0110.75 2h2.5a4.86 4.86 0 011.93.39 5.09 5.09 0 011.61 1.07 5.24 5.24 0 011.07 1.59 4.85 4.85 0 01.39 2v8.75a1.26 1.26 0 01-.35.88 1.19 1.19 0 01-.9.32zm10 3.75l.82 1.25H6.18L7 20.75zm-10-5h10V7a3.72 3.72 0 00-3.75-3.75h-2.5A3.72 3.72 0 007 7zm1.64 2.46h6.64l.86 1.25H7.82zM13.25 4.5a2.45 2.45 0 011.75.72A2.41 2.41 0 0115.75 7v3.75h-7.5V7A2.41 2.41 0 019 5.22a2.45 2.45 0 011.76-.72zM9.5 12a1.26 1.26 0 01.9 2.13 1.28 1.28 0 01-1.8 0A1.24 1.24 0 019.5 12zm5.31-5a1.54 1.54 0 00-1.56-1.56h-2.5A1.54 1.54 0 009.19 7v2.81h5.62zm-.31 5a1.26 1.26 0 01.9 2.13 1.28 1.28 0 01-1.8 0 1.24 1.24 0 01.9-2.13zm6.25 10H19.3l-2.46-3.75H17a2.44 2.44 0 001.09-.25z" />
          <path fill="none" d="M0 0h24v24H0z" />
        </g>
      </svg>
    </SvgIcon>
  );
}
