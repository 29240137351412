import PhoneIcon from '@mui/icons-material/Phone';
import CircularProgress from '@mui/material/CircularProgress';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';
import {v4 as uuidv4} from 'uuid';
import {OverviewCheckinContext} from '../OverviewCheckin.store';
import {DicaCallIconButton} from 'src/components';

export const FormList: React.FC = () => {
  const {t, i18n} = useTranslation();
  const {STATE} = React.useContext(OverviewCheckinContext);

  return (
    <>
      {!STATE.formControls.loadingData ? (
        <div style={{marginTop: '15px'}}>
          {STATE.results && STATE.results.length > 0 ? (
            STATE.results.map((el, ind) => {
              return (
                <div
                  style={{
                    marginBottom: '25px',
                    backgroundColor: `${ind % 2 !== 0 ? '#EDF4FB' : 'white'}`,
                    padding: '8px',
                    borderRadius: '4px',
                  }}
                  key={uuidv4()}
                >
                  <Title style={{fontSize: '24px'}}>{el.stationName}</Title>
                  <hr />
                  <div>
                    {el.presences
                      ? el.presences.map(presence => {
                          const startTime = presence.checkInTimestamp
                            ? new Date(presence.checkInTimestamp).getHours() +
                              ':' +
                              new Date(presence.checkInTimestamp).getMinutes().toString().padStart(2, '0')
                            : '';
                          const endTime = presence.checkOutTimestamp
                            ? new Date(presence.checkOutTimestamp).getHours() +
                              ':' +
                              new Date(presence.checkOutTimestamp).getMinutes().toString().padStart(2, '0')
                            : '';
                          return (
                            <div style={{display: 'grid', gridTemplateColumns: 'repeat(5,1fr) auto'}} key={uuidv4()}>
                              <p>{presence.name ? presence.name : ''}</p>
                              <p>{presence.role ? handleTranslationObject(i18n, presence.role) : ''}</p>
                              {STATE.formControls.hideTimeRegistrations ? <p></p> : <p>Check-in Time: {startTime}</p>}
                              {STATE.formControls.hideTimeRegistrations ? <p></p> : <p>Check-out Time: {endTime}</p>}
                              {presence.phoneNumber ? <p>{presence.phoneNumber}</p> : <p></p>}
                              {presence.phoneNumber ? (
                                <DicaCallIconButton
                                  text={t('check-in.overview.call')}
                                  color="white"
                                  number={presence.phoneNumber}
                                  Icon={PhoneIcon}
                                />
                              ) : null}
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                display: 'grid',
                placeItems: 'center',
                height: '200px',
                fontWeight: 'bold',
              }}
            >
              {STATE.formControls.isFirstSearch ? <p></p> : <p>{t('check-in.no-results')}</p>}
            </div>
          )}
        </div>
      ) : (
        <div style={{width: '100%', height: '50vh', display: 'grid', placeItems: 'center'}}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};
