import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {BottomNavigation, Form, ListView, ModalView, PrintStickerBox} from 'src/components';
import {ChangeStationModelView} from 'src/components/PrintSticker/ChangeStationModelView';
import {PrintStickerContext} from './PrintStickers.store';
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import {CacheContext} from 'src/context/cache/cache.store';

const LoadingIcon = () => (
  <Box>
    <LoadingOutlined style={{fontSize: 48}} spin />
  </Box>
);

export function PrintSickers() {
  const {t} = useTranslation();

  const {
    loadingPrintMissions,
    printMissions,
    handlePrint,
    selectForPrint,
    selectForEdit,
    selectedOrigin,
    handleOriginStation,
    handleNewOrigin,
    handleNewOriginSubmit,
    isPrinting,
    printMissionsDates,
    handlePrintMissionDates,
    printMissionsFilterState,
    printMissionsFilterReducer,
    handlePrintMissionSearch,
    assistanceFilter,
  } = React.useContext(PrintStickerContext);

  const {stations} = React.useContext(CacheContext);

  const handleSubmit = () => {
    handleNewOriginSubmit();
    handleOriginStation('');
  };
  const handleSelectStationDataChange = (id: string) => {
    handleNewOrigin(id);
  };

  const handleReset = () => {
    handleOriginStation('');
  };

  if (isPrinting) {
    return (
      <Box sx={{height: '100%', width: '100%', display: 'grid', placeItems: 'center'}}>
        <LoadingIcon />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        maxHeight: '100vh',
        paddingLeft: '0px',
        paddingRight: '0px',
        overflowY: 'scroll !important',
      }}
    >
      <Box sx={{padding: '20px', overflowY: 'auto', overflowX: 'hidden', marginBottom: '100px'}}>
        <Typography variant="h5"> {t('print-stickers.title-main')} </Typography>
        <Form
          searchOnReservation={undefined}
          //@ts-ignore
          toggleSearchOnReservation={() => {}}
          isReservation={undefined}
          missionDates={printMissionsDates}
          handleMissionDates={handlePrintMissionDates}
          filterState={printMissionsFilterState}
          filterReducer={printMissionsFilterReducer}
          handleSearch={handlePrintMissionSearch}
        />
        <ListView
          title={t('print-stickers.title')}
          data={printMissions}
          isPrintMissionFilter
          Item={PrintStickerBox}
          loading={loadingPrintMissions}
          filterState={printMissionsFilterState}
          filterReducer={printMissionsFilterReducer}
          assistanceFilter={assistanceFilter}
        />
      </Box>
      <ModalView
        style={{margin: 'auto'}}
        zIndex={1}
        isVisible={selectForEdit.length > 0}
        title={t('print-stickers.select-station')}
        okTitle={t('station-section.btn-choose')}
        cancelTitle={t('station-section.btn-cancel')}
        onOk={() => handleSubmit()}
        onCancel={() => handleReset()}
      >
        <ChangeStationModelView
          handleSelectStationDataChange={handleSelectStationDataChange}
          stationList={stations}
          selectedStation={selectedOrigin}
        />
      </ModalView>
      <BottomNavigation
        isPrintSticker
        onSubmit={handlePrint}
        hasStickers={selectForPrint.length <= 0 || printMissions.length <= 0}
        loadingPrintMissions={loadingPrintMissions}
      />
    </Box>
  );
}
