import {CoiCreateIncidentLocationActionTypes} from '../reducer';
import {useTranslation} from 'react-i18next';
import {CoiCreateIncidentLocationProps} from '../coiCreateIncidentLocation';
import {CoiAssignCoiButton, IOSSwitch} from '../../common';
import {DeleteOutline} from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextareaAutosize,
} from '@mui/material';
import {CoiAssignCoiModal, CoiTargetType, TargetType} from '../coiAssignModal';
import PersonIcon from '@mui/icons-material/Person';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import React from 'react';
import CreateIcon from '@mui/icons-material/Create';
import comment from 'antd/lib/comment';
import DeleteIconTwo from 'src/icons/DeleteIconTwo';
import {AssignedCoi} from './AssignedCoi';
import {Assistants} from './Assistant';
import {StationPresenceDto} from 'src/services/models/station-presence-dto';

interface AddressOverviewProps extends CoiCreateIncidentLocationProps {
  handleCommentModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AddressOverview = (props: AddressOverviewProps) => {
  const {t} = useTranslation();

  const [coiTarget, setCoiTarget] = React.useState<CoiTargetType | undefined>(undefined);
  const [isCoiModalVisible, setIsCoiModalVisble] = React.useState(false);

  const [newAddress, setNewAddress] = React.useState('');

  const handleCoiAssignModalTarget = (target: CoiTargetType | undefined) => {
    if (target === undefined) {
      setCoiTarget(undefined);
      setIsCoiModalVisble(false);
    } else {
      setCoiTarget(target);
      setIsCoiModalVisble(true);
    }
  };

  const assignCoi = (employee: StationPresenceDto) => {
    if (employee === undefined) {
      throw new Error('Employee is undefined');
    }
    props.coiCreateIncidentLocationDispatch({
      type: CoiCreateIncidentLocationActionTypes.ADD_COI_ADDRESS,
      payload: employee,
    });
  };

  const unAssignCoi = () => {
    props.coiCreateIncidentLocationDispatch({
      type: CoiCreateIncidentLocationActionTypes.REMOVE_COI_ADDRESS,
    });
  };

  const assignAssist = (assist: StationPresenceDto) => {
    if (assist === undefined) {
      throw new Error('Employee is undefined');
    }
    props.coiCreateIncidentLocationDispatch({
      type: CoiCreateIncidentLocationActionTypes.ADD_ASSIST_ADDRESS,
      payload: assist,
    });
  };

  const unAssignAssist = (id: string) => {
    props.coiCreateIncidentLocationDispatch({
      type: CoiCreateIncidentLocationActionTypes.REMOVE_ASSIST_ADDRESS,
      payload: id,
    });
  };

  const [handleEditAddress, setHandleEditAddress] = React.useState(false);
  return (
    <div style={{padding: '24px'}}>
      {coiTarget !== undefined && (
        <CoiAssignCoiModal
          address={props.coiCreateIncidentLocationState.address}
          assignCoi={assignCoi}
          unAssignCoi={unAssignCoi}
          assignAssist={assignAssist}
          unAssignAssist={unAssignAssist}
          assignIndex={undefined}
          target={coiTarget}
          open={isCoiModalVisible && coiTarget !== undefined}
          onClose={() => handleCoiAssignModalTarget(undefined)}
        />
      )}
      <p style={{margin: '0', fontWeight: 'bold', fontSize: '22px', marginBottom: '12px'}}>{t('coi.address')}</p>
      <div
        style={{
          padding: '10px',
          paddingLeft: '15px',
          display: 'grid',
          gridTemplateColumns: '15% 200px 1fr auto',
        }}
      >
        <span style={{fontWeight: 'bold', textTransform: 'capitalize'}}>{t('coi.main-incident')}</span>
        <span style={{fontWeight: 'bold', textTransform: 'capitalize'}}>{'address'}</span>
        <span style={{fontWeight: 'bold', textTransform: 'capitalize'}}>{'extra info'}</span>
      </div>
      <Box
        sx={{
          padding: '10px',
          paddingLeft: '15px',
          display: 'grid',
          gridTemplateColumns: '15% 200px 1fr auto',
          backgroundColor: 'white',
          marginTop: '10px',
          marginBottom: '10px',
          minHeight: '48px',
          position: 'relative',
          border: 'solid 1px rgba(207, 216, 220, 1)',
          borderRadius: '4px',
          '&::before': {
            content: '""',
            width: '5px',
            borderRadius: '0px 10px 10px 0px',
            backgroundColor: 'rgba(0, 59, 140, 1)',
            position: 'absolute',
            left: '0',
            height: '36px',
            top: '12px',
            visibility: 'visible',
          },
        }}
      >
        <div style={{display: 'grid', justifyItems: 'start', alignItems: 'center'}}>
          <p style={{whiteSpace: 'nowrap', overflow: 'hidden', margin: 0}}>
            <IOSSwitch checked={true} />
          </p>
        </div>

        <div style={{display: 'grid', justifyItems: 'start', alignItems: 'center'}}>
          <p style={{whiteSpace: 'nowrap', overflow: 'hidden', margin: 0}}>
            {props.coiCreateIncidentLocationState.address?.address &&
            props.coiCreateIncidentLocationState.address?.address.length > 20
              ? `${props.coiCreateIncidentLocationState.address?.address.slice(0, 20)}...`
              : props.coiCreateIncidentLocationState.address?.address}
            <IconButton onClick={() => setHandleEditAddress(prev => !prev)}>
              <CreateIcon />
            </IconButton>
          </p>
        </div>

        <div style={{display: 'grid', justifyItems: 'start', alignItems: 'center'}}>
          <p style={{whiteSpace: 'nowrap', overflow: 'hidden', margin: 0}}>
            {props.coiCreateIncidentLocationState.comment && props.coiCreateIncidentLocationState.comment.length > 12
              ? `${props.coiCreateIncidentLocationState.comment.slice(0, 12)}...`
              : props.coiCreateIncidentLocationState.comment}
            {props.coiCreateIncidentLocationState.comment &&
              props.coiCreateIncidentLocationState.comment?.length > 0 && (
                <IconButton onClick={() => props.handleCommentModal(true)}>
                  <CreateIcon />
                </IconButton>
              )}
          </p>
        </div>

        <div style={{display: 'grid', justifyItems: 'end', alignItems: 'center'}}>
          <div style={{display: 'flex', justifyContent: 'flex-end', gap: '24px'}}>
            <CoiAssignCoiButton
              onClick={() => {
                handleCoiAssignModalTarget({type: TargetType.ADDRESS});
              }}
            >
              {props.coiCreateIncidentLocationState.address?.employeeId !== undefined ||
              (props.coiCreateIncidentLocationState.address?.assistants &&
                props.coiCreateIncidentLocationState.address?.assistants?.length > 0)
                ? 'Edit COI'
                : t('coi.assign-spi')}
            </CoiAssignCoiButton>
            <IconButton
              onClick={() => {
                props.coiCreateIncidentLocationDispatch({
                  type: CoiCreateIncidentLocationActionTypes.REMOVE_ADDRESS,
                });
              }}
            >
              <DeleteIconTwo />{' '}
            </IconButton>
          </div>
        </div>
      </Box>

      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        {props.coiCreateIncidentLocationState.address?.employeeId && (
          <AssignedCoi
            id={props.coiCreateIncidentLocationState.address.employeeId || ''}
            name={props.coiCreateIncidentLocationState.address.assignedCoi?.name || ''}
            phone={props.coiCreateIncidentLocationState.address.assignedCoi?.phoneNumber || ''}
          />
        )}

        {props.coiCreateIncidentLocationState.address?.assistants &&
          props.coiCreateIncidentLocationState.address?.assistants.length > 0 &&
          props.coiCreateIncidentLocationState.address?.assistants.map(el => (
            <Assistants assistant={el} key={el.employeeId} />
          ))}
      </Box>
      <Dialog open={handleEditAddress} onClose={() => setHandleEditAddress(false)}>
        <DialogTitle>{t('coi.edit-address')}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextareaAutosize
              style={{
                minHeight: '150px',
                minWidth: '400px',
                borderRadius: '4px',
                padding: '8px',
                borderColor: 'lightgray',
              }}
              placeholder={t('coi.comment')}
              onChange={e => {
                setNewAddress(e.target.value);
              }}
              value={newAddress.length > 0 ? newAddress : props.coiCreateIncidentLocationState.address?.address || ''}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setNewAddress('');
              setHandleEditAddress(false);
            }}
          >
            {t('coi.cancel')}
          </Button>
          <Button
            disabled={newAddress.length === 0}
            variant="contained"
            onClick={() => {
              props.coiCreateIncidentLocationDispatch({
                type: CoiCreateIncidentLocationActionTypes.CHANGE_ADDRESS,
                payload: newAddress || '',
              });
              setHandleEditAddress(false);
            }}
          >
            {t('coi.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
