import {Icon, SvgIconTypeMap} from '@mui/material';
import './DicaCallIconButton.scss';
import {OverridableComponent} from '@mui/material/OverridableComponent';

type Props = {
  Icon: any;
  color?: string;
  text?: string;
  number?: any;
  className?: string;
};
export const DicaCallIconButton = ({color, text, number, Icon, className = 'dica-icon-button-container'}: Props) => (
  <div className={className} style={{backgroundColor: color}} role="button" aria-hidden="true">
    <a href={`dica:${number.replace('+', '000')}`} className="button-parent">
      <Icon style={{marginTop: '5px'}} />
      <div className="title"> {text} </div>
    </a>
  </div>
);
