import * as React from 'react';

import {List, Radio, Space} from 'antd';
import {Header} from '../Header/Header';
import {ModalView} from '../../ModalView/ModalView';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IMissionFilter} from 'src/scenes/coordinator/PrintStickers/PrintStickers.store';
import {MissionFilterActions, IMissionFilterType} from 'src/scenes/coordinator/PrintStickers/PrintMissionFilterReducer';

enum IPrintAction {
  ALL = 'ALL',
  TO_PRINT = 'TO PRINT',
  PRINTED = 'PRINTED',
}

type Props = {
  title?: string;
  data?: Array<string | object>;
  style?: object;
  isPrintMissionFilter?: boolean;
  isReservationFilter?: boolean;
  Item?: any;
  loading?: boolean;
  filterState: IMissionFilter;
  filterReducer: React.Dispatch<MissionFilterActions>;
  assistanceFilter: () => void;
};

export function ListView({
  title,
  data = [],
  style,
  isPrintMissionFilter = false,
  isReservationFilter = false,
  Item,
  loading,
  filterState,
  filterReducer,
  assistanceFilter,
}: Props) {
  const [filterValue, setFilterValue] = useState(() => (isReservationFilter ? 3 : 1));

  const getFilterValueFromState = () => {
    if (filterState.printAction === IPrintAction.ALL) {
      setFilterValue(1);
    } else if (filterState.printAction === IPrintAction.TO_PRINT) {
      setFilterValue(2);
    } else if (filterState.printAction === IPrintAction.PRINTED) {
      setFilterValue(3);
    }
  };

  const onChange = (e: any) => {
    setFilterValue(e.target.value);
    let printAction = IPrintAction.ALL;
    if (e.target.value === 2) {
      printAction = IPrintAction.TO_PRINT;
    }

    if (e.target.value === 3) {
      printAction = IPrintAction.PRINTED;
    }

    filterReducer({type: IMissionFilterType.PRINT_ACTION, payload: printAction});
  };

  const onSumbit = () => {
    assistanceFilter();
    setIsModalOpen(false);
  };

  React.useEffect(() => {
    getFilterValueFromState();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const {t} = useTranslation();

  const getTitle = (text: string) => {
    return <p style={{fontFamily: '72, sans-serif', color: ' #666666', fontSize: 14}}>{t(text)}</p>;
  };

  return (
    <>
      <List
        style={style}
        header={<Header title={title} onFilterClick={() => setIsModalOpen(true)} />}
        bordered
        loading={loading}
        dataSource={data}
        renderItem={item => (
          <List.Item>
            {/* @ts-ignore*/}
            <Item {...item} item={item} />
          </List.Item>
        )}
      />

      <ModalView
        style={{top: 200}}
        centered={false}
        width={300}
        isVisible={isModalOpen}
        title={t('filter-section.filter-header-title')}
        okTitle={t('filter-section.filter-choose')}
        cancelTitle={t('filter-section.filter-cancel')}
        onOk={() => {
          onSumbit();
        }}
        onCancel={() => setIsModalOpen(false)}
      >
        {isPrintMissionFilter && (
          <>
            {getTitle('reservation.filter-modal.by-mission')}
            <Radio.Group onChange={onChange} value={filterValue} style={{width: '100%'}}>
              <Space direction="vertical">
                <Radio value={1}> {t('reservation.filter-modal.print-sticker.all')} </Radio>
                <Radio value={2}> {t('reservation.filter-modal.print-sticker.not-printed')} </Radio>
                <Radio value={3}> {t('reservation.filter-modal.print-sticker.printed')} </Radio>
              </Space>
            </Radio.Group>
          </>
        )}
        {isReservationFilter && (
          <>
            {getTitle('reservation.filter-modal.by-res')}
            <Radio.Group onChange={onChange} value={filterValue} style={{width: '100%'}}>
              <Space direction="vertical">
                <Radio value={1}> {t('reservation.filter-modal.reservation.with')} </Radio>
                <Radio value={2}> {t('reservation.filter-modal.reservation.without')} </Radio>
                <Radio value={3}> {t('reservation.filter-modal.reservation.all')} </Radio>
              </Space>
            </Radio.Group>
          </>
        )}
      </ModalView>
    </>
  );
}
