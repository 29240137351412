import React from 'react';

const TrainDriverIcon = () => (
  <svg id="vector" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="#000000"
      d="M4.12 16.68V8.34H2.92v8.34c0 2.56 1.42 3.78 4 3.78h5.56v-1H6.9C4.74 19.46 4.12 18.57 4.12 16.68Z"
      id="path_0"
    />
    <path
      fill="#000000"
      d="M18 19.78l-3.34 -3.31c-0.87 -0.8 -1.39 -0.91 -3 -1H9.47V12.86l0.8 0.68a1.4 1.4 0 0 0 0.84 0.33h0.15l3.91 -0.2A1.43 1.43 0 0 0 15 10.82L11.68 11 8.76 8.54a3.27 3.27 0 0 0 -0.88 -0.43A2.39 2.39 0 0 0 9.76 5.78a2.35 2.35 0 0 0 -0.71 -1.67l0.49 -0.19a0.45 0.45 0 0 0 0.26 -0.6 0.44 0.44 0 0 0 -0.59 -0.26L9 3.13l0.08 -0.6a0.45 0.45 0 0 0 -0.17 -0.43A0.48 0.48 0 0 0 8.48 2L4 3.85a0.46 0.46 0 0 0 -0.09 0.8L5 5.44a1.87 1.87 0 0 0 0 0.34A2.38 2.38 0 0 0 6.65 8.05a2.31 2.31 0 0 0 -1.79 2.24V15.7a3 3 0 0 0 3 3H10c2.3 0 2.68 0.23 2.84 0.33a0.46 0.46 0 0 0 0.2 0.09l2.73 2.81a0.16 0.16 0 0 0 0.13 0.06h0a0.19 0.19 0 0 0 0.13 -0.05L18 20a0.18 0.18 0 0 0 0.06 -0.13 0.16 0.16 0 0 0 -0.06 -0.09ZM5.34 4.54l-0.22 -0.15 3 -1.19L8 3.51Zm0.57 1.24A1.39 1.39 0 0 1 6 5.28L8 4.5a1.43 1.43 0 0 1 0.79 1.28 1.46 1.46 0 1 1 -2.91 0Zm10 14.71l-2.12 -2.18a0.87 0.87 0 0 0 -0.44 -0.26C13 17.81 12.3 17.6 10 17.6H7.87A1.91 1.91 0 0 1 6 15.7V10.29A1.26 1.26 0 0 1 7.16 9.1a1.91 1.91 0 0 1 0.71 0 0.49 0.49 0 0 1 0.29 0.11l1.31 1.11L11.36 12l3.71 -0.19a0.48 0.48 0 0 1 0.51 0.46 0.49 0.49 0 0 1 -0.46 0.52l-3.9 0.2h-0.05a0.5 0.5 0 0 1 -0.29 -0.12L9.47 11.64 8.36 10.7v5a0.93 0.93 0 0 0 0.92 0.93h2.34c1.52 0 1.69 0.15 2.22 0.63l2.68 2.65Z"
      id="path_1"
    />
    <path
      fill="#000000"
      d="M21 13.47L18.06 8.81a0.51 0.51 0 0 0 -0.69 -0.16 0.5 0.5 0 0 0 -0.15 0.69L20.16 14a0.5 0.5 0 1 0 0.84 -0.53Z"
      id="path_2"
    />
  </svg>
);

export default TrainDriverIcon;
