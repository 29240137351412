// @ts-nocheck
import { decode } from 'jsonwebtoken'

export const valid = (_idToken, _accessToken) => {
    const idToken = decode(_idToken)
    const accessToken = decode(_accessToken)

    if (!idToken && !accessToken) {
        return false
    }

    const now = parseInt(Date.now() / 1000, 10)

    if (!accessToken) {
        return validateTimes(now, idToken.exp)
    }

    return validateTimes(idToken?.exp, accessToken?.exp, true) && validateTimes(now, idToken?.exp)
}

export const validateTimes = (a, b, shouldEqual = false) => (shouldEqual ? a === b : a < b)