import {CreateReservationDto, ReservationTypeDto} from 'src/services/models';
import {MissionType} from 'src/utils/MissionType';
import {regexForEmailValidation} from './util';
import {TransportTypeDto} from 'src/services/models/transport-type-dto';
import moment from 'moment';

export const FormValidation = (
  variant: ReservationTypeDto,
  reservation: CreateReservationDto,
  useNewPmrMission: boolean,
) => {
  //Check if every trainNumber arrival and departurestation are set
  const hasAllTrainInfoSet = reservation.travel.journeys
    .filter(el => el.transportType !== TransportTypeDto.Taxi)
    .every(journey => {
      return (
        journey.trainNumber &&
        journey.trainNumber.toString().length > 0 &&
        journey.departureUicCode &&
        journey.departureUicCode.length > 0 &&
        journey.arrivalUicCode &&
        journey.arrivalUicCode.length > 0
      );
    });

  let hasAssitances = true;
  if (variant === ReservationTypeDto.Disabled) {
    if (
      reservation.travel.traveler?.disableds &&
      reservation.travel.traveler?.disableds?.length > 0 &&
      reservation.travel.traveler.disableds.every(
        el => el.quantity && el.quantity > 0 && el.typeId && el.typeId.length > 0,
      )
    ) {
      hasAssitances = true;
    } else {
      hasAssitances = false;
    }
  }

  let hasCarAndDoorSet = false;

  if (variant === ReservationTypeDto.Disabled && useNewPmrMission === true) {
    hasCarAndDoorSet = true;
  } else {
    hasCarAndDoorSet = reservation.travel.journeys
      .filter(el => el.transportType !== TransportTypeDto.Taxi)
      .every((journey, ind) => {
        if (variant === ReservationTypeDto.Group && ind === 0) {
          return journey.coachNumber && journey.coachNumber.length > 0;
        } else {
          return true;
        }
      });
  }

  let hasGroup = true;
  if (variant === ReservationTypeDto.Group) {
    if (
      reservation.travel.traveler?.ageCategories &&
      reservation.travel.traveler.ageCategories.length > 0 &&
      reservation.travel.traveler.ageCategories.every(
        el => el.quantity && el.quantity > 0 && el.typeId && el.typeId.length > 0,
      )
    ) {
      hasGroup = true;
    } else {
      hasGroup = false;
    }
  }

  let hasClientInfo = false;
  if (
    reservation.client &&
    reservation.client.email &&
    reservation.client.email.length > 0 &&
    regexForEmailValidation.test(reservation.client.email) &&
    reservation.client.firstName &&
    reservation.client.firstName.length > 0 &&
    reservation.client.lastName &&
    reservation.client.lastName.length > 0 &&
    reservation.client.language &&
    reservation.client.language.length > 0 &&
    reservation.client.phoneNumber &&
    reservation.client.phoneNumber.length > 4
  ) {
    if (variant === ReservationTypeDto.Group) {
      if (reservation.client?.description && reservation.client.description.length > 0) {
        hasClientInfo = true;
      } else {
        hasClientInfo = false;
      }
    } else {
      hasClientInfo = true;
    }
  } else {
    hasClientInfo = false;
  }

  //Check if total passengers is atleast 15
  let hasTotalPassengers = true;
  if (reservation.travel.traveler?.ageCategories && variant === ReservationTypeDto.Group) {
    let totalAmountOfPassengers = 0;
    reservation.travel.traveler.ageCategories.forEach(el => {
      totalAmountOfPassengers = totalAmountOfPassengers + (el.quantity ? el.quantity : 0);
    });

    if (totalAmountOfPassengers >= 15) {
      hasTotalPassengers = true;
    } else {
      hasTotalPassengers = false;
    }
  }

  //Check for taxi rrservation to be oK
  const hasAllTaxiInfoSet = reservation.travel.journeys
    .filter(el => el.transportType === TransportTypeDto.Taxi)
    .every(el => {
      const departureToday = moment(el.departureTime).isBefore(moment().startOf('day'));

      return !departureToday && el.departureInfo && el.arrivalInfo && el.departureUicCode && el.arrivalUicCode;
    });

  if (
    hasAllTrainInfoSet &&
    hasAssitances &&
    hasCarAndDoorSet &&
    hasGroup &&
    hasClientInfo &&
    hasTotalPassengers &&
    hasAllTaxiInfoSet
  ) {
    return true;
  } else {
    return false;
  }
};
