import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { ModelError } from 'src/services/models';
import Paragraph from 'antd/lib/typography/Paragraph';
import { Alert, Button, Snackbar } from '@mui/material';




export const ErrorDialog: React.FC<{open: boolean, close: React.Dispatch<boolean>, error?: ModelError}> = ({
    open,
    close,
    error
}) => {
    return(
        <Dialog
            open={open}
            data-testid='error-snackbar'
        >
            <div style={{display: 'grid', gridTemplateRows: 'auto 1fr auto', padding: '8px'}}>
                <DialogTitle style={{padding: '8px'}}>Server error response: {error?.validationErrors ? '400' : '500'}</DialogTitle>
                <Paragraph
                    style={{padding: '8px'}}
                >
                    The operation could not be completed succesfully: {error?.code} - {error?.message}
                </Paragraph>
                <Paragraph
                    style={{padding: '8px'}}
                >
                    <ul>
                        {error?.validationErrors ? Object.keys(error?.validationErrors).map(el => {
                        if(error?.validationErrors){
                            {
                                return(error.validationErrors[el].map(is => {
                                    return(
                                        <li data-testid='error-validation-errors'>
                                            {/**@ts-ignore */}
                                            {el.toString()}: {is.code} - {is.message}
                                        </li>
                                    )
                                }))
                            }
                        }
                        }) : null}
                    </ul>
                </Paragraph>
                <Button variant="contained" data-testid='error-validation-button' onClick={() => close(false)}>Close</Button>
            </div>
        </Dialog>
    )
}