import {Dialog, Paper, Box, DialogProps} from '@mui/material';
import {blue} from '@mui/material/colors';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {colors} from 'src/utils/hexCode';

interface Props extends DialogProps {
  remarks: string[] | string;
  close: () => void;
}

export const RemarkDialog: React.FC<Props> = ({remarks, close, ...props}) => {
  const {t} = useTranslation();

  const formattedRemarks = () => {
    if (typeof remarks === 'string') {
      return [remarks];
    }

    return remarks;
  };
  return (
    <Dialog
      {...props}
      sx={{
        padding: '15px',
        '& .MuiDialog-paper': {
          padding: '10px 25px',
        },
      }}
    >
      <Box
        sx={{
          margin: '15px',
        }}
      >
        <Paper elevation={0}>
          <span
            style={{
              display: 'inline-block',
              color: colors.nbms_dark_blue,
              border: `solid 1px ${colors.nbms_dark_blue}`,
              textAlign: 'center',
              borderRadius: '50%',
              width: '24px',
              height: '24px',
              lineHeight: '24px',
              fontWeight: 'bold',
            }}
          >
            {formattedRemarks().length}
          </span>
          <h3 style={{display: 'inline', paddingLeft: '15px'}}>{t('mission-details-v2.banner.notes')}</h3>
        </Paper>
      </Box>
      <Box
        sx={{
          margin: '5px 15px',
        }}
      >
        {formattedRemarks().map((rem, ind) => {
          return (
            <Paper
              elevation={0}
              sx={{border: 'solid 1px #C2771066', padding: '15px', marginBottom: '15px', backgroundColor: '#C277100D'}}
              key={ind}
            >
              <h5>{t('mission-details-v2.banner.client-note')}</h5>
              {rem}
            </Paper>
          );
        })}
      </Box>
      <p
        style={{color: 'blue', textDecorationLine: 'underline', marginLeft: '15px', cursor: 'pointer'}}
        onClick={() => close()}
      >
        {t('close')}
      </p>
    </Dialog>
  );
};
