// @ts-nocheck
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function TrainSticker(props) {
  return (
    <SvgIcon {...props}>
      <svg data-name="ic_sticker added" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
        <rect data-name="Rectangle 1738" width={24} height={24} rx={4} />
        <path fill="none" d="M0 0h24v24H0z" />
        <g data-name="Group 7669">
          <path
            data-name="Path 5245"
            d="M14.4 10.3a.9.9 0 0 1-.9.9h-6l.6-.8c1.7-2.1 2.9-3 3.9-3h1.4a.9.9 0 0 1 .9.9v2Zm-1-1.9H12c-.6 0-1.4.6-2.5 1.9h3.8V8.4Z"
            fill="#fff"
          />
          <g data-name="Group 7668">
            <path
              data-name="Path 5246"
              d="M19 12.4a.5.5 0 0 1 0 .6l-1.9 1.9a.426.426 0 0 1-.6-.6l1.3-1.3h-4.4a.4.4 0 1 1 0-.8h4.4L16.6 11a.426.426 0 0 1 .6-.6Z"
              fill="#fff"
            />
          </g>
          <path
            data-name="Path 5247"
            d="M17.1 17.2a1.806 1.806 0 0 1-3 0 1.838 1.838 0 0 1-3.2-.3h-.3c-3.1 0-4.8-.6-5.4-1.7-.8-1.6.6-3.7 2-5.8l.1-.2A6.635 6.635 0 0 1 12.9 6H17v1h-4.2A5.64 5.64 0 0 0 8 9.7l-.1.2c-1.2 1.7-2.5 3.7-2 4.8.3.6 1.3 1.2 4.5 1.2h1.1l.1.4a.876.876 0 0 0 .9.7.961.961 0 0 0 .9-.7l.1-.4h1.2l.1.4a.876.876 0 0 0 .9.7.961.961 0 0 0 .9-.7l.1-.4h.6"
            fill="#fff"
          />
          <path
            data-name="Path 5248"
            d="M16 3.2H4.4A2.433 2.433 0 0 0 2 5.6v13.3a2.433 2.433 0 0 0 2.4 2.4h15.2a2.433 2.433 0 0 0 2.4-2.4V9.4Zm5 15.7a1.367 1.367 0 0 1-1.4 1.4H4.4A1.367 1.367 0 0 1 3 18.9V5.6a1.367 1.367 0 0 1 1.4-1.4H16V8a1.367 1.367 0 0 0 1.4 1.4H21Z"
            fill="#fff"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
