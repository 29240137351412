// @ts-nocheck
import {Row, Col, Typography} from 'antd';
import {FilterOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';

const {Text} = Typography;

type Props = {
  title?: string;
  onFilterClick?: () => void;
};

export function Header({title, onFilterClick}: Props) {
  const {t} = useTranslation();
  return (
    <Row>
      <Col span={12} style={{textAlign: 'left'}}>
        <Text
          style={{
            color: '#25336c',
            fontWeight: 700,
            fontFamily: '72, sans-serif',
            fontSize: 14,
            textAlign: 'left',
          }}
        >
          {title}
        </Text>
      </Col>
      <Col span={4} style={{textAlign: 'end'}} offset={8}>
        <Text style={{fontFamily: '72, sans-serif', color: '#25336c', textAlign: 'right', fontSize: 14}}>
          {t('filter.name')}
        </Text>
        <FilterOutlined style={{color: '#25336c', marginLeft: 10}} onClick={onFilterClick} />
      </Col>
    </Row>
  );
}
