export const getTimeZoneOffset = (): string => {
  const d = new Date();
  const dif = d.getTimezoneOffset();

  if (dif < 0) {
    const divide = (dif * -1) / 60;
    const float = isFloat(divide);

    return formatTimeString(divide, float, 'negative');
  } else if (dif > 0) {
    const divide = dif / 60;
    const float = isFloat(divide);

    return formatTimeString(divide, float, 'positive');
  } else {
    //GMT MAIN
    return '00:00';
  }
};

const formatTimeString = (divide: number, float: boolean, position: 'negative' | 'positive') => {
  const hour = divide >= 10 ? Math.floor(divide).toString() : `0${Math.floor(divide).toString()}`;
  const min = float ? '30' : '00';
  const plusOrMin = position === 'negative' ? '+' : '-';
  return plusOrMin + hour + ':' + min;
};

const isFloat = (n: number) => n % 1 !== 0;
