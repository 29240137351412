import {TextField, InputAdornment} from '@mui/material';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {MessageFilter} from './coiChatView';
import {ChatFilter} from './coiChatFilter';

export const ChatSearch: React.FC<{
  searchValue: string;
  onSearchChange: (value: string) => void;
  selectedOption: MessageFilter;
  onFilterChange: (option: MessageFilter) => void;
}> = ({searchValue, onSearchChange, selectedOption, onFilterChange}) => {
  return (
    <TextField
      variant="outlined"
      fullWidth
      value={searchValue}
      onChange={e => onSearchChange(e.target.value)}
      sx={{
        flex: 1,
        borderRadius: '5px',
        borderColor: '#a7cae4',
        borderWidth: '1px',
        backgroundColor: '#eff4f9',
        '& .MuiOutlinedInput-root': {
          borderRadius: '5px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#a7cae4',
          borderWidth: '1px',
          borderRadius: '5px',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#a7cae4',
          borderRadius: '5px',
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{color: '#1374b9'}} />
          </InputAdornment>
        ),
        style: {padding: 0, paddingLeft: 8, paddingRight: 8},
        endAdornment: <ChatFilter selectedOption={selectedOption} onFilterChange={onFilterChange} />,
      }}
    />
  );
};
