
// @ts-nocheck

import React from 'react';
import {
    AuthorizationServiceConfiguration,
    AuthorizationRequest, RedirectRequestHandler,
    AuthorizationNotifier, TokenRequest, GRANT_TYPE_AUTHORIZATION_CODE, BaseTokenRequestHandler,
    LocalStorageBackend, DefaultCrypto
} from '@openid/appauth';
import { valid } from 'src/context/authentication/isValidIdToken';
import { NoHashQueryStringUtils } from 'src/utils/noHasQueryStringUtils';
import {LoginView} from './LoginView'
import axios from 'axios'
import './styles.scss'



class Login extends React.Component{
    configuration

    authorizationHandler = new RedirectRequestHandler(new LocalStorageBackend(), new NoHashQueryStringUtils(), window.location, new DefaultCrypto())
    notifier = new AuthorizationNotifier()


    constructor(props) {

        super(props)

        this.state = {
            loginPossible: false,
            error: false,
        }

        this.authorizationHandler.setAuthorizationNotifier(this.notifier)
        this.notifier.setAuthorizationListener((request, response, error) => {
            if (error) console.error(error) // eslint-disable-line

            if (response) {

                const tokenRequest = new TokenRequest({
                    client_id: request.clientId,
                    client_secret: undefined,
                    redirect_uri: request.redirectUri,
                    grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
                    refresh_token: undefined,
                    code: response.code,
                    extras: {
                        code_verifier: request.internal.code_verifier,
                    },
                })

                const tokenHandler = new BaseTokenRequestHandler()

                const jsonconf =
                    window.localStorage.getItem('configjson') !== undefined
                        ? JSON.parse(window.localStorage.getItem('configjson'))
                        : this.configuration

                tokenHandler.performTokenRequest(jsonconf, tokenRequest).then(token => {
                    const { idToken, accessToken } = token

                    if (valid(idToken, accessToken)) {

                        window.localStorage.setItem(this.props.variables?.ID_KEY_PATH, idToken)
                        window.localStorage.setItem(this.props.variables?.ACCESS_KEY_PATH, accessToken)
                        window.localStorage.removeItem('configjson')
                        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
                        this.props.setValidUser(true)

                    }
                })
            }
        })
    }

    intitialize = async () => {

        await this.authorizationHandler.completeAuthorizationRequestIfPossible()
        this.authorizationHandler.setAuthorizationNotifier(this.notifier)
        
        if(this.props.variables?.OIDC_PATH.length > 0){
            await AuthorizationServiceConfiguration.fetchFromIssuer(this.props.variables?.OIDC_PATH)
            .then(configuration => {
                window.localStorage.setItem('configjson', JSON.stringify(configuration))

                this.configuration = configuration
                this.setState({ loginPossible: true, error: false })
            })
            .catch(error => {
                console.error(error)
                this.setState({ loginPossible: false, error: true })
            })
        }
    }

    componentDidMount = () => {
        this.intitialize()
    }

    componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{}>, snapshot?: any): void {
        if(this.props.variables?.OIDC_PATH !== prevProps.variables?.OIDC_PATH){
            this.intitialize()
        }
    }

    handleLogin = () => {

        const request = new AuthorizationRequest(
            {
                client_id: this.props.variables?.CLIENT_ID,
                redirect_uri: this.props.variables?.REDIRECT_URI,
                scope: 'openid B2E-full dmz-hr',
                response_type: 'code',
                state: undefined,
            },
            undefined,
            true
        )

        this.authorizationHandler.performAuthorizationRequest(this.configuration, request)
    }

    render(){

    return(
        <LoginView loginPossible={this.state.loginPossible} handleLogin={this.handleLogin}/>
    )}
}

export default Login