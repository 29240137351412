// @ts-nocheck
import React from 'react';

import Box from '@mui/material/Box';
import {CategoryDivider} from 'src/components/coordinator/CategoryDivider/CategoryDivider';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import RouteIcon from 'src/components/icons/Route';
import CollegueIcon from 'src/components/icons/Collegue';
import PersonIcon from 'src/components/icons/Person';
import {TextInput} from 'src/components/inputs/TextInput/TextInput';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Button from '@mui/material/Button';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import PmrBlueIcon from '../../../components/images/ic_exchange-blue.svg';
import {PrintStickerContext} from '../PrintStickers/PrintStickers.store';
import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {CacheContext} from 'src/context/cache/cache.store';
import {getDisabilityFromId} from 'src/utils/getDisabilityFromId';
import {DisabilityDto} from 'src/services/models';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';
import moment from 'moment';
import {MissionContext} from '../MissionOverview/missions.store';

const loadingIcon = <LoadingOutlined style={{fontSize: 48}} spin />;

export default function PrintReservationDetails() {
  const {printMissionDetail, loadingPrintMissionDetails} = React.useContext(PrintStickerContext);
  const {disabilities, ageCategories} = React.useContext(CacheContext);
  const {t, i18n} = useTranslation();
  const {clearMissions} = React.useContext(MissionContext);

  const assistanceText = t('mission-details.label-need-for-assistance');

  React.useEffect(() => {
    clearMissions();
  }, []);

  const navigate = useNavigate();

  const showPMR = () => {
    const handicapDetailsList = printMissionDetail?.traveler?.disableds.map(el => {
      if (el.quantity !== 0 && el.quantity !== null) {
        return (
          <Box sx={{display: 'flex', gap: '15px'}}>
            <Box sx={{flex: 1}}>
              <TextInput
                id={`id-${el.quantity}-${el.typeId}`}
                style={{color: '#000'}}
                defaultValue={el.quantity ?? 0}
                disabled
              />
            </Box>
            <Box sx={{flex: 1}}>
              <TextInput
                id={`id-${el.typeId}-${el.quantity}`}
                style={{color: '#000'}}
                defaultValue={handleTranslationObject(
                  i18n,
                  getDisabilityFromId(disabilities as DisabilityDto[], el.typeId)?.description,
                )}
                disabled
              />
            </Box>
          </Box>
        );
      }
    });
    return (
      <>
        {handicapDetailsList && (
          <Box sx={{display: 'flex', gap: '15px'}}>
            <Box sx={{flex: 1}}>
              <span>{t('mission-details.show-pmr-data.label-quantity')} </span>
            </Box>
            <Box sx={{flex: 1}}>
              <span>{t('mission-details.show-pmr-data.label-type-person')} </span>
            </Box>
          </Box>
        )}
        {handicapDetailsList}
      </>
    );
  };

  const showGROBO = () => {
    const groupDetailsList = printMissionDetail?.traveler?.ageCategories.map(item => (
      <Box sx={{display: 'flex', gap: '15px'}}>
        <Box sx={{flex: 1}}>
          <TextInput
            id={`id-${item?.typeId}-${item.quantity}`}
            style={{color: '#000'}}
            defaultValue={item?.quantity ?? 0}
            disabled
          />
        </Box>
        <Box sx={{flex: 1}}>
          <TextInput
            id={`id-${item.quantity}-${item?.typeId}`}
            style={{color: '#000'}}
            defaultValue={
              handleTranslationObject(
                i18n,
                getDisabilityFromId(ageCategories as DisabilityDto[], item.typeId)?.description,
              ) || ['']
            }
            disabled
          />
        </Box>
      </Box>
    ));
    return (
      <>
        {groupDetailsList && (
          <Box sx={{display: 'flex', gap: '15px'}}>
            <Box sx={{flex: 1}}>
              <span>{t('mission-details.show-grobo-data.label-quantity')} </span>
            </Box>
            <Box sx={{flex: 1}}>
              <span>{t('mission-details.show-grobo-data.label-group-description')} </span>
            </Box>
          </Box>
        )}
        {groupDetailsList}
      </>
    );
  };

  const formattedTime = printMissionDetail?.journey?.time?.toString().split('+');

  return (
    <>
      <div style={{padding: '20px'}}>
        <Button variant="text" startIcon={<ArrowBackIosNewIcon />} onClick={() => history.goBack()}>
          {t('back-btn')}
        </Button>
      </div>
      {!loadingPrintMissionDetails && printMissionDetail ? (
        <Paper sx={{pt: 1, pr: 2, pl: 2}} elevation={3}>
          <Stack sx={{p: 2, pb: 9}} spacing={2}>
            <Box sx={{width: '49%'}}>
              <TextInput
                style={{color: '#000'}}
                label={t('reservation.form.number.label')}
                defaultValue={printMissionDetail.reservationId}
                disabled
              />
            </Box>
            <CategoryDivider icon={<RouteIcon color="#25336C" />} title="Itinerary" style={{fontSize: '1rem'}} />
            <Stack spacing={2}>
              <Box sx={{width: '49%'}}>
                <TextInput
                  style={{color: '#000'}}
                  label={t('')}
                  defaultValue={printMissionDetail.journey?.trainNumber}
                  disabled
                />
              </Box>
              <Box sx={{display: 'flex', gap: '50px'}}>
                <Box sx={{flex: 1}}>
                  <TextInput
                    style={{color: '#000'}}
                    label={t('reservation.form.train.label')}
                    defaultValue={printMissionDetail.journey?.otherStationName}
                    disabled
                  />
                </Box>
                <Box sx={{flex: 1}}>
                  <TextInput
                    style={{color: '#000'}}
                    label={t('trip-details-v2.details.departure')}
                    defaultValue={moment(formattedTime[0])
                      .utcOffset(formattedTime[1])
                      .format('ddd MMM DD YYYY HH:MM:SS')}
                    disabled
                  />
                </Box>
              </Box>
              <Box>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="help"
                    defaultValue="need help"
                    name="radio-buttons-group"
                    style={{display: 'flex', flexDirection: ' row'}}
                  >
                    <FormControlLabel
                      value="need help"
                      checked={true}
                      defaultValue="need help"
                      control={<Radio />}
                      label={assistanceText}
                      size="small"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box sx={{display: 'flex', gap: '35px'}}>
                <Box sx={{width: '49%'}}>
                  <TextInput
                    style={{color: '#000'}}
                    label={t('report-grobo.car-grobo.no')}
                    defaultValue={printMissionDetail.journey?.doorNumber || 0}
                    disabled
                  />
                </Box>
              </Box>

              <Box sx={{width: '49%'}}>
                <TextInput
                  style={{color: '#000'}}
                  label={t('create-grobo-mission.station')}
                  defaultValue={printMissionDetail.journey?.stationName}
                  disabled
                />
              </Box>
              <Box>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="help"
                    defaultValue="need help"
                    name="radio-buttons-group"
                    style={{display: 'flex', flexDirection: ' row'}}
                  >
                    <FormControlLabel
                      value="need help"
                      checked={true}
                      defaultValue="need help"
                      control={<Radio />}
                      label={assistanceText}
                      size="small"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Stack>
            {printMissionDetail.traveler?.ageCategories?.length > 0 && (
              <>
                <CategoryDivider
                  icon={<CollegueIcon color="#25336C" />}
                  title={t('reservation.detail.groupInformation')}
                  style={{fontSize: '1rem'}}
                />
                {showGROBO()}
              </>
            )}
            {printMissionDetail.traveler?.disableds?.length > 0 && (
              <>
                <CategoryDivider
                  icon={<img src={PmrBlueIcon} alt="icon" />}
                  title={t('mission-details.category-type-traveler')}
                  style={{fontSize: '1rem'}}
                />
                {showPMR()}
              </>
            )}

            <CategoryDivider icon={<PersonIcon color="#25336C" />} title="Client" style={{fontSize: '1rem'}} />
            <Stack spacing={2}>
              <Box sx={{display: 'flex', gap: '50px'}}>
                <Box sx={{flex: 1}}>
                  <TextInput
                    style={{color: '#000'}}
                    label={t('create-grobo-mission.surname')}
                    defaultValue={printMissionDetail.client?.firstName}
                    disabled
                  />
                </Box>
                <Box sx={{flex: 1}}>
                  <TextInput
                    style={{color: '#000'}}
                    label={t('create-grobo-mission.name')}
                    defaultValue={printMissionDetail.client?.lastName}
                    disabled
                  />
                </Box>
              </Box>
              <Box sx={{display: 'flex', gap: '50px'}}>
                <Box sx={{flex: 1}}>
                  <TextInput
                    style={{color: '#000'}}
                    label={t('create-mission.phone')}
                    defaultValue={printMissionDetail.client?.phoneNumber}
                    disabled
                  />
                </Box>
                <Box sx={{flex: 1}}>
                  <TextInput
                    style={{color: '#000'}}
                    label={t('reservation-language')}
                    defaultValue={printMissionDetail.client?.language}
                    disabled
                  />
                </Box>
              </Box>
              <Box>
                <TextInput
                  style={{color: '#000'}}
                  label={t('create-mission.mail')}
                  defaultValue={printMissionDetail.client?.email}
                  disabled
                />
              </Box>
            </Stack>
          </Stack>
        </Paper>
      ) : (
        <Box sx={{display: 'flex', placeItems: 'center', width: '100%', height: '80%'}}>
          <Spin style={{color: '#25336c', width: '100%'}} indicator={loadingIcon} />
        </Box>
      )}
    </>
  );
}
