// @ts-nocheck
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function Person(props) {
  return (
    <SvgIcon {...props}>
      <svg width="1em" height="1em" {...props}>
        <g data-name="Calque 2">
          <path d="M18.38 14.72a4.63 4.63 0 00-1-1.46 4.87 4.87 0 00-1.46-1 4.57 4.57 0 00-1.76-.35H13.1l.6-.21a4.87 4.87 0 001.46-1 4.77 4.77 0 001-1.44 4.52 4.52 0 00.35-1.78 4.61 4.61 0 00-.35-1.76 4.87 4.87 0 00-1-1.46 4.87 4.87 0 00-1.46-1A4.61 4.61 0 0011.94 3a4.52 4.52 0 00-1.78.35 4.77 4.77 0 00-1.44 1 4.87 4.87 0 00-1 1.46 4.43 4.43 0 00-.36 1.76 4.36 4.36 0 00.38 1.73 4.77 4.77 0 001 1.44 4.6 4.6 0 001.44 1 5.89 5.89 0 00.59.21H9.7a4.52 4.52 0 00-1.78.35 4.77 4.77 0 00-1.44 1 4.63 4.63 0 00-1 1.46 4.58 4.58 0 00-.36 1.76V21h13.61v-4.52a4.57 4.57 0 00-.35-1.76zm-3.16-7.2a3.28 3.28 0 01-3.28 3.29 3.28 3.28 0 01-3.29-3.29 3.26 3.26 0 013.29-3.28 3.26 3.26 0 013.28 3.28zM6.41 19.76v-3.28a3.28 3.28 0 013.29-3.29h4.48a3.28 3.28 0 013.28 3.29v3.28z" />
          <path fill="none" d="M0 0h24v24H0z" />
        </g>
      </svg>
    </SvgIcon>
  );
}
