import {Box} from '@mui/material';
import React from 'react';
import {StationPresenceDto} from 'src/services/models/station-presence-dto';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';

export const Assistants: React.FC<{assistant: StationPresenceDto}> = props => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(1,15%) auto 1fr',
        padding: '10px',
        paddingLeft: '15px',
        gap: '10px',
        minHeight: '48px',
      }}
    >
      <Box></Box>
      <Box>
        <PeopleAltOutlinedIcon /> <span style={{fontWeight: 'bold'}}>{props.assistant.name}</span>
      </Box>
      <div style={{display: 'flex', justifyContent: 'flex-end', gap: '24px'}}>
        <span style={{display: 'flex', gap: '5px'}}>
          <LocalPhoneOutlinedIcon color={'info'} />
          <a
            href={`dica:${props.assistant.phoneNumber?.replace('+', '000')}`}
            style={{justifySelf: 'end', textDecoration: 'none', fontWeight: 'bold'}}
          >
            {props.assistant.phoneNumber}
          </a>
        </span>
      </div>
    </Box>
  );
};
