// @ts-nocheck

import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function Dashboard(props) {
  return (
    <SvgIcon {...props}>
      <svg id="ic_dashboard" width="24" height="24" viewBox="0 0 24 24" {...props}>
        <path
          id="Path_5687"
          data-name="Path 5687"
          d="M22.556,22.556h-10V10.333h10Zm-8.889-1.111h7.778v-10H13.667Zm-2.222,1.111h-10V14.778h10ZM2.556,21.444h7.778V15.889H2.556Zm8.889-7.778h-10V1.444h10ZM2.556,12.556h7.778v-10H2.556Zm20-3.333h-10V1.444h10ZM13.667,8.111h7.778V2.556H13.667Z"
        />
        <rect id="Rectangle_1959" data-name="Rectangle 1959" width="24" height="24" fill="none" />
      </svg>
    </SvgIcon>
  );
}
