// @ts-nocheck
export const handleDataLocalization = (dataObject, keytoSearch, language) => {
  if (dataObject === null || dataObject === undefined) return null;
  const pattern = `${keytoSearch}${language}`;
  const arrayData = Object.entries(dataObject);
  const arrayValue = arrayData.map(item => item.reduce((key, value) => `${[key]}:${[value]}`));
  const valueItem = arrayValue
    .filter(item => item.includes(pattern))
    .map(value => value.split(':').pop())
    .pop();
  if (valueItem) {
    return valueItem;
  }
  return null;
};
