import {Box, TextField, Autocomplete} from '@mui/material';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {
  ActiveTrainDto,
  CreateReservationDto,
  ItineraryPointDto,
  ReservationTypeDto,
  TrainDto,
} from 'src/services/models';
import {getActiveTrains, getTrain} from '../services';
import {CreateMissionTrainNumber} from '../types';
import {ItineraryAutocomplete} from './itineraryAutoComplete';
import {CarAndDoor} from './CarAndDoor';
import {SettingsContext} from 'src/context/settings/settings.store';

export const TrainNumber: React.FC<CreateMissionTrainNumber> = ({index, reservation, setReservation, variant}) => {
  const {t} = useTranslation();
  const {variables} = React.useContext(EnvironmentContext);

  //STATE ----------------------------------------------------------------------------

  const [selectedTrainNumber, setSelectedTrainNumber] = React.useState(reservation.travel.journeys[index].trainNumber);
  const [loadingTrains, setLoadingTrains] = React.useState(true);
  const [activeTrains, setActiveTrains] = React.useState<ActiveTrainDto[]>([]);
  const [trainFromTrainNumber, setTrainFromTrainNumber] = React.useState<TrainDto | undefined>(undefined);

  //Itineraries
  const [departureStation, setDepartureStation] = React.useState<ItineraryPointDto | undefined>(undefined);
  const [arrivalStation, setArrivalStation] = React.useState<ItineraryPointDto | undefined>(undefined);

  //Errors
  const [invalidTrainNumber, setInvalidTrainNumber] = React.useState('Indicate the trainnumber');

  //PMR Feature flag
  const {RESERVATIONS_FEATURE_FLAG_NEW_PMR} = React.useContext(SettingsContext);

  //----------------------------------------------------------------------------------

  //SERVICES -------------------------------------------------------------------------
  const getActiveTrainsWithoutQuery = () => {
    setLoadingTrains(true);
    getActiveTrains(variables.BASE_ENDPOINT).then(res => {
      setActiveTrains(res);
      setLoadingTrains(false);
    });
  };

  const getActiveTrainsWithQuery = (trainNumber: string) => {
    setLoadingTrains(true);
    getActiveTrains(variables.BASE_ENDPOINT, parseInt(trainNumber)).then(res => {
      setActiveTrains(res);
      setLoadingTrains(false);
    });
  };

  const getTrainFromTrainNumber = (trainNumber: number) => {
    setLoadingTrains(true);
    getTrain(variables.BASE_ENDPOINT, trainNumber).then(res => {
      if (res && res.itinerary.length > 0) {
        setTrainFromTrainNumber(res);
        handleTrainItinerary(res);
      }
      setLoadingTrains(false);
    });
  };
  //---------------------------------------------------------------------------------

  //METHODS -------------------------------------------------------------------------

  const handleSelectTrainNumber = (value: string) => {
    //Set locally
    //@ts-ignore
    setSelectedTrainNumber(parseInt(value));

    //Adjust DTO
    const prevState = reservation;
    //@ts-ignore
    prevState.travel.journeys[index].trainNumber = parseInt(value);
    setReservation(_ps => {
      return {...prevState};
    });

    //DO TRAIN LOGIC
    //@ts-ignore
    getTrainFromTrainNumber(parseInt(value));
    setInvalidTrainNumber('');
  };

  const handleChangeInput = (e: string) => {
    if (e.length >= 2) {
      getActiveTrainsWithQuery(e);
    } else {
      getActiveTrainsWithoutQuery();
    }
  };

  const handleTrainItinerary = (train: TrainDto) => {
    //If first departure set arrival and leave departure
    let prevState = reservation;
    const iti = train.itinerary[train.itinerary.length - 1];
    if (index === 0) {
      //@ts-ignore
      prevState.travel.journeys[index].departureTime = '';
      prevState.travel.journeys[index].departureUicCode = '';
      prevState.travel.journeys[index].arrivalTime = iti.arrival;
      prevState.travel.journeys[index].arrivalUicCode = iti.uicCode;
      setArrivalStation(iti);
      setDepartureStation(undefined);
    } else {
      //Check if newly chosen train actually is valid
      const getAllUicCodes = train.itinerary.map(it => it.uicCode);
      const uicCodeOfPreviousArrival = prevState.travel.journeys[index - 1].arrivalUicCode;
      if (uicCodeOfPreviousArrival && getAllUicCodes.includes(uicCodeOfPreviousArrival)) {
        //The newly selected train has a correct departure
        prevState.travel.journeys[index].departureUicCode = uicCodeOfPreviousArrival;
        if (train.itinerary.find(el => el.uicCode === uicCodeOfPreviousArrival)?.departure === null) {
          setDepartureStation(undefined);
          setArrivalStation(undefined);
          prevState.travel.journeys[index].arrivalUicCode = '';
          prevState.travel.journeys[index].departureUicCode = '';
          setInvalidTrainNumber('The chosen trainumber is not valid');
        } else {
          prevState.travel.journeys[index].departureTime = train.itinerary.find(
            el => el.uicCode === uicCodeOfPreviousArrival,
          )?.departure as Date;
          const departure = train.itinerary.find(el => el.uicCode === uicCodeOfPreviousArrival);
          prevState.travel.journeys[index].arrivalTime = iti.arrival;
          prevState.travel.journeys[index].arrivalUicCode = iti.uicCode;
          setArrivalStation(iti);
          setDepartureStation(departure);
          setInvalidTrainNumber('');
        }
      } else {
        setDepartureStation(undefined);
        setArrivalStation(undefined);
        prevState.travel.journeys[index].arrivalUicCode = '';
        prevState.travel.journeys[index].departureUicCode = '';
        setInvalidTrainNumber('The chosen trainumber is not valid');
      }
    }

    setReservation(_ps => {
      return {...prevState};
    });
  };

  const handleItineraryChange = (itiName: string, type: 'Departure' | 'Arrival') => {
    //Find the correct itinerary
    const correctIti = trainFromTrainNumber?.itinerary.find(el => el.name === itiName);
    if (correctIti) {
      type === 'Arrival' ? setArrivalStation(correctIti) : setDepartureStation(correctIti);
    }
    //DO TRAIN LOGIC
    const prevState = reservation;

    if (type === 'Arrival' && correctIti) {
      prevState.travel.journeys[index].arrivalTime = correctIti.arrival;
      prevState.travel.journeys[index].arrivalUicCode = correctIti.uicCode;
    } else if (type === 'Departure' && correctIti) {
      prevState.travel.journeys[index].departureTime = correctIti.departure;
      prevState.travel.journeys[index].departureUicCode = correctIti.uicCode;
    }

    setReservation(_prev => {
      return {...prevState};
    });
  };

  const handleAssistanceChange = (withAssistance: boolean, type: 'Departure' | 'Arrival') => {
    const prevState = reservation;
    type === 'Departure'
      ? (prevState.travel.journeys[index].withDepartureAssistance = !withAssistance)
      : (prevState.travel.journeys[index].withArrivalAssistance = !withAssistance);

    setReservation(_prev => {
      return {...prevState};
    });
  };

  const handleCarNumber = (carNumber: string) => {
    const prevState = reservation;
    reservation.travel.journeys[index].coachNumber = carNumber;
    setReservation(_prev => {
      return {...prevState};
    });
  };

  const handleDoorNumber = (doorNumber: string) => {
    const prevState = reservation;
    reservation.travel.journeys[index].doorNumber = doorNumber;
    setReservation(_prev => {
      return {...prevState};
    });
  };

  const showCarAndDoor = () => {
    if (index === 0 && variant === ReservationTypeDto.Group) {
      return true;
    }

    if (variant === ReservationTypeDto.Disabled && !RESERVATIONS_FEATURE_FLAG_NEW_PMR) {
      return true;
    }

    return false;
  };

  //---------------------------------------------------------------------------------

  React.useEffect(() => {
    getActiveTrainsWithoutQuery();
  }, []);

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '15px',
      }}
    >
      <Autocomplete
        disabled={index < reservation.travel.journeys.length - 1}
        placeholder={t('reservation.form.train.label')}
        disablePortal
        disableClearable
        options={activeTrains.map(el => el.trainNumber.toString())}
        renderInput={args => (
          <TextField
            label={t('reservation.form.train.label')}
            helperText={index > 0 ? invalidTrainNumber : ''}
            error={invalidTrainNumber.length > 0}
            {...args}
          />
        )}
        onChange={(_e, val) => {
          handleSelectTrainNumber(val);
        }}
        //@ts-ignore
        onInputChange={e => handleChangeInput(e.target.value)}
      />
      <ItineraryAutocomplete
        variant={variant}
        type="Departure"
        train={trainFromTrainNumber}
        index={index}
        departureItinerary={departureStation}
        arrivalItinerary={arrivalStation}
        reservation={reservation}
        setReservation={setReservation}
        handleItineraryChange={handleItineraryChange}
        loading={loadingTrains}
        hasInCorrectTrainNumber={invalidTrainNumber.length > 0 || index < reservation.travel.journeys.length - 1}
        handleAssistanceChange={handleAssistanceChange}
        withAssistance={index === 0 ? true : reservation.travel.journeys[index].withDepartureAssistance || false}
      />
      {showCarAndDoor() && (
        <CarAndDoor
          index={index}
          reservation={reservation}
          variant={variant}
          handleCarNumber={handleCarNumber}
          setReservation={setReservation}
          handleDoorNumber={handleDoorNumber}
        />
      )}
      <ItineraryAutocomplete
        variant={variant}
        type="Arrival"
        train={trainFromTrainNumber}
        index={index}
        arrivalItinerary={arrivalStation}
        departureItinerary={departureStation}
        reservation={reservation}
        setReservation={setReservation}
        handleItineraryChange={handleItineraryChange}
        loading={loadingTrains}
        hasInCorrectTrainNumber={invalidTrainNumber.length > 0 || index < reservation.travel.journeys.length - 1}
        handleAssistanceChange={handleAssistanceChange}
        withAssistance={reservation.travel.journeys[index].withArrivalAssistance || false}
      />
    </Box>
  );
};
