import * as React from 'react';
import {Box, Container, TextField, List, ListItem, Select, MenuItem, Autocomplete, Input} from '@mui/material';
import {CreateMissionClient, ValidationError} from '../types';
import {LanguageDto, ReservationTypeDto} from 'src/services/models';
import {useTranslation} from 'react-i18next';
import {regexForEmailValidation} from '../util';

enum Key {
  NAME = 'name',
  LAST_NAME = 'last_name',
  PHONE = 'phone',
  LANGUAGE = 'language',
  GROUP = 'group',
  EMAIL = 'mail',
  TEXT = 'text',
}
const grid = {
  display: 'grid',
  gap: '15px',
  marginBottom: '15px',
};

const twoGrid = {
  ...grid,
  gridTemplateColumns: '1fr 1fr',
};

export const Client: React.FC<CreateMissionClient> = ({reservation, setReservation, variant}) => {
  const {t} = useTranslation();

  const [firstNameError, setFirstNameError] = React.useState<ValidationError>({
    hasError: true,
    error: t('create-prm-mission.form-first-name-required'),
  });
  const [lastNameError, setLastNameError] = React.useState<ValidationError>({
    hasError: true,
    error: t('create-prm-mission.form-last-name-required'),
  });
  const [phoneError, setPhoneError] = React.useState<ValidationError>({
    hasError: true,
    error: t('create-prm-mission.form-phone-required'),
  });
  const [emailError, setEmailError] = React.useState<ValidationError>({
    hasError: true,
    error: t('create-prm-mission.form-email-required'),
  });
  const [groupNameError, setGroupNameError] = React.useState<ValidationError>({
    hasError: true,
    error: t('create-prm-mission.form-group-name-required'),
  });
  const [languageError, setLanguageError] = React.useState<ValidationError>({
    hasError: true,
    error: t('create-prm-mission.form-language-required'),
  });

  const handleFormChange = (value: string | LanguageDto, key: Key) => {
    if (key === Key.NAME) {
      setReservation(prev => ({...prev, client: {...prev.client, firstName: value}}));
    }
    if (key === Key.LAST_NAME) {
      setReservation(prev => ({...prev, client: {...prev.client, lastName: value}}));
    }
    if (key === Key.EMAIL) {
      setReservation(prev => ({...prev, client: {...prev.client, email: value.toLocaleLowerCase()}}));
    }
    if (key === Key.GROUP) {
      setReservation(prev => ({...prev, client: {...prev.client, description: value}}));
    }
    if (key === Key.PHONE) {
      setReservation(prev => ({...prev, client: {...prev.client, phoneNumber: value}}));
    }
    if (key === Key.LANGUAGE) {
      setReservation(prev => ({...prev, client: {...prev.client, language: value as LanguageDto}}));
    }
    if (key === Key.TEXT) {
      setReservation(prev => ({...prev, remarks: value}));
    }
  };

  React.useEffect(() => {
    //Filter all values
    reservation.client?.firstName && reservation.client.firstName.length > 0
      ? setFirstNameError({hasError: false, error: ''})
      : setFirstNameError({hasError: true, error: t('create-prm-mission.form-first-name-required')});

    reservation.client?.lastName && reservation.client.lastName.length > 0
      ? setLastNameError({hasError: false, error: ''})
      : setLastNameError({hasError: true, error: t('create-prm-mission.form-last-name-required')});

    reservation.client?.description && reservation.client.description.length > 0
      ? setGroupNameError({hasError: false, error: ''})
      : setGroupNameError({hasError: true, error: t('create-prm-mission.form-group-name-required')});

    reservation.client?.email &&
    reservation.client.email.length > 0 &&
    regexForEmailValidation.test(reservation.client.email)
      ? setEmailError({hasError: false, error: ''})
      : setEmailError({hasError: true, error: t('create-prm-mission.form-email-required')});

    reservation.client?.language && reservation.client.language.length > 0
      ? setLanguageError({hasError: false, error: ''})
      : setLanguageError({hasError: true, error: t('create-prm-mission.form-language-required')});

    reservation.client?.phoneNumber && reservation.client.phoneNumber.length > 4
      ? setPhoneError({hasError: false, error: ''})
      : setPhoneError({hasError: true, error: t('create-prm-mission.form-phone-required')});
  }, [reservation]);

  return (
    <Container maxWidth={false}>
      <Box sx={twoGrid}>
        <TextField
          type="text"
          helperText={firstNameError.error}
          error={firstNameError.hasError}
          onChange={event => handleFormChange(event.target.value, Key.NAME)}
          color={firstNameError.hasError ? 'error' : 'success'}
          label={t('create-grobo-mission.surname')}
        />
        <TextField
          error={lastNameError.hasError}
          type="text"
          helperText={lastNameError.error}
          onChange={event => handleFormChange(event.target.value, Key.LAST_NAME)}
          color={lastNameError.hasError ? 'error' : 'success'}
          label={t('create-grobo-mission.name')}
        />
      </Box>
      <Box sx={grid}>
        {variant === ReservationTypeDto.Group && (
          <TextField
            type="text"
            helperText={groupNameError.error}
            error={groupNameError.hasError}
            onChange={event => handleFormChange(event.target.value, Key.GROUP)}
            color={groupNameError.hasError ? 'error' : 'success'}
            label={t('create-grobo-mission.group-name')}
          />
        )}
      </Box>
      <Box sx={twoGrid}>
        <TextField
          type="number"
          helperText={phoneError.error}
          error={phoneError.hasError}
          onChange={event => handleFormChange(event.target.value, Key.PHONE)}
          color={phoneError.hasError ? 'error' : 'success'}
          label={t('create-grobo-mission.phone')}
        />
        <Autocomplete
          disableClearable
          disablePortal
          options={Object.values(LanguageDto)}
          renderInput={args => (
            <TextField
              error={languageError.hasError}
              helperText={languageError.error}
              label={t('settings.changelanguage.select-language')}
              {...args}
            />
          )}
          onChange={(event, value) => handleFormChange(value, Key.LANGUAGE)}
          color={languageError.hasError ? 'error' : 'success'}
        />
      </Box>
      <Box sx={grid}>
        <TextField
          type="email"
          error={emailError.hasError}
          helperText={emailError.error}
          onChange={event => handleFormChange(event.target.value, Key.EMAIL)}
          color={emailError.hasError ? 'error' : 'success'}
          label={t('create-grobo-mission.mail')}
        />
      </Box>
      <Box sx={{...grid, marginBottom: '45px'}}>
        <Input
          minRows={8}
          multiline
          sx={{border: 'solid 1px lightgrey', borderRadius: '3px', padding: '8px'}}
          placeholder={t('create-grobo-mission.text-box')}
          onChange={event => handleFormChange(event.target.value, Key.TEXT)}
        />
      </Box>
    </Container>
  );
};
