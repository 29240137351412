import axios, {AxiosError} from 'axios';
import {SearchStationPresencesDto} from 'src/services/models/search-station-presences-dto';
import {StationPresenceDto} from 'src/services/models/station-presence-dto';

export const getStationPresences = async (baseUrl: string, searchData: SearchStationPresencesDto) => {
  try {
    const res = await axios.post<StationPresenceDto[]>(`${baseUrl}/api/v2/presences`, searchData);
    if (res) {
      return Promise.resolve(res.data);
    }
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};
