import { useContext } from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {Box, Typography, Table, TableHead, TableBody, TableRow, TableCell, Button} from '@mui/material';

import DirectionsRailwayOutlinedIcon from '@mui/icons-material/DirectionsRailwayOutlined';
import DirectionsBusOutlinedIcon from '@mui/icons-material/DirectionsBusOutlined';
import LocalTaxiOutlinedIcon from '@mui/icons-material/LocalTaxiOutlined';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';

import {CacheContext} from 'src/context/cache/cache.store';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';
import {LocalizedTextDto} from 'src/services/models';

import {EvacuationDto} from 'src/services/models/evacuation-dto';
import {EvacuationVehicleTypeDto} from 'src/services/models/evacuation-vehicle-type-dto';

export const CoiEvacuationMethods = ({
  evacuationsTrains,
  evacuationsBuses,
  evacuationsTaxis,
  evacuationsFoot,
  evacuationsEmergencyVehicle,
  handleOpenEvacuationModal,
  isIncidentClosed,
}: {
  evacuationsTrains: EvacuationDto[] | undefined;
  evacuationsBuses: EvacuationDto[] | undefined;
  evacuationsTaxis: EvacuationDto[] | undefined;
  evacuationsFoot: EvacuationDto[] | undefined;
  evacuationsEmergencyVehicle: EvacuationDto[] | undefined;
  handleOpenEvacuationModal: (evacuation: EvacuationDto) => void;
  isIncidentClosed: boolean;
}) => {
  const {t} = useTranslation();
  return (
    <Box
      sx={{
        borderRadius: '4px',
        padding: '15px 20px',
      }}
    >
      <Typography sx={{fontWeight: 'bold', fontSize: '18px'}}>
        {t('coi.incident-evacuation-methods')}
      </Typography>
      {!!evacuationsTrains?.length && evacuationsTrains.length > 0 ? (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px'}}>
          <CoiEvacuationMethodHeader title={t('coi.incident-trains')} amount={evacuationsTrains?.length || 0}>
            <DirectionsRailwayOutlinedIcon sx={{fontSize: '18px', color: 'black'}} />
          </CoiEvacuationMethodHeader>
          <CoiEvacuationMethodTable
            items={evacuationsTrains}
            type={EvacuationVehicleTypeDto.Train}
            handleEditEvacuation={handleOpenEvacuationModal}
            isEvacuationEditable={isIncidentClosed}
          />
        </Box>
      ) : null}
      {!!evacuationsBuses?.length && evacuationsBuses.length > 0 ? (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px'}}>
          <CoiEvacuationMethodHeader title={t('coi.incident-buses')} amount={evacuationsBuses?.length || 0}>
            <DirectionsBusOutlinedIcon sx={{fontSize: '18px', color: 'black'}} />
          </CoiEvacuationMethodHeader>
          <CoiEvacuationMethodTable
            items={evacuationsBuses}
            type={EvacuationVehicleTypeDto.Bus}
            handleEditEvacuation={handleOpenEvacuationModal}
            isEvacuationEditable={isIncidentClosed}
          />
        </Box>
      ) : null}
      {!!evacuationsTaxis?.length && evacuationsTaxis.length > 0 ? (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px'}}>
          <CoiEvacuationMethodHeader title={t('coi.incident-taxis')} amount={evacuationsTaxis?.length || 0}>
            <LocalTaxiOutlinedIcon sx={{fontSize: '18px', color: 'black'}} />
          </CoiEvacuationMethodHeader>
          <CoiEvacuationMethodTable
            items={evacuationsTaxis}
            type={EvacuationVehicleTypeDto.Taxi}
            handleEditEvacuation={handleOpenEvacuationModal}
            isEvacuationEditable={isIncidentClosed}
          />
        </Box>
      ) : null}
      {!!evacuationsFoot?.length && evacuationsFoot.length > 0 ? (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px'}}>
          <CoiEvacuationMethodHeader title={t('coi.incident-foot')} amount={evacuationsFoot?.length || 0}>
            <DirectionsWalkIcon sx={{fontSize: '18px', color: 'black'}} />
          </CoiEvacuationMethodHeader>
          <CoiEvacuationMethodTable
            items={evacuationsFoot}
            type={EvacuationVehicleTypeDto.Foot}
            handleEditEvacuation={handleOpenEvacuationModal}
            isEvacuationEditable={isIncidentClosed}
            isFootEvacuation
          />
        </Box>
      ) : null}
      {!!evacuationsEmergencyVehicle?.length && evacuationsEmergencyVehicle.length > 0 ? (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px'}}>
        <CoiEvacuationMethodHeader
          title={t('coi.incident-emergency-vehicle')}
          amount={evacuationsEmergencyVehicle?.length || 0}
        >
          <AirportShuttleIcon sx={{fontSize: '18px', color: 'black'}} />
        </CoiEvacuationMethodHeader>
        <CoiEvacuationMethodTable
          items={evacuationsEmergencyVehicle}
          type={EvacuationVehicleTypeDto.EmergencyVehicle}
          handleEditEvacuation={handleOpenEvacuationModal}
          isEvacuationEditable={isIncidentClosed}
        />
        </Box>
      ) : null}
    </Box>
  );
};

const CoiEvacuationMethodHeader = ({
  title,
  amount,
  children,
}: {
  title: string;
  amount: number;
  children?: React.ReactNode;
}) => {
  if (amount > 0) {
    return (
      <Typography sx={{display: 'flex', alignItems: 'center', gap: '10px', fontSize: '14px'}}>
        {children}
        {title} ({amount})
      </Typography>
    );
  }

  return null;
};

const CoiEvacuationMethodTable = ({
  items,
  type,
  handleEditEvacuation,
  isEvacuationEditable,
  isFootEvacuation,
}: {
  items: EvacuationDto[];
  type: EvacuationVehicleTypeDto;
  handleEditEvacuation: (evacuation: EvacuationDto) => void;
  isEvacuationEditable: boolean;
  isFootEvacuation?: boolean;
}) => {
  const {t, i18n} = useTranslation();
  const {stations} = useContext(CacheContext);

  const findStationNameById = (id: string): string => {
    if (id) {
      const result = stations.find(station => station.id === id)?.name;
      if (result) {
        return handleTranslationObject(i18n, result as LocalizedTextDto[])
      }
      return '';
    }
    return ''
  }

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell sx={{fontWeight: 700, flex: 1}}>{t(isFootEvacuation ? 'check-in.station' : 'coi.incident-number')}</TableCell>
          <TableCell sx={{fontWeight: 700}}>{t('coi.incident-arrived')}</TableCell>
          <TableCell sx={{fontWeight: 700}}>{t('coi.incident-departure-hour')}</TableCell>
          <TableCell sx={{fontWeight: 700}}>{t('coi.incident-passengers')}</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map(evacuation => (
          <TableRow key={`evacuation-${type}-${evacuation.id}`}>
            <TableCell sx={{ width: '200px'}}>{isFootEvacuation ? findStationNameById(evacuation.station || '') : evacuation.transportId}</TableCell>
            <TableCell >
              {moment(evacuation.arrivalTime).format('HHumm') !== 'Invalid date'
                ? moment(evacuation.arrivalTime).format('HHumm')
                : ''}
            </TableCell>
            <TableCell>
              {moment(evacuation.departureTime).format('HHumm') !== 'Invalid date'
                ? moment(evacuation.departureTime).format('HHumm')
                : ''}
            </TableCell>
            <TableCell>{evacuation.numberOfPassengers}</TableCell>
            <TableCell align="right" sx={{width: '25px'}}>
              <Button
                disabled={isEvacuationEditable}
                sx={{
                  display: 'flex',
                  width: '25px',
                  border: 'none',
                  backgroundColor: 'transparent',
                  padding: 0,
                  color: isEvacuationEditable ? '#C4C4C4' : '#0069B4',
                }}
                onClick={() => handleEditEvacuation(evacuation)}
              >
                <CreateOutlinedIcon />
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
