import React from 'react';

import {AssistanceDetailsComponents} from '.';

import {CardItemContent} from '../MissionItem.styles';
import {MissionSummaryDto} from 'src/services/models/mission-summary-dto';

import CircleIcon from '@mui/icons-material/Circle';
import {ClientStatusDto} from 'src/services/models/client-status-dto';
import {Box} from '@mui/material';
import SquareIcon from '@mui/icons-material/Square';

const getPresenceAvailability = (status: ClientStatusDto) => {
  switch (status) {
    case ClientStatusDto.Present:
      return (
        <p>
          <CircleIcon sx={{color: 'green', fontSize: '14px'}} />
        </p>
      );
    case ClientStatusDto.Absent:
      return (
        <p>
          <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
            <polygon points="8,1 1,15 15,15" fill="#b80101" transform="rotate(90 8 8)" />
          </svg>
        </p>
      );
    case ClientStatusDto.Unknown:
      return (
        <p>
          <SquareIcon sx={{color: 'grey', fontSize: '14px'}} />
        </p>
      );
    default:
      return null;
  }
};

const AssistanceDetails: React.FC<{mission: MissionSummaryDto}> = ({mission}) => {
  return (
    <CardItemContent direction="row" margin={4} spacing={0.8} alignItems={'center'}>
      <Box sx={{display: 'grid', placeItems: 'center'}}>
        {getPresenceAvailability(mission.clientStatus as ClientStatusDto)}
      </Box>
      <AssistanceDetailsComponents.MissionType mission={mission} />
      <AssistanceDetailsComponents.AssistanceType mission={mission} />
      <AssistanceDetailsComponents.ExtraAssistance mission={mission} />
    </CardItemContent>
  );
};

export default AssistanceDetails;
