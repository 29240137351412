// @ts-nocheck
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export function UserChecked(props) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g id="Calque_2" data-name="Calque 2">
          <g id="all">
            <g id="ic_check">
              <path
                class="cls-1"
                d="M16.38,14.72a4.63,4.63,0,0,0-1-1.46,4.87,4.87,0,0,0-1.46-1,4.57,4.57,0,0,0-1.76-.35H11.1l.6-.21a4.87,4.87,0,0,0,1.46-1,4.77,4.77,0,0,0,1-1.44,4.52,4.52,0,0,0,.35-1.78,4.61,4.61,0,0,0-.35-1.76,4.87,4.87,0,0,0-1-1.46,4.87,4.87,0,0,0-1.46-1A4.61,4.61,0,0,0,9.94,3a4.52,4.52,0,0,0-1.78.35,4.77,4.77,0,0,0-1.44,1,4.87,4.87,0,0,0-1,1.46,4.43,4.43,0,0,0-.36,1.76A4.36,4.36,0,0,0,5.74,9.3a4.77,4.77,0,0,0,1,1.44,4.6,4.6,0,0,0,1.44,1,5.89,5.89,0,0,0,.59.21h-1a4.52,4.52,0,0,0-1.78.35,4.77,4.77,0,0,0-1.44,1,4.63,4.63,0,0,0-1,1.46,4.58,4.58,0,0,0-.36,1.76V21H16.73V16.48A4.57,4.57,0,0,0,16.38,14.72Zm-3.16-7.2a3.28,3.28,0,0,1-3.28,3.29A3.28,3.28,0,0,1,6.65,7.52,3.26,3.26,0,0,1,9.94,4.24a3.26,3.26,0,0,1,3.28,3.28ZM4.41,19.76V16.48A3.28,3.28,0,0,1,7.7,13.19h4.48a3.28,3.28,0,0,1,3.28,3.29v3.28Z"
              />
              <path class="cls-1" d="M23.23,1.31,18,7.51l-3.2-3.28L16.05,3,18,5,21.94,0Z" />
              <rect fill="transparent" class="cls-2" width="24" height="24" />
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
