import type {i18n} from 'i18next';
import {LanguageDto, LocalizedTextDto} from 'src/services/models';
import {ExternalLanguageDto} from 'src/services/models/external-language-dto';

export const handleTranslationObject = (
  i18n: i18n,
  translations: LocalizedTextDto[] | ExternalLanguageDto[] | undefined | null,
): string => {
  if (i18n === undefined || translations === undefined || translations === null) {
    return 'No translation found';
  }

  let activeLanguage = LanguageDto.English;

  if (i18n.language.toLocaleUpperCase() === 'NL') activeLanguage = LanguageDto.Dutch;
  if (i18n.language.toLocaleUpperCase() === 'FR') activeLanguage = LanguageDto.French;
  if (i18n.language.toLocaleUpperCase() === 'EN') activeLanguage = LanguageDto.English;

  const correctTranslation = translations.find(el => el.language === activeLanguage);

  if (correctTranslation?.text) {
    return correctTranslation?.text;
  } else {
    return 'No translation found';
  }
};
