import {useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';

const exceptions = ['details', 'reservation', 'change-route', 'create-incident'];

export const useAutoRedirect = <T extends {[key: string]: {[key: string]: string}}>(routes: T, replaceBy: string) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const {pathname} = location;

    // Skip redirection if current pathname matches any exception
    if (exceptions.some(exception => pathname.includes(exception))) {
      return;
    }

    // Flatten the routes and verify if current pathname exists in routes
    const flattenedRoutes = Object.values(routes).flatMap(route => Object.values(route));

    if (!flattenedRoutes.includes(pathname.replace('/', ''))) {
      navigate(`/${replaceBy}`);
    }
  }, [location, routes, replaceBy, navigate]);
};
