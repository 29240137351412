// @ts-nocheck
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function Route(props) {
  return (
    <SvgIcon {...props}>
      <svg
        id="ic_itinerary-routes"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        {...props}
      >
        <path
          id="Path_5406"
          data-name="Path 5406"
          d="M20.528,2a1.364,1.364,0,0,1,1,.424,1.382,1.382,0,0,1,.421,1V20.571a1.36,1.36,0,0,1-.421,1.027,1.4,1.4,0,0,1-1,.4H3.472a1.378,1.378,0,0,1-1.021-.4,1.4,1.4,0,0,1-.4-1.027V3.429a1.419,1.419,0,0,1,.4-1A1.34,1.34,0,0,1,3.472,2Zm0,1.429H3.472V20.572H20.528ZM6.36,19.143H4.894l1.865-4.018,1.288.625ZM11.2,9.366,6.315,6.6V4.857l5.508,3.214Zm-3.154,3.17,1.244.669-.622,1.25L7.381,13.83Zm2.532-1.92-.666,1.3-1.244-.625.622-1.3Zm0,3.214,1.288.625-.666,1.3-1.244-.625Zm1.866-7.053-1.289-.625.667-1.3,1.243.625Zm.044,9.6.622-1.3L14.4,15.7,13.777,17Zm1.954-5.312a2.068,2.068,0,0,1-.178-.849,2.45,2.45,0,0,1,.711-1.763,2.315,2.315,0,0,1,1.732-.737,2.246,2.246,0,0,1,1.71.737,2.493,2.493,0,0,1,.689,1.763,2.085,2.085,0,0,1-.178.849L16.664,14.9Zm1.244,5.312L16.931,17l-.622,1.25-1.288-.625Z"
        />
        <rect id="Rectangle_1735" data-name="Rectangle 1735" width="24" height="24" fill="none" />
      </svg>
    </SvgIcon>
  );
}
