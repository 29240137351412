import {COIStore} from 'src/context/coi/coi.createIncident.store';
import {
  CoiCreateIncidentLocation,
  CoiCreateIncidentInformation,
} from '../coiComponents/coiCreateIncidentComponents/reducer';
import {coiCreateIncidentInformationDisabledValidations} from './coiCreateIncidentInformation';
import {ActionTypeDto} from 'src/services/models/action-type-dto';
import {IncidentTypeDto} from 'src/services/models/incident-type-dto';

export const coiVerifyCreation = (
  locationState: CoiCreateIncidentLocation,
  informationState: CoiCreateIncidentInformation,
  COIStore: COIStore,
): boolean => {
  const hasAddress = locationState.address;
  const hasTrains = locationState.trains;
  const hasPtcar = !!locationState.ptcar;

  if (
    informationState.catalogId.length === 0 &&
    !coiCreateIncidentInformationDisabledValidations.catalog(COIStore, informationState)
  ) {
    return false;
  }

  //If catalog is availble then it's required
  if (
    informationState.subCatalogId.length === 0 &&
    !coiCreateIncidentInformationDisabledValidations.subCatalog(COIStore, informationState)
  ) {
    return false;
  }

  //If type extra than we require info
  if (informationState.type === IncidentTypeDto.Other && informationState.extraInfo.length <= 0) {
    return false;
  }

  //If subcatalog is available then it's required

  if (hasAddress && hasAddress.address && hasAddress.address.length > 0 && !(hasTrains.length > 0)) {
    return true;
  }

  if (hasTrains.length > 0) {
    return true;
  }

  if (hasPtcar) {
    return true;
  }

  return false;
};
