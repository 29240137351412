import React from 'react';

const DeleteIconTwo = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.25 4.49998V3.24998H8.91C8.9521 3.0238 9.04815 2.81111 9.19 2.62998C9.34716 2.45184 9.53733 2.30582 9.75 2.19998C10.0626 2.05445 10.4055 1.98587 10.75 1.99998H13.29C13.6908 2.00492 14.0848 2.10427 14.44 2.28998C14.6283 2.38717 14.7898 2.52925 14.9101 2.70366C15.0304 2.87807 15.106 3.07944 15.13 3.28998H20.75V4.49998H3.25ZM20.75 5.74998V6.99998H19.5L18.25 20.75C18.2732 20.9196 18.2568 21.0922 18.2022 21.2544C18.1475 21.4167 18.0561 21.564 17.9351 21.6851C17.8141 21.8061 17.6667 21.8975 17.5045 21.9521C17.3422 22.0068 17.1696 22.0231 17 22H7C6.83041 22.0231 6.65775 22.0068 6.49554 21.9521C6.33332 21.8975 6.18593 21.8061 6.0649 21.6851C5.94387 21.564 5.85246 21.4167 5.79784 21.2544C5.74321 21.0922 5.72684 20.9196 5.75 20.75L4.5 6.99998H3.25V5.74998H20.75ZM7 20.75H17L18.25 6.99998H5.75L7 20.75ZM7.86 9.53998L9.07 9.41998L9.85 18.17L8.64 18.29L7.86 9.53998ZM12.63 9.45998V18.25H11.3V9.45998H12.63ZM14.07 18.17L14.85 9.41998L16.14 9.53998L15.36 18.29L14.07 18.17Z"
      fill="#333333"
    />
  </svg>
);

export default DeleteIconTwo;
