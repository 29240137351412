import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface CoiCloseIncidentDialogProps {
  isOpen: boolean;
  subtitle?: string;
  handleSubmit: (report: string) => void;
  handleClose: () => void;
}

export const CoiCloseIncidentDialog: FC<CoiCloseIncidentDialogProps> = ({ isOpen, subtitle, handleSubmit, handleClose }) => {
  const { t } = useTranslation();
  const [report, setReport] = useState('');

  return (
    <Dialog maxWidth="md" fullWidth open={isOpen} onClose={handleClose}>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #B0BEC5', padding: '10px 20px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '28px', fontFamily: 'Arial' }}>
            {t('coi.close-incident')}
          </Typography>
          {subtitle && <Typography sx={{ fontSize: '18px', fontFamily: 'Arial' }}>{subtitle}</Typography>}
        </Box>
        <CloseIcon sx={{ fontSize: '28px', color: 'black', cursor: 'pointer' }} onClick={handleClose} />
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px' }}>
        <Typography sx={{ fontSize: '22px', marginBottom: '10px', fontWeight: '700', fontFamily: 'Arial' }}>{t('coi.incident-report')}</Typography>
        <textarea
          style={{ width: '100%', height: '150px', padding: '10px', borderRadius: '4px', fontSize: '16px', resize: 'none' }}
          placeholder={t('coi.incident-report')}
          value={report}
          onChange={(e) => setReport(e.target.value)}
        />
      </DialogContent>
      <DialogActions sx={{ display: 'flex', gap: '10px', justifyContent: 'flex-end', alignItems: 'center', padding: '0 24px 20px 24px' }}>
        <Button
          sx={{
            textDecoration: 'underline',
            textTransform: 'capitalize',
            fontFamily: 'Arial',
            fontSize: '16px',
            height: '50px',
            padding: '16px 24px'
          }}
          variant="text"
          onClick={handleClose}
        >
          {t('filter-section.filter-cancel')}
        </Button>
        <Button
          sx={{
            textTransform: 'capitalize',
            height: '50px',
            fontSize: '16px',
            fontWeight: 700,
            fontFamily: 'Arial',
            padding: '16px 24px'
          }}
          variant="contained"
          disabled={!report}
          onClick={() => handleSubmit(report)}
        >
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
