//@ts-nocheck

import {Train} from '@mui/icons-material';
import {Box} from '@mui/material';
import {formatTime} from 'src/utils/utils';
import FowardArrow from '../icons/FowardArrow';
import assistanceLogo from '../../components/images/ic_pmr_on.svg';
import {Checkbox} from 'antd';
import Adult from '../icons/Adult';

export const ListItem = ({
  amountOfTravelers,
  trainNumber,
  departureTime,
  departureName,
  arrivalTime,
  arrivalName,
  departureAssistanceNeeded,
  arrivalAssistanceNeeded,
  key,
  isManage = false,
  handleActiveMissionAssistance,
  index,
}) => {
  return (
    <Box
      key={key}
      sx={{
        display: 'flex',
        color: '#25336c',
        padding: '4px',
        alignItems: 'center',
      }}
    >
      <Box sx={{flex: 2}}>
        <span>
          <Train color="#25336c" />
        </span>
        <span style={{fontWeight: '700', fontFamily: 'sans-serif'}}> {trainNumber} </span>
      </Box>
      {isManage && (
        <Box sx={{flex: 2}}>
          <Checkbox
            defaultChecked={departureAssistanceNeeded}
            onChange={e => handleActiveMissionAssistance(e.target.checked, index, 'departure')}
          />
        </Box>
      )}
      <Box sx={{flex: 1}}>
        <span>{formatTime(departureTime)}</span>
      </Box>
      <Box sx={{flex: 4}}>
        {departureAssistanceNeeded && getAssistanceLogo}
        {departureName}
      </Box>

      <Box sx={{flex: 4}}>
        <FowardArrow color="#25336c" />
      </Box>
      {isManage && (
        <Box sx={{flex: 1}}>
          <Checkbox
            defaultChecked={arrivalAssistanceNeeded}
            onChange={e => handleActiveMissionAssistance(e.target.checked, index, 'arrival')}
          />
        </Box>
      )}
      <Box sx={{flex: 1}}>
        <span>{formatTime(arrivalTime)}</span>
      </Box>
      <Box sx={{flex: 4}}>
        {arrivalAssistanceNeeded && getAssistanceLogo}
        {arrivalName}
      </Box>
      <Box sx={{flex: 1}}>
        {amountOfTravelers}
        <Adult />
      </Box>
    </Box>
  );
};

const getAssistanceLogo = <img src={assistanceLogo} alt="Icon" style={{height: '24px', width: '24px'}} />;
