export enum Language {
  English = 'En',
  French = 'Fr',
  Dutch = 'Nl',
}

export function numberToPlaceString(number: number, language: Language): string {
  if (number < 1 || number > 20) {
    throw new Error('Number out of range. Please provide a number between 1 and 20.');
  }

  const translations: Record<Language, string[]> = {
    [Language.English]: [
      'first',
      'second',
      'third',
      'fourth',
      'fifth',
      'sixth',
      'seventh',
      'eighth',
      'ninth',
      'tenth',
      'eleventh',
      'twelfth',
      'thirteenth',
      'fourteenth',
      'fifteenth',
      'sixteenth',
      'seventeenth',
      'eighteenth',
      'nineteenth',
      'twentieth',
    ],
    [Language.French]: [
      'premier',
      'deuxième',
      'troisième',
      'quatrième',
      'cinquième',
      'sixième',
      'septième',
      'huitième',
      'neuvième',
      'dixième',
      'onzième',
      'douzième',
      'treizième',
      'quatorzième',
      'quinzième',
      'seizième',
      'dix-septième',
      'dix-huitième',
      'dix-neuvième',
      'vingtième',
    ],
    [Language.Dutch]: [
      'eerste',
      'tweede',
      'derde',
      'vierde',
      'vijfde',
      'zesde',
      'zevende',
      'achtste',
      'negende',
      'tiende',
      'elfde',
      'twaalfde',
      'dertiende',
      'veertiende',
      'vijftiende',
      'zestiende',
      'zeventiende',
      'achttiende',
      'negentiende',
      'twintigste',
    ],
  };

  return translations[language][number - 1];
}

/*
export const mockReservation = {
  travels: [
    {
      journeys: [
        {
          id: '1921771',
          departureTime: '2022-05-03T15:13:00+00:00',
          departureDelay: '00:00:00',
          departureUicCode: '8819406',
          departureName: 'BRUSSELS AIRPORT - ZAVENTEM',
          withDepartureAssistance: true,
          departureCommercialTrackName: null,
          departurePlannedTrackName: '12',
          arrivalTime: '2022-05-03T15:33:00+00:00',
          arrivalDelay: '00:00:00',
          arrivalUicCode: '8814001',
          arrivalName: 'BRUSSEL-ZUID / BRUXELLES-MIDI',
          withArrivalAssistance: true,
          arrivalCommercialTrackName: null,
          arrivalPlannedTrackName: '12',
          trainNumber: 2234,
          coachNumber: null,
          doorNumber: '12',
          seatNumber: null,
        },
        {
          id: '1921772',
          departureTime: '2022-05-03T15:13:00+00:00',
          departureDelay: '00:00:00',
          departureUicCode: '8819406',
          departureName: 'LEUVEN',
          withDepartureAssistance: true,
          departureCommercialTrackName: null,
          departurePlannedTrackName: '1',
          arrivalTime: '2022-05-03T15:33:00+00:00',
          arrivalDelay: '00:00:00',
          arrivalUicCode: '8814001',
          arrivalName: 'BREDENEN',
          withArrivalAssistance: true,
          arrivalCommercialTrackName: null,
          arrivalPlannedTrackName: '23',
          trainNumber: 2234,
          coachNumber: null,
          doorNumber: '12',
          seatNumber: null,
        },
      ],
      travelDate: '2022-05-03',
      traveler: {
        quantity: 1,
        ageCategories: [],
        disableds: [
          {
            typeId: 'pmr-2',
            quantity: 1,
            symbol: 'fixed-wheelchair',
          },
        ],
      },
    },
  ],
  missions: [
    {
      id: '',
      journeyId: '1921771', // Important so the FE can match it with the joruneyId inside joruneys
      missionType: 'Departure',
      owner: {
        id: '000000000',
        name: 'Bypass User',
      },
      status: 'Completed',
    },
    {
      id: '',
      journeyId: '1921772', // Important so the FE can match it with the joruneyId inside joruneys
      missionType: 'Departure',
      owner: {
        id: '000000000',
        name: 'Bjarne',
      },
      status: 'Pending',
    },
  ],
  id: '1544991',
  type: 'Group',
  remarks: 'CCregion',
  client: {
    description: 'Scouts',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    language: 'English',
  },

  status: 'Unknown',
};
*/
