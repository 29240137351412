import {StationDto} from 'src/services/models';

import {ReservationDto} from 'src/services/models/reservation-dto';

import {IMissionFilter} from '../PrintStickers/PrintStickers.store';

enum IPrintAction {
  ALL = 'ALL',
  TO_PRINT = 'TO PRINT',
  PRINTED = 'PRINTED',
}

export const groupMissionsFilter = (
  filter: IMissionFilter,
  _searchOnReservation: boolean,
  missions: ReservationDto[],
  _stations: StationDto[],
): ReservationDto[] => {
  let result = missions;
  const creatUniqueArray = (arr: any[]) => {
    //@ts-ignore
    return [...new Map(arr.map(v => [v.id, v])).values()];
  };

  if (filter.groupName.length > 0) {
    const inter: ReservationDto[] = [];
    result.forEach(reservation => {
      if (reservation.client?.description?.toLocaleUpperCase().includes(filter.groupName.toLocaleUpperCase())) {
        inter.push(reservation);
      }
    });
    result = inter;
  }

  //TrainNumber
  if (filter.trainNumber !== 0) {
    const inter: ReservationDto[] = [];
    result.forEach(reservation => {
      reservation.travels?.forEach(travel => {
        travel.journeys?.forEach(journey => {
          if (journey.trainNumber?.toString().includes(filter.trainNumber.toString())) {
            inter.push(reservation);
          }
        });
      });
    });
    result = inter;
  }

  if (filter.printAction === IPrintAction.ALL) {
    const inter: ReservationDto[] = [];
    result.forEach(reservation => {
      reservation.travels?.forEach(travel => {
        travel.journeys?.forEach(journey => {
          if (journey.withArrivalAssistance || journey.withDepartureAssistance) {
            inter.push(reservation);
          }
        });
      });
    });
    //@ts-ignore
    const uniqueInter = creatUniqueArray(inter);
    result = uniqueInter;
  }

  if (filter.printAction === IPrintAction.TO_PRINT) {
    const inter: ReservationDto[] = [];
    result.forEach(reservation => {
      reservation.travels?.forEach(travel => {
        travel.journeys?.forEach(journey => {
          if (!journey.withArrivalAssistance && !journey.withDepartureAssistance) {
            inter.push(reservation);
          }
        });
      });
    });
    //@ts-ignore
    const uniqueInter = creatUniqueArray(inter);
    result = uniqueInter;
  }

  return result;
};
