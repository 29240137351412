import React from 'react';
import {Dialog, DialogContent, DialogProps, DialogTitle, DialogTitleTypeMap, SxProps, Theme} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from 'react-i18next';

export interface IModernModal extends DialogProps {
  open: boolean;
  onClose: () => void;
  modalTitle: string;
  children: React.ReactNode;
  titleStyles?: SxProps<Theme>;
}

export const ModernModal = ({open, onClose, modalTitle, children, titleStyles, ...rest}: IModernModal) => {
  const {t} = useTranslation();
  return (
    <Dialog open={open} onClose={onClose} {...rest} keepMounted={false} disableAutoFocus>
      <DialogTitle sx={titleStyles}>
        <h3>{modalTitle}</h3>
        <IconButton
          aria-label={t('close')}
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'black',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};
