import React from 'react';
import {CacheContext} from '../cache/cache.store';
import {StationDto} from 'src/services/models';
import {localStorageKeys} from 'src/utils/localStorageKeys';

type IDistrictContext = {
  selectedDistrict: string[];
  selectedStations: string[];
  handleMutateDistricts: (newStations: string[]) => void;
};

export const DistrictContext = React.createContext<IDistrictContext>({
  selectedDistrict: [],
  selectedStations: [],
  handleMutateDistricts: ([]) => {},
});

export const DistrictProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const {stations} = React.useContext(CacheContext);

  const [selectedDistrict, setSelectedDistrict] = React.useState<string[]>([]);
  const [selectedStations, setSelectedStations] = React.useState<string[]>([]);

  const handleMutateDistricts = (newStations: string[]) => {
    if (newStations.length === 0) {
      setSelectedDistrict([]);
      setSelectedStations([]);
      return;
    }

    const allSelectedStations = (stations as StationDto[]).filter(station => newStations.includes(station.id || ''));
    const newDistricts: string[] = [];

    allSelectedStations.forEach(station => {
      if (station.districtId && newDistricts.includes(station.districtId)) {
        return;
      } else if (station.districtId) newDistricts.push(station.districtId);
      return;
    });

    setSelectedStations(newStations);
    setSelectedDistrict(newDistricts);
  };

  React.useEffect(() => {
    const stationsFromLocalStore = localStorage.getItem(localStorageKeys.SELECED_STATIONS);
    if (stationsFromLocalStore && stationsFromLocalStore?.length > 0) {
      handleMutateDistricts(stationsFromLocalStore?.split(','));
    }
  }, []);

  return (
    <DistrictContext.Provider
      value={{
        selectedDistrict,
        selectedStations,
        handleMutateDistricts,
      }}
    >
      {children}
    </DistrictContext.Provider>
  );
};

export const useDistrictContext = () => {
  const {selectedDistrict, selectedStations, handleMutateDistricts} = React.useContext(DistrictContext);

  return {
    selectedDistrict,
    selectedStations,
    handleMutateDistricts,
  };
};
