import axios, {AxiosError} from 'axios';
import {MissionDetailDto} from '../models/mission-detail-dto';
import {SearchMissionsDto} from '../models/search-missions-dto';
import {isFullDistrictSelected} from 'src/utils/verifyIfStationsSelected';

const getMissions = async (baseUrl: string, missionDetails: SearchMissionsDto) => {
  if (missionDetails) {
    const fullUrl = `${baseUrl}/api/missions`;
    try {
      const res = await axios.post<MissionDetailDto[]>(fullUrl, {
        districtIds:
          missionDetails.districtIds && missionDetails.districtIds[0].length > 0 && isFullDistrictSelected()
            ? missionDetails.districtIds
            : undefined,
        stationIds:
          missionDetails.districtIds && missionDetails.districtIds[0].length > 0 && isFullDistrictSelected()
            ? undefined
            : missionDetails.stationIds,
        date: missionDetails.date,
      });
      return Promise.resolve({data: res.data, selectedDate: missionDetails.date});
    } catch (error) {
      return Promise.reject(error as AxiosError);
    }
  }
  Promise.resolve(false);
};

export default getMissions;
