import React from 'react';

const SaveIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_31688_826)">
      <path
        d="M2 0.999268C1.73478 0.999268 1.48043 1.10462 1.29289 1.29216C1.10536 1.4797 1 1.73405 1 1.99927V13.9993C1 14.2645 1.10536 14.5188 1.29289 14.7064C1.48043 14.8939 1.73478 14.9993 2 14.9993H14C14.2652 14.9993 14.5196 14.8939 14.7071 14.7064C14.8946 14.5188 15 14.2645 15 13.9993V1.99927C15 1.73405 14.8946 1.4797 14.7071 1.29216C14.5196 1.10462 14.2652 0.999268 14 0.999268H9.5C9.23478 0.999268 8.98043 1.10462 8.79289 1.29216C8.60536 1.4797 8.5 1.73405 8.5 1.99927V9.29227L11.146 6.64527C11.2399 6.55138 11.3672 6.49864 11.5 6.49864C11.6328 6.49864 11.7601 6.55138 11.854 6.64527C11.9479 6.73915 12.0006 6.86649 12.0006 6.99927C12.0006 7.13204 11.9479 7.25938 11.854 7.35327L8.354 10.8533C8.30755 10.8998 8.25238 10.9368 8.19163 10.962C8.13089 10.9872 8.06577 11.0002 8 11.0002C7.93423 11.0002 7.86911 10.9872 7.80837 10.962C7.74762 10.9368 7.69245 10.8998 7.646 10.8533L4.146 7.35327C4.09951 7.30678 4.06264 7.25159 4.03748 7.19085C4.01232 7.13011 3.99937 7.06501 3.99937 6.99927C3.99937 6.93352 4.01232 6.86842 4.03748 6.80768C4.06264 6.74694 4.09951 6.69176 4.146 6.64527C4.19249 6.59878 4.24768 6.5619 4.30842 6.53674C4.36916 6.51159 4.43426 6.49864 4.5 6.49864C4.56574 6.49864 4.63084 6.51159 4.69158 6.53674C4.75232 6.5619 4.80751 6.59878 4.854 6.64527L7.5 9.29227V1.99927C7.5 1.46883 7.71071 0.960127 8.08579 0.585054C8.46086 0.209981 8.96957 -0.000732422 9.5 -0.000732422H14C14.5304 -0.000732422 15.0391 0.209981 15.4142 0.585054C15.7893 0.960127 16 1.46883 16 1.99927V13.9993C16 14.5297 15.7893 15.0384 15.4142 15.4135C15.0391 15.7886 14.5304 15.9993 14 15.9993H2C1.46957 15.9993 0.960859 15.7886 0.585786 15.4135C0.210714 15.0384 0 14.5297 0 13.9993V1.99927C0 1.46883 0.210714 0.960127 0.585786 0.585054C0.960859 0.209981 1.46957 -0.000732422 2 -0.000732422H4.5C4.63261 -0.000732422 4.75979 0.051946 4.85355 0.145714C4.94732 0.239482 5 0.366659 5 0.499268C5 0.631876 4.94732 0.759053 4.85355 0.852821C4.75979 0.946589 4.63261 0.999268 4.5 0.999268H2Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_31688_826">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SaveIcon;
