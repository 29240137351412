import axios, {AxiosError} from 'axios';

export const exportMissions = async (baseUrl: string, body: any) => {
  try {
    const json = await axios.post(baseUrl + '/api/missions/export', body, { responseType: 'blob' });
    return json.data;
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
}
