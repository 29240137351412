import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {routes} from 'src/constants/routes';
import {SettingsContext} from 'src/context/settings/settings.store';

export const Support = () => {
  const location = useLocation();
  const {EXTERNAL_LINK_SUPPORT_SERVICE_NOW} = React.useContext(SettingsContext);

  React.useEffect(() => {
    if (location.pathname.includes('settings/support')) {
      window.open(EXTERNAL_LINK_SUPPORT_SERVICE_NOW, '_blank');
    }
  }, []);

  return <Navigate to={`/${routes.missions.overview}`} />;
};
