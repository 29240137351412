import React from 'react';

const LightAssistanceIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.369 22.867" width="24.369" height="22.867">
      <g xmlns="http://www.w3.org/2000/svg" transform="matrix(0 1 -1 0 24.369 -0)">
        <g id="Groupe_15211" data-name="Groupe 15211" transform="translate(31.538 -560.189)">
          <g id="noun-walk-4664645-FFFFFF" transform="translate(-24.598 553.484)">
            <path
              id="Tracé_6495"
              data-name="Tracé 6495"
              d="M11.324,3.5a.3.3,0,0,0-.087-.032A7.08,7.08,0,0,1,8.768,2.6a4.25,4.25,0,0,1-.711-.932A4.767,4.767,0,0,0,7.419.8a2.815,2.815,0,0,0-3.8-.119L3.608.694.951,3.249a2.038,2.038,0,0,0-.59,1.194L.206,6.473a.941.941,0,0,0,.818.984l.629.082a.93.93,0,0,0,.121.008.914.914,0,0,0,.908-.766v0l.195-1.405a.272.272,0,0,0,0-.038c0-.118.07-.468.718-1.18.1-.109.2-.211.291-.3V7.8a2.043,2.043,0,0,0,.056.441A.279.279,0,0,0,3.9,8.32l-.669,2.267a5.55,5.55,0,0,1-.447,1.025L.128,15.238l-.015.022a.629.629,0,0,0-.039.653.663.663,0,0,0,.6.3H1.685a1.63,1.63,0,0,0,1.2-.608L5.341,12.8a.26.26,0,0,0,.019-.024,3.948,3.948,0,0,0,.526-1.186l.03-.134,2.822,4.129a1.6,1.6,0,0,0,1.2.628h.692a.751.751,0,0,0,.616-.286.712.712,0,0,0,.087-.66.277.277,0,0,0-.017-.041L8,8.863A4.2,4.2,0,0,1,7.828,7.8V5.02l3.283.769.009,0a.856.856,0,0,0,.986-.556,1.442,1.442,0,0,0-.782-1.73ZM5.317,11.463a3.414,3.414,0,0,1-.429.975l-2.458,2.8a.27.27,0,0,0-.019.024,1.041,1.041,0,0,1-.726.374H.671a.243.243,0,0,1-.088-.013.212.212,0,0,1,.027-.071l2.659-3.625.015-.022a6.009,6.009,0,0,0,.51-1.165L4.287,9.07,5.467,10.8Zm6.245-6.429a.273.273,0,0,1-.32.2L7.6,4.383h0a.3.3,0,0,0-.065-.007.288.288,0,0,0-.291.284V7.8A4.687,4.687,0,0,0,7.452,9.06.27.27,0,0,0,7.47,9.1l3.317,6.358a.155.155,0,0,1-.01.132.178.178,0,0,1-.144.051H9.94a1.02,1.02,0,0,1-.718-.375L4.764,8.743A2.039,2.039,0,0,1,4.47,7.8V3.188A.288.288,0,0,0,4.179,2.9a.294.294,0,0,0-.189.068h0a9.859,9.859,0,0,0-.823.8A2.7,2.7,0,0,0,2.3,5.314L2.1,6.7a.339.339,0,0,1-.375.275L1.1,6.894a.367.367,0,0,1-.315-.378l.155-2.03a1.466,1.466,0,0,1,.408-.821l.007-.007L4.009,1.106a2.226,2.226,0,0,1,2.982.08,4.237,4.237,0,0,1,.565.775A4.786,4.786,0,0,0,8.356,3a4.13,4.13,0,0,0,1.64.75c.493.143.933.237,1.077.267a.874.874,0,0,1,.489,1.015Z"
              transform="translate(-2.015 19.325) rotate(-90)"
              fill="#2b7c2b"
              stroke="#2b7c2b"
              strokeWidth="0.8"
            />
            <path
              id="Tracé_6496"
              data-name="Tracé 6496"
              d="M2.333,4.665A2.333,2.333,0,1,0,0,2.333,2.335,2.335,0,0,0,2.333,4.665Zm0-4.034a1.7,1.7,0,1,1-1.7,1.7A1.7,1.7,0,0,1,2.333.631Z"
              transform="translate(-6.54 11)"
              fill="#2b7c2b"
              stroke="#2b7c2b"
              strokeWidth="0.8"
            />
          </g>
          <g
            id="noun-walk-4664645-FFFFFF-2"
            data-name="noun-walk-4664645-FFFFFF"
            transform="translate(-23.265 564.824)"
          >
            <path
              id="Tracé_6495-2"
              data-name="Tracé 6495"
              d="M11.324,3.5a.3.3,0,0,0-.087-.032A7.08,7.08,0,0,1,8.768,2.6a4.25,4.25,0,0,1-.711-.932A4.767,4.767,0,0,0,7.419.8a2.815,2.815,0,0,0-3.8-.119L3.608.694.951,3.249a2.038,2.038,0,0,0-.59,1.194L.206,6.473a.941.941,0,0,0,.818.984l.629.082a.93.93,0,0,0,.121.008.914.914,0,0,0,.908-.766v0l.195-1.405a.272.272,0,0,0,0-.038c0-.118.07-.468.718-1.18.1-.109.2-.211.291-.3V7.8a2.043,2.043,0,0,0,.056.441A.279.279,0,0,0,3.9,8.32l-.669,2.267a5.55,5.55,0,0,1-.447,1.025L.128,15.238l-.015.022a.629.629,0,0,0-.039.653.663.663,0,0,0,.6.3H1.685a1.63,1.63,0,0,0,1.2-.608L5.341,12.8a.26.26,0,0,0,.019-.024,3.948,3.948,0,0,0,.526-1.186l.03-.134,2.822,4.129a1.6,1.6,0,0,0,1.2.628h.692a.751.751,0,0,0,.616-.286.712.712,0,0,0,.087-.66.277.277,0,0,0-.017-.041L8,8.863A4.2,4.2,0,0,1,7.828,7.8V5.02l3.283.769.009,0a.856.856,0,0,0,.986-.556,1.442,1.442,0,0,0-.782-1.73ZM5.317,11.463a3.414,3.414,0,0,1-.429.975l-2.458,2.8a.27.27,0,0,0-.019.024,1.041,1.041,0,0,1-.726.374H.671a.243.243,0,0,1-.088-.013.212.212,0,0,1,.027-.071l2.659-3.625.015-.022a6.009,6.009,0,0,0,.51-1.165L4.287,9.07,5.467,10.8Zm6.245-6.429a.273.273,0,0,1-.32.2L7.6,4.383h0a.3.3,0,0,0-.065-.007.288.288,0,0,0-.291.284V7.8A4.687,4.687,0,0,0,7.452,9.06.27.27,0,0,0,7.47,9.1l3.317,6.358a.155.155,0,0,1-.01.132.178.178,0,0,1-.144.051H9.94a1.02,1.02,0,0,1-.718-.375L4.764,8.743A2.039,2.039,0,0,1,4.47,7.8V3.188A.288.288,0,0,0,4.179,2.9a.294.294,0,0,0-.189.068h0a9.859,9.859,0,0,0-.823.8A2.7,2.7,0,0,0,2.3,5.314L2.1,6.7a.339.339,0,0,1-.375.275L1.1,6.894a.367.367,0,0,1-.315-.378l.155-2.03a1.466,1.466,0,0,1,.408-.821l.007-.007L4.009,1.106a2.226,2.226,0,0,1,2.982.08,4.237,4.237,0,0,1,.565.775A4.786,4.786,0,0,0,8.356,3a4.13,4.13,0,0,0,1.64.75c.493.143.933.237,1.077.267a.874.874,0,0,1,.489,1.015Z"
              transform="translate(-2.015 19.325) rotate(-90)"
              fill="#2b7c2b"
              stroke="#2b7c2b"
              strokeWidth="0.8"
            />
            <path
              id="Tracé_6496-2"
              data-name="Tracé 6496"
              d="M2.333,4.665A2.333,2.333,0,1,0,0,2.333,2.335,2.335,0,0,0,2.333,4.665Zm0-4.034a1.7,1.7,0,1,1-1.7,1.7A1.7,1.7,0,0,1,2.333.631Z"
              transform="translate(-6.54 11)"
              fill="#2b7c2b"
              stroke="#2b7c2b"
              strokeWidth="0.8"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LightAssistanceIcon;
