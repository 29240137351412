//Icons
import FilterIcon from '../../../components/images/ic_filter-blue.svg';
import TrainIcon from '../../../components/images/ic_train-blue.svg';
import TypeOfAssistanceIcon from '../../../components/images/ic_exchange-blue.svg';
import GroupInformationIcon from '../../../components/images/ic_colleague_3x-blue.svg';
import AdultIcon from '../../../components/images/ic_adult-blue.svg';
import TrainStickerIcon from '../../../components/icons/TrainSticker';
import DoorIcon from '../../../components/images/ic_door-gray.svg';
import TrackIcon from '../../../components/images/ic_perron-gray.svg';
import AmountOfTravelersIcon from '../../../components/images/ic_manager.svg';
import RequiresAssistanceIcon from '../../../components/images/ic_pmr_on.svg';
import FullAssistanceIcon from 'src/icons/FullAssistanceIcon';
import LightAssistanceIcon from 'src/icons/LightAssistanceIcon';
import {Search} from '@mui/icons-material';
import ChildIcon from 'src/icons/ChildIcon';
import SeniorIcon from 'src/icons/SeniorIcon';
import TrainDriverIcon from 'src/icons/TrainDriverIcon';
import TrainManagerIcon from 'src/icons/TrainManagerIcon';
import FoldingWheelchairIcon from 'src/icons/FoldingWheelchairIcon';
import FixedWheelchairIcon from 'src/icons/FixedWheelchairIcon';
import ElectricWheelchairIcon from 'src/icons/ElectricWheelchaiIcon';
import ReducedMobility from 'src/components/icons/ReducedMobility';
import Blind from 'src/components/icons/Blind';
import OrientationProblemsIcon from 'src/icons/OrientationProblemsIcon';
import SeatIcon from 'src/components/images/ic_seat.svg';

type IconLegend = {
  name: string;
  icon: string | React.ReactNode;
};

export const Icons: IconLegend[] = [
  {
    name: 'icons.filter',
    icon: FilterIcon,
  },
  {
    name: 'icons.search',
    icon: Search,
  },

  {
    name: 'icons.train',
    icon: TrainIcon,
  },
  {
    name: 'icons.door',
    icon: DoorIcon,
  },

  {
    name: 'icons.track',
    icon: TrackIcon,
  },
  {
    name: 'icons.seat',
    icon: SeatIcon,
  },
  {
    name: 'icons.children',
    icon: ChildIcon,
  },
  {
    name: 'icons.adult',
    icon: AdultIcon,
  },

  {
    name: 'icons.senior',
    icon: SeniorIcon,
  },
  {
    name: 'icons.type-of-assistance',
    icon: TypeOfAssistanceIcon,
  },
  {
    name: 'icons.amount-of-travelers',
    icon: AmountOfTravelersIcon,
  },
  {
    name: 'icons.requires-assistance',
    icon: RequiresAssistanceIcon,
  },
  {
    name: 'icons.full-assistance',
    icon: FullAssistanceIcon,
  },

  {
    name: 'icons.light-assistance',
    icon: LightAssistanceIcon,
  },
  {
    name: 'icons.train-sticker',
    icon: TrainStickerIcon,
  },
  {
    name: 'icons.group-info',
    icon: GroupInformationIcon,
  },

  {
    name: 'icons.train-driver',
    icon: TrainDriverIcon,
  },
  {
    name: 'icons.train-manager',
    icon: TrainManagerIcon,
  },
  {
    name: 'icons.folding-wheelchair',
    icon: FoldingWheelchairIcon,
  },
  {
    name: 'icons.fixed-wheelchair',
    icon: FixedWheelchairIcon,
  },
  {
    name: 'icons.electronic-wheelchair',
    icon: ElectricWheelchairIcon,
  },
  {
    name: 'icons.limited-mobility',
    icon: ReducedMobility,
  },
  {
    name: 'icons.blind',
    icon: Blind,
  },
  {
    name: 'icons.orientation-problems',
    icon: OrientationProblemsIcon,
  },
];
