import {AdvancedSearch} from './AdvancedSearch';
import {ReservationStatus} from './ReservationStatus';
import {DateRange} from './DateRange';
import {OwnerAndClient} from './OwnerAndClient';
import {Buttons} from './Buttons';
import { AssistanceTypes } from './AssistanceTypes';

const FormElements = {
  AdvancedSearch,
  DateRange,
  ReservationStatus,
  OwnerAndClient,
  Buttons,
  AssistanceTypes,
};

export default FormElements;
