import {Box} from '@mui/system';

export const StepButton = ({alternate}: {alternate: boolean}) => {
  return (
    <Box
      sx={{
        height: '18px',
        width: '18px',
        borderRadius: '18px',
        boxSizing: 'border-box',
        border: 'solid 5px white',
        filter: 'drop-shadow(1px 8px 10px #d8d8d8)',
        backgroundColor: `${alternate ? '#25336C' : '#D7D7D7'}`,
        display: 'inline-block',
        placeSelf: 'center',
        zIndex: 4,
      }}
    />
  );
};

export const HorizontalLine = ({
  height,
  left,
  top,
  dashed = false,
}: {
  height: string;
  left: string;
  top: string;
  dashed?: boolean;
}) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        width: '1px',
        border: `${dashed ? 'dashed' : 'solid'} 1px lightgrey`,
        height: height,
        left: left,
        top: top,
        zIndex: 3,
      }}
    />
  );
};
