import React from 'react'

const SeniorIcon = () => (
    <svg id="ic_senior" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <rect id="Rectangle_1626" data-name="Rectangle 1626" width="24" height="24" fill="none"/>
        <path id="Path_5158" data-name="Path 5158" d="M13.92,21.81H12.13a.5.5,0,0,1-.5-.5V17l-.69-1-.11,1.75a.52.52,0,0,1-.1.27L8,21.61a.46.46,0,0,1-.32.2.52.52,0,0,1-.38-.1L5.9,20.64a.5.5,0,0,1-.1-.7L8,17V13.53a.49.49,0,0,1-.39.2H5.84a.5.5,0,0,1-.5-.5V9a.52.52,0,0,1,.25-.44L10.44,5.8h.11a2.46,2.46,0,0,1,.58-.19A2.29,2.29,0,1,1,13,6.06a2.39,2.39,0,0,1,.62.76l.71,1.52A14.19,14.19,0,0,0,17.49,11a.5.5,0,0,1-.43.89l-.43-.15-.12.06-.33.7a.51.51,0,0,1-.63.26A8,8,0,0,1,12.78,11a6.62,6.62,0,0,0-.15,1.38v.45l1.68,2.41a.47.47,0,0,1,.09.28v5.84A.5.5,0,0,1,13.92,21.81Zm-1.29-1h.79V15.63l-1.68-2.39a.62.62,0,0,1-.09-.27v-.64a7.53,7.53,0,0,1,.47-2.6.49.49,0,0,1,.41-.33.47.47,0,0,1,.47.22,6.36,6.36,0,0,0,2.5,2l.2-.43a.48.48,0,0,1,.12-.16,10.51,10.51,0,0,1-2.36-2.24L12.7,7.26a1.32,1.32,0,0,0-1.78-.56h-.06L6.34,9.3v3.43h.8V10.05a.5.5,0,0,1,.25-.44l1.35-.76a.5.5,0,0,1,.72.58A10.5,10.5,0,0,0,9,12.33v4.79a.47.47,0,0,1-.1.3l-2,2.72.63.47,2.31-3.07.19-3a.5.5,0,0,1,.91-.26l1.6,2.28a.52.52,0,0,1,.09.29ZM8.14,10.34v.52a5.62,5.62,0,0,1,.09-.57ZM12.58,2.5a1.3,1.3,0,1,0,1.29,1.3,1.3,1.3,0,0,0-1.29-1.3Z" fill="#333"/>
        <path id="Path_5159" data-name="Path 5159" d="M17.71,22a.45.45,0,0,1-.45-.45V12.42c0-.1,0-.64-.43-.64s-.5.67-.5.7v.72a.45.45,0,1,1-.9,0v-.74a1.49,1.49,0,0,1,1.4-1.57,1.41,1.41,0,0,1,1.33,1.53v9.13a.45.45,0,0,1-.45.45Z" fill="#333"/>
    </svg>
)

export default SeniorIcon