import './IconButton.scss';

const IconButton = ({
  color,
  text,
  icon,
  onClick,
  className = 'icon-button-container',
}: {
  color: string;
  text: string;
  icon: string;
  onClick: () => void;
  className: string;
}) => (
  <div className={className} style={{backgroundColor: color}} onClick={onClick} role="button" aria-hidden="true">
    <div className="button-parent">
      {icon && (
        <div className="icon">
          <img src={icon} alt="icon-description" />
        </div>
      )}
      <div className="title">
        <p className="text">{text}</p>
      </div>
    </div>
  </div>
);

export default IconButton;
