// @ts-nocheck
import Grid from '@mui/material/Grid';
import {styled} from '@mui/material/styles';
import {Box} from '@mui/system';

export const LeftScreenContainer = styled(Box)({
  backgroundColor: '#EFF4F9',
  minHeight: 'calc(100vh - 64px)',
  maxHeight: 'calc(100vh - 64px)',
  overflowY: 'scroll',
  width: '100%',
  zIndex: 1,
});

export const RightScreenContainer = styled(Box)({
  backgroundColor: '#ffffff',
  minHeight: 'calc(100vh - 64px)',
  maxHeight: 'calc(100vh - 64px)',
  overflowY: 'scroll',
});

export const emptyList = {width: '120px', height: '120px', color: '#777777'};

export const loaderScreen = {
  paddingBottom: 120,
  paddingTop: 0,
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  display: 'flex',
  height: '100vh',
};
