import React, {useEffect} from 'react';
import {useDistrictContext} from 'src/context/district/district.store'; // Adjust the import path as necessary

export const useOnDistrictsOrStationsChange = (callback: () => void) => {
  const {selectedDistrict, selectedStations} = useDistrictContext();

  useEffect(() => {
    // Trigger the callback function whenever selectedDistrict or selectedStations change
    callback();
  }, [selectedDistrict, selectedStations, callback]);
};

export const useOnDistrictsOrStationsChangeAsync = (asyncCallback: () => Promise<void>) => {
  const {selectedDistrict, selectedStations} = useDistrictContext();

  useEffect(() => {
    // Define an async function to execute the callback
    const executeAsyncCallback = async () => {
      try {
        await asyncCallback();
      } catch (error) {
        console.error('Error executing async callback:', error);
      }
    };

    // Call the async function
    executeAsyncCallback();
  }, [selectedDistrict, selectedStations, asyncCallback]);
};
