import React from 'react'


const FixedWheelchairIcon = () => (
    <svg id="ic_wheelchair_fix" data-name="ic_wheelchair fix" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <rect id="Rectangle_1644" data-name="Rectangle 1644" width="24" height="24" fill="none"/>
        <path id="Path_5214" data-name="Path 5214" d="M18.65,13.5a.64.64,0,0,0,.39-.16A.55.55,0,0,0,19.2,13V11.1a.55.55,0,0,0-.16-.39.59.59,0,0,0-.39-.16h0a4.83,4.83,0,0,1-3.41-1.56L14,7.59A2.46,2.46,0,0,0,13.22,7h0a2.38,2.38,0,0,0-.63-.23,2.45,2.45,0,1,0-1.1,0,2.52,2.52,0,0,0-1.9,2.46v2.48l-.05-.05a.55.55,0,0,0-.54-.1,5.3,5.3,0,1,0,6.25,6.25.52.52,0,0,0-.11-.46V17.3H16.2v4.2a.55.55,0,0,0,.55.55h1.9a.55.55,0,0,0,.55-.55V16.27a2.45,2.45,0,0,0-2.45-2.44h-2.3V12.17a8.41,8.41,0,0,0,4.2,1.33ZM12,3.05A1.35,1.35,0,1,1,10.65,4.4,1.35,1.35,0,0,1,12,3.05ZM16.75,16.2H12a1.36,1.36,0,0,1-1.35-1.35V9.24a1.4,1.4,0,0,1,1.22-1.42,1.37,1.37,0,0,1,.81.17h.08a1.47,1.47,0,0,1,.39.29l1.23,1.36a6,6,0,0,0,3.72,1.9v.78a7.76,7.76,0,0,1-3.85-1.69.554.554,0,0,0-.9.43v3.28a.55.55,0,0,0,.55.54h2.85a1.36,1.36,0,0,1,1.35,1.35V21h-.8V16.8a.55.55,0,0,0-.55-.6ZM10.1,21a4.2,4.2,0,0,1-1.5-8.13v.88a3.4,3.4,0,1,0,4.55,4.55H14A4.22,4.22,0,0,1,10.1,21ZM12,17.3h.41a.55.55,0,0,0-.14.22,2.3,2.3,0,1,1-2.93-2.94.6.6,0,0,0,.21-.14v.41A2.45,2.45,0,0,0,12,17.3Z" fill="#333"/>
    </svg>
)

export default FixedWheelchairIcon