// @ts-nocheck
import {Input, Typography, Space} from 'antd';

const {Text} = Typography;

type Props = {
  label?: string;
  placeholderValue?: string;
  onChange?: any;
  defaultValue?: string;
  disabled?: boolean;
  style?: any;
};

export function TextInput({label, placeholderValue, onChange, defaultValue, disabled, style}: Props) {
  return (
    <Space direction="vertical" style={{width: '100%'}}>
      {label && <Text style={{fontSize: 14, color: '#666666'}}>{label}</Text>}
      <Input
        style={style}
        onChange={onChange}
        placeholder={placeholderValue}
        defaultValue={defaultValue}
        disabled={disabled}
      />
    </Space>
  );
}
