import {useDistrictContext} from 'src/context/district/district.store';
import {CoiCreateIncidentLayout} from './coiComponents/coiCreateIncidentComponents/layout';
import {
  intialCoiCreateIncidentInformation,
  CoiCreateIncidentInformationReducer,
  CoiCreateIncidentInformation,
  CoiCreateIncidentInformationActions,
  CoiCreateIncidentLocation,
  CoiCreateIncidentLocationActions,
  CoiCreateIncidentLocationReducer,
  initialCoiCreateIncidentLocation,
} from './coiComponents/coiCreateIncidentComponents/reducer';
import React from 'react';
import {useOnDistrictsOrStationsChange} from 'src/hooks/useDistrictChange';
import {SelectStationComponent, TargetScreen} from 'src/utils/selectStation';

export interface CoiCreateIncidentStore {
  coiCreateIncidentInformationState: CoiCreateIncidentInformation;
  coiCreateIncidentInformationDispatch: React.Dispatch<CoiCreateIncidentInformationActions>;
  coiCreateIncidentLocationState: CoiCreateIncidentLocation;
  coiCreateIncidentLocationDispatch: React.Dispatch<CoiCreateIncidentLocationActions>;
}

export default function CoiCreateIncident() {
  //State and context
  const [coiCreateIncidentInformationState, coiCreateIncidentInformationDispatch] = React.useReducer(
    CoiCreateIncidentInformationReducer,
    intialCoiCreateIncidentInformation,
  );

  const [coiCreateIncidentLocationState, coiCreateIncidentLocationDispatch] = React.useReducer(
    CoiCreateIncidentLocationReducer,
    initialCoiCreateIncidentLocation,
  );

  const {selectedStations} = useDistrictContext();

  const [showTrain, setShowTrain] = React.useState(selectedStations.length === 0);

  const handleDistrictChange = () => {
    selectedStations.length === 0 ? setShowTrain(true) : setShowTrain(false);
  };

  useOnDistrictsOrStationsChange(handleDistrictChange);

  if (showTrain) {
    return <SelectStationComponent target={TargetScreen.INCIDENTS} />;
  }

  return (
    <CoiCreateIncidentLayout
      coiCreateIncidentInformationState={coiCreateIncidentInformationState}
      coiCreateIncidentInformationDispatch={coiCreateIncidentInformationDispatch}
      coiCreateIncidentLocationState={coiCreateIncidentLocationState}
      coiCreateIncidentLocationDispatch={coiCreateIncidentLocationDispatch}
    />
  );
}
