import {Box} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from 'react-i18next';

export const NotCompleteRoute = ({destination}: {destination: string | undefined | null}) => {
  const {t} = useTranslation();

  return (
    <Box
      sx={{
        display: 'grid',
        gap: '15px',
        gridTemplateColumns: '60px auto',
        border: 'solid 1px #DFDFDF',
        borderRadius: '8px',
        maxWidth: '470px',
        marginLeft: '25px',
      }}
    >
      <Box
        sx={{
          padding: '10px',
          backgroundColor: '#FAEBEB',
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <CloseIcon sx={{color: 'red'}} />
      </Box>
      <Box>
        <p style={{color: 'red', fontWeight: 'bold', marginBottom: '0px', marginTop: '15px'}}>
          {t('route-not-complete')}
        </p>
        <p>
          {t('cr.add-route-to')}{' '}
          <b>
            {'<<'} {destination} {'>>'}
          </b>
        </p>
      </Box>
    </Box>
  );
};
