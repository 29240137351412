import React from 'react';
import {Box, Button, CircularProgress, Paper, styled} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {AuthContext} from 'src/context/authentication/store.auth';
import {CoiCreateIncidentStore} from '../../coi.createIncident';
import {CoiCreateIncidentInformation} from './coiCreateIncidentInformation';
import {CoiCreateIncidentLocation} from './coiCreateIncidentLocation';
import {useNavigate} from 'react-router-dom';
import {SaveIncidentDto} from 'src/services/models/save-incident-dto';
import {coiVerifyCreation} from '../../coiUtils/coiVerifyCreation';
import {SaveIncidentAddressDto} from 'src/services/models/save-incident-address-dto';
import {LocationTypeDto} from 'src/services/models/location-type-dto';
import useHandleCall from 'src/hooks/useHandleCall';
import {createIncident} from '../../data';
import {EnvironmentContext} from 'src/context/environment/environment.store';

import {v4 as uuidv4} from 'uuid';
import {SaveIncidentTrainDto} from 'src/services/models/save-incident-train-dto';
import {SaveIncidentPtcarDto} from 'src/services/models/save-incident-ptcar-dto';
import {CoiCreateIncidentInformationActionTypes, CoiCreateIncidentLocationActionTypes} from './reducer';
import {useCoiCreateIncidentContext} from 'src/context/coi/coi.createIncident.store';
import {SettingsContext} from 'src/context/settings/settings.store';
import {StationPresenceDto} from 'src/services/models/station-presence-dto';

const LayoutContainer = styled(Box)(({theme}) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 200px',
  height: 'calc(100vh - 64px)',
  padding: '24px',
  backgroundColor: '#EFF4F9',
  maxHeight: 'calc(100vh - 64px)',
  overflow: 'auto',
  [theme.breakpoints.down(1600)]: {
    gridTemplateColumns: 'none',
    gridTemplateRows: '1fr 50px'
  }
}));

const ButtonsContainer = styled(Box)(({theme}) => ({
  display: 'grid',
  justifyItems: 'end',
  alignItems: 'end',
  gridTemplateColumns: '1fr 1fr',
  [theme.breakpoints.down(1600)]: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '20px',
    marginTop: '20px'
  }
}));

interface CoiCreateIncidentLayoutProps extends CoiCreateIncidentStore {}

export const CoiCreateIncidentLayout = (props: CoiCreateIncidentLayoutProps) => {
  const {t} = useTranslation();
  const {variables} = React.useContext(EnvironmentContext);
  const [isCreating, setIsCreating] = React.useState(false);
  const [isAllowedToSubmit, setIsAllowedToSubmit] = React.useState(false);
  const navigate = useNavigate();
  const {executeCall, loadingCall, errorCall} = useHandleCall();

  const {prevRoute} = React.useContext(SettingsContext);

  const COIStore = useCoiCreateIncidentContext();

  React.useEffect(() => {
    setIsAllowedToSubmit(
      coiVerifyCreation(props.coiCreateIncidentLocationState, props.coiCreateIncidentInformationState, COIStore),
    );
  }, [props.coiCreateIncidentLocationState, props.coiCreateIncidentInformationState]);

  const handleCreation = async () => {
    if (!isAllowedToSubmit) {
      return;
    }

    setIsCreating(true);

    const district = localStorage.getItem('districtId');

    let locations = [];

    if (props.coiCreateIncidentLocationState.address) {
      locations.push({
        mainLocation: true,
        extraInfo: props.coiCreateIncidentLocationState.comment,
        type: LocationTypeDto.Address,
        id: uuidv4(),
        address: props.coiCreateIncidentLocationState.address.address,
        assignments:
          props.coiCreateIncidentLocationState.address.assignments &&
          (props.coiCreateIncidentLocationState.address.assignments as StationPresenceDto[]).map(el => el.employeeId),
        employeeId: props.coiCreateIncidentLocationState.address.employeeId,
      } as SaveIncidentAddressDto);
    } else {
      const trainLocations = props.coiCreateIncidentLocationState.trains.map(el => {
        return {
          assignments: el.assistants?.map(el => el.employeeId),
          departureDate: el.departureDate,
          employeeId: el.assignedCoi?.employeeId,
          id: uuidv4(),
          mainLocation: el.mainLocation,
          natureCode: null,
          trainNumber: el.trainNumber,
          type: LocationTypeDto.Train,
        } as SaveIncidentTrainDto;
      });

      let ptCar: undefined | SaveIncidentPtcarDto = undefined;

      if (props.coiCreateIncidentLocationState.ptcar) {
        ptCar = {
          assignments: props.coiCreateIncidentLocationState.ptcar?.assistants?.map(el => el.employeeId as string),
          employeeId: props.coiCreateIncidentLocationState.ptcar?.assignedCoi?.employeeId,
          uicCode: props.coiCreateIncidentLocationState.ptcar?.id,
          type: LocationTypeDto.Ptcar,
          mainLocation: props.coiCreateIncidentLocationState.ptcar?.mainLocation,
          id: uuidv4(),
        };
      }

      locations = [...trainLocations];

      if (ptCar) {
        locations.push(ptCar);
      }
    }

    const incident: SaveIncidentDto = {
      catalogId: props.coiCreateIncidentInformationState.catalogId,
      extraInfo: props.coiCreateIncidentInformationState.extraInfo,
      subCatalogId: props.coiCreateIncidentInformationState.subCatalogId,
      type: props.coiCreateIncidentInformationState.type,
      district: district,
      locations: locations,
    };

    await executeCall(createIncident(variables.BASE_ENDPOINT, incident));
    if (!errorCall) {
      props.coiCreateIncidentInformationDispatch({type: CoiCreateIncidentInformationActionTypes.RESET});
      props.coiCreateIncidentLocationDispatch({type: CoiCreateIncidentLocationActionTypes.RESET});
      navigate('/coi/overview');
    }
  };

  if (isCreating) {
    return (
      <div style={{display: 'grid', placeItems: 'center', height: '100vh'}}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <LayoutContainer>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '0.6fr 1fr',
          gridTemplateRows: '40px 1fr',
          gap: '24px',
        }}
      >
        <div style={{gridRow: '1/2', gridColumn: '1/3'}}>
          <p style={{margin: '0', fontWeight: 'bold', fontSize: '28px', marginBottom: '12px'}}>
            {t('coi.create-incident')}
          </p>
        </div>
        <Paper elevation={2} style={{gridRow: '2/3', gridColumn: '1/2'}}>
          <CoiCreateIncidentInformation {...props} />
        </Paper>
        <Paper elevation={2} style={{gridRow: '2/3', gridColumn: '2/3'}}>
          <CoiCreateIncidentLocation {...props} />
        </Paper>
      </div>
      <ButtonsContainer>
        <Button
          disabled={!isAllowedToSubmit}
          onClick={() => {
            setIsCreating(true);
            handleCreation();
          }}
          variant="contained"
        >
          {t('coi.create')}
        </Button>
        <Button
          onClick={() => {
            props.coiCreateIncidentInformationDispatch({type: CoiCreateIncidentInformationActionTypes.RESET});
            props.coiCreateIncidentLocationDispatch({type: CoiCreateIncidentLocationActionTypes.RESET});
            navigate(-1);
          }}
          variant="outlined"
        >
          {t('coi.cancel')}
        </Button>
      </ButtonsContainer>
    </LayoutContainer>
  );
};
