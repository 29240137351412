import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Typography
} from "@mui/material";

import { ModernModal } from "src/components/ModernModal/ModernModal";
import { EvacuationDto } from "src/services/models/evacuation-dto";
import { EvacuationVehicleTypeDto } from "src/services/models/evacuation-vehicle-type-dto";
import { CoiEvacuationFormTaxi } from './coiEvacuationFormTaxi';
import { CoiEvacuationFormTrain } from './coiEvacuationFormTrain';
import { CoiEvacuationFormFoot } from './coiEvacuationFormFoot';
import { CoiEvacuationFormBus } from './coiEvacuationFormBus';
import { CoiEvacuationFormEmergencyVehicle } from './coiEvacuationFormEmergencyVehicle';

interface CoiEvacuationMethodsDialogProps {
  isOpen: boolean;
  evacuation: EvacuationDto | undefined;
  handleClose: () => void;
  handleSubmit: (values: EvacuationDto) => void;
}

export const CoiEvacuationMethodsDialog: FC<CoiEvacuationMethodsDialogProps> = ({ isOpen, evacuation, handleSubmit, handleClose }) => {
  const { t } = useTranslation();
  const [form, setForm] = useState({...evacuation});
  const [errors, setErrors] = useState<string[]>([]);

  const handleCloseModal = () => {
    setErrors([]);
    setForm({});
    handleClose();
  }

  const handleChangeInput = (name: string, value: string | number) => {
    setForm({...form, [name]: value});
  }

  const handleSubmitForm = () => {
    handleSubmit({ id: evacuation?.id, ...form });
  }

  return (
    <ModernModal open={isOpen} onClose={handleCloseModal} modalTitle={t("coi.incident-evacuation-methods")}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, paddingTop: '20px', minWidth: '550px' }}>
        {evacuation?.type === EvacuationVehicleTypeDto.Train ? (
          <CoiEvacuationFormTrain
            values={form}
            handleChangeInput={handleChangeInput}
            defaultValues={evacuation}
            errors={errors}
            setErrors={setErrors}
          />
        ) : null}
        {evacuation?.type === EvacuationVehicleTypeDto.Taxi ? (
          <CoiEvacuationFormTaxi
            values={form}
            handleChangeInput={handleChangeInput}
            defaultValues={evacuation}
            errors={errors}
            setErrors={setErrors}
          />
        ) : null}
        {evacuation?.type === EvacuationVehicleTypeDto.Foot ? (
          <CoiEvacuationFormFoot
            values={form}
            handleChangeInput={handleChangeInput}
            defaultValues={evacuation}
            errors={errors}
            setErrors={setErrors}
          />
        ) : null}
        {evacuation?.type === EvacuationVehicleTypeDto.Bus ? (
          <CoiEvacuationFormBus
            values={form}
            handleChangeInput={handleChangeInput}
            defaultValues={evacuation}
            errors={errors}
            setErrors={setErrors}
          />
        ) : null}
        {evacuation?.type === EvacuationVehicleTypeDto.EmergencyVehicle ? (
          <CoiEvacuationFormEmergencyVehicle
            values={form}
            handleChangeInput={handleChangeInput}
            defaultValues={evacuation}
            errors={errors}
            setErrors={setErrors}
          />
        ) : null}
        <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end", marginTop: '10px' }}>
          <Button sx={{ height: '50px', padding: '0 20px' }} onClick={handleCloseModal}>{t("station-section.btn-cancel")}</Button>
          <Button sx={{ height: '50px', padding: '0 20px' }} variant="contained" disabled={errors.length > 0} onClick={handleSubmitForm}>{t("filter-section.filter-update")}</Button>
        </Box>
      </Box>
    </ModernModal>
  )
}
