import React, {useRef, useEffect} from 'react';
import {Box, List} from '@mui/material';
import {ChatMessageDtoPaginatedListResponseDto} from 'src/services/models/chat-message-dto-paginated-list-response-dto';
import {ChatMessage} from './coiChatMessage';
import {IncidentDetailsDto} from 'src/services/models/incident-details-dto';
import {ChatFooter} from './chatFooter';
import {useTranslation} from 'react-i18next';

type IChatMessageList = {
  chats: ChatMessageDtoPaginatedListResponseDto | null;
  appendMoreResults: () => Promise<void>;
  incident: IncidentDetailsDto;
  handleNewSearch: () => Promise<void>;
  handleSelectLocation: (locationId: string) => void;
};

export const ChatMessagesList: React.FC<IChatMessageList> = ({chats, appendMoreResults, incident, handleNewSearch, handleSelectLocation}) => {
  const listRef = useRef<HTMLUListElement>(null);
  const {t} = useTranslation();

  // Handle loading more messages when scrolling to the top
  const handleScroll = async () => {
    const list = listRef.current;
    if (list && chats?.moreResultsToken) {
      // Only attempt to load more if moreResultsToken exists
      if (Math.floor(list.scrollTop + list.scrollHeight) === list.clientHeight) {
        await appendMoreResults();
      }
    }
  };

  // Scroll to the bottom when the component mounts or new messages arrive
  useEffect(() => {
    const list = listRef.current;
    if (list) {
      // Scroll to the bottom
      // list.scrollTop = list.scrollHeight;
    }
  }, [chats?.items]); // Watch for changes in items to scroll down

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: '1fr 180px',
        height: '100%',
        maxHeight: '70vh',
        border: 'solid 1px #a7cae4',
        borderRadius: '5px',
      }}
    >
      {/* Scrollable List with reversed order */}
      <List
        ref={listRef}
        sx={{display: 'flex', flexDirection: 'column-reverse', overflowY: 'auto', minHeight: '500px'}}
        onScroll={handleScroll}
      >
        {chats?.items?.map((el, idx) => <ChatMessage key={idx} chat={el} containerRef={listRef} />) || (
          <p>{t('no-messages')}</p>
        )}{' '}
        {/* Handle empty/null items */}
      </List>

      {/* Fixed Footer */}
      <Box
        sx={{
          height: '180px',
          backgroundColor: '#eff4f9',
          borderTop: 'solid 1px #a7cae4',
        }}
      >
        <ChatFooter
          incident={incident}
          handleNewSearch={handleNewSearch}
          handleSelectLocation={handleSelectLocation}
        />
      </Box>
    </Box>
  );
};
