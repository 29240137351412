export const initialFormControlState = {
    viewAllStations: true,
    viewOnlyActive: true,
    hideTimeRegistrations: true,
    withHistory: false,
    stationFilter: '',
    date: new Date().toISOString().substring(0,10),
    loadingData: false,
    isFirstSearch: true,
}

export enum FormControlActionsType {
    VIEW_ALL = 'VIEW_ALL',
    VIEW_ONLY_ACTIVE = 'VIEW_ONLY_ACTIVE',
    HIDE_TIME_REG = 'HIDE_TIME_REG',
    WITH_HISTORY = 'WITH_HISTORY',
    STATION_FILTER = 'STATION_FILTER',
    DATE = 'DATA',
    LOADING = 'LOADING_DATA',
    FIRST_SEARCH = 'FIRST_SEARCH'
}

export type IFormControlActions =  
    {type: FormControlActionsType.VIEW_ALL, payload: boolean} |
    {type: FormControlActionsType.VIEW_ONLY_ACTIVE, payload: boolean} |
    {type: FormControlActionsType.HIDE_TIME_REG, payload: boolean} |
    {type: FormControlActionsType.WITH_HISTORY, payload: boolean} |
    {type: FormControlActionsType.STATION_FILTER, payload: string} |
    {type: FormControlActionsType.DATE, payload: string} |
    {type: FormControlActionsType.LOADING, payload: boolean} |
    {type: FormControlActionsType.FIRST_SEARCH, payload: boolean}
    


export const formControlReducer = (state: typeof initialFormControlState, action: IFormControlActions) => {
    switch (action.type){
        case FormControlActionsType.VIEW_ALL:
            return {...state, viewAllStations: action.payload};
        case FormControlActionsType.VIEW_ONLY_ACTIVE:
            return {...state, viewOnlyActive: action.payload};
        case FormControlActionsType.HIDE_TIME_REG: 
            return {...state, hideTimeRegistrations: action.payload};
        case FormControlActionsType.WITH_HISTORY: 
            return {...state, withHistory: action.payload};
        case FormControlActionsType.STATION_FILTER:
            return {...state, stationFilter: action.payload};
        case FormControlActionsType.DATE:
            return {...state, date: action.payload};
        case FormControlActionsType.LOADING:
            return {...state, loadingData: action.payload}
        case FormControlActionsType.FIRST_SEARCH:
            return {...state, isFirstSearch: action.payload}
        default:
            return state;
    }
}