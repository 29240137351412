import moment from 'moment';
import {DisabledOriginalJourneyDto} from 'src/services/models/disabled-original-journey-dto';
import {JourneyDto} from 'src/services/models/journey-dto';

export const mapDisabledJourneysToTravels = (data: DisabledOriginalJourneyDto[]): JourneyDto[] => {
  const result: JourneyDto[] = data.map(el => ({
    arrivalName: el.arrivalName,
    arrivalTime: el.arrivalTime,
    arrivalTrackName: '',
    arrivalUicCode: el.arrivalUicCode,
    departureName: el.departureName,
    departureTime: el.departureTime,
    departureTrackName: '',
    departureUicCode: el.departureUicCode,
    direction: '',
    duration: moment(el.departureTime).diff(moment(el.arrivalTime)).toString(),
    intermediateStations: el.intermediateStations,
    transportId: el.transportId?.toString(),
    transportName: el.transportId?.toString(),
  }));

  return result;
};
