import {Box} from '@mui/material';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {MissionStatusDto} from 'src/services/models/mission-status-dto';

enum Colors {
  New = '#25336C',
  Accepted = '#C27710',
  Canceled = '#BB0000',
  Started = '#2B7C2B',
  Deleted = '#BB0001',
}

export const Circular: React.FC<{color: string}> = ({color}) => {
  return (
    <span
      style={{
        display: 'inline-block',
        width: '12px',
        height: '12px',
        backgroundColor: color,
        border: 'solid 3px white',
        borderRadius: '15px',
        boxShadow: 'rgb(144 143 143) 0px 1px 3px 0px',
      }}
    />
  );
};

export const StatusItem: React.FC<{status: MissionStatusDto}> = ({status}) => {
  const {t} = useTranslation();

  const getColor = (status: MissionStatusDto): string => {
    switch (status) {
      case MissionStatusDto.New:
      case MissionStatusDto.Completed:
        return Colors.New;
      case MissionStatusDto.Assigned:
        return Colors.Accepted;
      case MissionStatusDto.Started:
        return Colors.Started;
      case MissionStatusDto.Stopped:
        return Colors.Canceled;
      case MissionStatusDto.Deleted:
        return Colors.Deleted;
      default:
        return '';
    }
  };

  return (
    <Box>
      <Circular color={getColor(status)} />
      <span
        style={{
          color: getColor(status),
          fontSize: '12px',
          fontWeight: 'bold',
          marginLeft: '5px',
        }}
      >
        {t(`mission_status.${status}`)}
      </span>
    </Box>
  );
};
