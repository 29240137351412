// @ts-nocheck
import React, {useEffect, useState} from 'react';
import i18next from 'i18next';
import {useTranslation} from 'react-i18next';
import {CacheContext} from 'src/context/cache/cache.store';
import StationList from './StationList';
import './StationModal.scss';
import StationSearch from './StationSearch';
import {DateDirection, MissionContext} from 'src/scenes/coordinator/MissionOverview/missions.store';

let selectIds = [];
let choosenIds = [];
let initialStationData = [];

if (localStorage.getItem('selectedStations') !== null && localStorage.getItem('selectedStations') !== '') {
  const savedStations = localStorage.getItem('selectedStations').split(',');
  for (const station of savedStations) {
    if (selectIds.indexOf(parseInt(station)) === -1) {
      selectIds.push(station);
    }
  }
}

const StationModal = ({clickedChoose, handleClose, show, isCoordinator}) => {
  const {handleDateChange} = React.useContext(MissionContext);
  const {stations, districts} = React.useContext(CacheContext);

  const [selectedDistrictId, setSelectedDistrictId] = React.useState(localStorage.getItem('districtId'));

  const showHideClassName = show ? 'modal d-block' : 'modal d-none';
  const [stationMockList, setStationMockList] = useState(initialStationData);
  const [isloading, setIsLoading] = useState(false);
  if (
    i18next.language === 'en-US' ||
    i18next.language === undefined ||
    localStorage.getItem('language') === undefined
  ) {
    i18next.language = 'En';
  }

  const [valueInputSearch, setValueInputSearch] = useState('');

  const {t} = useTranslation();

  useEffect(() => {
    setIsLoading(true);
    mergeData();
  }, []);

  const mergeData = () => {
    if (stations) {
      const merged = stations.map(el => {
        const districtId = el.districtId;
        const matchingDistrict = districts.filter(match => match.id === districtId)[0];
        const districtNameDe = matchingDistrict.name[0].text;
        const districtNameNl = matchingDistrict.name[0].text;
        const districtNameFr = matchingDistrict.name[1].text;
        const districtNameEn = matchingDistrict.name[2].text;
        const nameDe = el.name[0].text;
        const nameNl = el.name[0].text;
        const nameFr = el.name[1].text;
        const nameEn = el.name[2].text;
        const ptCarCode = el.id;
        return {
          districtId,
          districtNameDe,
          districtNameEn,
          districtNameFr,
          districtNameNl,
          nameDe,
          nameNl,
          nameFr,
          nameEn,
          ptCarCode,
          uicCode: ptCarCode,
        };
      });
      fetchStations(merged);
    }
  };
  const fetchStations = merged => {
    const stationData = merged.map(list => ({...list, active: false}));
    initialStationData = [...stationData];
    setIsLoading(false);
    setStationMockList(stationData);
    if (localStorage.getItem('selectedStations') !== null) {
      setStationFilterData(localStorage.getItem('selectedStations'));
    }
  };

  const cleartextInputHandler = () => {
    if (selectIds.length > 0) {
      const selectedStation = initialStationData.map(item => {
        if (selectIds.includes(item.uicCode)) {
          return {
            ...item,
            active: true,
          };
        }
        return {
          ...item,
        };
      });
      setStationMockList(selectedStation);
    } else {
      setStationMockList(initialStationData);
    }

    setValueInputSearch('');
  };

  const staionSelectedHandler = list => {
    selectIds = [...list];
  };

  const resetHandler = () => {
    selectIds = [];
    choosenIds = [];
    const resetStationData = stationMockList.map(list => ({...list, active: false}));
    clickedChoose(selectIds, 'No Station Selected');
    setStationMockList(resetStationData);
    cleartextInputHandler();
    handleDateChange(DateDirection.RESET, 0);
    handleClose();
    setSelectedDistrictId('');
  };

  const handleCancel = () => {
    setValueInputSearch('');
    if (selectIds !== choosenIds) {
      selectIds = [...choosenIds];
      if (selectIds.length > 0) {
        const selectedStation = initialStationData.map(item => {
          if (selectIds.includes(item.uicCode)) {
            return {
              ...item,
              active: true,
            };
          }
          return {
            ...item,
          };
        });
        setStationMockList(selectedStation);
      }
    }
    cleartextInputHandler();
    handleClose();
  };

  const setStationFilterData = filterStations => {
    if (filterStations.length > 0) {
      const selectedStation = initialStationData.map(item => {
        if (filterStations.includes(item.uicCode)) {
          return {
            ...item,
            active: true,
          };
        }
        return {
          ...item,
        };
      });
      setStationMockList(selectedStation);
    } else {
      setStationMockList(initialStationData);
    }
  };

  const loadingMessage = () => i18next.t('station.station-list');

  const handleChooseStation = selectIds => {
    choosenIds = [...selectIds];
    if (selectIds.length === 0) {
      clickedChoose(selectIds, 'No Station Selected');
    } else if (selectIds.length > 0 && selectIds.length < 2) {
      const topStCode = selectIds[0];
      const stationRow = initialStationData.filter(key => key.uicCode === topStCode);
      clickedChoose(
        selectIds,
        i18next.language === 'En'
          ? stationRow[0].nameEn
          : i18next.language === 'Fr'
          ? stationRow[0].nameFr
          : stationRow[0].nameNl,
      );
    } else if (selectIds.length > 1) {
      let multipleDist = false;
      const topStCode = selectIds[0];
      const stationRow = initialStationData.filter(key => key.uicCode === topStCode);
      const topDistName =
        i18next.language === 'En'
          ? stationRow[0].districtNameEn
          : i18next.language === 'Fr'
          ? stationRow[0].districtNameFr
          : stationRow[0].districtNameNl;
      selectIds.map(code => {
        const rowStation = initialStationData.filter(key => key.uicCode === code);
        const checkdistLan =
          i18next.language === 'En'
            ? rowStation[0].districtNameEn
            : i18next.language === 'Fr'
            ? rowStation[0].districtNameFr
            : stationRow[0].districtNameNl;
        if (checkdistLan !== undefined && checkdistLan !== topDistName) {
          multipleDist = true;
          return true;
        }
        return false;
      });
      if (multipleDist) clickedChoose(selectIds, 'Multiple districts');
      else clickedChoose(selectIds, topDistName);
    }
  };

  return (
    <div className={showHideClassName}>
      <div className={`${isCoordinator ? 'modal-container-coordinator' : 'modal-container'} modal-header-container`}>
        <div className="modal-header modal-station-header">
          <span className="region-main-header">{t('station-section.modal-header-title')}</span>
          <button type="button" className="btn btn-common btn-without-bg" onClick={resetHandler}>
            {t('station-section.btn-reset')}
          </button>
        </div>
        <div className="modal-body modal-station-body">
          <StationSearch
            data={stationMockList}
            setData={setStationMockList}
            masterData={initialStationData}
            cleredInput={cleartextInputHandler}
            inputValue={valueInputSearch}
            setValueInputSearch={setValueInputSearch}
          />
          {isloading ? (
            loadingMessage()
          ) : (
            <StationList
              stationRowClicked={statIds => staionSelectedHandler(statIds)}
              data={stationMockList}
              setData={setStationMockList}
              selectIds={selectIds}
              selectedDistrictId={selectedDistrictId}
              setSelectedDistrictId={setSelectedDistrictId}
            />
          )}
        </div>
        <div className="modal-footer modal-station-footer">
          <button
            type="button"
            className="btn btn-primary btn-choose btn-common"
            onClick={() => handleChooseStation(selectIds)}
          >
            {t('station-section.btn-choose')}
          </button>
          <button type="button" className="btn btn-common btn-without-bg" onClick={handleCancel}>
            {t('station-section.btn-cancel')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default StationModal;
