// @ts-nocheck
import * as React from 'react';

import Box from '@mui/material/Box';
import SelectComponent from 'src/scenes/coordinator/SelectComponent';
import {useTranslation} from 'react-i18next';
import {StationDto} from 'src/services/models';
import {CacheContext} from 'src/context/cache/cache.store';
import {getStationFromName} from 'src/utils/getStationFromName';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';

export const ChangeStationModelView = ({selectedStation, stationList, handleSelectStationDataChange}) => {
  const {stations} = React.useContext(CacheContext);

  const {t, i18n} = useTranslation();

  return (
    <Box>
      <SelectComponent
        placeholder={t('print-stickers.select-station')}
        size="default"
        allowClear
        isSearch={true}
        searchOnLabel={true}
        options={stationList.map((el: StationDto) => ({text: handleTranslationObject(i18n, el.name), value: el.id}))}
        isAvoid
        defaultValue={getStationFromName(i18n, selectedStation, stations as StationDto[])}
        isItalic
        onSelectedChange={id => handleSelectStationDataChange(id)}
      />
      <Box sx={{borderTop: '1px solid #d7d7d7'}} />
    </Box>
  );
};
