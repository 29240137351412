export const ButtonLabel: React.FC<{text: string; disabled?: boolean}> = ({text, disabled = false}) => {
  return (
    <p
      data-testid="button-label"
      style={{fontSize: '14px', textAlign: 'center', color: disabled ? '#a8a8a8' : 'black'}}
    >
      {text}
    </p>
  );
};
