export const colors = {
  grey_3: '#333333',
  grey_7: '#777777',
  black_opaque: '#00000029',
  nbms_dark_blue: '#25336C',
  mission_overview: {
    highlight_group: '#25336C',
    succesfull: '#2B7C2B',
    error: '#BB0000',
  },
};
