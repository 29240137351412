import React, {useMemo} from 'react';
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {CacheContext} from 'src/context/cache/cache.store';
import {StationDto, LocalizedTextDto, CreateReservationDto} from 'src/services/models';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';
import {getDrivingDuration} from 'src/scenes/change-route/data';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import moment, {min} from 'moment';
import {GeoDurationResponseDto} from 'src/services/models/geo-duration-response-dto';

type DepartureArrivalProps = {
  reservation: CreateReservationDto;
  setReservation: React.Dispatch<React.SetStateAction<CreateReservationDto>>;
  index: number;
  setIsValid?: React.Dispatch<boolean>;
};

export const DepartureArrivalStationForm = ({
  index,
  reservation,
  setReservation,
  setIsValid,
}: DepartureArrivalProps) => {
  const {t, i18n} = useTranslation();
  const {stations} = React.useContext(CacheContext);
  const {variables} = React.useContext(EnvironmentContext);

  const [travelTime, setTravelTime] = React.useState<GeoDurationResponseDto | undefined>();

  const [departureDateError, setDepartureDateError] = React.useState(false);
  const [departureTimeError, setDepartureTimeError] = React.useState(false);

  const [departureDate, setDepartureDate] = React.useState(
    moment(reservation.travel.journeys[index].departureTime).local().format('YYYY-MM-DD'),
  );
  const [departureTime, setDepartureTime] = React.useState(
    moment(reservation.travel.journeys[index].departureTime).local().format('HH:mm'),
  );
  const [arrivalDate, setArrivalDate] = React.useState(
    moment(reservation.travel.journeys[index].arrivalTime).local().format('YYYY-MM-DD'),
  );
  const [arrivalTime, setArrivalTime] = React.useState(
    moment(reservation.travel.journeys[index].arrivalTime).local().format('HH:mm'),
  );

  const getStationItems = useMemo(() => {
    return (stations as StationDto[])
      .filter(el => el.id !== undefined)
      .sort((a, b) =>
        handleTranslationObject(i18n, a.name as LocalizedTextDto[]).localeCompare(
          handleTranslationObject(i18n, b.name as LocalizedTextDto[]),
        ),
      )
      .map(s => (
        <MenuItem value={s.id || ''} key={s.id}>
          {handleTranslationObject(i18n, s.name as LocalizedTextDto[])}
        </MenuItem>
      ));
  }, [stations]);

  const ensureArrivalAfterDeparture = (isFromDepartureChange: boolean) => {
    const departureMoment = moment(`${departureDate} ${departureTime}`, 'YYYY-MM-DD HH:mm').local();
    const arrivalMoment = moment(`${arrivalDate} ${arrivalTime}`, 'YYYY-MM-DD HH:mm').local();

    if (isFromDepartureChange) {
      const newArrivalMoment = addSecondsToDate(departureMoment, travelTime?.timeInSeconds || 0);
      setArrivalDate(newArrivalMoment.format('YYYY-MM-DD'));
      setArrivalTime(newArrivalMoment.format('HH:mm'));
      //@ts-ignore
      reservation.travel.journeys[index].arrivalTime = newArrivalMoment.format();
    } else if (arrivalMoment.isBefore(departureMoment) || arrivalMoment.isSame(departureMoment)) {
      const newArrivalMoment = addSecondsToDate(departureMoment, 60);
      setArrivalDate(newArrivalMoment.format('YYYY-MM-DD'));
      setArrivalTime(newArrivalMoment.format('HH:mm'));
      //@ts-ignore
      reservation.travel.journeys[index].arrivalTime = newArrivalMoment.format();
    } else {
      //@ts-ignore
      reservation.travel.journeys[index].arrivalTime = arrivalMoment.format();
    }
  };

  const handleDateChange = (date: string) => {
    setDepartureDate(date);
    const updatedDate = moment(date, 'YYYY-MM-DD', true).local();

    if (updatedDate.isValid()) {
      setDepartureDateError(false); //@ts-ignore
      reservation.travel.journeys[index].departureTime = updatedDate
        .set({
          hour: moment(departureTime, 'HH:mm').hour(),
          minute: moment(departureTime, 'HH:mm').minute(),
        })
        .format();
      ensureArrivalAfterDeparture(true);
      setReservation({...reservation});
    } else {
      setDepartureDateError(true);
    }
  };

  const handleTimeChange = (time: string) => {
    if (index > 0) {
      const departureMoment = moment(`${departureDate} ${time}`, 'YYYY-MM-DD HH:mm').local();
      const prevArrival = moment(reservation.travel.journeys[index - 1].arrivalTime).isBefore(moment(departureMoment));
      if (!prevArrival) {
        return;
      }
    }
    setDepartureTime(time);
    const updatedTime = moment(time, 'HH:mm', true).local();

    if (updatedTime.isValid()) {
      setDepartureTimeError(false);
      //@ts-ignore
      reservation.travel.journeys[index].departureTime = moment(departureDate)
        .set({
          hour: updatedTime.hour(),
          minute: updatedTime.minute(),
        })
        .format();
      ensureArrivalAfterDeparture(true);
      setReservation({...reservation});
    } else {
      setDepartureTimeError(true);
    }
  };

  const changeDeparture = async (station: StationDto | undefined) => {
    const prevState = reservation;
    prevState.travel.journeys[index].departureUicCode = station?.id;

    const duration = await getDrivingDuration(variables.BASE_ENDPOINT, {
      fromStationUicCode: reservation.travel.journeys[index].departureUicCode,
      toStationUicCode: reservation.travel.journeys[index].arrivalUicCode,
    });
    setTravelTime(duration);

    const arrival = addSecondsToDate(
      moment(reservation.travel.journeys[index].departureTime),
      duration.timeInSeconds || 0,
    );
    //@ts-ignore
    prevState.travel.journeys[index].arrivalTime = moment(arrival).local();

    setReservation(_prev => {
      return {...prevState};
    });
  };

  const changeArrival = async (station: StationDto | undefined) => {
    const prevState = reservation;
    prevState.travel.journeys[index].arrivalUicCode = station?.id;

    const duration = await getDrivingDuration(variables.BASE_ENDPOINT, {
      fromStationUicCode: reservation.travel.journeys[index].departureUicCode,
      toStationUicCode: reservation.travel.journeys[index].arrivalUicCode,
    });
    setTravelTime(duration);

    const arrival = addSecondsToDate(
      moment(reservation.travel.journeys[index].departureTime),
      duration.timeInSeconds || 0,
    );
    //@ts-ignore
    prevState.travel.journeys[index].arrivalTime = moment(arrival).local();

    setReservation(_prev => {
      return {...prevState};
    });
  };

  const addSecondsToDate = (date: moment.Moment, seconds: number): moment.Moment => {
    return date.clone().add(seconds, 'seconds');
  };

  const handleArrivalDateChange = async (date: string) => {
    setArrivalDate(date);
    ensureArrivalAfterDeparture(false);
  };
  const handleArrivalTimeChange = async (time: string) => {
    setArrivalTime(time);
    ensureArrivalAfterDeparture(false);
  };

  const handlePickupPoint = (e: string) => {
    const prevState = reservation;
    prevState.travel.journeys[index].departureInfo = e;

    setReservation(_prev => {
      return {...prevState};
    });
  };

  const handleDropoffPoint = (e: string) => {
    const prevState = reservation;
    prevState.travel.journeys[index].arrivalInfo = e;

    setReservation(_prev => {
      return {...prevState};
    });
  };

  const handleDepartureAssistanceChange = () => {
    const prevState = reservation;
    prevState.travel.journeys[index].withDepartureAssistance =
      !prevState.travel.journeys[index].withDepartureAssistance;

    setReservation(_prev => {
      return {...prevState};
    });
  };

  const handleArrivalAssitanceChange = () => {
    const prevState = reservation;
    prevState.travel.journeys[index].withArrivalAssistance = !prevState.travel.journeys[index].withArrivalAssistance;

    setReservation(_prev => {
      return {...prevState};
    });
  };

  const handleFilterStations = (value: string) => {
    const filteredOptions = stations?.find(
      station =>
        station?.name &&
        (station?.name?.[0]?.text?.toLowerCase().includes(value.toLowerCase()) ||
          station?.name?.[1]?.text?.toLowerCase().includes(value.toLowerCase()) ||
          station?.name?.[2]?.text?.toLowerCase().includes(value.toLowerCase())),
    );
    return filteredOptions;
  };

  React.useEffect(() => {
    if (index > 0 && reservation.travel.journeys[index - 1].arrivalUicCode) {
      const prevTrain = reservation.travel.journeys[index - 1].arrivalUicCode;
      const t = (stations as StationDto[]).find(el => el.id === prevTrain);
      changeDeparture(t);
    }
  }, [index]);

  React.useEffect(() => {}, [reservation]);

  React.useEffect(() => {
    ensureArrivalAfterDeparture(true);
  }, [travelTime, departureDate, departureTime]);

  React.useEffect(() => {
    ensureArrivalAfterDeparture(false);
  }, [arrivalDate, arrivalTime]);

  return (
    <Box sx={{display: 'grid', gridTemplateRows: '1fr 1fr', gap: '15px'}}>
      <FormControl>
        <InputLabel id="dep-station">{t('create-mission.station')}</InputLabel>
        <Select
          label={'Departure Station'}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 320, // Set maximum height for the menu
              },
            },
          }}
          error={reservation.travel.journeys[index].departureUicCode === undefined}
          required
          labelId="dep-station"
          disabled={index !== 0 || index < reservation.travel.journeys.length - 1}
          value={
            index !== 0
              ? reservation.travel.journeys[index - 1].arrivalUicCode
              : reservation.travel.journeys[index].departureUicCode
          }
          onChange={e => {
            changeDeparture((stations as StationDto[]).find(s => s.id === e.target.value));
          }}
        >
          {getStationItems}
        </Select>
      </FormControl>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
        }}
      >
        <Checkbox
          checked={reservation.travel.journeys[index].withDepartureAssistance}
          onClick={() => handleDepartureAssistanceChange()}
          disabled={index === 0 || index < reservation.travel.journeys.length - 1}
        />
        <p style={{lineHeight: '50px', margin: '0'}}>{t('create-grobo-mission.assistance')}</p>
      </Box>
      <FormControl>
        <InputLabel id="dep-pickup">{t('dep-pickup-point')}</InputLabel>
        <OutlinedInput
          disabled={index < reservation.travel.journeys.length - 1}
          error={
            reservation.travel.journeys[index].departureInfo === undefined ||
            reservation.travel.journeys[index].departureInfo?.length === 0
          }
          required
          type="text"
          label={'Departure pickup point'}
          onChange={e => handlePickupPoint(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <InputLabel id="arr-station">{t('create-mission.arrival')}</InputLabel>
        <Select
          disabled={index < reservation.travel.journeys.length - 1}
          label={'Arrival Station'}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 320, // Set maximum height for the menu
              },
            },
          }}
          error={reservation.travel.journeys[index].arrivalUicCode === undefined}
          required
          labelId="arr-station" // Make sure this matches the InputLabel's id
          value={reservation.travel.journeys[index].arrivalUicCode}
          onChange={e => {
            changeArrival((stations as StationDto[]).find(s => s.id === e.target.value));
          }}
        >
          {getStationItems}
        </Select>
      </FormControl>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
        }}
      >
        <Checkbox
          disabled={index < reservation.travel.journeys.length - 1}
          checked={reservation.travel.journeys[index].withArrivalAssistance}
          onClick={() => handleArrivalAssitanceChange()}
        />
        <p style={{lineHeight: '50px', margin: '0'}}>{t('create-grobo-mission.assistance')}</p>
      </Box>
      <FormControl>
        <InputLabel id="dep-pickup">{t('arr-dropoff-point')}</InputLabel>
        <OutlinedInput
          disabled={index < reservation.travel.journeys.length - 1}
          error={
            reservation.travel.journeys[index].arrivalInfo === undefined ||
            reservation.travel.journeys[index].arrivalInfo?.length === 0
          }
          type="text"
          label={t('arr-dropoff-point')}
          onChange={e => handleDropoffPoint(e.target.value)}
        />
      </FormControl>
      <TextField
        required
        disabled
        value={
          travelTime === undefined
            ? `${t('travel-time')} --:--`
            : `${t('travel-time')}  ${moment.utc((travelTime.timeInSeconds || 0) * 1000).format('HH:mm:ss')}`
        }
      />
      <Divider textAlign="left">{t('dep-date-time')}</Divider>
      <Box sx={{display: 'grid', gap: '15px', gridTemplateColumns: '1fr 1fr'}}>
        <TextField
          disabled={index < reservation.travel.journeys.length - 1}
          type="date"
          label={t('mission-details.label-departure-date')}
          value={departureDate}
          onChange={e => handleDateChange(e.target.value)}
          InputLabelProps={{shrink: true}}
          error={departureDateError}
          helperText={departureDateError ? 'Invalid date' : ''}
          inputProps={{
            min: moment().format('YYYY-MM-DD'), // Set the min date to today
          }}
        />
        <TextField
          type="time"
          label={t('coi.departure-time')}
          disabled={index < reservation.travel.journeys.length - 1}
          value={departureTime}
          onChange={e => handleTimeChange(e.target.value)}
          InputLabelProps={{shrink: true}}
          error={departureTimeError}
          helperText={departureTimeError ? 'Invalid time' : ''}
        />
      </Box>

      <Divider textAlign="left">{t('arr-date-time')}</Divider>

      <Box sx={{display: 'grid', gap: '15px', gridTemplateColumns: '1fr 1fr'}}>
        <TextField
          disabled={index < reservation.travel.journeys.length - 1}
          type="date"
          label={t('labels.arr-date')}
          value={arrivalDate}
          onChange={e => handleArrivalDateChange(e.target.value)}
          InputLabelProps={{shrink: true}}
          inputProps={{
            min: departureDate, // Set the min date to the selected departure date
          }}
        />
        <TextField
          disabled={index < reservation.travel.journeys.length - 1}
          type="time"
          label={t('labels.arr-time')}
          value={arrivalTime}
          onChange={e => handleArrivalTimeChange(e.target.value)}
          InputLabelProps={{shrink: true}}
        />
      </Box>
    </Box>
  );
};
