import moment from 'moment';
import {ReservationStatusDto} from 'src/services/models/reservation-status-dto';

export enum FormActionKind {
  DEPARTURE_DATE = 'DEPARTURE_DATE',
  ARRIVAL_DATE = 'ARRIVAL_DATE',
  STATUS = 'STATUS',
  OWNER = 'OWNER',
  CLIENT = 'CLIENT',
  DEPARTURE = 'DEPARTURE',
  ARRIVAL = 'ARRIVAL',
  RESERVATION = 'RESERVATION',
  TRAIN = 'TRAIN',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  EMAIL = 'EMAIL',
  STATION = 'STATION',
  RESET = 'RESET',
  ASSISTANCE = 'ASSISTANCE',
}

export type FormAction =
  | {type: FormActionKind.DEPARTURE_DATE; payload: moment.Moment | undefined | null}
  | {type: FormActionKind.ARRIVAL_DATE; payload: moment.Moment | undefined | null}
  | {type: FormActionKind.STATUS; payload: ReservationStatusDto}
  | {type: FormActionKind.OWNER | FormActionKind.CLIENT; payload: string}
  | {type: FormActionKind.DEPARTURE | FormActionKind.ARRIVAL; payload: boolean}
  | {type: FormActionKind.RESERVATION; payload: string}
  | {type: FormActionKind.TRAIN; payload: string}
  | {type: FormActionKind.LAST_NAME | FormActionKind.FIRST_NAME | FormActionKind.EMAIL; payload: string}
  | {type: FormActionKind.STATION; payload: string | undefined}
  | {type: FormActionKind.RESET; payload: null}
  | {type: FormActionKind.ASSISTANCE; payload: string | undefined};

export interface FormState {
  departureDate: moment.Moment | undefined | null;
  arrivalDate: moment.Moment | undefined | null;
  status: ReservationStatusDto[];
  owner: string | undefined;
  client: string | undefined;
  departure: boolean;
  arrival: boolean;
  reservation: string | undefined;
  train: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  station: string | undefined;
  assistance: string | undefined;
}

export const initialFormState: FormState = {
  departureDate: moment(),
  arrivalDate: moment(),
  status: [] as ReservationStatusDto[],
  owner: '',
  client: '',
  departure: false,
  arrival: false,
  reservation: '',
  train: '',
  firstName: '',
  lastName: '',
  email: '',
  station: '',
  assistance: '',
};

export const formReducer = (state: FormState, action: FormAction) => {
  const {type, payload} = action;
  switch (type) {
    case FormActionKind.DEPARTURE_DATE:
      return {
        ...state,
        departureDate: payload,
      };
    case FormActionKind.ARRIVAL_DATE:
      return {
        ...state,
        arrivalDate: payload,
      };
    case FormActionKind.STATUS:
      const existingStatuses = state.status;
      if (existingStatuses.includes(payload)) {
        const ind = existingStatuses.indexOf(payload);
        existingStatuses.splice(ind, 1);
      } else {
        existingStatuses.push(payload);
      }
      return {
        ...state,
        status: existingStatuses,
      };
    case FormActionKind.CLIENT:
      return {
        ...state,
        client: payload,
      };
    case FormActionKind.OWNER:
      return {
        ...state,
        owner: payload,
      };
    case FormActionKind.DEPARTURE:
      return {
        ...state,
        departure: payload,
      };
    case FormActionKind.ARRIVAL:
      return {
        ...state,
        arrival: payload,
      };
    case FormActionKind.RESERVATION:
      return {
        ...state,
        train: '',
        station: '',
        departure: false,
        arrival: false,
        reservation: payload,
      };
    case FormActionKind.ASSISTANCE:
      return {
        ...state,
        assistance: payload,
      };
    case FormActionKind.TRAIN:
      return {
        ...state,
        station: '',
        reservation: '',
        departure: false,
        arrival: false,
        train: payload,
      };
    case FormActionKind.FIRST_NAME:
      return {
        ...state,
        firstName: payload,
      };
    case FormActionKind.LAST_NAME:
      return {
        ...state,
        lastName: payload,
      };
    case FormActionKind.EMAIL:
      return {
        ...state,
        email: payload,
      };
    case FormActionKind.STATION:
      return {
        ...state,
        train: '',
        reservation: '',
        station: payload,
      };
    case FormActionKind.RESET:
      return {...initialFormState, status: []};
    default:
      return state;
  }
};
