import {useTranslation} from 'react-i18next';
import {CoiCreateIncidentLocationActionTypes, TrainWithMainLocationDto} from '../reducer';
import {CoiCreateIncidentLocationProps} from '../coiCreateIncidentLocation';
import {CoiAssignCoiButton, IOSSwitch} from '../../common';
import {Box, IconButton, Tooltip} from '@mui/material';
import {CoiTargetType, TargetType} from '../coiAssignModal';

import {SettingsContext} from 'src/context/settings/settings.store';
import React from 'react';
import DeleteIconTwo from 'src/icons/DeleteIconTwo';
import {AssignedCoi} from './AssignedCoi';
import {Assistants} from './Assistant';

export const TrainListElement = ({
  train,
  props,
  ind,
  handleCoiAssignModalTarget,
}: {
  train: TrainWithMainLocationDto;
  props: CoiCreateIncidentLocationProps;
  ind: number;
  handleCoiAssignModalTarget: (target: CoiTargetType | undefined, targetIndex: number) => void;
}) => {
  const {EXTERNAL_LINK_ATMS} = React.useContext(SettingsContext);
  const {t} = useTranslation();

  const fullNameDep = train.itinerary[0].name;
  const truncatedNameDep = fullNameDep.length > 8 ? `${fullNameDep.substring(0, 8)}...` : fullNameDep;
  const fullNameArr = train.itinerary[train.itinerary.length - 1].name;
  const truncatedNameArr = fullNameArr.length > 8 ? `${fullNameArr.substring(0, 8)}...` : fullNameArr;

  return (
    <Box
      sx={{
        border: 'solid 1px rgba(207, 216, 220, 1)',
        borderRadius: '4px',
        '&::before': {
          content: '""',
          width: '5px',
          borderRadius: '0px 10px 10px 0px',
          backgroundColor: 'rgba(0, 59, 140, 1)',
          position: 'absolute',
          left: '0',
          height: '36px',
          top: '12px',
          visibility: train.mainLocation ? 'visible' : 'hidden',
        },
      }}
    >
      <Box
        sx={{
          gridTemplateColumns: 'repeat(2,15%) repeat(2, auto) auto',
          padding: '10px',
          paddingLeft: '15px',
          display: 'grid',
          gap: '10px',
          backgroundColor: 'white',
          minHeight: '48px',
          position: 'relative',
          borderRadius: '4px',
          '&::before': {
            content: '""',
            width: '5px',
            borderRadius: '0px 10px 10px 0px',
            backgroundColor: 'rgba(0, 59, 140, 1)',
            position: 'absolute',
            left: '0',
            height: '36px',
            top: '10px',
            visibility: train.mainLocation ? 'visible' : 'hidden',
          },
        }}
      >
        <div style={{display: 'grid', justifyItems: 'start', alignItems: 'center'}}>
          <IOSSwitch
            checked={train.mainLocation}
            onChange={() => {
              props.coiCreateIncidentLocationDispatch({
                type: CoiCreateIncidentLocationActionTypes.CHANGE_MAIN_LOCATION,
                payload: ind,
              });
            }}
          />
        </div>
        <div style={{display: 'grid', justifyItems: 'start', alignItems: 'center'}}>
          <a href={`${EXTERNAL_LINK_ATMS}/trains/${train.trainNumber}/${train.departureDate}`} target="_blank">
            <span
              style={{
                border: 'solid 1px grey',
                color: 'blue',
                display: 'inline-block',
                blockSize: 'fit-content',
                minWidth: '55px',
                textAlign: 'center',
                borderRadius: '5px',
              }}
            >
              {train.trainNumber}
            </span>
          </a>
        </div>
        <div style={{display: 'grid', justifyItems: 'start', alignItems: 'center'}}>
          <Tooltip title={fullNameDep}>
            <p style={{whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '150px', margin: 0}}>{truncatedNameDep}</p>
          </Tooltip>
        </div>
        <div style={{display: 'grid', justifyItems: 'start', alignItems: 'center'}}>
          <Tooltip title={fullNameArr}>
            <p style={{whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '150px', margin: 0}}>{truncatedNameArr}</p>
          </Tooltip>
        </div>
        <div style={{display: 'grid', justifyItems: 'end', alignItems: 'center'}}>
          <div style={{display: 'flex', justifyContent: 'flex-end', gap: '24px'}}>
            <CoiAssignCoiButton
              onClick={() => {
                handleCoiAssignModalTarget(
                  {
                    type: TargetType.TRAIN,
                    payload: {indOfTrain: ind, train: train},
                  },
                  ind,
                );
              }}
            >
              {train.assignedCoi?.employeeId !== undefined || (train.assistants && train.assistants.length > 0)
                ? 'Edit COI'
                : t("coi.assign-spi")}
            </CoiAssignCoiButton>
            <IconButton
              onClick={() => {
                props.coiCreateIncidentLocationDispatch({
                  type: CoiCreateIncidentLocationActionTypes.REMOVE_TRAINS,
                  payload: train.trainNumber,
                });
              }}
            >
              <DeleteIconTwo />
            </IconButton>
          </div>
        </div>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        {train.assignedCoi && (
          <AssignedCoi
            id={train.assignedCoi.employeeId || ''}
            name={train.assignedCoi.name || ''}
            phone={train.assignedCoi.phoneNumber || ''}
          />
        )}
        {train.assistants &&
          train.assistants.length > 0 &&
          train.assistants.map(el => <Assistants assistant={el} key={el.employeeId} />)}
      </Box>
    </Box>
  );
};
