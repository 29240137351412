import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {styled} from '@mui/material/styles';

export const SelectMenu = styled(Select)((props: {selectedIndex?: number}) => ({
  color: props.selectedIndex === 12 ? '#0069B4' : '#373737',
  fontSize: '0.9rem',
  fontWeight: 500,
  '& .MuiSelect-select:focus': {
    backgroundColor: 'transparent',
  },
}));

export const SelectMenuItem = styled(MenuItem)({
  fontSize: '0.9rem',
  color: '#373737',
  fontWeight: 500,
  backgroundColor: 'transparent',
});

export const MenuList = styled(List)({
  '&& .Mui-selected, && .Mui-selected:hover': {
    '&, & .MuiListItemIcon-root': {
      color: '#0069B4',
    },
  },
});
