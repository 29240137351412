import React, {FC, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import moment from 'moment';
import {
  Box,
  Typography,
  Button,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Snackbar,
  CircularProgress,
  styled,
} from '@mui/material';

import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import {EnvironmentContext} from 'src/context/environment/environment.store';
import {followIncident, getIncident, getIncidentsPhotos, saveEvacuationMethod} from 'src/scenes/coi/data';
import useHandleCall from 'src/hooks/useHandleCall';
import {IncidentDetailsDto} from 'src/services/models/incident-details-dto';
import {CoiChatView} from '../coiChatComponents/coiChatView';
import {PresenceTypeDto} from 'src/services/models/presence-type-dto';
import {FoodTypeDto} from 'src/services/models/food-type-dto';
import {EvacuationVehicleTypeDto} from 'src/services/models/evacuation-vehicle-type-dto';
import {CoiMissionDetail} from './coiMissionDetail';
import {CoiCloseIncidentDialog} from '../coiCloseIncidentDialog/coiCloseIncidentDialog';
import {closeIncident} from 'src/scenes/coi/data';
import {TrainDetailsDto} from 'src/services/models/train-details-dto';
import {IncidentStatusDto} from 'src/services/models/incident-status-dto';
import {IncidentMissionStatusDto} from 'src/services/models/incident-mission-status-dto';
import {CoiMissionImageDialog} from '../coiMissionImageDialog/coiMissionImageDialog';
import {useCoiCreateIncidentContext} from 'src/context/coi/coi.createIncident.store';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';
import {FoodDto} from 'src/services/models/food-dto';
import {PresenceDto} from 'src/services/models/presence-dto';
import {AuthContext} from 'src/context/authentication/store.auth';
import {CoiAssignCoiModal, CoiTargetType, TargetType} from '../coiCreateIncidentComponents/coiAssignModal';
import {DistrictContext} from 'src/context/district/district.store';
import {EvacuationDto} from 'src/services/models/evacuation-dto';
import {CoiEvacuationMethodsDialog} from '../coiEvacuationMethodDialog/coiEvacuationMethodDialog';
import {LocationDetailsDto} from 'src/services/models/location-details-dto';
import {NetworkContext} from 'src/context/network/network.store';
import {TrainStatus} from 'src/services/models';
import {StationPresenceDto} from 'src/services/models/station-presence-dto';
import {updatePersonel} from './data';
import {DistributionTypeDto} from 'src/services/models/distribution-type-dto';
import {DistributionDto} from 'src/services/models/distribution-dto';
import {AddressDetailsDto} from 'src/services/models/address-details-dto';
import {PtcarDetailsDto} from 'src/services/models/ptcar-details-dto';
import {AssignmentDto} from 'src/services/models/assignment-dto';
import {LocationTypeDto} from 'src/services/models/location-type-dto';

const BoxContainer = styled('div')(({theme}) => ({
  display: 'flex',
  gap: '10px',
  flexDirection: 'row',
  [theme.breakpoints.down(1600)]: {
    flexDirection: 'column',
  },
}));

const BoxTableContainer = styled('div')(({theme}) => ({
  border: '1px solid rgba(0, 105, 180, 0.3)',
  borderRadius: '4px',
  marginBottom: '10px',
  padding: '15px 20px',
  [theme.breakpoints.down(1600)]: {
    padding: '15px 5px',
    '& > p': {
      paddingLeft: '15px',
    },
    '& > a': {
      paddingLeft: '15px',
      width: 'fit-content',
      display: 'flex',
    },
  },
}));

const ResponsiveTableCell = styled(TableCell)(({theme}) => ({
  [theme.breakpoints.down(1600)]: {
    padding: '6px 6px',
  },
}));

interface CoiIncidentDetailProps {
  incidentId: string;
  handleCloseIncidentCallback: () => void;
  handleFollowIncidentCallback: () => void;
}

export const CoiIncidentDetail: FC<CoiIncidentDetailProps> = ({
  incidentId,
  handleCloseIncidentCallback,
  handleFollowIncidentCallback,
  ...props
}) => {
  const {userData} = useContext(AuthContext);
  const location = useLocation();
  const {selectedDistrict} = useContext(DistrictContext);
  const {validateNetworkCall} = useContext(NetworkContext);
  const {t, i18n} = useTranslation();
  const {executeCall, loadingCall, errorCall} = useHandleCall();
  const {executeCall: executeGetPhotoCall, loadingCall: loadingGetPhotoCall} = useHandleCall();
  const coiStore = useCoiCreateIncidentContext();
  const {variables} = useContext(EnvironmentContext);
  const [incident, setIncident] = useState<IncidentDetailsDto>();
  const [incidentCopy, setIncidentCopy] = useState<IncidentDetailsDto | undefined>(undefined);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [isPhotoDialogOpen, setIsPhotoDialogOpen] = useState<boolean>(false);
  const [photoUrl, setPhotoUrl] = useState<any>('');
  const [isEvacuationModalOpen, setIsEvacuationModalOpen] = useState<boolean>(false);
  const [selectedMission, setSelectedMission] = useState<LocationDetailsDto>();
  const [evacuation, setEvacuation] = useState<EvacuationDto>();
  const [isEvacuationSnackbarOpen, setIsEvacuationSnackbarOpen] = useState<boolean>(false);

  // COI Assignment Dialog
  const [isAssignCoiDialogOpen, setIsAssignCoiDialogOpen] = useState<boolean>(false);
  const [coiTarget, setCoiTarget] = useState<TargetType>();
  const [assignIndex, setAssigIndex] = useState<number | undefined>();
  const [isHandlingPutCall, setIsHandlingPutCall] = React.useState(false);

  //Render trigger
  const [reTriggerModal, setReTriggerModal] = React.useState(true);

  const getIncidentById = async () => {
    const data = await executeCall(getIncident(variables.BASE_ENDPOINT, incidentId));
    if (!data) return;
    setIncident(data);
  };

  const getPhotoUrl = async (missionId: string, photoId: string) => {
    const data = await executeGetPhotoCall(getIncidentsPhotos(variables.BASE_ENDPOINT, missionId, photoId));
    if (data) {
      const dataUrl = URL.createObjectURL(data as Blob);
      setPhotoUrl(dataUrl);
    }
  };

  useEffect(() => {
    if (!incidentId) {
      setIncident(undefined);
      return;
    }
    getIncidentById();
  }, [incidentId, selectedDistrict]);

  const handleFollowIncident = async () => {
    await executeCall(followIncident(variables.BASE_ENDPOINT, incident?.id || ''));
    if (location.pathname.includes('follow-up')) {
      handleCloseIncidentCallback();
      setIncident(undefined);
    } else {
      getIncidentById();
    }
    handleFollowIncidentCallback();
  };

  const handleCloseIncident = async (report: string) => {
    await executeCall(closeIncident(variables.BASE_ENDPOINT, incident?.id || '', report));
    setIsDialogOpen(false);
    if (!errorCall) {
      setIsSnackbarOpen(true);
      getIncidentById();
      handleCloseIncidentCallback();
    }
  };

  const handleOpenPhoto = (missionId: string, photoId: string) => {
    getPhotoUrl(missionId, photoId);
    setIsPhotoDialogOpen(true);
  };

  const handleClosePhotoDialog = () => {
    setIsPhotoDialogOpen(false);
    setPhotoUrl('');
  };

  const trains = React.useMemo(() => {
    if (incidentCopy) {
      const t = incidentCopy.locations?.filter(location => location.type === 'Train') || [];
      return t;
    } else {
      const t = incident?.locations?.filter(location => location.type === 'Train') || [];
      return t;
    }
  }, [incident, reTriggerModal, incident?.locations]);

  const trainPersonel = React.useMemo(() => {
    return trains.map(el => ({assignments: el.assignments, coi: el.employeeId}));
  }, [trains]);

  const ptcars = React.useMemo(() => {
    if (incidentCopy) {
      const t = incidentCopy.locations?.filter(location => location.type === 'Ptcar') || [];
      return t;
    } else {
      const t = incident?.locations?.filter(location => location.type === 'Ptcar') || [];
      return t;
    }
  }, [incident, reTriggerModal, incident?.locations]);

  const [ptCarPersonel, setPtCarPersonel] = React.useState<{
    assignments: AssignmentDto[] | null | undefined;
    coi: string | null | undefined;
  }>({
    assignments: undefined,
    coi: undefined,
  });

  React.useEffect(() => {
    setPtCarPersonel(_prev => ({
      assignments: ptcars[0]?.assignments || undefined,
      coi: ptcars[0]?.employeeId || undefined,
    }));
  }, [ptcars]);

  const addresses = React.useMemo(() => {
    if (incidentCopy) {
      const t = incidentCopy.locations?.filter(location => location.type === 'Address') || [];
      return t;
    } else {
      const t = incident?.locations?.filter(location => location.type === 'Address') || [];
      return t;
    }
  }, [incident, reTriggerModal]);

  const addressPersonal = React.useMemo(
    () => addresses[0] && {assignments: addresses[0].assignments, coi: addresses[0].employeeId},
    [addresses],
  );

  const coiArrived = React.useMemo(
    () => incident?.locations?.filter(location => location.coiArrived?.arrivedAtExactLocation) || [],
    [incident],
  );
  const locationsWithPhotos = React.useMemo(
    () => incident?.locations?.filter(location => !!location?.photos) || [],
    [incident],
  );
  const trainInDistress = React.useMemo(
    () =>
      incident?.locations?.filter(
        location => location.type === 'Train' && location.trainInDistress?.isTrainInDistress,
      ) || [],
    [incident],
  );
  const evacuations = React.useMemo(
    () => incident?.locations?.filter(location => location.evacuation?.length) || [],
    [incident],
  );
  const locationsWidthFood = React.useMemo(
    () => incident?.locations?.filter(location => location.distribution?.length) || [],
    [incident],
  );

  const foodCounterByType = (type: DistributionTypeDto) => {
    let temp = 0;
    locationsWidthFood.forEach(loc =>
      loc.distribution?.forEach((distribution: DistributionDto) => {
        if (distribution.type === type && !!distribution.quantity) {
          temp += distribution.quantity;
        }
      }),
    );
    return temp;
  };

  const presencesCounterByType = (type: PresenceTypeDto) => {
    let temp = 0;
    incident?.locations?.forEach(loc =>
      loc.presences?.forEach((presence: PresenceDto) => {
        if (presence.type === type && !!presence.arrivalAt) {
          temp++;
        }
      }),
    );
    return temp;
  };

  const translationByCatalogAndSubCatalog = () => {
    const catalog = coiStore.referenceData.find(d => d.id === incident?.catalogId);
    if (catalog) {
      const subCatalog = catalog.subCatalogs.find(d => d.id === incident?.subCatalogId);
      if (subCatalog) {
        return `${handleTranslationObject(i18n, catalog.names)} ${handleTranslationObject(i18n, subCatalog.names)}`;
      } else {
        return handleTranslationObject(i18n, catalog.names);
      }
    }
    return '';
  };

  const checkIfIsCancellable = () => {
    return (
      incident?.status === IncidentStatusDto.Finalized ||
      !!incident?.locations?.filter(l => l.status !== IncidentMissionStatusDto.Completed)?.length ||
      userData?.b_employeeid !== incident?.employeeId
    );
  };

  const handleOpenEvacuationModal = (mission: LocationDetailsDto, evacuation: EvacuationDto) => {
    setSelectedMission(mission);
    setEvacuation(evacuation);
    setIsEvacuationModalOpen(true);
  };

  const handleCloseEvacuationModal = () => {
    setIsEvacuationModalOpen(false);
    setSelectedMission(undefined);
    setEvacuation(undefined);
  };

  const handleSaveEvacuationMethod = async (values: EvacuationDto) => {
    if (selectedMission && selectedMission.evacuation) {
      const temp = [...selectedMission.evacuation].map(e => (e.id === values.id ? {...e, ...values} : e));
      await saveEvacuationMethod(variables.BASE_ENDPOINT, selectedMission.missionId || '', temp)
        .then(() => {
          setIsEvacuationSnackbarOpen(true);
          getIncidentById();
        })
        .catch(err => {
          if (err.response) {
            validateNetworkCall(err.response.status, err.response.data);
          }
        })
        .finally(() => {
          handleCloseEvacuationModal();
        });
    }
  };

  const handleCoiAssignDialogTarget = (target: TargetType | undefined, targetIndex: number | undefined | null) => {
    if (target === undefined) {
      setCoiTarget(undefined);
      setIsAssignCoiDialogOpen(false);
      setAssigIndex(undefined);
    } else if (TargetType.TRAIN && targetIndex !== undefined && targetIndex !== null) {
      setCoiTarget(target);
      setIsAssignCoiDialogOpen(true);
      setAssigIndex(targetIndex);
    } else {
      setCoiTarget(target);
      setIsAssignCoiDialogOpen(true);
      setAssigIndex(undefined);
    }
  };

  const getCoiTargetType = (): CoiTargetType | undefined => {
    if (coiTarget === TargetType.TRAIN && assignIndex !== undefined) {
      const coi: StationPresenceDto = {
        employeeId: trainPersonel[assignIndex].coi,
        isCoi: !!trainPersonel[assignIndex].coi,
      };
      const assistants: StationPresenceDto[] =
        trainPersonel[assignIndex].assignments?.map(el => ({
          employeeId: el.employeeId,
          isCoi: false,
        })) || [];

      return {
        type: coiTarget,
        payload: {
          indOfTrain: assignIndex,
          train: {
            assignedCoi: coi,
            assistants: assistants,
            departureDate: null,
            itinerary: [],
            mainLocation: false,
            status: TrainStatus.Scheduled,
            trainNumber: 0,
          },
        },
      };
    }

    if (coiTarget === TargetType.PT_CAR) {
      const coi: StationPresenceDto = {
        employeeId: ptCarPersonel.coi,
        isCoi: !!ptCarPersonel.coi,
      };
      const assistants: StationPresenceDto[] =
        ptCarPersonel.assignments?.map(el => ({
          employeeId: el.employeeId,
          isCoi: false,
        })) || [];

      return {
        type: coiTarget,
        payload: {
          assistanceType: 'Full',
          numberOfPlatforms: 0,
          districtId: '',
          id: ptcars[0].id || '',
          name: [],
          assignedCoi: coi,
          assistants: assistants,
          mainLocation: false,
        },
      };
    }
    if (coiTarget === TargetType.ADDRESS) {
      return {
        type: coiTarget,
      };
    }
  };

  const handleCoiAssignSave = async (index: number | undefined) => {
    if (incidentCopy && coiTarget === TargetType.TRAIN && index !== undefined) {
      const selectedTrain = trains[index];
      const newPersonel = {
        employeeId: selectedTrain.employeeId || '',
        assignments: (selectedTrain.assignments?.map(el => el.employeeId) as string[]) || ([] as string[]),
      };

      await executeCall(
        updatePersonel(variables.BASE_ENDPOINT, incident?.id || '', selectedTrain.id || '', newPersonel),
      );
    }

    if (incidentCopy && coiTarget === TargetType.PT_CAR) {
      const selectedPtcar = ptcars[0];
      const newPersonel = {
        employeeId: selectedPtcar.employeeId || '',
        assignments: (selectedPtcar.assignments?.map(el => el.employeeId) as string[]) || ([] as string[]),
      };

      await executeCall(
        updatePersonel(variables.BASE_ENDPOINT, incident?.id || '', selectedPtcar.id || '', newPersonel),
      );
    }

    if (incidentCopy && coiTarget === TargetType.ADDRESS) {
      const seletedAddres = addresses[0];
      const newPersonel = {
        employeeId: seletedAddres.employeeId || '',
        assignments: (seletedAddres.assignments?.map(el => el.employeeId) as string[]) || ([] as string[]),
      };

      await executeCall(
        updatePersonel(variables.BASE_ENDPOINT, incident?.id || '', seletedAddres.id || '', newPersonel),
      );
    }

    handleCoiAssignDialogTarget(undefined, undefined);
    await getIncidentById();
  };

  const handleCoiUpsertState = (
    index: number | undefined,
    target: TargetType,
  ):
    | {
        location: LocationDetailsDto | AddressDetailsDto | PtcarDetailsDto | TrainDetailsDto;
        newIncident: IncidentDetailsDto;
        indexOfLocation: number;
      }
    | undefined => {
    if (index === undefined) {
      return undefined;
    }

    let location = undefined;
    const newIncident = incidentCopy ? JSON.parse(JSON.stringify(incidentCopy)) : JSON.parse(JSON.stringify(incident)); // Shallow copy of incident and locations
    if (target === TargetType.TRAIN) {
      location = newIncident.locations && newIncident.locations[index];
    }

    if (target === TargetType.PT_CAR) {
      location =
        newIncident.locations &&
        newIncident.locations.filter((loc: {type: LocationTypeDto}) => loc.type === 'Ptcar')[0];
    }

    if (target === TargetType.ADDRESS) {
      location =
        newIncident.locations &&
        newIncident.locations.filter((loc: {type: LocationTypeDto}) => loc.type === LocationTypeDto.Address)[0];
    }

    if (!location || !newIncident || !newIncident.locations) {
      return undefined;
    }

    return {
      location: location,
      newIncident: newIncident,
      indexOfLocation: index,
    };
  };

  //NEW COI FUNCTIONS
  const assignCoiFromIncident = async (coi: StationPresenceDto, ind: number | undefined) => {
    if (!coiTarget) {
      return;
    }

    const upsertData = handleCoiUpsertState(ind, coiTarget);

    if (!upsertData) {
      return;
    }

    const {location, newIncident, indexOfLocation} = upsertData;

    if (!newIncident.locations) {
      return;
    }

    location.employeeId = coi.employeeId;
    location.employeeName = coi.name;
    location.employeePhoneNumber = coi.phoneNumber;
    newIncident.locations[indexOfLocation] = {...location};
    setIncidentCopy(_prev => ({...newIncident}));
    setReTriggerModal(prev => !prev);
  };

  const assignAssistFromIncident = async (assist: StationPresenceDto, ind: number | undefined) => {
    if (!coiTarget) {
      return;
    }

    const upsertData = handleCoiUpsertState(ind, coiTarget);

    if (!upsertData) {
      return;
    }

    const {location, newIncident, indexOfLocation} = upsertData;

    if (location.employeeId === assist.employeeId) {
      return;
    }

    if (!newIncident.locations) {
      return;
    }

    const addAssignment = (
      location: LocationDetailsDto | AddressDetailsDto | PtcarDetailsDto | TrainDetailsDto,
      assist: StationPresenceDto,
    ) => {
      const newAssignment: AssignmentDto = {
        employeeId: assist.employeeId,
        employeeName: assist.name,
        employeePhoneNumber: assist.phoneNumber,
      };

      location.assignments = location.assignments ? [...location.assignments, newAssignment] : [newAssignment];
    };

    addAssignment(location, assist);
    newIncident.locations[indexOfLocation] = {...location};
    setIncidentCopy(_prev => ({...newIncident}));

    setReTriggerModal(prev => !prev);
  };

  const unAssignAssistFromIncident = async (id: string, ind: number | undefined) => {
    if (!coiTarget) {
      return;
    }

    const upsertData = handleCoiUpsertState(ind, coiTarget);

    if (!upsertData) {
      return;
    }

    const {location, newIncident, indexOfLocation} = upsertData;

    if (!newIncident.locations) {
      return;
    }

    location.assignments = location.assignments ? location.assignments.filter(el => el.employeeId !== id) : [];
    newIncident.locations[indexOfLocation] = {...location};
    setIncidentCopy(_prev => ({...newIncident}));
    setReTriggerModal(prev => !prev);
  };

  if (loadingCall) {
    return (
      <Box sx={{display: 'grid', placeItems: 'center', height: '100%'}}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        padding: '25px',
        width: '100%',
        height: 'calc(100vh - 64px)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography sx={{fontWeight: 'bold', fontSize: '28px', marginBottom: '10px'}}>
            {incident?.type} {translationByCatalogAndSubCatalog()}
          </Typography>
          <BoxContainer sx={{display: 'flex', gap: '10px'}}>
            {incident?.createdAt ? (
              <Typography
                sx={{
                  border: '2px solid black',
                  borderRadius: '4px',
                  padding: '2px 4px',
                  fontSize: '14px',
                  height: 'fit-content',
                  width: 'fit-content',
                }}
              >
                {moment(incident.createdAt).format('DD.MM.yyyy')}
              </Typography>
            ) : null}
            <Typography sx={{fontSize: '18px'}}>
              {t('coi.incident-coordinator')} - {incident?.employeeName} - {incident?.displayId}
            </Typography>
          </BoxContainer>
        </Box>
        <BoxContainer>
          <Button
            variant={incident?.isFollowing ? 'contained' : 'outlined'}
            onClick={() => handleFollowIncident()}
            sx={{
              border: incident?.isFollowing ? 'none' : '2px solid #0069B4',
              height: '48px',
              width: '197px',
              fontFamily: 'Arial',
              fontWeight: 700,
            }}
          >
            <FlagOutlinedIcon sx={{fontSize: '24px'}} />
            <Typography sx={{textTransform: 'none', marginLeft: '8px', fontWeight: 700}}>
              {t('coi.follow-incident')}
            </Typography>
          </Button>
          <Button
            disabled={checkIfIsCancellable()}
            sx={{border: '2px solid #0069B4', height: '48px', width: '197px', fontFamily: 'Arial', fontWeight: 700}}
            variant="outlined"
            onClick={() => setIsDialogOpen(true)}
          >
            <CheckCircleOutlineOutlinedIcon sx={{fontSize: '24px'}} />
            <Typography sx={{textTransform: 'none', marginLeft: '8px', fontWeight: 700}}>
              {t('coi.close-incident')}
            </Typography>
          </Button>
        </BoxContainer>
      </Box>
      <Grid container sx={{height: '100%', marginTop: '20px', overflowY: 'auto'}}>
        <Grid item xs={12} lg={8} xl={7} sx={{padding: '0px 20px 20px 0', overflowY: 'auto'}}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              xl={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <BoxTableContainer>
                <Typography sx={{fontWeight: 'bold', fontSize: '18px', marginBottom: '10px'}}>
                  {t('coi.incident-presences')}
                </Typography>
                <Table size="small">
                  <TableBody>
                    {!!coiArrived.length ? (
                      <TableRow sx={{padding: '10px 0px'}}>
                        <ResponsiveTableCell sx={{fontWeight: 'bold'}}>{t('common.coi')}</ResponsiveTableCell>
                        <ResponsiveTableCell align="right">{coiArrived.length}</ResponsiveTableCell>
                      </TableRow>
                    ) : null}
                    {Object.values(PresenceTypeDto).map(presenceType => {
                      const presenceAmount = presencesCounterByType(presenceType);
                      return presenceAmount ? (
                        <TableRow key={presenceType}>
                          <ResponsiveTableCell align="left" sx={{fontWeight: 'bold'}}>
                            {presenceType}
                          </ResponsiveTableCell>
                          <ResponsiveTableCell align="right">{presenceAmount}</ResponsiveTableCell>
                        </TableRow>
                      ) : null;
                    })}
                  </TableBody>
                </Table>
              </BoxTableContainer>
              <BoxTableContainer>
                <Typography sx={{fontWeight: 'bold', fontSize: '18px', marginBottom: '10px'}}>
                  {t('coi.incident-photos')}
                </Typography>
                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '10px'}}>
                  {locationsWithPhotos.map(location =>
                    location.photos?.map(photo =>
                      photo?.thumbnail?.base64Content ? (
                        <Button
                          key={`${location.missionId}-${photo.id}`}
                          sx={{border: 'none', backgroundColor: 'transparent'}}
                          onClick={() =>
                            photo?.id && location.missionId && handleOpenPhoto(location.missionId, photo.id)
                          }
                        >
                          <img
                            src={`data:image/jpeg;base64,${photo.thumbnail.base64Content}`}
                            alt={`photo-${photo.id || ''}`}
                            width="50px"
                          />
                        </Button>
                      ) : null,
                    ),
                  )}
                </Box>
              </BoxTableContainer>
            </Grid>
            <Grid
              item
              xs={12}
              xl={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <BoxTableContainer>
                <Typography sx={{fontWeight: 'bold', fontSize: '18px', marginBottom: '10px'}}>
                  {t('coi.train-in-emergency')}
                </Typography>
                <Table size="small">
                  <TableBody>
                    {trainInDistress.map((location: TrainDetailsDto) => (
                      <TableRow key={`location-${location.id}`} sx={{padding: '10px 0px'}}>
                        <ResponsiveTableCell sx={{fontWeight: 'bold'}}>
                          {location.trainNumber ? (
                            <a
                              target="_blank"
                              href={`${variables.ATMS_BASE_URL}/trains/${location.trainNumber}/${location.departureDate}`}
                            >
                              <Typography
                                sx={{
                                  fontWeight: 700,
                                  fontSize: '16px',
                                  color: '#0069B4',
                                  border: '1px solid #707070',
                                  padding: '2px 4px',
                                  borderRadius: '4px',
                                  width: 'fit-content',
                                }}
                              >
                                {location.trainNumber}
                              </Typography>
                            </a>
                          ) : null}
                        </ResponsiveTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </BoxTableContainer>
              <BoxTableContainer>
                <Typography sx={{fontWeight: 'bold', fontSize: '18px', marginBottom: '10px'}}>
                  {t('coi.incident-evacuation')}
                </Typography>
                <Table size="small">
                  <TableBody>
                    {Object.values(EvacuationVehicleTypeDto).map(type => {
                      const evacuationAmountByType = evacuations.reduce(
                        (acc, location) =>
                          acc + (location.evacuation?.filter(evacuation => evacuation.type === type).length || 0),
                        0,
                      );
                      return evacuationAmountByType ? (
                        <TableRow key={`evacuation-${type}`}>
                          <ResponsiveTableCell>{t(`coi.evacuation-type.${type?.toLowerCase()}`)}</ResponsiveTableCell>
                          <ResponsiveTableCell align="right">
                            {evacuationAmountByType?.toLocaleString()}
                          </ResponsiveTableCell>
                        </TableRow>
                      ) : null;
                    })}
                  </TableBody>
                </Table>
              </BoxTableContainer>
              <BoxTableContainer>
                <Typography sx={{fontWeight: 'bold', fontSize: '18px', marginBottom: '10px'}}>
                  {t('coi.incident-food')}
                </Typography>
                <Table size="small">
                  <TableBody>
                    {Object.values(DistributionTypeDto)
                      .sort()
                      .map(type => {
                        const distributionAmountByType = foodCounterByType(type);
                        return distributionAmountByType ? (
                          <TableRow key={`food-${type}`}>
                            <ResponsiveTableCell sx={{fontWeight: 'bold'}}>
                              {t(`coi.food-type.${type?.toLowerCase()}`)}
                            </ResponsiveTableCell>
                            <ResponsiveTableCell align="right">
                              {distributionAmountByType?.toLocaleString()?.replaceAll(',', '.')}
                            </ResponsiveTableCell>
                          </TableRow>
                        ) : null;
                      })}
                  </TableBody>
                </Table>
              </BoxTableContainer>
            </Grid>
          </Grid>
          {!!trains.length ? (
            <CoiMissionDetail
              locations={trains}
              title={t('coi.train-in-emergency')}
              handleOpenPhoto={handleOpenPhoto}
              handleOpenEvacuationModal={handleOpenEvacuationModal}
              isIncidentClosed={incident?.status === IncidentStatusDto.Finalized}
              handleOpenCoiTarget={(targetIndex: number | null) => {
                handleCoiAssignDialogTarget(TargetType.TRAIN, targetIndex);
              }}
            />
          ) : null}
          {!!ptcars.length ? (
            <CoiMissionDetail
              locations={[ptcars[0]]}
              title={t('coi.incident-impacted-ptcars')}
              handleOpenPhoto={handleOpenPhoto}
              handleOpenEvacuationModal={handleOpenEvacuationModal}
              isIncidentClosed={incident?.status === IncidentStatusDto.Finalized}
              handleOpenCoiTarget={(targetIndex: number | null) => {
                handleCoiAssignDialogTarget(TargetType.PT_CAR, targetIndex);
              }}
            />
          ) : null}
          {!!addresses.length ? (
            <CoiMissionDetail
              locations={addresses}
              title={t('coi.incident-impacted-locations')}
              handleOpenPhoto={handleOpenPhoto}
              handleOpenEvacuationModal={handleOpenEvacuationModal}
              isIncidentClosed={incident?.status === IncidentStatusDto.Finalized}
              handleOpenCoiTarget={(targetIndex: number | null) => {
                handleCoiAssignDialogTarget(TargetType.ADDRESS, targetIndex);
              }}
            />
          ) : null}
        </Grid>
        <Grid item xs={12} lg={4} xl={5}>
          <CoiChatView incident={incident} />
        </Grid>
      </Grid>
      <CoiCloseIncidentDialog
        isOpen={isDialogOpen}
        handleSubmit={handleCloseIncident}
        handleClose={() => setIsDialogOpen(false)}
        subtitle={`${t('coi.incident-coordinator')} - ${incident?.employeeName}`}
      />
      <CoiMissionImageDialog
        imgUrl={photoUrl}
        isOpen={isPhotoDialogOpen}
        handleClose={handleClosePhotoDialog}
        isLoading={loadingGetPhotoCall}
      />
      <CoiEvacuationMethodsDialog
        isOpen={isEvacuationModalOpen && evacuation !== undefined}
        evacuation={evacuation}
        handleSubmit={handleSaveEvacuationMethod}
        handleClose={handleCloseEvacuationModal}
      />

      {coiTarget && (
        <CoiAssignCoiModal
          open={isAssignCoiDialogOpen && coiTarget !== undefined}
          onClose={() => {
            handleCoiAssignDialogTarget(undefined, null);
            setIncidentCopy(undefined);
            setReTriggerModal(prev => !prev);
          }}
          assignIndex={assignIndex}
          reAssign={{
            handleCancel: () => {
              setIncidentCopy(undefined);
              setReTriggerModal(prev => !prev);
              handleCoiAssignDialogTarget(undefined, null);
            },
            handleSave: i => handleCoiAssignSave(i),
          }}
          target={getCoiTargetType() as CoiTargetType}
          assignCoi={(coi, ind) => {
            assignCoiFromIncident(coi, ind);
          }}
          unAssignCoi={i => {}}
          assignAssist={(a, i) => {
            assignAssistFromIncident(a, i);
          }}
          unAssignAssist={(d, i) => {
            unAssignAssistFromIncident(d, i);
          }}
          address={{
            assignedCoi: {
              employeeId: addressPersonal && addressPersonal.coi,
            },
            address: '',
            assistants:
              addressPersonal && addressPersonal.assignments
                ? addressPersonal.assignments.map(el => ({employeeId: el.employeeId}))
                : [],
          }}
          trains={trains.map(el => ({
            assignedCoi: {employeeId: el.employeeId, isCoi: !!el.employeeId},
            assistants: el.assignments
              ? el.assignments.map(el => ({employeeId: el.employeeId} as StationPresenceDto))
              : ([] as StationPresenceDto[]),
            trainNumber: 0,
            departureDate: null,
            itinerary: [],
            mainLocation: false,
            status: TrainStatus.Scheduled,
          }))}
          ptcar={{
            mainLocation: false,
            assignedCoi: ptCarPersonel && {employeeId: ptCarPersonel.coi},
            assistants:
              ptCarPersonel && ptCarPersonel.assignments
                ? ptCarPersonel.assignments.map(el => ({employeeId: el.employeeId}))
                : [],
            assistanceType: 'Full',
            numberOfPlatforms: 0,
            districtId: '',
            id: ptcars && ptcars.length > 0 ? ptcars[0].id : '',
          }}
          isHandlingPutCall={isHandlingPutCall}
          {...props}
        />
      )}
      <Snackbar
        open={isSnackbarOpen}
        key="close-incident-snackbar"
        autoHideDuration={4000}
        onClose={() => setIsSnackbarOpen(false)}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        message={t('coi.incident-closed')}
        sx={{
          backgroundColor: 'white',
          borderRadius: '4px',
          minWidth: '350px',
          border: '1px solid #D7D7D7',
          padding: '20px',
          boxShadow: '2px 2px 2px 0px rgba(0,0,0,0.2)',
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CheckCircleOutlineIcon sx={{fontSize: '28px', color: 'green', marginRight: '10px'}} />
          <Typography sx={{fontSize: '16px', fontFamily: 'Arial'}}>{t('coi.incident-closed')}</Typography>
        </Box>
      </Snackbar>
      <Snackbar
        open={isEvacuationSnackbarOpen}
        key="close-evacuation-snackbar"
        autoHideDuration={4000}
        onClose={() => setIsEvacuationSnackbarOpen(false)}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        message={t('coi.evacuation-method-saved')}
        sx={{
          backgroundColor: 'white',
          borderRadius: '4px',
          minWidth: '350px',
          border: '1px solid #D7D7D7',
          padding: '20px',
          boxShadow: '2px 2px 2px 0px rgba(0,0,0,0.2)',
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CheckCircleOutlineIcon sx={{fontSize: '28px', color: 'green', marginRight: '10px'}} />
          <Typography sx={{fontSize: '16px', fontFamily: 'Arial'}}>{t('coi.evacuation-method-saved')}</Typography>
        </Box>
      </Snackbar>
    </Box>
  );
};
