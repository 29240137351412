import {
  Grid,
  TextField,
  Collapse,
  Stack,
  ButtonGroup,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Alert,
} from '@mui/material';
import {Box} from '@mui/system';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {FormAction, FormActionKind, FormState} from '../../formState';
import {AdvancedSelection} from '../../types';
import {useTranslation} from 'react-i18next';
import {CacheContext} from 'src/context/cache/cache.store';
import {LocalizedTextDto, StationDto} from 'src/services/models';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';
import { handleFilterStations } from 'src/scenes/change-route/NewRoutePlanner/StationAutoComplete';

const buttonGroupStyles = {
  padding: '18px 40px',
  borderColor: 'lightgray',
  color: 'black',
  '&:active': {
    border: 'solid 1px lightgray',
  },
  '&:hover': {
    borderColor: 'lightgray',
    backgroundColor: '#EFF4F9',
  },
};

export const AdvancedSearch: React.FC<{
  state: FormState;
  dispatch: React.Dispatch<FormAction>;
  isAdvancedSearch: boolean;
  advancedSelection: AdvancedSelection;
  disableSearch: boolean;
  setAdvancedSelection: React.Dispatch<AdvancedSelection>;
}> = ({state, dispatch, isAdvancedSearch, advancedSelection, setAdvancedSelection, disableSearch}) => {
  const {t} = useTranslation();

  return (
    <Collapse in={isAdvancedSearch}>
      <Box sx={{marginTop: '15px', backgroundColor: '#F7F7F7', padding: ' 15px 25px'}}>
        <Stack>
          <ButtonGroup sx={{margin: '15px 0px'}}>
            <Button
              sx={{...buttonGroupStyles, backgroundColor: advancedSelection === 'STATIONS' ? '#EFF4F9' : 'white'}}
              onClick={() => setAdvancedSelection('STATIONS')}
            >
              {t('pmr.search.stations')}
            </Button>
            <Button
              sx={{
                ...buttonGroupStyles,
                backgroundColor: advancedSelection === 'RESERVATIONS' ? '#EFF4F9' : 'white',
              }}
              onClick={() => setAdvancedSelection('RESERVATIONS')}
            >
              {t('pmr.search.reservation')}
            </Button>
            <Button
              sx={{...buttonGroupStyles, backgroundColor: advancedSelection === 'TRAIN' ? '#EFF4F9' : 'white'}}
              onClick={() => setAdvancedSelection('TRAIN')}
            >
              {t('pmr.search.train')}
            </Button>
          </ButtonGroup>
          <Grid container gap={0} sx={{margin: '15px 0px'}}>
            {advancedSelection === 'STATIONS' && (
              <AdvancedStationSearch disableSearch={disableSearch} state={state} dispatch={dispatch} />
            )}
            {advancedSelection === 'RESERVATIONS' && <AdvancedReservationSearch state={state} dispatch={dispatch} />}
            {advancedSelection === 'TRAIN' && <AdvancedTrainSearch state={state} dispatch={dispatch} />}
          </Grid>
        </Stack>
      </Box>
    </Collapse>
  );
};

const AdvancedStationSearch: React.FC<{
  state: FormState;
  dispatch: React.Dispatch<FormAction>;
  disableSearch: boolean;
}> = ({state, dispatch, disableSearch}) => {
  const {t, i18n} = useTranslation();

  const {stations} = React.useContext(CacheContext);

  const defaultStation = (stations as StationDto[]).find(el => el.id === state.station);
  const [autoCompleteKey, setAutoCompleteKey] = React.useState(0);

  React.useEffect(() => {
    if (state.station !== undefined && state.station.length > 0) {
      setAutoCompleteKey(0);
    } else {
      setAutoCompleteKey(1);
    }
  }, [state]);

  return (
    <Grid container gap={1} sx={{margin: '15px 0px'}}>
      <Grid item xs={3} padding={'0px 10px 0px 0px'}>
        <Autocomplete
          disablePortal
          fullWidth
          value={
            defaultStation && {
              label: handleTranslationObject(i18n, defaultStation?.name as LocalizedTextDto[]),
              val: defaultStation?.id || '',
              name: defaultStation?.name
            }
          }
          placeholder={state.station}
          renderInput={params => <TextField {...params} label={t('report-grobo.station-grobo-placeholder')} />}
          sx={{backgroundColor: 'white'}}
          options={(stations as StationDto[])
            .map(el => ({
              label: handleTranslationObject(i18n, el.name as LocalizedTextDto[]),
              val: el.id,
              name: el.name
            }))
            .sort((a, b) => a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase()))}
          onChange={(e, el) => {
            dispatch({payload: el?.val ? el.val : undefined, type: FormActionKind.STATION});
          }}
          key={autoCompleteKey}
          filterOptions={(el, state) => {
            return handleFilterStations(el, state.inputValue)}}
        />
      </Grid>
      <Grid>
        <FormGroup sx={{display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '15px', height: '100%'}}>
          <FormControlLabel
            sx={{display: 'inline-block', alignSelf: 'center'}}
            control={
              <Checkbox
                disabled={!state.station || state.station.length === 0}
                checked={state.departure}
                onChange={() => dispatch({payload: !state.departure, type: FormActionKind.DEPARTURE})}
              />
            }
            label={t('pmr.search.departure-station')}
          />
          <FormControlLabel
            sx={{display: 'inline-block', alignSelf: 'center'}}
            control={
              <Checkbox
                disabled={!state.station || state.station.length === 0}
                checked={state.arrival}
                onChange={() => dispatch({payload: !state.arrival, type: FormActionKind.ARRIVAL})}
              />
            }
            label={t('pmr.search.arrival-station')}
          />
        </FormGroup>
      </Grid>
      {disableSearch && (
        <Alert severity="error" variant="standard">
          {t('pmr.search.arrival-or-departure')}
        </Alert>
      )}
    </Grid>
  );
};

/*
      <Grid item xs={6} padding={'0px 0px 0px 10px'}>
        <TextField
          fullWidth
          InputProps={{startAdornment: <SearchIcon />}}
          type="text"
          sx={{backgroundColor: 'white'}}
          placeholder={t('pmr.search.arrival-station')}
          value={state.arrival}
          onChange={e => dispatch({payload: e.target.value, type: FormActionKind.ARRIVAL})}
        />
      </Grid>
*/

const AdvancedReservationSearch: React.FC<{state: FormState; dispatch: React.Dispatch<FormAction>}> = ({
  state,
  dispatch,
}) => {
  const {t} = useTranslation();

  return (
    <Grid container gap={0} sx={{margin: '15px 0px'}}>
      <Grid item xs={6} padding={'0px 10px 0px 0px'}>
        <TextField
          fullWidth
          InputProps={{startAdornment: <SearchIcon />}}
          type="text"
          sx={{backgroundColor: 'white'}}
          placeholder={t('pmr.search.reservation-id')}
          value={state.reservation}
          onChange={e => dispatch({payload: e.target.value, type: FormActionKind.RESERVATION})}
        />
      </Grid>
    </Grid>
  );
};

const AdvancedTrainSearch: React.FC<{state: FormState; dispatch: React.Dispatch<FormAction>}> = ({state, dispatch}) => {
  const {t} = useTranslation();

  return (
    <Grid container gap={0} sx={{margin: '15px 0px'}}>
      <Grid item xs={6} padding={'0px 10px 0px 0px'}>
        <TextField
          fullWidth
          InputProps={{startAdornment: <SearchIcon />}}
          type="number"
          sx={{backgroundColor: 'white'}}
          placeholder={t('pmr.search.train-number')}
          value={state.train}
          onChange={e => {
            if (state.train && e.target.value.length === state.train?.length - 1) {
              dispatch({payload: e.target.value, type: FormActionKind.TRAIN});
            } else if (Number.isInteger(Number.parseFloat(e.target.value))) {
              dispatch({payload: e.target.value, type: FormActionKind.TRAIN});
            }
          }}
        />
      </Grid>
    </Grid>
  );
};
