import {Close} from '@mui/icons-material';
import {Alert} from '@mui/lab';
import {Container, Autocomplete} from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import {Box} from '@mui/system';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {CacheContext} from 'src/context/cache/cache.store';
import {AgeCategoryDto, CreateGroupDto, DisabilityDto, LocalizedTextDto, ReservationTypeDto} from 'src/services/models';
import {getDisabilityFromId} from 'src/utils/getDisabilityFromId';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';
import {CreateMissionGroupCompositions} from '../types';

export const GroupComposition: React.FC<CreateMissionGroupCompositions> = ({reservation, setReservation, variant}) => {
  const {ageCategories} = React.useContext(CacheContext) as {ageCategories: AgeCategoryDto[]};
  const {t, i18n} = useTranslation();

  //Errors
  const [minimumAmountInGroup, setMinimumAmountInGroup] = React.useState(t('create-prm-mission.error-min-travelers'));

  const getAgeCategories = () => {
    return ageCategories.map(el => {
      if (el.description) {
        return {label: handleTranslationObject(i18n, el.description), value: el.id};
      }
    });
  };

  const handleGroupIdChange = (value: {label: string; value: string}, index: number) => {
    const prevState = reservation;
    if (prevState.travel.traveler?.ageCategories) {
      prevState.travel.traveler.ageCategories[index].typeId = value.value;
    }

    setReservation(_prev => {
      return {...prevState};
    });
  };
  const handleAgeGroupAmountChange = (value: number, index: number) => {
    const prevState = reservation;
    if (prevState.travel.traveler?.ageCategories) {
      prevState.travel.traveler.ageCategories[index].quantity = value;
    }

    setReservation(_prev => {
      return {...prevState};
    });
  };

  const getValue = (ageCategory: CreateGroupDto) => {
    const dis = getDisabilityFromId(ageCategories as DisabilityDto[], ageCategory.typeId ? ageCategory.typeId : '');
    if (dis) {
      return {
        label: handleTranslationObject(i18n, dis.description as LocalizedTextDto[]),
        value: ageCategory.typeId ? ageCategory.typeId : '',
      };
    }
  };

  const handleAddAgeCategory = () => {
    const prevState = reservation;
    prevState.travel.traveler?.ageCategories?.push({} as CreateGroupDto);

    setReservation(_prev => {
      return {...prevState};
    });
  };

  const handleRemoveAgeCategory = (index: number) => {
    const prevState = reservation;
    prevState.travel.traveler?.ageCategories?.splice(index, 1);

    setReservation(_prev => {
      return {...prevState};
    });
  };

  React.useEffect(() => {
    //Check for validity in component
    if (reservation.type === ReservationTypeDto.Group) {
      //At least 15 travelers
      let totalAmountOfTravelers = 0;
      reservation.travel.traveler?.ageCategories?.forEach(el => {
        if (el.quantity) {
          totalAmountOfTravelers = totalAmountOfTravelers + el.quantity;
        }
      });

      //Check if all amounts have been set
      const hasAllAmountsBeenSet = reservation.travel.traveler?.ageCategories?.every(el => {
        return el.quantity && el.typeId && el.quantity > 0 && el.typeId.length > 0;
      });

      if (totalAmountOfTravelers >= 15 && hasAllAmountsBeenSet) {
        setMinimumAmountInGroup(t(''));
      } else {
        setMinimumAmountInGroup(t('create-prm-mission.error-min-travelers'));
      }
    }
  }, [reservation]);

  return (
    <Container maxWidth={false}>
      {reservation.travel.traveler?.ageCategories?.map((age, ind) => {
        return (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: `200px 1fr ${ind > 0 ? '40px' : ''}`,
              gap: '15px',
              marginBottom: '15px',
            }}
          >
            <TextField
              type="number"
              InputProps={{
                inputProps: {min: 0},
              }}
              defaultValue={0}
              value={age.quantity}
              onChange={event => handleAgeGroupAmountChange(parseInt(event.target.value), ind)}
              error={age.quantity === 0 || age.quantity === undefined}
              color={age.quantity === 0 || age.quantity === undefined ? 'error' : 'success'}
            />
            <Autocomplete
              disableClearable
              options={getAgeCategories()}
              value={getValue(age)}
              renderInput={args => (
                <TextField
                  placeholder={t('create-prm-mission.add-age-cat')}
                  {...args}
                  error={age.typeId?.length === 0 || age.typeId === undefined}
                />
              )}
              //@ts-ignore
              onChange={(_event, value) => handleGroupIdChange(value, ind)}
              color={age.typeId?.length === 0 || age.typeId === undefined ? 'error' : 'success'}
            />
            {ind > 0 && (
              <IconButton onClick={() => handleRemoveAgeCategory(ind)}>
                <Close />
              </IconButton>
            )}
          </Box>
        );
      })}
      {variant === ReservationTypeDto.Group && minimumAmountInGroup.length > 0 ? (
        <Alert severity="error">{minimumAmountInGroup}</Alert>
      ) : null}
      <Box
        sx={{
          marginTop: '15px',
        }}
      >
        <Button onClick={() => handleAddAgeCategory()} variant="contained">
          {t('create-prm-mission.add-age-cat')}
        </Button>
      </Box>
    </Container>
  );
};
