// @ts-nocheck
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const FullAssistanceIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.333 32" width="32.333" height="32">
      <g xmlns="http://www.w3.org/2000/svg" transform="matrix(0 1 -1 0 32.333 -0)">
        <g id="ic_wheelchair_fix" data-name="ic_wheelchair fix" transform="translate(13 -8)">
          <rect
            id="Rectangle_1644"
            data-name="Rectangle 1644"
            width="32"
            height="32.333"
            transform="translate(-13 8)"
            fill="none"
          />
          <path
            id="Tracé_5214"
            data-name="Tracé 5214"
            d="M15.334,12.445a.708.708,0,0,0,.43-.167.568.568,0,0,0,.174-.362l-.02-2.034a.591.591,0,0,0-.181-.419.679.679,0,0,0-.434-.175h0A5.545,5.545,0,0,1,11.508,7.58L10.12,6.068a2.794,2.794,0,0,0-.87-.64h0a2.745,2.745,0,0,0-.7-.253,2.572,2.572,0,0,0,2.059-2.832A2.748,2.748,0,0,0,7.889-.011a2.618,2.618,0,0,0-2.673,2.3A2.694,2.694,0,0,0,7.331,5.163,2.65,2.65,0,0,0,5.253,7.776l.026,2.655-.056-.054a.632.632,0,0,0-.6-.113A5.577,5.577,0,0,0,0,16.16,5.919,5.919,0,0,0,5.514,21.5a5.7,5.7,0,0,0,6.1-4.472.55.55,0,0,0-.127-.494v-.054l1.174.011.045,4.5a.614.614,0,0,0,.615.595l2.1.02a.585.585,0,0,0,.6-.583l-.056-5.6a2.734,2.734,0,0,0-2.74-2.639l-2.548-.025-.018-1.777a9.7,9.7,0,0,0,4.666,1.469ZM7.856,1.185a1.506,1.506,0,0,1,1.51,1.46A1.437,1.437,0,0,1,7.885,4.076a1.506,1.506,0,0,1-1.51-1.46A1.447,1.447,0,0,1,7.856,1.185Zm5.4,14.13L8,15.265a1.518,1.518,0,0,1-1.51-1.46L6.427,7.8A1.48,1.48,0,0,1,7.763,6.291a1.579,1.579,0,0,1,.9.191h.089a1.671,1.671,0,0,1,.435.315l1.377,1.469A6.88,6.88,0,0,0,14.7,10.341l.008.835a8.927,8.927,0,0,1-4.282-1.851.63.63,0,0,0-.643-.081.574.574,0,0,0-.349.532l.035,3.512a.614.614,0,0,0,.615.584l3.157.03a1.518,1.518,0,0,1,1.51,1.46l.051,5.107-.886-.009-.045-4.5a.587.587,0,0,0-.162-.453.632.632,0,0,0-.454-.2ZM5.943,20.384a4.727,4.727,0,0,1-4.632-3.725,4.381,4.381,0,0,1,2.884-5l.009.942a3.536,3.536,0,0,0-2.026,3.8,3.8,3.8,0,0,0,3.183,3.077,3.722,3.722,0,0,0,3.931-1.957l.941.009a4.561,4.561,0,0,1-4.291,2.849Zm2.065-3.941.454,0a.572.572,0,0,0-.153.234,2.5,2.5,0,0,1-2.677,1.6,2.57,2.57,0,0,1-2.261-2.194A2.4,2.4,0,0,1,5.033,13.5a.649.649,0,0,0,.231-.148l0,.439a2.734,2.734,0,0,0,2.74,2.649Z"
            transform="matrix(-0.259, -0.966, 0.966, -0.259, -5.217, 37.299)"
            fill="#c27710"
          />
          <g id="Tracé_6494" data-name="Tracé 6494" transform="translate(9.559 36.827) rotate(-90)" fill="none">
            <path d="M25.084,0V7.5H0Z" stroke="none" />
            <path
              d="M 24.08398246765137 1.342884063720703 L 6.832807540893555 6.502924919128418 L 24.08398246765137 6.502924919128418 L 24.08398246765137 1.342884063720703 M 25.08398246765137 -5.245208740234375e-06 L 25.08398246765137 7.502924919128418 L 3.814697265625e-06 7.502924919128418 L 25.08398246765137 -5.245208740234375e-06 Z"
              stroke="none"
              fill="#c27710"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default FullAssistanceIcon;
