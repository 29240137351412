import React from 'react';
import {AssistanceNeededChip} from '../../../MissionDetailsNew/components/Chips';
import {Dialog, DialogContent, DialogProps, Grid} from '@mui/material';
import {TravelProps} from '../Details/Travel';
import {MissionTypeDto} from 'src/services/models/mission-type-dto';
import {ReservationTypeDto} from 'src/services/models';
import {useTranslation} from 'react-i18next';
import {JourneyItem} from 'src/scenes/coordinator/MissionDetailsNew/components/Journey/JourneyItem';
import {TravelHeader} from '../Details/TravelHeader';
import {JourneyItemGroup} from '../Details/JourneyItemGroup';
import {MissionContext} from 'src/scenes/coordinator/MissionOverview/missions.store';
import getMissionDetails from 'src/services/GET/getMissionDetails';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {ExtendedJourneyItemGroup} from './ExtendedTravelGroup';
import {MissionStatusDto} from 'src/services/models/mission-status-dto';
import {ReservationStatusDto} from 'src/services/models/reservation-status-dto';

interface Props extends DialogProps, TravelProps {
  nthJourney: string;
  hasAnyAssistances: () => boolean | undefined;
  getTime: (time: Date | undefined) => string;
  hasWheelChair: () => boolean;
  status: MissionStatusDto | ReservationStatusDto | undefined;
}

export const ExtendedTravelModal: React.FC<Props> = ({
  reservation,
  ind,
  journey,
  type,
  variant,
  status,
  nthJourney,
  hasAnyAssistances,
  getTime,
  hasWheelChair,
  ...rest
}) => {
  const {t} = useTranslation();
  const {missions} = React.useContext(MissionContext);
  const {variables} = React.useContext(EnvironmentContext);

  if (reservation && journey) {
    return (
      <Dialog fullWidth maxWidth="lg" sx={{padding: '15px', border: 'solid 2px green'}} {...rest}>
        <DialogContent>
          <Grid container>
            <Grid container spacing={2}>
              <Grid item width="auto">
                <h4 style={{display: 'inline', textTransform: 'capitalize', margin: '0px'}}>
                  {nthJourney} {t('mission-details-v2.journey.journey-title')}
                </h4>
              </Grid>
              <Grid item xs>
                {hasAnyAssistances() && <AssistanceNeededChip />}
              </Grid>
            </Grid>
            <TravelHeader />

            {journey.map((journ, ind) => {
              return (
                <React.Fragment key={journ.id}>
                  <ExtendedJourneyItemGroup
                    reservation={reservation}
                    ind={ind}
                    journ={journ}
                    journey={journey}
                    type={type}
                    variant={variant}
                    hasWheelChairArr={journ.isWheelchairOnArrivalNeeded || false}
                    hasWheelChairDep={journ.isWheelchairOnDepartureNeeded || false}
                    status={status}
                  />
                </React.Fragment>
              );
            })}
          </Grid>
        </DialogContent>
      </Dialog>
    );
  } else {
    return null;
  }
};
