import Box from '@mui/material/Box';
import {Container, ContainerText, Border} from './CategoryDivider.styles';
import {CSSProperties} from 'react';

export const CategoryDivider = ({
  icon,
  title,
  style,
}: {
  icon: React.ReactNode;
  title: string;
  style: CSSProperties | undefined;
}) => {
  return (
    <>
      <Container>
        {icon}
        <ContainerText style={style}>{title}</ContainerText>
      </Container>
      <Border light />
    </>
  );
};
