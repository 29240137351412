import {Box, Divider} from '@mui/material';
import * as React from 'react';
import {ChangeAssistance} from 'src/scenes/coordinator/Reservation/GroupReservation.store';
import {ReservationDto} from 'src/services/models/reservation-dto';
import {ListItem} from './GroupReservationBoxListItem';

interface Props {
  reservation: ReservationDto;
  assistances: ChangeAssistance[];
  handleActiveMissionAssistance: (value: boolean, index: number, place: 'departure' | 'arrival') => void;
}

export const GroupReservationModalBox: React.FC<Props> = ({
  reservation,
  assistances,
  handleActiveMissionAssistance,
}) => {
  return (
    <Box
      sx={{
        borderTop: '1px solid #d7d7d7',
        width: '100%',
      }}
    >
      {' '}
      <Box sx={{display: 'flex', alignItems: 'center', gap: '5px'}}>
        <span>{reservation.id}</span>
        <span>-</span>
        <span> {reservation?.client?.description} </span>
      </Box>
      {reservation.travels
        ? reservation.travels.map(travel => {
            return (
              <Box>
                <Divider textAlign="left">{travel.travelDate}</Divider>
                {travel?.journeys?.map(journey => {
                  return (
                    <ListItem
                      key={`${journey.arrivalUicCode} + ${journey.departureUicCode} + ${journey.trainNumber} + ${journey.arrivalTime} + ${journey.departureTime} + ${reservation.id}`}
                      //@ts-ignore
                      amountOfTravelers={travel.traveler?.quantity}
                      handleActiveMissionAssistance={handleActiveMissionAssistance}
                      isManage={true}
                      trainNumber={journey.trainNumber}
                      departureName={journey.departureName}
                      departureTime={journey.departureTime}
                      arrivalName={journey.arrivalName}
                      arrivalTime={journey.arrivalTime}
                      departureAssistanceNeeded={journey.withDepartureAssistance}
                      arrivalAssistanceNeeded={journey.withArrivalAssistance}
                      //@ts-ignore
                      index={assistances.findIndex(el => el.journeyId === journey.id)}
                    />
                  );
                })}
              </Box>
            );
          })
        : null}
    </Box>
  );
};
