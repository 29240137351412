/* tslint:disable */
/* eslint-disable */
//DTO
/**
 * 
 * @export
 * @enum {string}
 */
export enum ReasonTypeDto {
    Delete = 'Delete',
    End = 'End',
    Refuse = 'Refuse',
    Stop = 'Stop'
}

