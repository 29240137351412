export const travelItemStyles = {
  customToggle: {
    marginRight: '14px',
    borderRadius: '14px',
    '&.Mui-selected': {
      border: 'solid 2px #0069B4',
      backgroundColor: 'white',
      textAlign: 'center',
      padding: '3px',
    },
    '&.Mui-selected::after': {
      content: '""',
      backgroundColor: '#0069B4',
      width: '14px',
      height: '14px',
      borderRadius: '10px',
    },
    '&.Mui-disabled': {
      backgroundColor: 'lightgrey',
    },
  },
  itemTime: {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    padding: '0px 8px',
  },
  seperator: {
    color: 'darkgray',
    display: 'flex',
    alignItems: 'center',
  },
};
