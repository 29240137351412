import axios, {AxiosError} from 'axios';

export const getUserPermissions = async (baseUrl: string) => {
  try {
    const userPermissions = await axios.get(`${baseUrl}/api/permissions`);
    return userPermissions.data;
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};
