import {Button, Dialog, Grid} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReservationsContext} from 'src/context/reservations/reservations.store';

export const RefusalModal: React.FC<{open: boolean; onClose: React.Dispatch<boolean>}> = ({open, onClose}) => {
  const {t} = useTranslation();

  const {setFocusedReservation, setIsDeclineViewVisible} = React.useContext(ReservationsContext);

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <div style={{padding: '25px'}}>
        <h5 style={{textAlign: 'center'}}>{t('pmr.cancel-procedure')}</h5>
        <p style={{maxWidth: '250px', textAlign: 'center'}}>{t('pmr.cancel-modal-text')}</p>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button fullWidth onClick={() => onClose(false)}>
              {t('back-btn')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                setFocusedReservation(null);
                setIsDeclineViewVisible(false);
                onClose(false);
              }}
            >
              {t('check-in.btn-yes')}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};
