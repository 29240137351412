import {AddressOverview} from './address';
import {ShowEmptyState} from './empty';
import {PtCarElement} from './PtCar';
import {TrainAndPtCarOverview} from './trainAndPtCar';
import {TrainListElement} from './TrainList';

export const CoiCreateIncidentInformationListElements = {
  Address: AddressOverview,
  TrainAndPtCar: TrainAndPtCarOverview,
  Empty: ShowEmptyState,
  PtCar: PtCarElement,
  TrainList: TrainListElement,
};
