import {Chip, ListItem, ListItemAvatar, ListItemText, Typography, Tooltip} from '@mui/material';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {useEffect, useState, useRef} from 'react';
import {ChatMessageDto} from 'src/services/models/chat-message-dto';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';
import {CoiActionTypeDto} from 'src/services/models/coi-action-type-dto';
import {CoiArrivedDto} from 'src/services/models/coi-arrived-dto';
import {EvacuationDto} from 'src/services/models/evacuation-dto';
import {PresenceDto} from 'src/services/models/presence-dto';
import {DistributionDto} from 'src/services/models/distribution-dto';
import {Link} from 'react-router-dom';

export const ChatMessage: React.FC<{chat: ChatMessageDto; containerRef: React.RefObject<HTMLUListElement>}> = ({
  chat,
  containerRef,
}) => {
  const {i18n, t} = useTranslation();
  const [maxWidth, setMaxWidth] = useState<number | null>(null);
  const messageRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        setMaxWidth(containerWidth * 0.9); // Set max width to 90% of the container width
      }
    };

    // Initial width setup
    updateWidth();

    // Resize observer to handle updates on resize
    const resizeObserver = new ResizeObserver(updateWidth);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    // Cleanup observer on unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef]);

  // Function to truncate location to 10 characters
  const truncateLocation = (location: string) => {
    return location.length > 10 ? `${location.substring(0, 10)}...` : location;
  };

  const chatBase = 'chat-actions';

  const getActionMessage = (chat: ChatMessageDto, action: CoiActionTypeDto | undefined): string | JSX.Element => {
    let message: string | JSX.Element = '';

    if (action === undefined) {
      message = 'No Action Passed';
    }

    //@ts-ignore
    if (chat.action && chat.action.stoppedAt) {
      message = t(chatBase + '.pause');
    }

    switch (action) {
      case CoiActionTypeDto.Accept:
        message = t(chatBase + '.user') + ` ${chat.employee?.name} ` + t(chatBase + '.accept');
        break;

      case CoiActionTypeDto.Refuse:
        message = t(chatBase + '.user') + ` ${chat.employee?.name} ` + t(chatBase + '.refuse');
        break;

      case CoiActionTypeDto.Start:
        message = t(chatBase + '.user') + ` ${chat.employee?.name} ` + t(chatBase + '.start');
        break;

      case CoiActionTypeDto.Photos:
        message = t(chatBase + '.user') + ` ${chat.employee?.name} ` + t(chatBase + '.pic');
        break;

      case CoiActionTypeDto.TrainDistress:
        message = t(chatBase + '.user') + ` ${chat.employee?.name} ` + t(chatBase + '.train-in-need');
        break;

      case CoiActionTypeDto.Evacuation:
        message = (
          <>
            {(chat.action as {evacuation: EvacuationDto[]}).evacuation.map((el, index) => (
              <span key={index}>
                {t(chatBase + '.evac')} {el.transportId} {t(chatBase + '.needed')}
                <br />
              </span>
            ))}
          </>
        );
        break;

      case CoiActionTypeDto.Presences:
        message = (
          <>
            {(chat.action as {presences: PresenceDto[]}).presences
              .filter(el => el.arrivalAt !== null)
              .map((el, index) => (
                <span key={index}>
                  {el.type} {t(chatBase + '.presence')}
                  <br />
                </span>
              ))}
          </>
        );
        break;

      case CoiActionTypeDto.Distribution:
        message = (
          <>
            {(chat.action as {distribution: DistributionDto[]})?.distribution
              .filter((el: DistributionDto) => el.quantity !== 0)
              .map((el: DistributionDto, index: number) => (
                <span key={index}>
                  {t(`coi.food-type.${el.type?.toLowerCase()}`)} ({el.quantity}) {t(chatBase + '.distribution')}
                  <br />
                </span>
              ))}
          </>
        );

        break;

      case CoiActionTypeDto.ReturnTrip:
        message = t(chatBase + '.user') + ` ${chat.employee?.name} ` + t(chatBase + '.return');
        break;

      case CoiActionTypeDto.Close:
        message = t(chatBase + '.user') + ` ${chat.employee?.name} ` + t(chatBase + '.close');
        break;

      case CoiActionTypeDto.CoiArrival:
        message = (chat.action as CoiArrivedDto).arrivedAtGate
          ? t(chatBase + '.user') + ` ${chat.employee?.name} ` + t(chatBase + '.coi-arrive-gate')
          : t(chatBase + '.user') + ` ${chat.employee?.name} ` + t(chatBase + '.coi-arrive-loc');
        break;

      default:
        message = 'No action found on the message';
    }
    return message;
  };

  const regex: RegExp =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

  const processMsg = (message: string) => {
    const sentences = message?.split(/\r?\n/);

    return sentences.map((sentence: string) => {
      if (sentence.match(regex)) {
        const words = sentence.split(' ');
        return (
          <Typography>
            {words.map((word: string) =>
              word.match(regex) ? (
                <Link to={word.includes('http') ? word : `https://${word}`} target="_blank" rel="noreferrer">
                  {word}
                </Link>
              ) : (
                ' ' + word + ' '
              ),
            )}
          </Typography>
        );
      } else {
        return <Typography>{' ' + sentence}</Typography>;
      }
    });
  };

  return (
    <ListItem sx={{alignItems: 'flex-start'}}>
      <ListItemAvatar sx={{marginRight: 2}}>
        <Chip
          sx={{
            backgroundColor: '#25336C',
            color: 'white',
            borderRadius: '5px',
            position: 'relative',
            top: '50%',
          }}
          label={moment(chat.createdAt).format('HH:mm')}
        />
      </ListItemAvatar>
      <ListItemText
        sx={{
          maxWidth: maxWidth ? `${maxWidth}px` : '100%', // Set max width dynamically
          wordWrap: 'break-word', // Ensure text wraps within the container
          overflow: 'hidden', // Hide overflow text
          textOverflow: 'ellipsis', // Display ellipsis for overflow text
        }}
      >
        <Typography sx={{fontWeight: 'bold', color: '#798c96'}}>
          <Tooltip title={chat.location || 'ALL'} arrow>
            <span>{chat.location === null ? 'ALL' : truncateLocation(chat.location || '')}</span>
          </Tooltip>{' '}
          - {handleTranslationObject(i18n, chat.roleName || [])} - {chat.employee?.name}
        </Typography>
        <Typography>
          {chat.message !== null && chat.action === null
            ? processMsg(chat.message || '')
            : getActionMessage(chat, chat.type)}
        </Typography>
      </ListItemText>
    </ListItem>
  );
};
