// @ts-nocheck
import Box from '@mui/material/Box';

import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import TrainStickerIcon from 'src/components/icons/TrainSticker';
import ListItem from '@mui/material/ListItem';
import ReturnIcon from 'src/components/icons/Return';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import FowardArrowIcon from 'src/components/icons/FowardArrow';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';

import moment from 'moment';

import Stack from '@mui/material/Stack';

import {
  CardBox,
  CardContent,
  CardItem,
  CardItemCentered,
  CardItemContent,
  ContentItem,
  leftPanel,
  rightPanel,
} from './MissionItem.styles';

import JourneyDetails from './JourneyDetails/JourneyDetails';
import ReservationDetails from './ReservationDetails/ReservationDetails';
import {MissionTypeDto} from 'src/services/models/mission-type-dto';
import {colors} from 'src/utils/hexCode';
import {StatusItem} from './StatusItem';
import AssistanceDetails from './AssistanceDetails/AssistanceDetails';
import {MissionDto} from 'src/services/models/mission-dto';
import {MissionSummaryDto} from 'src/services/models/mission-summary-dto';
import {InfoCircleFilled} from '@ant-design/icons';
import {TransportTypeDto} from 'src/services/models/transport-type-dto';

export default function MissionItem({
  dataItem,
  isAtDeparture,
  isAtArrival,
  isPMR,
  id,
  handleClick,
  selectedindex,
  isAssigned,
}) {
  const isTaxi = dataItem.journey.transportType === TransportTypeDto.Taxi;
  const {t} = useTranslation();

  const hasTime = () => {
    return dataItem.missionType === MissionTypeDto.Stickering ? dataItem.journey.originTime : dataItem.journey.time;
  };

  const hasStation = (stickerStart?: boolean) => {
    if (dataItem.missionType === MissionTypeDto.Stickering) {
      return stickerStart ? dataItem.journey.stationName : dataItem?.journey?.originStationName;
    }
    return dataItem.missionType === MissionTypeDto.Arrival
      ? dataItem.journey.otherStationName
      : dataItem.journey.stationName;
  };

  const hasDelay = () => {
    if (dataItem.missionType === MissionTypeDto.Stickering) {
      return dataItem.journey.originDelay;
    }
    return dataItem.missionType === MissionTypeDto.Arrival ? dataItem.journey.otherDelay : dataItem.journey.delay;
  };

  const hasOtherStation = () => {
    return dataItem.missionType === MissionTypeDto.Arrival
      ? dataItem.journey.stationName
      : dataItem.journey.otherStationName;
  };

  const hasTrainNumber = dataItem?.journey?.trainNumber;

  const formatTime = hasTime =>
    hasTime ? moment(hasTime).utcOffset(hasTime.split('+')[1], true).format('HH:mm') : null;

  const reservationDetails = () =>
    dataItem && dataItem?.traveler !== undefined ? (
      <ReservationDetails
        dataItem={dataItem}
        isGrobo={!isPMR}
        margin={0}
        hasSticker={dataItem?.missionType === MissionTypeDto.Stickering}
      />
    ) : null;

  const showDelay =
    hasDelay() && hasDelay() !== '00:00:00' ? (
      <ContentItem
        color={colors.mission_overview.error}
        size={0.7}
        radius={3}
        padding="0 3px"
        border={`1px solid ${colors.mission_overview.error}`}
      >
        {`+${moment.duration(hasDelay()).asMinutes()}`}
      </ContentItem>
    ) : null;

  const showRemarkIcon = dataItem.hasRemarks ? (
    <Box sx={{position: 'absolute', right: '2px', top: '20px'}}>
      <InfoCircleFilled style={{color: '#0069B4'}} />
    </Box>
  ) : null;

  return (
    <ListItem style={{padding: 0}}>
      <ListItemButton
        style={{padding: '0 10px'}}
        key={id}
        selected={id === selectedindex}
        onClick={() => {
          handleClick(id);
        }}
      >
        <CardBox elevation={3} isPMR={isPMR} isSelected={id === selectedindex} isAssigned={isAssigned}>
          <Box style={{flexDirection: 'column'}}>
            <CardContent container spacing={1} style={{paddingLeft: 4}}>
              <CardItem item>{/* <Checkbox defaultChecked style={{width: '0.7em', height: '0.7em'}} /> */}</CardItem>
              <CardItem item xs={true} style={isAtDeparture ? leftPanel : null}>
                {isAtDeparture ? (
                  <>
                    <CardItemContent
                      sx={{position: 'relative', minHeight: '40px'}}
                      direction="row"
                      margin={4}
                      grow
                      spacing={0.5}
                    >
                      <ContentItem
                        size={0.81}
                        color={colors.nbms_dark_blue}
                        grow
                        style={{overflow: 'hidden', maxHeight: 40, wordBreak: 'break-word'}}
                      >
                        {hasStation()}
                      </ContentItem>
                      <ContentItem
                        color={isTaxi ? '#C27710' : colors.nbms_dark_blue}
                        size={0.7}
                        radius={16}
                        backgroud={isTaxi ? '#F9F1E7' : '#EFF4F9'}
                        padding="2px 6px"
                      >
                        {isTaxi ? 'TAXI' : hasTrainNumber}
                      </ContentItem>
                      <ContentItem color={colors.mission_overview.succesfull} style={{width: 35}}>
                        {formatTime(hasTime())}
                      </ContentItem>
                      {showDelay}
                      {showRemarkIcon}
                    </CardItemContent>
                    <JourneyDetails
                      coachInfo={dataItem?.journey?.coachNumber}
                      seatInfo={dataItem?.journey?.seatNumber}
                      doorInfo={dataItem?.journey?.doorNumber}
                      trackInfo={dataItem?.journey?.lastPlannedTrackName}
                    />
                    <AssistanceDetails mission={dataItem as MissionSummaryDto} />
                    {dataItem?.missionType === MissionTypeDto.Stickering && (
                      <TrainStickerIcon color={colors.mission_overview.succesfull} />
                    )}
                  </>
                ) : (
                  <CardItemCentered direction="column" spacing={1}>
                    <ContentItem color={colors.grey_7} style={{textAlign: 'center'}}>
                      {hasStation(true)}
                    </ContentItem>
                  </CardItemCentered>
                )}
              </CardItem>
              <Divider orientation="vertical" flexItem style={{marginTop: 10}}>
                {isPMR ? (
                  <ReturnIcon color={colors.nbms_dark_blue} />
                ) : (
                  <FowardArrowIcon color={colors.nbms_dark_blue} />
                )}
              </Divider>
              <CardItem item xs={true} style={isAtArrival ? rightPanel : null}>
                {isAtArrival || dataItem.missionType === MissionTypeDto.Stickering ? (
                  <>
                    <CardItemContent
                      sx={{position: 'relative', minHeight: '40px'}}
                      direction="row"
                      margin={0}
                      grow
                      spacing={0.5}
                    >
                      <ContentItem
                        color={colors.nbms_dark_blue}
                        grow
                        style={{overflow: 'hidden', maxHeight: 40, wordBreak: 'break-word'}}
                      >
                        {hasOtherStation()}
                      </ContentItem>
                      <ContentItem
                        color={isTaxi ? '#C27710' : colors.nbms_dark_blue}
                        size={0.7}
                        radius={16}
                        backgroud={isTaxi ? '#F9F1E7' : '#EFF4F9'}
                        padding="2px 6px"
                      >
                        {isTaxi ? 'TAXI' : hasTrainNumber}
                      </ContentItem>
                      <ContentItem color={colors.mission_overview.succesfull} style={{width: 35}}>
                        {hasTime() && moment(hasTime()).format('HH:mm')}
                      </ContentItem>
                      {showDelay}
                      {showRemarkIcon}
                    </CardItemContent>
                    {!(dataItem.missionType === MissionTypeDto.Stickering) && (
                      <JourneyDetails
                        coachInfo={dataItem?.journey?.coachNumber}
                        seatInfo={dataItem?.journey?.seatNumber}
                        doorInfo={dataItem?.journey?.doorNumber}
                        trackInfo={dataItem?.journey?.lastPlannedTrackName}
                      />
                    )}
                    {dataItem.missionType === MissionTypeDto.Stickering && (
                      <JourneyDetails
                        trackInfo={dataItem?.journey?.originLastPlannedTrackName}
                        coachInfo={dataItem?.journey?.coachNumber}
                        seatInfo={dataItem?.journey?.seatNumber}
                        doorInfo={dataItem?.journey?.doorNumber}
                      />
                    )}
                    <AssistanceDetails mission={dataItem as MissionSummaryDto} />
                    {dataItem?.missionType === MissionTypeDto.Stickering && (
                      <CardItem sx={{paddingTop: '0px'}}>
                        <ContentItem
                          color={colors.nbms_dark_blue}
                          size={0.7}
                          radius={16}
                          padding="2px 6px"
                          flexDirection={'row'}
                          display="flex"
                          gap={'5px'}
                          sx={{placeItems: 'center'}}
                        >
                          <TrainStickerIcon color={colors.mission_overview.succesfull} />
                          <ContentItem>
                            {!(dataItem.missionType === MissionTypeDto.Stickering) &&
                              dataItem?.journey?.originStationName}
                          </ContentItem>
                        </ContentItem>
                      </CardItem>
                    )}
                    {!isPMR && dataItem?.missionType === MissionTypeDto.Stickering && (
                      <>
                        <Typography
                          variant="caption"
                          display="block"
                          style={{color: colors.grey_3, fontSize: 12, fontWeight: 700}}
                        >
                          {t('mission.mission-print-sticker-first-mission')}
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          style={{color: colors.grey_3, fontSize: 12, fontWeight: 500}}
                        >
                          {hasStation()}
                        </Typography>
                      </>
                    )}
                  </>
                ) : (
                  <CardItemCentered direction="column" spacing={1}>
                    <ContentItem color={colors.grey_7} style={{textAlign: 'center'}}>
                      {hasOtherStation()}
                    </ContentItem>
                  </CardItemCentered>
                )}
              </CardItem>
            </CardContent>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              style={{padding: 10, borderTop: '1px solid #D7D7D7'}}
            >
              <Stack direction="row" style={{alignItems: 'center'}}>
                <StatusItem status={dataItem?.status} />
              </Stack>
              <Stack direction="row" style={{alignItems: 'center'}}>
                {isAssigned && (
                  <Box sx={{display: 'flex', flexDirection: 'row'}}>
                    <PersonOutlineOutlinedIcon style={{color: colors.mission_overview.succesfull, fontSize: 20}} />
                    <ContentItem
                      color={colors.grey_3}
                      size={0.73}
                      style={{marginLeft: 10, color: colors.mission_overview.succesfull}}
                    >
                      {`${t('home.coordinator.assigned-to')} ${dataItem?.owner?.name}`}
                    </ContentItem>
                  </Box>
                )}
              </Stack>
            </Stack>
          </Box>
        </CardBox>
      </ListItemButton>
    </ListItem>
  );
}
