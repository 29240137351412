import {AxiosError} from 'axios';
import moment from 'moment';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {CacheContext} from 'src/context/cache/cache.store';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {NetworkContext} from 'src/context/network/network.store';
import {DistrictDto, ModelError, ReservationTypeDto, StationDto} from 'src/services/models';
import {EndPrintMissionDetailDto} from 'src/services/models/end-print-mission-detail-dto';
import {EndPrintMissionsDto} from 'src/services/models/end-print-missions-dto';
import {PrintMissionDetailDto} from 'src/services/models/print-mission-detail-dto';
import {PrintMissionDto} from 'src/services/models/print-mission-dto';
import {getDistrictFromName} from 'src/utils/getDistrictIdFromName';
import {intitialFilterState, MissionFilterActions, missionFilterReducer} from './PrintMissionFilterReducer';
import {printStickersFilter} from './printStickers.filter';
import {endMission, getPrintMission, getPrints, getPrintStickerMissions, setOrigin} from './PrintStickers.service';
import {localStorageKeys} from 'src/utils/localStorageKeys';
import { AuthContext, AuthPermissions } from 'src/context/authentication/store.auth';

enum IPrintAction {
  ALL = 'ALL',
  TO_PRINT = 'TO PRINT',
  PRINTED = 'PRINTED',
}

export interface IMissionFilter {
  groupName: string;
  trainNumber: number;
  reservationNumber: string;
  arrivalStation: string;
  departureStation: string;
  stationOfFirstDeparture: string;
  printAction: IPrintAction;
}

export interface IPrintStickerStore {
  printMissions: PrintMissionDto[];
  printMissionDetail: PrintMissionDetailDto | undefined;
  selectedPrintMission: PrintMissionDto | undefined;
  printMissionsFilterState: IMissionFilter;
  printMissionsFilterReducer: React.Dispatch<MissionFilterActions>;
  printMissionsDates: {
    startDate: string;
    endDate: string;
  };
  selectForPrint: string[];
  loadingPrintMissions: boolean;
  loadingPrintMissionDetails: boolean;
  selectForEdit: string;
  selectedOrigin: string;
  isPrinting: boolean;
  setLoadingPrintMissions: React.Dispatch<boolean>;
  handlePrintMissionDates: (date: [string, string]) => void;
  handleSelectedPrintMissionDetail: (id: string) => void;
  handlePrintMissionSearch: () => void;
  handleSelectForPrint: (id: string, variant: 'SELECT' | 'DESELECT') => void;
  handlePrint: (isPreview: boolean) => void;
  handleOriginStation: (id: string) => void;
  handleNewOrigin: (id: string) => void;
  handleNewOriginSubmit: () => void;
  assistanceFilter: () => void;
  initPrintStickerStore: () => void;
}

export const PrintStickerContext = React.createContext({
  printMissionsFilterState: {
    printAction: IPrintAction.ALL,
  },
} as IPrintStickerStore);

const PrintStickerProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const {variables} = React.useContext(EnvironmentContext);
  const {validateNetworkCall} = React.useContext(NetworkContext);
  const {permissions} = React.useContext(AuthContext);

  const selectedStations = localStorage.getItem('selectedStations');

  const [loadingPrintMissions, setLoadingPrintMissions] = React.useState(false);
  const [loadingPrintMissionDetails, setLoadingPrintMissionDetails] = React.useState(false);

  const [printMissions, setPrintMissions] = React.useState<PrintMissionDto[]>([]);
  const [selectedPrintMission, setSelectedPrintMission] = React.useState<PrintMissionDto | undefined>(undefined);
  const [printMissionDetail, setPrintMissionDetail] = React.useState<PrintMissionDetailDto | undefined>(undefined);
  const [printMissionsDates, setPrintMissionsDates] = React.useState<{startDate: string; endDate: string}>({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(7, 'days').format('YYYY-MM-DD'),
  });

  const [printMissionsFilterState, printMissionsFilterReducer] = React.useReducer(
    missionFilterReducer,
    intitialFilterState(true),
  );
  const [isPrinting, setIsPrinting] = React.useState(false);

  const [selectForPrint, setSelectForPrint] = React.useState<string[]>([]);

  const [pdfUrl, setPdfUrl] = React.useState('');
  const [doPrint, setDoPrint] = React.useState(false);
  const downloadRef = React.useRef(null);

  const [selectForEdit, setSelectForEdit] = React.useState<string>('');
  const [selectedOrigin, setSelectedOrigin] = React.useState('');
  const [newOrigin, setNewOrigin] = React.useState('');

  const SERVICES = {
    getAllPrintStickerMissions: () => {
      const districtId = localStorage.getItem(localStorageKeys.SELECTED_DISTRICT) || '';
      if (selectedStations?.length === 0 && districtId.length === 0) {
        return null;
      }
      if (permissions && permissions?.includes(AuthPermissions.PrintStickeringMissions)) {
        setLoadingPrintMissions(true);
        getPrintStickerMissions(variables.BASE_ENDPOINT, {
          from: printMissionsDates.startDate,
          to: printMissionsDates.endDate,
          districtIds: [districtId],
          stationIds: districtId.length > 0 ? undefined : selectedStations?.split(','),
        })
          .then(res => {
            if (res) {
              setPrintMissions(printStickersFilter(printMissionsFilterState, res));
            }
            setLoadingPrintMissions(false);
          })
          .catch((err: AxiosError) => {
            if (err.response) {
              validateNetworkCall(err.response.status, err.response.data as ModelError);
            }
            setLoadingPrintMissions(false);
          });
      }
    },
    getPrintStickerMissionDetail: (id: string) => {
      setLoadingPrintMissionDetails(true);
      if (id) {
        getPrintMission(variables.BASE_ENDPOINT, id)
          .then(res => {
            if (res) {
              setPrintMissionDetail(res);
            }

            setLoadingPrintMissionDetails(false);
          })
          .catch((err: AxiosError) => {
            if (err.response) {
              validateNetworkCall(err.response.status, err.response.data as ModelError);
            }
            setLoadingPrintMissionDetails(false);
          });
      }
    },
    printStickerMissions: (isPreview: boolean) => {
      setLoadingPrintMissions(true);
      const origin = printMissions.find(el => el.id === selectForPrint[0]);
      const originName = origin ? origin.journey?.originStationName : '';
      getPrints(variables.BASE_ENDPOINT, selectForPrint, originName ? originName : '', isPreview)
        .then(async res => {
          if (res) {
            const url = window.URL.createObjectURL(new Blob([res as string], {type: 'application/pdf'}));
            setPdfUrl(url);
            setDoPrint(true);
            //@ts-ignore
            downloadRef.current.click();
            const missions = selectForPrint.map(el => {
              const detail: EndPrintMissionDetailDto = {
                missionId: el,
                reservationType: ReservationTypeDto.Group,
              };
              return detail;
            });
            if (!isPreview) {
              const missionsToEnd: EndPrintMissionsDto = {missions: missions};
              await SERVICES.endMissions(missionsToEnd);
            }
            SERVICES.getAllPrintStickerMissions();
            setIsPrinting(false);
          }
        })
        .catch((err: AxiosError) => {
          if (err.response) {
            validateNetworkCall(err.response.status, err.response.data as ModelError);
            setIsPrinting(false);
          }
        });
      setSelectForPrint([]);
      setLoadingPrintMissions(false);
    },
    updateOrigin: () => {
      setLoadingPrintMissions(true);
      setOrigin(variables.BASE_ENDPOINT, newOrigin, selectForEdit)
        .then(res => {
          if (res) {
            setLoadingPrintMissions(false);
            SERVICES.getAllPrintStickerMissions();
          }
        })
        .catch((err: AxiosError) => {
          if (err.response) {
            validateNetworkCall(err.response.status, err.response.data as ModelError);
            setLoadingPrintMissions(false);
          }
        });
    },
    endMissions: async (missionsToEnd: EndPrintMissionsDto) => {
      await endMission(variables.BASE_ENDPOINT, missionsToEnd)
        .then(res => {
          if (res) {
          }
        })
        .catch((err: AxiosError) => {
          if (err.response) {
            validateNetworkCall(err.response.status, err.response.data as ModelError);
          }
        });
    },
  };

  const handlePrintMissionDates = (dates: [string, string]) => {
    setPrintMissionsDates({
      startDate: moment(dates[0]).format('yyyy-MM-DD'),
      endDate: moment(dates[1]).format('yyyy-MM-DD'),
    });
  };

  const handleSelectedPrintMissionDetail = (id: string) => {
    const mission = printMissions.find(el => el.id === id);
    if (mission && mission.id) {
      setSelectedPrintMission(mission);
      SERVICES.getPrintStickerMissionDetail(mission.id);
    }
  };

  const handleSelectForPrint = (id: string, variant: 'SELECT' | 'DESELECT') => {
    if (variant === 'SELECT') {
      setSelectForPrint(prev => [...prev, id]);
    } else {
      const prevousState = selectForPrint;
      const filtered = prevousState.filter(el => el !== id);
      setSelectForPrint(filtered);
    }
  };

  const handlePrintMissionSearch = () => {
    SERVICES.getAllPrintStickerMissions();
  };

  const handlePrint = (isPrint: boolean) => {
    if (selectForPrint.length > 0 && !loadingPrintMissions) {
      setIsPrinting(true);
      SERVICES.printStickerMissions(isPrint);
    }
  };

  const handleOriginStation = (id: string) => {
    setSelectForEdit(id);
    const name = printMissions.find(el => id === el.id)?.journey?.originStationName;
    setSelectedOrigin(name || '');
  };

  const handleNewOrigin = (id: string) => {
    setNewOrigin(id);
  };

  const handleNewOriginSubmit = () => {
    SERVICES.updateOrigin();
  };

  const assistanceFilter = () => {
    SERVICES.getAllPrintStickerMissions();
  };

  const initPrintStickerStore = () => {
    SERVICES.getAllPrintStickerMissions();
  };

  React.useEffect(() => {
    initPrintStickerStore();
  }, [permissions]);

  return (
    <PrintStickerContext.Provider
      value={{
        printMissions,
        printMissionDetail,
        selectedPrintMission,
        printMissionsFilterState,
        printMissionsFilterReducer,
        printMissionsDates,
        selectForPrint,
        selectForEdit,
        selectedOrigin,
        isPrinting,
        loadingPrintMissions,
        loadingPrintMissionDetails,
        handlePrintMissionDates,
        handleSelectedPrintMissionDetail,
        handlePrintMissionSearch,
        handleSelectForPrint,
        handlePrint,
        handleOriginStation,
        handleNewOrigin,
        handleNewOriginSubmit,
        assistanceFilter,
        setLoadingPrintMissions,
        initPrintStickerStore,
      }}
    >
      {children}
      {doPrint ? <a href={pdfUrl} target="_blank" ref={downloadRef} /> : null}
    </PrintStickerContext.Provider>
  );
};

export default PrintStickerProvider;
