/* tslint:disable */
/* eslint-disable */
//DTO
/**
 * 
 * @export
 * @enum {string}
 */
export enum LanguageDto {
    Dutch = 'Dutch',
    French = 'French',
    German = 'German',
    English = 'English'
}

