import axios, {AxiosError} from 'axios';
import {EndPrintMissionsDto} from 'src/services/models/end-print-missions-dto';
import {MissionActionResultDto} from 'src/services/models/mission-action-result-dto';
import {PrintMissionDetailDto} from 'src/services/models/print-mission-detail-dto';
import {PrintMissionDto} from 'src/services/models/print-mission-dto';
import {SearchPrintMissionsDto} from 'src/services/models/search-print-missions-dto';
import {StickerPdfRequestDto} from 'src/services/models/sticker-pdf-request-dto';

const getPrintStickerMissions = async (baseUrl: string, params: SearchPrintMissionsDto) => {
  const fullUrl = `${baseUrl}/api/missions/print`;
  try {
    const res = await axios.post<PrintMissionDto[]>(fullUrl, {
      ...params,
    });
    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};

const getPrintMission = async (baseUrl: string, id: string) => {
  const fullUrl = `${baseUrl}/api/missions/print/${id}`;

  try {
    const res = await axios.get<PrintMissionDetailDto>(fullUrl);
    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};

const getPrints = async (baseUrl: string, ids: string[], station: string | undefined, isPrint: boolean) => {
  const fullUrl = `${baseUrl}/api/missions/print/pdf`;

  const body: StickerPdfRequestDto = {
    ids: ids,
    originStation: station,
    keepBackground: isPrint,
  };

  try {
    const res = await axios(fullUrl, {
      data: body,
      method: 'POST',
      responseType: 'blob',
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err as AxiosError);
  }
};

const setOrigin = async (baseUrl: string, stationUic: string, missionId: String) => {
  const fulUrl = `${baseUrl}/api/missions/origin`;

  try {
    const res = await axios.post<MissionActionResultDto>(fulUrl, {
      missionId,
      stationUic,
    });
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};

const endMission = async (baseUrl: string, missionsToEnd: EndPrintMissionsDto) => {
  const fullUrl = `${baseUrl}/api/missions/print/end`;

  try {
    const res = await axios.post<MissionActionResultDto>(fullUrl, {
      ...missionsToEnd,
    });
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};

export {getPrintStickerMissions, getPrintMission, getPrints, setOrigin, endMission};
