import type {i18n} from 'i18next';
import {LanguageDto, StationDto} from 'src/services/models';

export const getStationFromName = (i18n: i18n, name: string, stations: StationDto[]) => {
  let activeLanguage = LanguageDto.English;
  if (i18n.language.toLocaleUpperCase() === 'NL') activeLanguage = LanguageDto.Dutch;
  if (i18n.language.toLocaleUpperCase() === 'FR') activeLanguage = LanguageDto.French;
  if (i18n.language.toLocaleUpperCase() === 'EN') activeLanguage = LanguageDto.English;

  const filterOnLanguage = stations.map(s => {
    return {id: s.id, name: s.name?.find(t => t.language === activeLanguage)};
  });

  const getid = filterOnLanguage.find(el => el?.name?.text === name);

  return getid?.id;
};
