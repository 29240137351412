import React from 'react';

import {Box} from '@mui/system';
import {Paper, Grid, TextField} from '@mui/material';
import {FormAction, FormActionKind, FormState} from '../../formState';
import {useTranslation} from 'react-i18next';

export const OwnerAndClient: React.FC<{state: FormState; dispatch: React.Dispatch<FormAction>}> = ({
  state,
  dispatch,
}) => {
  const {t} = useTranslation();

  return (
    <Box sx={{padding: '0px 25px'}}>
      <Paper elevation={0} sx={{marginBottom: '15px', marginTop: '15px'}}>
        <b>{t('pmr.search.owner-and-client')}</b>
      </Paper>
      <Grid container>
        <Grid item xs={3}>
          <TextField
            label={t('create-mission.surname')}
            sx={{width: '95%'}}
            value={state.firstName}
            onChange={e => dispatch({type: FormActionKind.FIRST_NAME, payload: e.target.value})}
            type="text"
            placeholder={t('create-mission.surname')}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label={t('create-mission.name')}
            sx={{width: '95%'}}
            value={state.lastName}
            onChange={e => dispatch({type: FormActionKind.LAST_NAME, payload: e.target.value})}
            type="text"
            placeholder={t('create-mission.name')}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label={t('create-mission.mail')}
            sx={{width: '95%'}}
            value={state.email}
            onChange={e => dispatch({type: FormActionKind.EMAIL, payload: e.target.value})}
            type={t('create-mission.mail')}
            placeholder={t('create-mission.mail')}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

//WOner of the mission might nt be able to be implemented
/**


<Grid item xs={3}>
  <TextField
    label={t('pmr.search.owner-of-mission')}
    sx={{width: '95%'}}
    value={state.owner}
    onChange={e => dispatch({type: FormActionKind.OWNER, payload: e.target.value})}
    type="text"
    placeholder={t('pmr.search.owner-of-mission')}
    disabled={true}
  />
</Grid>



 */

/**
  
<Grid item>
  <TextField
    value={state.client}
    onChange={e => dispatch({type: FormActionKind.CLIENT, payload: e.target.value})}
    type="text"
    placeholder={t('pmr.search.owner-and-client')}
  />
</Grid> 
 */
