import * as React from 'react';

import {SearchOutlined} from '@ant-design/icons';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import {Checkbox, Col, DatePicker, Row, Space, Typography} from 'antd';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import SelectComponent from 'src/scenes/coordinator/SelectComponent';
import {ButtonOutlined} from '../buttons/ButtonOutlined/ButtonOutlined';
import {DateRangeInput} from '../inputs/DateRangeInput/DateRangeInput';
import {ControlledTextInput} from '../inputs/TextInput/ControlledTextInput';
import {IMissionFilter} from 'src/scenes/coordinator/PrintStickers/PrintStickers.store';
import {MissionFilterActions, IMissionFilterType} from 'src/scenes/coordinator/PrintStickers/PrintMissionFilterReducer';
import {CacheContext} from 'src/context/cache/cache.store';
import {StationDto} from 'src/services/models';
import {getStationFromId} from 'src/utils/getStationFromId';
import {getStationFromName} from 'src/utils/getStationFromName';
const {Text} = Typography;

interface Props {
  isReservation: boolean | undefined;
  searchOnReservation: boolean | undefined;
  toggleSearchOnReservation: React.Dispatch<boolean>;
  missionDates: {
    startDate: string;
    endDate: string;
  };
  handleMissionDates: (date: [string, string]) => void;
  filterState: IMissionFilter;
  filterReducer: React.Dispatch<MissionFilterActions>;
  handleSearch: () => void;
}

const Form: React.FC<Props> = ({
  isReservation,
  missionDates,
  handleMissionDates,
  filterState,
  filterReducer,
  handleSearch,
  searchOnReservation,
  toggleSearchOnReservation,
}) => {
  const {stations} = React.useContext(CacheContext);

  const {t, i18n} = useTranslation();

  const stationList = (stations as StationDto[])
    // .map(el => el.id)
    .map(el => ({
      id: el.id,
      value: el.id ? getStationFromId(i18n, el.id, stations as StationDto[]) : '',
      name: el.name
    }
    ))
    .sort((a, b) => !!a.value && !!b.value ? a.value.localeCompare(b.value) : -1);

  const [checked, setChecked] = useState(false);

  return (
    <Box>
      <form
        onSubmit={(e: any) => {
          e.preventDefault();
        }}
      >
        <Collapse in={checked} collapsedSize={90}>
          <Row gutter={26} style={{marginTop: 20, marginBottom: 20}}>
            <Col span={10}>
              <Space direction="vertical" style={{width: '100%'}}>
                <Row>
                  {isReservation ? (
                    <Space direction="vertical" style={{width: '100%'}}>
                      <Text style={{fontSize: 14, color: '#666666'}}>{t('check-in.overview.date')}</Text>
                      <DatePicker
                        style={{width: '100%'}}
                        //@ts-ignore
                        onChange={data => handleMissionDates(data)}
                        disabled={isReservation ? searchOnReservation : undefined}
                        placeholder={missionDates.startDate}
                        allowClear={false}
                      />
                    </Space>
                  ) : (
                    <DateRangeInput
                      defaultEndDay={missionDates !== undefined ? missionDates.endDate : ''}
                      onChange={(data: [string, string]) => handleMissionDates(data)}
                      label={t('check-in.overview.date')}
                      value={missionDates}
                    />
                  )}
                </Row>
                <Row>
                  <ControlledTextInput
                    onChange={(e: {target: {value: string}}) =>
                      filterReducer({type: IMissionFilterType.GROUP_NAME, payload: e.target.value})
                    }
                    label={t('reservation.form.group.label')}
                    placeholderValue={t('reservation.form.group.placeholder')}
                    value={filterState ? filterState.groupName : ''}
                    disabled={isReservation ? searchOnReservation : undefined}
                  />
                </Row>
                <Row>
                  <ControlledTextInput
                    onChange={(e: {target: {value: string}}) =>
                      filterReducer({
                        type: IMissionFilterType.TRAIN_NUMBER,
                        payload: !Number.isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : 0,
                      })
                    }
                    label={t('reservation.form.train.label')}
                    placeholderValue={t('reservation.form.train.placeholder')}
                    disabled={isReservation ? searchOnReservation : undefined}
                    value={filterState === undefined || filterState.trainNumber === 0 ? null : filterState.trainNumber}
                  />
                </Row>
              </Space>
            </Col>

            <Col span={10} offset={4}>
              <Space direction="vertical" style={{width: '100%'}}>
                <Row>
                  {isReservation ? (
                    <Col span={2}>
                      <div style={{display: 'grid', placeItems: 'center', height: '100%'}}>
                        <Checkbox
                          checked={searchOnReservation}
                          onChange={() => toggleSearchOnReservation(!searchOnReservation)}
                        />
                      </div>
                    </Col>
                  ) : null}
                  <Col span={isReservation ? 22 : 24}>
                    <ControlledTextInput
                      onChange={(e: {target: {value: string}}) =>
                        filterReducer({type: IMissionFilterType.RESERVATION_NUMBER, payload: e.target.value})
                      }
                      label={t('reservation.form.number.label')}
                      placeholderValue={t('reservation.form.number.placeholder')}
                      value={filterState ? filterState.reservationNumber : ''}
                      style={{width: '100%'}}
                      disabled={isReservation ? !searchOnReservation : undefined}
                    />
                  </Col>
                </Row>
                <Row>
                  <Space direction="vertical" style={{width: '100%'}}>
                    <Text style={{fontSize: 14, color: '#666666'}}> {t('create-grobo-mission.arrival')} </Text>
                    <SelectComponent
                      size="default"
                      placeholder={t('reservation.form.arrival-station-placeholder')}
                      onSelectedChange={(e: string, val: {key: string}) => {
                        filterReducer({type: IMissionFilterType.ARRIVAL_STATION, payload: val?.key || ''});
                      }}
                      options={stationList}
                      isItalic
                      isSearch
                      value={getStationFromId(
                        i18n,
                        filterState ? filterState.arrivalStation : '',
                        stations as StationDto[],
                      )}
                      isAvoid={undefined}
                      disabled={isReservation ? searchOnReservation : undefined}
                      allowClear
                    />
                  </Space>
                </Row>
                <Row>
                  <Space direction="vertical" style={{width: '100%'}}>
                    <Text style={{fontSize: 14, color: '#666666'}}> {t('create-grobo-mission.station')} </Text>
                    <SelectComponent
                      size="default"
                      placeholder={t('reservation.form.departure-station-placeholder')}
                      onSelectedChange={(e: string, val: {key: string}) =>
                        filterReducer({type: IMissionFilterType.DEPARTURE_STATION, payload: val?.key || ''})
                      }
                      options={stationList}
                      isItalic
                      isSearch
                      value={getStationFromId(
                        i18n,
                        filterState ? filterState.departureStation : '',
                        stations as StationDto[],
                      )}
                      isAvoid={undefined}
                      disabled={isReservation ? searchOnReservation : undefined}
                      allowClear
                    />
                  </Space>
                </Row>
                {isReservation ? null : (
                  <Row>
                    <Text style={{fontSize: 14, color: '#666666'}}> {t('reservation.form.firstDeparture.label')} </Text>
                    <SelectComponent
                      size="default"
                      placeholder={t('reservation.form.firstDeparture.placeholder')}
                      onSelectedChange={(e: string) => {
                        filterReducer({
                          type: IMissionFilterType.STATION_FIRST_DEPARTURE,
                          payload: getStationFromName(i18n, e, stations as StationDto[]) || '',
                        });
                      }}
                      options={stationList}
                      isItalic
                      isSearch
                      value={getStationFromId(
                        i18n,
                        filterState ? filterState.stationOfFirstDeparture : '',
                        stations as StationDto[],
                      )}
                      isAvoid={undefined}
                      disabled={isReservation ? searchOnReservation : undefined}
                      allowClear
                    />
                  </Row>
                )}
              </Space>
            </Col>
          </Row>
        </Collapse>
        <Row style={{marginTop: 32, justifyContent: 'flex-end', gap: 15}}>
          <ButtonOutlined
            label={t('create-grobo-mission.search')}
            onClick={() => handleSearch()}
            icon={<SearchOutlined />}
            htmlType="submit"
            disabled={isReservation && searchOnReservation ? filterState.reservationNumber.length === 0 : false}
          />
        </Row>
      </form>
      <div
        style={{
          width: '300px',
          margin: 'auto',
        }}
      >
        <Divider className="divider-with-color" onClick={() => setChecked(!checked)}>
          {!checked ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        </Divider>
      </div>
    </Box>
  );
};

export {Form};
