import React from 'react';
import Badge from '@mui/material/Badge';
import {styled} from '@mui/material/styles';

type Props = {
  isSelected: boolean;
  amount: number;
};

export const BadgeItem: React.FC<Props> = ({isSelected, amount}) => {
  if (isSelected) {
    return <BadgeItemSelectedStyles data-testid="badge-item-selected" badgeContent={amount.toString()} />;
  }

  return <BadgeItemStyles data-testid="badge-item" badgeContent={amount.toString()} />;
};

export const baseStyles = {
  fontSize: '0.6rem',
  fontWeight: 700,
  padding: '0 2px',
  right: 5,
};

export const BadgeItemStyles = styled(Badge)({
  '& .MuiBadge-badge': {
    ...baseStyles,
    color: '#0069B4',
    border: '1px solid #0069B4',
    backgroundColor: '#fff',
  },
});

export const BadgeItemSelectedStyles = styled(Badge)({
  '& .MuiBadge-badge': {
    ...baseStyles,
    color: '#fff',
    backgroundColor: '#0069B4',
  },
});
