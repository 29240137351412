import {Autocomplete, TextField} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {CacheContext} from 'src/context/cache/cache.store';
import {LocalizedTextDto, StationDto} from 'src/services/models';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';

export const handleFilterStations = (list: any[], value: string) => {
  const filteredOptions = list?.filter(station =>
    (station?.name[0]?.text.toLowerCase().includes(value.toLowerCase()) ||
    station?.name[1]?.text.toLowerCase().includes(value.toLowerCase()) ||
    station?.name[2]?.text.toLowerCase().includes(value.toLowerCase()))
  );
  return filteredOptions;
};

function StationAutoComplete({handleArrivalChange}: {handleArrivalChange: (a: string, b: string) => void}) {
  const {stations} = React.useContext(CacheContext);
  const {t, i18n} = useTranslation();


  return (
    <Autocomplete
      renderInput={args => <TextField label={t('create-mission.arrival')} {...args} />}
      placeholder={t('create-mission.arrival')}
      getOptionLabel={option => option.label}
      options={(stations as unknown as StationDto[]).map(el => ({
        label: handleTranslationObject(i18n, el.name as LocalizedTextDto[]),
        id: el.id,
        name: el.name
      }))}
      onChange={(_e, val) => handleArrivalChange(val?.id || '', val?.label || '')}
      filterOptions={(el, state) => {
        return handleFilterStations(el, state.inputValue)}}
    />
  );
}

export default StationAutoComplete;
