import React from 'react';
import {Box} from '@mui/system';
import {Grid, Button} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ReservationsContext} from 'src/context/reservations/reservations.store';
import {FormState} from '../../formState';

export const Buttons: React.FC<{
  setIsAdvancedSearch: React.Dispatch<React.SetStateAction<boolean>>;
  isAdvancedSearch: boolean;
  searchState: FormState;
  disableSearch: boolean;
  clearForm: () => void;
}> = ({setIsAdvancedSearch, isAdvancedSearch, searchState, disableSearch, clearForm}) => {
  const {t} = useTranslation();
  const {searchReservations, isSearching} = React.useContext(ReservationsContext);

  return (
    <Box sx={{marginTop: '15px', padding: '0px 25px 25px 25px', display: 'grid', gridTemplateColumns: 'auto 1fr'}}>
      <Button onClick={clearForm} variant="outlined" sx={{textTransform: 'none'}}>
        {t('pmr.search.clear')}
      </Button>
      <Grid container gap={2} sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
        <Grid item>
          <Button
            sx={{textTransform: 'none', textDecoration: 'underline', height: '50px'}}
            variant="text"
            onClick={() => setIsAdvancedSearch(prev => !prev)}
          >
            {isAdvancedSearch ? t('pmr.search.close') : t('pmr.search.advanced-search')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={
              isSearching ||
              disableSearch ||
              ((searchState.reservation === undefined || searchState.reservation.length === 0) &&
                searchState.departureDate === null) ||
              ((searchState.reservation === undefined || searchState.reservation.length === 0) === undefined &&
                searchState.arrivalDate === null)
            }
            onClick={() => searchReservations(searchState)}
            sx={{textTransform: 'none', padding: '6px 22px', height: '50px'}}
            color="primary"
            variant="contained"
          >
            {t('pmr.search.search')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

/*




*/
