import {Box, Container} from '@mui/material';
import {useParams} from 'react-router-dom';
import ChangeRouteProvider, {ChangeRouteContext} from './ChangeRoute.store';
import {ChangeRouteViews} from './views';
import React from 'react';
import {NoReservationsIcon} from '../pmr/icons';
import {useTranslation} from 'react-i18next';

export const ChangeRoute = () => {
  return (
    <ChangeRouteProvider>
      <ChangeRouteContainer />
    </ChangeRouteProvider>
  );
};

const ChangeRouteContainer = () => {
  const {id} = useParams<{id: string}>();
  const {selectedOriginalRoute} = React.useContext(ChangeRouteContext);
  const {t} = useTranslation();

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '260px 1fr',
        width: '100%',
        height: 'calc(100vh - 64px)',
      }}
    >
      <ChangeRouteViews.Journey id={id || ''} />
      {selectedOriginalRoute === null ? (
        <Container
          maxWidth={false}
          sx={{
            overflowY: 'scroll !important',
            maxHeight: '100vh',
            minHeight: '100vh',
            paddingBottom: '120px',
            paddingLeft: '0px !important',
            paddingRight: '0px !important',
            display: 'grid',
            placeItems: 'center',
          }}
        >
          <Box sx={{textAlign: 'center'}}>
            <NoReservationsIcon />
            <h4 style={{marginTop: '25px'}}>{t('cr.no-travel')}</h4>
            <p style={{color: 'grey', fontWeight: 'bold'}}>{t('cr.adjust-route')}</p>
          </Box>
        </Container>
      ) : (
        <Box sx={{display: 'grid', gridTemplateColumns: '520px 1fr', backgroundColor: '#EFF4F9'}}>
          <ChangeRouteViews.Original />
          <ChangeRouteViews.Alternative />
        </Box>
      )}
    </Box>
  );
};
