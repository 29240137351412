import {COIStore} from 'src/context/coi/coi.createIncident.store';
import {CoiCreateIncidentInformation} from '../coiComponents/coiCreateIncidentComponents/reducer';

export const coiCreateIncidentInformationDisabledValidations = {
  catalog: (coiStore: COIStore, state: CoiCreateIncidentInformation) => {
    return coiStore.referenceData.filter(d => d.type === state.type).length === 0;
  },
  subCatalog: (coiStore: COIStore, state: CoiCreateIncidentInformation) => {
    return (
      coiStore.referenceData.filter(d => d.type === state.type).length === 0 ||
      state.catalogId.length === 0 ||
      coiStore.referenceData.filter(d => d.type === state.type).find(d => d.id === state.catalogId)?.subCatalogs
        .length === 0
    );
  },
};
