export const isDateInBetweenGivenDates = (start: string | null, end: string | null): boolean => {
  const now = new Date().getTime();
  //If both are null
  if (start === null && end === null) {
    return true;
  }

  //If start is null check if we are already passed the end
  if (start === null && end !== null) {
    const endToMillis = new Date(end).getTime();
    if (endToMillis >= now) return true;
    return false;
  }

  //If end is null and start not check if start is before now
  if (start !== null && end === null) {
    const startToMillis = new Date(start).getTime();
    if (startToMillis <= now) return true;
    return false;
  }

  //If both exist check if we are inbetween
  if (start !== null && end !== null) {
    const startToMillis = new Date(start).getTime();
    const endToMillis = new Date(end).getTime();

    if (startToMillis <= now && endToMillis >= now) return true;
  }

  return false;
};
