import moment from 'moment';
import {Reducer} from 'react';
export interface ChangeRouteState {
  departureName: string;
  departureUic: string;
  arrivalName: string;
  arrivalUic: string;
  date: string;
  time: string;
}

export const initialChangeRouteReducerState: ChangeRouteState = {
  arrivalName: '',
  departureName: '',
  departureUic: '',
  arrivalUic: '',
  date: moment(Date.now()).format('DD-MM-yyyy'),
  time: moment(Date.now()).format('HH:mm'),
};

export enum ChangeRouteActionTypes {
  ARRIVAL = 'arrivalName',
  DEPARTURE = 'departureName',
  DATE = 'date',
  TIME = 'time',
}

export type ChangeRouteReducerActions =
  | {type: ChangeRouteActionTypes.ARRIVAL | ChangeRouteActionTypes.DEPARTURE; payload: {name: string; uic: string}}
  | {type: ChangeRouteActionTypes.DATE | ChangeRouteActionTypes.TIME; payload: string};

export const changeRouteReducer: Reducer<ChangeRouteState, ChangeRouteReducerActions> = (state, action) => {
  switch (action.type) {
    case ChangeRouteActionTypes.ARRIVAL:
      return {...state, arrivalName: action.payload.name, arrivalUic: action.payload.uic};
    case ChangeRouteActionTypes.DEPARTURE:
      return {...state, departureName: action.payload.name, departureUic: action.payload.uic};
    case ChangeRouteActionTypes.DATE:
      return {...state, date: action.payload};
    case ChangeRouteActionTypes.TIME:
      return {...state, time: action.payload};
    default:
      return state;
  }
};
