import {Button} from 'antd';
import './ButtonText.scss';
import React from 'react';

type Props = {
  label?: string;
  icon?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export function ButtonText({label, icon, onClick}: Props) {
  return (
    <Button type="text" icon={icon} className="text-button" onClick={onClick}>
      {label}
    </Button>
  );
}
