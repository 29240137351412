import axios, {AxiosError} from 'axios';
import {DisabledOriginalTravelsDto} from 'src/services/models/disabled-original-travels-dto';
import {GeoDurationRequestDto} from 'src/services/models/geo-duration-request-dto';
import {GeoDurationResponseDto} from 'src/services/models/geo-duration-response-dto';
import {ReplaceTravelsActionDto} from 'src/services/models/replace-travels-action-dto';
import {TravelResponseDto} from 'src/services/models/travel-response-dto';

export const getOriginaleRoute = async (baseUrl: string, id: string): Promise<DisabledOriginalTravelsDto> => {
  try {
    const res = await axios.get<DisabledOriginalTravelsDto>(
      `${baseUrl}/api/reservations/disableds/${id}/original-travels`,
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};

export const getDrivingDuration = async (
  baseUrl: string,
  requestData: GeoDurationRequestDto,
): Promise<GeoDurationResponseDto> => {
  try {
    const res = await axios.post<GeoDurationResponseDto>(
      `${baseUrl}/api/alternative-travel/driving-duration`,
      requestData,
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};

export const getAlternativeRoutes = async (
  baseUrl: string,
  fromUicCode: string,
  toUicCode: string,
  departingAt: Date | string,
  minimumTransferTime: number,
  maximumTransferTime: number,
  moreResultsToken?: string,
): Promise<TravelResponseDto> => {
  try {
    const res = await axios.get<TravelResponseDto>(
      `${baseUrl}/api/alternative-travel?fromUicCode=${fromUicCode}&toUicCode=${toUicCode}&departingAt=${encodeURIComponent(
        departingAt.toString(),
      )}&moreResultsToken=${
        moreResultsToken === undefined ? '' : moreResultsToken
      }&minimumTransferTime=${minimumTransferTime}&maximumTransferTime=${maximumTransferTime}`,
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};

export const saveAlternativeRoute = async (baseUrl: string, data: ReplaceTravelsActionDto) => {
  try {
    const res = await axios.post(`${baseUrl}/api/reservations/replace`, {
      ...data,
    });
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};
