import {Grid, SvgIcon} from '@mui/material';
import {CacheContext} from 'src/context/cache/cache.store';
import BlindPersonIcon from 'src/icons/BlindPersonIcon';
import ElectricWheelchairIcon from 'src/icons/ElectricWheelchaiIcon';
import FixedWheelchairIcon from 'src/icons/FixedWheelchairIcon';
import FoldingWheelchairIcon from 'src/icons/FoldingWheelchairIcon';
import OrientationProblemsIcon from 'src/icons/OrientationProblemsIcon';
import ReducedMobilityIcon from 'src/icons/ReducedMobilityIcon';
import {GroupDto} from 'src/services/models/group-dto';
import {MissionDetailDto} from 'src/services/models/mission-detail-dto';
import React from 'react';
import {getDisabilityFromId} from 'src/utils/getDisabilityFromId';
import {DisabilityDto, LocalizedTextDto} from 'src/services/models';
import {useTranslation} from 'react-i18next';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';
import {ReservationTravelDto} from 'src/services/models/reservation-travel-dto';

const returnCorrectDisabledIcon = (groboId: string) => {
  switch (groboId) {
    case 'grobo-1':
    case 'pmr-1':
    case 'pmr-fw':
      return <FoldingWheelchairIcon />;
    case 'grobo-2':
    case 'pmr-2':
    case 'pmr-wc':
      return <FixedWheelchairIcon />;
    case 'grobo-4':
    case 'pmr-3':
    case 'pmr-ew':
      return <ElectricWheelchairIcon />;
    case 'grobo-5':
    case 'pmr-4':
    case 'pmr-lm':
      return <ReducedMobilityIcon />;
    case 'grobo-6':
    case 'pmr-5':
    case 'pmr-bp':
      return <BlindPersonIcon />;
    case 'grobo-7':
    case 'pmr-21':
    case 'pmr-to':
      return <OrientationProblemsIcon />;
    default:
      return '';
  }
};

export const TravelersAssistanceInformation: React.FC<{
  mission?: MissionDetailDto;
  travel?: ReservationTravelDto;
  t: (arg0: string) => string;
}> = ({mission, travel, t}) => {
  const {disabilities} = React.useContext(CacheContext);
  const {i18n} = useTranslation();

  if (mission === undefined && travel === undefined) {
    return null;
  }

  const getDataSource = mission ? mission : travel ? travel : undefined;

  if (getDataSource === undefined) {
    return null;
  }

  if (getDataSource.traveler?.disableds && getDataSource.traveler.disableds.length === 0) {
    return null;
  }

  return (
    <Grid
      item
      xs={12}
      padding={'12px 16px'}
      sx={{border: 'solid 1px #99C3E1', borderRadius: '5px', backgroundColor: '#F9FBFD'}}
    >
      <h5>{t('mission-details.label-need-for-assistance')}</h5>
      <p style={{color: '#666666', fontWeight: 'bold'}}>{t('mission-details-v2.traveler.assistance-requested')}</p>
      <Grid container>
        {getDataSource.traveler?.disableds && getDataSource.traveler.disableds.length > 0
          ? getDataSource.traveler.disableds.map((disabled: GroupDto) => {
              return (
                !disabled.typeId?.includes('99') && (
                  <Grid item xs={4} key={disabled.typeId}>
                    <SvgIcon>{returnCorrectDisabledIcon(disabled.typeId ? disabled.typeId : '')}</SvgIcon>
                    <b style={{marginLeft: '10px', marginRight: '5px'}}>{disabled.quantity}</b>
                    {/* @ts-ignore*/}
                    {disabled.typeId
                      ? handleTranslationObject(
                          i18n,
                          getDisabilityFromId(disabilities as DisabilityDto[], disabled.typeId)
                            ?.description as LocalizedTextDto[],
                        )
                      : ''}
                  </Grid>
                )
              );
            })
          : null}
      </Grid>
    </Grid>
  );
};
