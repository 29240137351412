import {Grid, Stack} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import TrainDriverIcon from 'src/icons/TrainDriverIcon';
import TrainManagerIcon from 'src/icons/TrainManagerIcon';
import {TrainMemberDto} from 'src/services/models/train-member-dto';

export const TrainMemberInformation: React.FC<{
  driver: TrainMemberDto | null | undefined;
  manager: TrainMemberDto | null | undefined;
}> = ({driver, manager}) => {
  const showDriver = (d: TrainMemberDto | null | undefined) => {
    if (d) {
      const hasPhone = d.phoneNumber !== null && d.phoneNumber !== undefined;
      const hasName = d.name !== null && d.phoneNumber !== undefined;
      if (hasPhone || hasName) {
        return true;
      }
      return false;
    }
    return false;
  };
  const {t} = useTranslation();
  return (
    <Grid container xs={12} mb={'15px'} mt={'5px'} spacing={1}>
      {manager && (
        <Grid item xs={6}>
          <Stack spacing={1} sx={{...styles}}>
            <div>
              <TrainManagerIcon />
              <b style={{marginLeft: '15px', fontSize: '18px'}}>{t('mission-details-v2.traveler.manager')}</b>
            </div>
            <p>{manager.name}</p>
            <a href={`tel:${manager.phoneNumber}` || ''}>{manager.phoneNumber}</a>
          </Stack>
        </Grid>
      )}
      {showDriver(driver) && (
        <Grid item xs={6}>
          <Stack spacing={1} sx={{...styles}}>
            <div>
              <TrainDriverIcon />
              <b style={{marginLeft: '15px', fontSize: '18px'}}>{t('mission-details-v2.traveler.driver')}</b>
            </div>
            <p>{driver && driver.name}</p>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

const styles = {
  border: 'solid 1px #D7D7D7',
  borderRadius: '8px',
  padding: '15px',
  boxSizing: 'border-box',
  minHeight: '130px',
};
