import {Box, MenuItem, Select, SelectChangeEvent, IconButton, TextField, Button} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import {useContext, useState, useEffect, useRef} from 'react';
import {IncidentDetailsDto} from 'src/services/models/incident-details-dto';
import {mapLocationsToMenuItems} from './util';
import SendIcon from '@mui/icons-material/Send';
import useHandleCall from 'src/hooks/useHandleCall';
import {sendChats} from '../../data';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import React from 'react';
import {useTranslation} from 'react-i18next';

interface ChatFooterProps {
  incident: IncidentDetailsDto;
  handleNewSearch: () => Promise<void>;
  handleSelectLocation: (locationId: string) => void;
}

export const ChatFooter: React.FC<ChatFooterProps> = ({
  incident,
  handleNewSearch,
  handleSelectLocation
}) => {
  const {t} = useTranslation();
  const userRole = localStorage.getItem('user_role') || null;
  const [selectedLocation, setSelectedLocation] = useState<{value: string; text: string}>({
    value: 'all',
    text: t('comm-to-all'),
  });
  const [message, setMessage] = useState('');
  const {executeCall, loadingCall} = useHandleCall<void>();
  const {variables} = useContext(EnvironmentContext);

  const [selectWidth, setSelectWidth] = useState<number>(0);
  const selectViewRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const updateWidth = () => {
      if (selectViewRef.current) {
        setSelectWidth(selectViewRef.current.clientWidth);
      }
    };

    // Initial width setup
    updateWidth();

    // Resize observer to update width on resize
    const resizeObserver = new ResizeObserver(updateWidth);
    if (selectViewRef.current) {
      resizeObserver.observe(selectViewRef.current);
    }

    // Cleanup observer on unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleSelectChange = (event: SelectChangeEvent<string>, child: any) => {
    setSelectedLocation({value: event.target.value as string, text: child.props.children});
    handleSelectLocation(event.target.value as string);
  };

  const handleClearSelection = () => {
    setSelectedLocation({value: '', text: ''});
    handleSelectLocation('');
  };

  const handleSendMessage = async () => {
    if (userRole === null) {
      return;
    }
    await executeCall(
      sendChats(variables.BASE_ENDPOINT, {
        incidentId: incident.id || '',
        locationId: selectedLocation.value === 'all' ? null : selectedLocation.value,
        message: message.trim(),
        role: userRole,
      }),
    ).then(async () => {
      await handleNewSearch();
    });
    setMessage('');
    handleClearSelection();
  };

  return (
    <Box sx={{display: 'grid', gridTemplateRows: 'auto 1fr', gap: 2, padding: '8px', height: '100%'}}>
      {/* Select and Clear Button */}
      <Box
        sx={{display: 'flex', alignItems: 'center', gap: 1, maxWidth: selectWidth ? `${selectWidth}px` : 'auto'}}
        ref={selectViewRef}
      >
        <Select
          sx={{
            backgroundColor: 'white',
            flex: 1,
            maxWidth: selectWidth ? `${selectWidth}px` : 'auto', // Ensure maxWidth is respected
            '.MuiSelect-select': {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
          disabled={loadingCall}
          value={selectedLocation.value}
          onChange={handleSelectChange}
          displayEmpty
          renderValue={selected =>
            selectedLocation.text ? (
              <Box
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: selectWidth ? `calc(${selectWidth}px - 100px)` : 'auto',
                }}
              >
                {selectedLocation.text} {/* Truncate text inside the Select */}
              </Box>
            ) : (
              t('comm-to-all')
            )
          }
        >
          <MenuItem
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: selectWidth ? `${selectWidth}px` : 'auto',
            }}
            value={'all'}
          >
            {t('comm-to-all')}
          </MenuItem>
          {incident.locations && mapLocationsToMenuItems(incident.locations)}
        </Select>

        <IconButton
          disabled={!selectedLocation.value}
          onClick={handleClearSelection}
          sx={{
            height: '100%',
            width: '40px',
            backgroundColor: 'none',
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ClearIcon />
        </IconButton>
      </Box>

      {/* Text Area and Send Button */}
      {userRole !== null && (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            height: '100%',
            backgroundColor: 'white',
            padding: '8px',
            gap: '8px',
          }}
        >
          <TextField
            disabled={loadingCall}
            sx={{
              flex: 1,
              backgroundColor: 'white',
              height: '100%',
              alignSelf: 'stretch',
              '& .MuiInputBase-root': {
                border: 'none',
                height: '100%',
                padding: 0,
              },
              '& .MuiInputBase-input': {
                padding: '8px',
                boxSizing: 'border-box',
                border: 'none',
              },
            }}
            multiline
            maxRows={2}
            value={message}
            onChange={e => setMessage(e.target.value)}
            placeholder={t('placeholders.type-message')}
          />
          <Button
            disabled={message.trim().length === 0 || loadingCall}
            variant="contained"
            color="primary"
            onClick={handleSendMessage}
            sx={{height: '100%'}}
          >
            <SendIcon />
          </Button>
        </Box>
      )}
      {userRole === null && (
        <Box
          sx={{
            display: 'grid',
            placeItems: 'center',
            height: '100%',
            backgroundColor: 'white',
            padding: '8px',
            gap: '8px',
          }}
        >
          {t('check-in-before-message')}
        </Box>
      )}
    </Box>
  );
};
