import { i18n } from "i18next";
import { StationDto } from "src/services/models";
import { MissionStatusDto } from "src/services/models/mission-status-dto";
import { PrintMissionDto } from "src/services/models/print-mission-dto";
import { getStationFromId } from "src/utils/getStationFromId";
import { IMissionFilter } from "./PrintStickers.store";

enum IPrintAction {
    ALL = 'ALL',
    TO_PRINT = 'TO PRINT',
    PRINTED = 'PRINTED'
}

export const printStickersFilter = (filter: IMissionFilter, missions: PrintMissionDto[]): PrintMissionDto[] => {
    let result = missions;

    //Groupname
    if(filter.groupName.length > 0){
        result = result.filter(el => el.groupName?.toLocaleUpperCase().includes(filter.groupName.toLocaleUpperCase()))
    }

    //TrainNumber
    if(filter.trainNumber !== 0){
        result = result.filter(el => el.journey?.trainNumber?.toString().toLocaleUpperCase().includes(filter.trainNumber.toString().toLocaleUpperCase()))
    }

    //ArrivalStation
    if(filter.arrivalStation.length > 0){
        result = result.filter(el => el.journey?.otherStationId?.toLocaleUpperCase().includes(filter.arrivalStation.toLocaleUpperCase()))
    }

    //Departurestation
    if(filter.departureStation.length > 0){
        result = result.filter(el => el.journey?.stationId?.toLocaleUpperCase().includes(filter.departureStation.toLocaleUpperCase()))
    }

    //reservationNumber
    if(filter.reservationNumber.length > 0){
        result = result.filter(el => el.reservationId?.toLocaleUpperCase().includes(filter.reservationNumber.toLocaleUpperCase()))
    }

    //stationOfFirstDeparture
    if(filter.stationOfFirstDeparture.length > 0){
        result = result.filter(el => el.journey?.originStationId?.toLocaleUpperCase().includes(filter.stationOfFirstDeparture.toLocaleUpperCase()))
    }

    //PrintAction
    if(filter.printAction === IPrintAction.ALL){
        return result;
    } else if (filter.printAction === IPrintAction.PRINTED){
        return result.filter(el => el.status === MissionStatusDto.Completed || el.status === MissionStatusDto.Stopped)
    } else  if (filter.printAction === IPrintAction.TO_PRINT){
        return result.filter(el => el.status === MissionStatusDto.New)
    }
    return result;
}