// @ts-nocheck
import {DatePicker, Space, Typography} from 'antd';

import moment from 'moment';
import {formatType} from 'src/utils/utils';
const {Text} = Typography;
const {RangePicker} = DatePicker;

export function DateRangeInput({label, onChange, defaultEndDay, allowClear = false, value}) {
  const onDateChange = data => {
    if (data) {
      const [from, to] = data;
      onChange([from.format('MM-DD-YYYY'), to.format('MM-DD-YYYY')]);
    } else {
      onChange(['', '']);
    }
  };

  return (
    <Space direction="vertical" style={{width: '100%'}}>
      {label && <Text style={{fontSize: 14, color: '#666666'}}>{label}</Text>}
      <RangePicker
        style={{width: '100%'}}
        format={formatType}
        onChange={onDateChange}
        defaultValue={value ? [moment(value.startDate), moment(value.endDate)] : [moment().add(3, 'days'), moment().add(defaultEndDay, 'days')]}
        allowClear={allowClear}
        allowEmpty={allowClear}
      />
    </Space>
  );
}
