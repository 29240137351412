import {useContext, FC} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  styled,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import PersonIcon from '@mui/icons-material/Person';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import {Phone} from '@mui/icons-material';

import {TrainDetailsDto} from 'src/services/models/train-details-dto';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {StatusItem} from 'src/components/StatusItem/StatusItem';
import {PresenceDto} from 'src/services/models/presence-dto';
import {FoodDto} from 'src/services/models/food-dto';
import {CoiEvacuationMethods} from './coiEvacuationMethods';
import {PtcarDetailsDto} from 'src/services/models/ptcar-details-dto';
import {AddressDetailsDto} from 'src/services/models/address-details-dto';
import {EvacuationDto} from 'src/services/models/evacuation-dto';
import {LocationDetailsDto} from 'src/services/models/location-details-dto';
import { DistributionDto } from 'src/services/models/distribution-dto';

interface CoiMissionDetailProps {
  locations: TrainDetailsDto[] | PtcarDetailsDto[] | AddressDetailsDto[];
  title: string;
  handleOpenPhoto: (missionId: string, photoId: string) => void;
  handleOpenEvacuationModal: (mission: LocationDetailsDto, evacuation: EvacuationDto) => void;
  isIncidentClosed: boolean;
  handleOpenCoiTarget: (targetIndex: number | null) => void;
}

export const CoiMissionDetail: FC<CoiMissionDetailProps> = ({
  locations,
  title,
  handleOpenPhoto,
  handleOpenEvacuationModal,
  isIncidentClosed,
  handleOpenCoiTarget,
}) => {
  const {t} = useTranslation();
  const {variables} = useContext(EnvironmentContext);

  const BoxContainer = styled('div')(({theme}) => ({
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    [theme.breakpoints.down(1600)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    }
  }));

  const AccordionSummaryBox = styled(Box)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    width: '100%',
    paddingRight: '10px',
    [theme.breakpoints.down(1600)]: {
      alignItems: 'flex-start',
    }
  }));

  return (
    <Box sx={{marginTop: '20px'}}>
      <Typography sx={{fontWeight: 'bold', fontSize: '18px', fontFamily: 'Arial, sans-serif', marginBottom: '10px'}}>
        {title}
      </Typography>
      <Box>
        {locations.map((location: TrainDetailsDto, indx: number) => {
          const evacuationsTrains = location.evacuation?.filter(evacuation => evacuation.type === 'Train');
          const evacuationsBuses = location.evacuation?.filter(evacuation => evacuation.type === 'Bus');
          const evacuationsTaxis = location.evacuation?.filter(evacuation => evacuation.type === 'Taxi');
          const evacuationsFoot = location.evacuation?.filter(evacuation => evacuation.type === 'Foot');
          const evacuationsEmergencyVehicle = location.evacuation?.filter(
            evacuation => evacuation.type === 'EmergencyVehicle',
          );
          const foodOnTrain = [...(location?.distribution || [])].sort((a, b) => {
            if (!!a.type && !!b.type) {
              return a.type > b.type ? 1 : b.type > a.type ? -1 : 0;
            }
            return 0;
          });
          const photosOnTrain = location.photos;

          return (
            <Accordion
              key={location.id}
              elevation={0}
              sx={{
                border: '1px solid rgba(0, 105, 180, 0.3)',
                borderRadius: '4px',
                marginBottom: '10px',
                '&::before': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id={`location-${location.id}-header`}
                aria-controls={`location-${location.id}-content`}
              >
                <AccordionSummaryBox>
                  <Box>
                    {location?.trainNumber ? (
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '10px',
                          alignItems: 'center',
                          marginRight: '10px',
                          position: 'relative',
                          }}
                        >
                        {location.mainLocation ? (
                          <Box
                            sx={{
                              width: '8px',
                              height: '40px',
                              backgroundColor: '#003B8C',
                              borderRadius: '0 10px 10px 0',
                              top: '-6px',
                              left: '-16px',
                              position: 'absolute',
                            }}
                          />
                        ) : null}
                        <a
                          target="_blank"
                          href={`${variables.ATMS_BASE_URL}/trains/${location.trainNumber}/${location.departureDate}`}
                        >
                          <Typography
                            sx={{
                              fontWeight: 'bold',
                              color: '#0069B4',
                              border: '1.5px solid #707070',
                              padding: '2px 4px',
                              borderRadius: '4px',
                            }}
                          >
                            {location.trainNumber}
                          </Typography>
                        </a>
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{height: 'auto', paddingTop: '10px', backgroundColor: '#25336C'}}
                        />
                      </Box>
                    ) : null}
                  </Box>
                  {location?.trainNumber ? (
                    <BoxContainer>
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          textWrap: 'pretty',
                        }}
                      >
                        {location?.fromStation}
                      </Typography>
                      <Divider sx={{backgroundColor: 'grey', height: '2px', width: '20px'}} />
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          textWrap: 'pretty',
                        }}
                      >
                        {'toStation' in location ? location.toStation : ''}
                      </Typography>
                    </BoxContainer>
                  ) : null}
                  {'name' in location ? (
                    <Box sx={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                      marginRight: '10px',
                      position: 'relative',
                      }}
                    >
                      {location.mainLocation ? (
                          <Box
                            sx={{
                              width: '8px',
                              height: '40px',
                              backgroundColor: '#003B8C',
                              borderRadius: '0 10px 10px 0',
                              top: '-6px',
                              left: '-16px',
                              position: 'absolute',
                            }}
                          />
                        ) : null}
                      <Typography sx={{fontWeight: 'bold'}}>{location.name}</Typography>
                    </Box>
                  ) : null}
                  {'address' in location ? (
                    <Box sx={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                      marginRight: '10px',
                      position: 'relative',
                      }}
                    >
                      {location.mainLocation ? (
                          <Box
                            sx={{
                              width: '8px',
                              height: '40px',
                              backgroundColor: '#003B8C',
                              borderRadius: '0 10px 10px 0',
                              top: '-6px',
                              left: '-16px',
                              position: 'absolute',
                            }}
                          />
                        ) : null}
                      <Typography sx={{fontWeight: 'bold'}}>{location.address}</Typography>
                    </Box>
                  ) : null}
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                      justifySelf: 'flex-end',
                      marginLeft: 'auto',
                    }}
                  >
                    {'status' in location && (
                      <StatusItem
                        label={(location.status as string) || ''}
                        color={location.status ? '#C27710' : 'grey'}
                      />
                    )}
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                        border: '1.5px solid black',
                        borderRadius: '8px',
                        padding: '2px 8px',
                        // marginLeft: '10px',
                        minWidth: 'fit-content',
                        height: 'fit-content',
                        width: 'fit-content',
                      }}
                      onClick={event => {
                        event.stopPropagation();
                        handleOpenCoiTarget(indx);
                      }}
                    >
                      <AssignmentIndOutlinedIcon sx={{fontSize: '18px', color: 'black'}} />
                      <Typography sx={{fontWeight: 'bold', fontSize: '14px'}}>{t('coi.incident-edit-coi')}</Typography>
                    </Box>
                  </Box>
                </AccordionSummaryBox>
              </AccordionSummary>
              <AccordionDetails sx={{padding: '0 0 10px 0'}}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    backgroundColor: '#EFF4F9',
                    padding: '20px 20px 20px 90px',
                  }}
                >
                  {location.employeeName ? (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box sx={{display: 'flex', gap: '10px'}}>
                        <PersonIcon sx={{fontSize: '16px'}} />
                        <Typography sx={{fontWeight: 700, fontSize: '14px'}}>{location.employeeName}</Typography>
                      </Box>
                      {location.employeePhoneNumber ? (
                        <a
                          href={`dica:${location.employeePhoneNumber?.replace('+', '000')}`}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#066CB5',
                            textDecoration: 'none',
                          }}
                        >
                          <Phone sx={{marginRight: '10px', fontSize: '20px'}} />
                          <Typography sx={{fontWeight: 700, fontSize: '14px'}}>
                            {location.employeePhoneNumber}
                          </Typography>
                        </a>
                      ) : null}
                    </Box>
                  ) : null}
                  {location.assignments?.map(assignment => (
                    <Box
                      key={`assigments-employee-${assignment.employeeId}`}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '10px',
                      }}
                    >
                      <Box sx={{display: 'flex', gap: '10px'}}>
                        <SupervisorAccountOutlinedIcon sx={{fontSize: '16px'}} />
                        <Typography sx={{fontWeight: 700, fontSize: '14px'}}>{assignment.employeeName}</Typography>
                      </Box>
                      {assignment.employeePhoneNumber ? (
                        <a
                          href={`dica:${assignment.employeePhoneNumber?.replace('+', '000')}`}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#066CB5',
                            textDecoration: 'none',
                          }}
                        >
                          <Phone sx={{marginRight: '10px', fontSize: '20px'}} />
                          <Typography sx={{fontWeight: 700, fontSize: '14px'}}>
                            {assignment.employeePhoneNumber}
                          </Typography>
                        </a>
                      ) : null}
                    </Box>
                  ))}
                </Box>
                <Box
                  sx={{
                    borderRadius: '4px',
                    marginBottom: '10px',
                    padding: '15px 20px',
                  }}
                >
                  <Typography sx={{fontWeight: 'bold', fontSize: '18px', marginBottom: '10px'}}>
                    {t('coi.incident-presences')}
                  </Typography>
                  <Table size="small">
                    <TableBody>
                      {!!location.coiArrived ? (
                        <TableRow sx={{padding: '10px 0px'}}>
                          <TableCell sx={{fontWeight: 'bold'}}>{t('common.coi')}</TableCell>
                          <TableCell align="right">
                            {moment(location.coiArrived.arrivedAtExactLocation).format('HHumm')}
                          </TableCell>
                        </TableRow>
                      ) : null}
                      {location.presences
                        ?.filter((p: PresenceDto) => !!p.arrivalAt)
                        .map(presence => (
                          <TableRow key={`presence-${presence.type}`}>
                            <TableCell sx={{fontWeight: 'bold'}}>{presence.type}</TableCell>
                            <TableCell align="right">{moment(presence.arrivalAt).format('HHumm')}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Box>
                <CoiEvacuationMethods
                  evacuationsTrains={evacuationsTrains}
                  evacuationsBuses={evacuationsBuses}
                  evacuationsTaxis={evacuationsTaxis}
                  evacuationsFoot={evacuationsFoot}
                  evacuationsEmergencyVehicle={evacuationsEmergencyVehicle}
                  handleOpenEvacuationModal={(evacuation: EvacuationDto) =>
                    handleOpenEvacuationModal(location, evacuation)
                  }
                  isIncidentClosed={isIncidentClosed}
                />
                {!!foodOnTrain?.length ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                      marginTop: '20px',
                      padding: '15px 20px',
                    }}
                  >
                    <Typography sx={{fontWeight: 'bold', fontSize: '18px', marginBottom: '10px'}}>
                      {t('coi.incident-food')}
                    </Typography>
                    <Table size="small">
                      <TableBody>
                        {foodOnTrain?.map((food: DistributionDto) => (
                          <TableRow key={`detail-food-${food.type}`}>
                            <TableCell>{t(`coi.food-type.${food.type?.toLowerCase()}`)}</TableCell>
                            <TableCell align="right">{food.quantity?.toLocaleString()?.replaceAll(',', '.')}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                ) : null}
                {!!photosOnTrain?.length ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                      marginTop: '20px',
                      padding: '15px 20px',
                    }}
                  >
                    <Typography sx={{fontWeight: 'bold', fontSize: '18px', marginBottom: '10px'}}>
                      {t('coi.incident-photos')}
                    </Typography>
                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '10px'}}>
                      {photosOnTrain?.map(photo => (
                        <Button
                          key={`${location.missionId}-${photo.id}`}
                          sx={{border: 'none', backgroundColor: 'transparent'}}
                          onClick={() =>
                            photo?.id && location.missionId && handleOpenPhoto(location.missionId, photo.id)
                          }
                        >
                          <img
                            src={`data:image/jpeg;base64,${photo?.thumbnail?.base64Content}`}
                            alt={`photo-${photo.id || ''}`}
                            width="50px"
                          />
                        </Button>
                      ))}
                    </Box>
                  </Box>
                ) : null}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </Box>
  );
};
