import {useTranslation} from 'react-i18next';
import {CoiCreateIncidentStore} from '../../coi.createIncident';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  TextareaAutosize,
  TextField,
} from '@mui/material';
import {CoiFormButton} from '../common';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import {addLocationValidations, CoiCreateIncidentLocationActionTypes} from './reducer';
import React, {useMemo} from 'react';
import {CoiCreateIncidentOverview} from './coiCreateIncidentOverview';
import {ActiveTrainDto, LocalizedTextDto, StationDto, TrainDto} from 'src/services/models';
import {getActiveTrains, getTrain} from 'src/scenes/Pages/CreateMissionNew/services';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {CacheContext} from 'src/context/cache/cache.store';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';
import {handleFilterStations} from 'src/scenes/change-route/NewRoutePlanner/StationAutoComplete';

export interface CoiCreateIncidentLocationProps extends CoiCreateIncidentStore {}

export const CoiCreateIncidentLocation = ({
  coiCreateIncidentLocationState,
  coiCreateIncidentLocationDispatch,
  ...rest
}: CoiCreateIncidentLocationProps) => {
  const {t, i18n} = useTranslation();
  const {variables} = React.useContext(EnvironmentContext);
  const {stations} = React.useContext(CacheContext);

  const [formAddress, setFormAddress] = React.useState(coiCreateIncidentLocationState.address?.address || '');
  const [formTrain, setFormTrain] = React.useState<TrainDto | undefined>(undefined);

  //Ptcar
  const [formPtCar, setFormPtCar] = React.useState<StationDto | undefined>(undefined);
  const [clearPtCar, setClearPtCar] = React.useState(0);
  //Train search
  const [clearSearch, setClearSearch] = React.useState(0);
  const [activeTrains, setActiveTrains] = React.useState<ActiveTrainDto[]>([]);

  //comment modal
  const [commentModalOpen, setCommentModalOpen] = React.useState<boolean>(false);
  const [comment, setComment] = React.useState<undefined | string>(undefined);

  React.useEffect(() => {
    setFormAddress(coiCreateIncidentLocationState.address?.address || '');
  }, [coiCreateIncidentLocationState]);

  const handleChangeTrainInput = (e: string) => {
    if (e.length >= 2) {
      getActiveTrainsWithQuery(e);
    } else {
      getActiveTrainsWithoutQuery();
    }
  };

  const getActiveTrainsWithoutQuery = () => {
    getActiveTrains(variables.BASE_ENDPOINT).then(res => {
      setActiveTrains(res);
    });
  };

  const getActiveTrainsWithQuery = (trainNumber: string) => {
    getActiveTrains(variables.BASE_ENDPOINT, parseInt(trainNumber)).then(res => {
      setActiveTrains(res);
    });
  };

  const getTrainFromTrainNumber = (trainNumber: number) => {
    getTrain(variables.BASE_ENDPOINT, trainNumber, true).then((res: TrainDto) => {
      setFormTrain(res);
    });
  };

  //Menu Items
  // Stations

  const getStationItems = useMemo(() => {
    return (stations as StationDto[])
      .filter(el => el.id !== undefined)
      .map(s => (
        <MenuItem value={s.id || ''} key={s.id}>
          {handleTranslationObject(i18n, s.name as LocalizedTextDto[])}
        </MenuItem>
      ));
  }, [stations]);

  return (
    <div
      style={{
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <p style={{margin: '0', fontWeight: 'bold', fontSize: '22px', marginBottom: '12px'}}>
        {t('coi.location-information')}
      </p>
      <div style={{display: 'grid', gridTemplateColumns: '1fr 145px', gap: '15px', marginBottom: '12px'}}>
        <Autocomplete
          key={clearSearch}
          placeholder={t('coi.train-number')}
          disablePortal
          options={activeTrains
            .filter(
              el =>
                !coiCreateIncidentLocationState.trains
                  .map(s => s.trainNumber.toString())
                  .includes(el.trainNumber.toString()),
            )
            .map(el => el.trainNumber.toString())}
          renderInput={args => <TextField label={t('reservation.form.train.label')} {...args} />}
          disabled={!addLocationValidations.addTrain(coiCreateIncidentLocationState)}
          onChange={(_e, val) => getTrainFromTrainNumber(parseInt(val || '0'))}
          onInputChange={(_e, val) => {
            handleChangeTrainInput(val);
          }}
        />
        <CoiFormButton
          disabled={
            !addLocationValidations.addTrain(coiCreateIncidentLocationState) ||
            formTrain === undefined ||
            JSON.stringify(formTrain) === JSON.stringify({})
          }
          onClick={() => {
            if (formTrain) {
              coiCreateIncidentLocationDispatch({
                type: CoiCreateIncidentLocationActionTypes.ADD_TRAINS,
                payload: formTrain,
              });
              setFormTrain(undefined);
              setActiveTrains([]);
              setClearSearch(prev => prev + 1);
            }
          }}
        >
          {t('coi.add-train')}
        </CoiFormButton>
      </div>
      <div style={{display: 'grid', gridTemplateColumns: '1fr 145px', gap: '15px', marginBottom: '15px'}}>
        <Autocomplete
          key={clearPtCar}
          options={(stations as StationDto[])
            .filter(el => el.id !== undefined)
            .sort((a, b) =>
              handleTranslationObject(i18n, a.name as LocalizedTextDto[]).localeCompare(
                handleTranslationObject(i18n, b.name as LocalizedTextDto[]),
              ),
            )}
          getOptionLabel={option => handleTranslationObject(i18n, option.name as LocalizedTextDto[])}
          renderInput={params => <TextField {...params} label={t('coi.ptcar')} />}
          onChange={(_e, val) => {
            setFormPtCar(val || undefined);
          }}
          filterOptions={(el, state) => {
            return handleFilterStations(el, state.inputValue);
          }}
        />

        <CoiFormButton
          disabled={formPtCar === undefined}
          onClick={() => {
            if (formPtCar) {
              coiCreateIncidentLocationDispatch({
                type: CoiCreateIncidentLocationActionTypes.ADD_PTCAR,
                payload: formPtCar,
              });
            }
            setFormPtCar(undefined);
            setClearPtCar(prev => prev + 1);
          }}
        >
          {t('coi.add-ptcar')}
        </CoiFormButton>
      </div>
      <div style={{display: 'grid', gridTemplateColumns: '1fr 52px 145px', gap: '15px'}}>
        <TextField
          label={t('coi.address')}
          type="text"
          value={formAddress}
          disabled={!addLocationValidations.addAddress(coiCreateIncidentLocationState)}
          onChange={e => {
            setFormAddress(e.target.value);
          }}
        />
        <IconButton
          sx={{
            border: 'solid 1px rgba(175, 175, 175, 0.87)',
            borderRadius: '4px',
            '&:hover': {color: 'rgba(0, 105, 180, 1)', borderColor: 'rgba(0, 105, 180, 1)'},
          }}
          disabled={formAddress.length === 0}
          onClick={() => {
            setCommentModalOpen(true);
          }}
        >
          <AddCommentOutlinedIcon sx={{transform: 'scaleX(-1)'}} />
        </IconButton>
        <CoiFormButton
          disabled={!addLocationValidations.addAddress(coiCreateIncidentLocationState) || formAddress === ''}
          onClick={() => {
            coiCreateIncidentLocationDispatch({
              type: CoiCreateIncidentLocationActionTypes.ADD_ADDRESS,
              payload: formAddress,
            });
          }}
        >
          {t('coi.add-address')}
        </CoiFormButton>
      </div>
      <CoiCreateIncidentOverview
        coiCreateIncidentLocationState={coiCreateIncidentLocationState}
        coiCreateIncidentLocationDispatch={coiCreateIncidentLocationDispatch}
        handleCommentModal={setCommentModalOpen}
        {...rest}
      />
      <Dialog open={commentModalOpen} onClose={() => setCommentModalOpen(false)}>
        <DialogTitle>{t('common.add-comment')}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextareaAutosize
              style={{
                minHeight: '150px',
                minWidth: '400px',
                borderRadius: '4px',
                padding: '8px',
                borderColor: 'lightgray',
              }}
              placeholder={t('mission.mission-details-btn.placeholder')}
              onChange={e => setComment(e.target.value)}
              value={comment}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setCommentModalOpen(false);
              setComment(coiCreateIncidentLocationState.comment);
            }}
          >
            {t('coi.cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              coiCreateIncidentLocationDispatch({
                type: CoiCreateIncidentLocationActionTypes.ADD_ADDRESS_AND_COMMENT,
                payload: {
                  address: formAddress,
                  comment: comment ? comment : '',
                },
              });
              setCommentModalOpen(false);
            }}
          >
            {t('settings.changelanguage.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
