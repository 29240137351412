import axios, {Axios, AxiosError} from 'axios';
import {CoordinatorRoles} from 'src/services/models/coordinator-roles-dto';

export type checkinCallData = {
  districtId: string;
  roleId: string;
  phoneNumber: string;
};

type checkoutCallData = {
  districtId: string;
};

type CheckinStatus = {
  districtId: string;
  isCheckedIn: boolean;
  phoneNumber: string;
  role: CoordinatorRoles;
};

export const getCoordinatorRoles = async (baseUrl: string) => {
  try {
    const res: {data: Array<CoordinatorRoles>} = await axios.get(`${baseUrl}/api/roles`);
    return res.data;
  } catch (error) {}
};

export const checkCoordinatorIn = async (baseurl: string, data: checkinCallData) => {
  try {
    const res = await axios.post(`${baseurl}/api/workday/check-in`, data);
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};

export const checkCoordinatorOut = async (baseUrl: string, data: checkoutCallData): Promise<boolean> => {
  try {
    await axios.post(`${baseUrl}/api/workday/check-out`, data);
    return Promise.resolve(true);
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};

export const getCoordinatorCheckinStatus = async (baseUrl: string) => {
  try {
    const res: {data: CheckinStatus} = await axios.get(`${baseUrl}/api/workday/status`);
    return res.data;
  } catch (error) {}
};
