import {Grid, IconButton, Paper} from '@mui/material';
import React from 'react';
import {AssistanceNeededChip} from '../../../MissionDetailsNew/components/Chips';

import moment from 'moment';
import {MissionTypeDto} from 'src/services/models/mission-type-dto';
import {MissionStatusDto} from 'src/services/models/mission-status-dto';
import {ReservationJourneyDto} from 'src/services/models/reservation-journey-dto';
import {JourneyItem} from 'src/scenes/coordinator/MissionDetailsNew/components/Journey/JourneyItem';
import {useTranslation} from 'react-i18next';
import {Language, numberToPlaceString} from '../../util';
import {DisabilityDto, ReservationTypeDto} from 'src/services/models';
import {getWheelchairActivity} from 'src/utils/getWheelchairActivity';
import {ReservationDto} from 'src/services/models/reservation-dto';
import ExpandIcon from 'src/icons/ExpandIcon';
import {ExtendedTravelModal} from '../ExtendedTravel/ExtendedTravelModal';
import {TravelHeader} from './TravelHeader';
import {JourneyItemGroup} from './JourneyItemGroup';
import StickerOfIcon from 'src/icons/StickerOfIcon';
import PrintStickerIcon from 'src/components/icons/PrintStickerIcon';
import TrainIcon from 'src/icons/TrainIcon';
import {ReservationStatusDto} from 'src/services/models/reservation-status-dto';

export interface TravelProps {
  reservation: ReservationDto | undefined;
  journey: ReservationJourneyDto[] | undefined;
  status: MissionStatusDto | ReservationStatusDto | undefined;
  type: MissionTypeDto | ReservationTypeDto | undefined;
  variant: ReservationTypeDto | undefined;
  ind: number;
}

export const Travel: React.FC<TravelProps> = ({journey, status, type, ind, variant, reservation}) => {
  const [showExtendedTravel, setShowExtendedTravel] = React.useState(false);
  const [nthJourney, setNthjourney] = React.useState('');
  const {t, i18n} = useTranslation();

  React.useEffect(() => {
    setNthjourney(numberToPlaceString(ind + 1, i18n.language as Language));
  }, [i18n.language]);

  const getTime = (time: Date | undefined) => {
    if (time) {
      return moment(time).format('HH:mm');
    } else {
      return '';
    }
  };

  const handleCloseModal = () => {
    setShowExtendedTravel(false);
  };

  const hasAnyAssistances = () => {
    return journey?.some(el => el.withArrivalAssistance) || journey?.some(el => el.withDepartureAssistance);
  };

  const hasWheelChair = () => {
    return getWheelchairActivity(
      reservation?.travels ? (reservation.travels[0].traveler?.disableds as unknown as Array<{typeId: string}>) : [],
    );
  };

  if (!journey) {
    return null;
  }

  return (
    <Paper
      elevation={1}
      sx={{
        borderRadius: '12px',
        padding: '24px',
        border: `${hasAnyAssistances() ? 'solid 3px green' : 'none'}`,
        marginTop: '0px',
      }}
    >
      <Grid container>
        <Grid container spacing={2}>
          <Grid item width="auto">
            <h4 style={{display: 'inline', textTransform: 'capitalize', margin: '0px'}}>
              {nthJourney} {t('mission-details-v2.journey.journey-title')}
            </h4>
          </Grid>
          <Grid item width={'170px'}>
            {hasAnyAssistances() && <AssistanceNeededChip />}
          </Grid>
          {type === MissionTypeDto.Stickering ? (
            <>
              <Grid item width={'25px'}>
                {status === MissionStatusDto.Completed ? <PrintStickerIcon /> : <StickerOfIcon />}
              </Grid>
            </>
          ) : null}

          <Grid item xs sx={{display: 'flex', justifyContent: 'flex-end', padding: '0px'}}>
            {/*@ts-ignore*/}
            {reservation.missions.length > 1 && (
              <IconButton
                size="large"
                onClick={() =>
                  //@ts-ignore
                  setShowExtendedTravel(reservation.missions.length > 0)
                }
              >
                <ExpandIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <TravelHeader />

        {journey.map((journ, ind) => {
          return (
            <React.Fragment key={journ.id}>
              <JourneyItemGroup
                reservation={reservation}
                ind={ind}
                journ={journ}
                journey={journey}
                type={type}
                variant={variant}
                hasWheelChairDep={journ.isWheelchairOnDepartureNeeded || false}
                hasWheelChairArr={journ.isWheelchairOnArrivalNeeded || false}
                //@ts-ignore
                hasFinished={reservation.missions.length > 0 && reservation.missions[ind]?.status}
              />
            </React.Fragment>
          );
        })}
      </Grid>
      <ExtendedTravelModal
        hasAnyAssistances={hasAnyAssistances}
        nthJourney={nthJourney}
        status={status}
        type={type}
        variant={variant}
        journey={journey}
        ind={ind}
        reservation={reservation}
        open={showExtendedTravel}
        onClose={handleCloseModal}
        getTime={getTime}
        hasWheelChair={hasWheelChair}
      />
    </Paper>
  );
};
