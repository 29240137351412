// @ts-nocheck
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function FowardArrow(props) {
  return (
    <SvgIcon {...props}>
      <svg id="ic_forward" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
        <rect
          id="Rectangle_1597"
          data-name="Rectangle 1597"
          width="24"
          height="24"
          transform="translate(24 24) rotate(180)"
          fill="none"
        />
        <path
          id="Path_5101"
          data-name="Path 5101"
          d="M21.65,11.12a1.28,1.28,0,0,1,0,1.76l-6.21,6.25a1.23,1.23,0,0,1-1.8,0,1.28,1.28,0,0,1,0-1.76l4.14-4.14H3.25a1.26,1.26,0,0,1-.88-.35,1.231,1.231,0,0,1,0-1.76,1.19,1.19,0,0,1,.88-.37H17.78l-4.1-4.1a1.24,1.24,0,0,1-.39-.9,1.12,1.12,0,0,1,.39-.86,1.143,1.143,0,0,1,1.72,0Z"
        />
      </svg>
    </SvgIcon>
  );
}
