import axios from "axios"
import { cachePaths } from "./cachePaths"

export const getReferenceData = async (baseUrl: string): Promise<Array<any>> => {
    const endPoints = []
    for (const key in cachePaths){
        //@ts-ignore
        endPoints.push(cachePaths[key].url)
    }

    const data =  await Promise.all(endPoints.map(el => axios.get(`${baseUrl}${el}`))).then(res => {
        if(res){
            return res
        }
    }).catch(err => {
        return err;
    })
    return data

}