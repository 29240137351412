// @ts-nocheck
import React, {ReactNode} from 'react';
import {Modal} from 'antd';
import {ButtonFilled} from '../buttons/ButtonFilled/ButtonFilled';
import {ButtonText} from '../buttons/ButtonText/ButtonText';

type Props = {
  style?: object;
  title?: ReactNode | string;
  width?: number;
  isVisible?: boolean;
  okTitle?: string;
  cancelTitle?: string;
  onOk?: () => void;
  onCancel?: () => void;
  children?: React.ReactNode;
  centered?: boolean;
  zIndex?: number;
  loading?: boolean;
};

export function ModalView({
  style,
  width = 500,
  title,
  isVisible,
  onOk,
  onCancel,
  loading,
  children,
  okTitle,
  cancelTitle,
  centered = true,
  zIndex,
}: Props) {
  return (
    <Modal
      zIndex={zIndex}
      style={style}
      title={title}
      centered={centered}
      open={isVisible}
      onCancel={onCancel}
      footer={[
        <ButtonFilled loading={loading} label={okTitle} onClick={onOk} />,
        <ButtonText label={cancelTitle} onClick={onCancel} />,
      ]}
      width={width}
    >
      {children}
    </Modal>
  );
}
