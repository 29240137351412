import { IMissionFilter, IPrintStickerStore } from "./PrintStickers.store";

enum IPrintAction {
    ALL = 'ALL',
    TO_PRINT = 'TO PRINT',
    PRINTED = 'PRINTED'
}

export const intitialFilterState = (isPrintAction: boolean): IMissionFilter => {

    return {
        groupName: '',
        trainNumber: 0,
        arrivalStation: '',
        departureStation: '',
        reservationNumber: '',
        stationOfFirstDeparture: '',
        printAction: isPrintAction ? IPrintAction.ALL : IPrintAction.PRINTED
    }
}
export enum IMissionFilterType {
    GROUP_NAME = 'GROUP_NAME',
    TRAIN_NUMBER = 'TRAIN_NUMBER',
    RESERVATION_NUMBER = 'RESERVATION_NUMBER',
    ARRIVAL_STATION = 'ARRIVAL_STATION',
    DEPARTURE_STATION = 'DEPARTURE_STATION',
    STATION_FIRST_DEPARTURE = 'STATION_FIRST_DEPARTURE',
    PRINT_ACTION = 'PRINT_ACTION'
}

export type MissionFilterActions = 
    | {type: IMissionFilterType.GROUP_NAME, payload: string}
    | {type: IMissionFilterType.TRAIN_NUMBER, payload: number}
    | {type: IMissionFilterType.RESERVATION_NUMBER, payload: string}
    | {type: IMissionFilterType.ARRIVAL_STATION, payload: string}
    | {type: IMissionFilterType.DEPARTURE_STATION, payload: string}
    | {type: IMissionFilterType.STATION_FIRST_DEPARTURE, payload: string}
    | {type: IMissionFilterType.PRINT_ACTION, payload: IPrintAction}



export const missionFilterReducer = (state: IMissionFilter, action: MissionFilterActions) => {
    const {type, payload} = action
    switch(type){
        case IMissionFilterType.GROUP_NAME:
            return {
                ...state,
                groupName: payload
            }
        case IMissionFilterType.TRAIN_NUMBER:
            return {
                ...state,
                trainNumber: payload
            }
        case IMissionFilterType.RESERVATION_NUMBER:
            return {
                ...state,
                reservationNumber: payload
            }
        case IMissionFilterType.ARRIVAL_STATION:
            return {
                ...state,
                arrivalStation: payload
            }
        case IMissionFilterType.DEPARTURE_STATION:
            return {
                ...state,
                departureStation: payload
            }
        case IMissionFilterType.STATION_FIRST_DEPARTURE:
            return {
                ...state,
                stationOfFirstDeparture: payload
            }
        case IMissionFilterType.PRINT_ACTION:
            return {
                ...state,
                printAction: payload
            }
        default:
            return {...state}
    }
}