//@ts-nocheck
import React from 'react';
import environment from './environment.service';

interface IEnvironmentContext {
  variables: {
    ACCESS_KEY_PATH: string;
    ID_KEY_PATH: string;
    OIDC_PATH: string;
    CLIENT_ID: string;
    CLIENT_SECRET: string;
    REDIRECT_URI: string;
    BASE_ENDPOINT: string;
    INSIGHTS_KEY: string;
    APP_VERSION: string;
    USE_NEW_DESIGNS: boolean;
    USE_PMR_3: boolean;
    USE_COI: boolean;
    PMR_REFRESH_RATE: number;
    ATMS_BASE_URL: string;
    DICOS_VERSION: string;
  };
}

export const EnvironmentContext = React.createContext<IEnvironmentContext>({} as IEnvironmentContext);

const EnvironmentProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [ACCESS_KEY_PATH, set_ACCESS_KEY_PATH] = React.useState('');
  const [ID_KEY_PATH, set_ID_KEY_PATH] = React.useState('');
  const [OIDC_PATH, set_OIDC_PATH] = React.useState('');
  const [CLIENT_ID, set_CLIENT_ID] = React.useState('');
  const [CLIENT_SECRET, set_CLIENT_SECRET] = React.useState('');
  const [REDIRECT_URI, set_REDIRECT_URI] = React.useState('');
  const [BASE_ENDPOINT, set_BASE_ENDPOINT] = React.useState('');
  const [INSIGHTS_KEY, set_INSIGHTS_KEY] = React.useState('');
  const [APP_VERSION, set_APP_VERSION] = React.useState('');
  const [USE_NEW_DESIGNS, set_USE_NEW_DESIGNS] = React.useState(false);
  const [USE_PMR_3, set_USE_PMR_3] = React.useState(false);
  const [USE_COI, set_USE_COI] = React.useState(false);
  const [PMR_REFRESH_RATE, set_PMR_REFRESH_RATE] = React.useState<number>(0.5);
  const [ATMS_BASE_URL, set_ATMS_BASE_URL] = React.useState('');
  const [DICOS_VERSION, set_DICOS_VERSION] = React.useState('Unknown');

  const variables = {
    ACCESS_KEY_PATH,
    ID_KEY_PATH,
    OIDC_PATH,
    CLIENT_ID,
    CLIENT_SECRET,
    REDIRECT_URI,
    BASE_ENDPOINT,
    INSIGHTS_KEY,
    APP_VERSION,
    USE_NEW_DESIGNS,
    USE_PMR_3,
    USE_COI,
    PMR_REFRESH_RATE,
    ATMS_BASE_URL,
    DICOS_VERSION,
  };

  const fetchVariablesFromEndpoint = async () => {
    await environment.then(res => {
      const {
        REACT_APP_ACCESS_KEY_PATH,
        REACT_APP_ID_KEY_PATH,
        REACT_APP_OIDC_PATH,
        REACT_APP_CLIENT_ID,
        REACT_APP_CLIENT_SECRET,
        REACT_APP_REDIRECT_URI,
        REACT_APP_BASE_ENDPOINT,
        INSIGHTS_KEY,
        APP_VERSION,
        REACT_APP_USE_NEW_DESIGNS,
        REACT_APP_USE_PMR_3,
        REACT_APP_USE_COI,
        REACT_APP_ATMS_BASE_URL,
        REACT_APP_DICOS_VERSION,
        REACT_APP_PMR_REFRESH_RATE,
      } = res.data;
      set_ACCESS_KEY_PATH(REACT_APP_ACCESS_KEY_PATH);
      set_ID_KEY_PATH(REACT_APP_ID_KEY_PATH);
      set_OIDC_PATH(REACT_APP_OIDC_PATH);
      set_CLIENT_ID(REACT_APP_CLIENT_ID);
      set_CLIENT_SECRET(REACT_APP_CLIENT_SECRET);
      set_REDIRECT_URI(REACT_APP_REDIRECT_URI);
      set_BASE_ENDPOINT(REACT_APP_BASE_ENDPOINT);
      set_INSIGHTS_KEY(INSIGHTS_KEY);
      set_APP_VERSION(APP_VERSION);
      set_USE_NEW_DESIGNS(
        (REACT_APP_USE_NEW_DESIGNS as string) !== undefined
          ? (REACT_APP_USE_NEW_DESIGNS as string).toLocaleUpperCase() === 'TRUE'
          : false,
      );
      set_USE_PMR_3(
        (REACT_APP_USE_PMR_3 as string) !== undefined
          ? (REACT_APP_USE_PMR_3 as string).toLocaleUpperCase() === 'TRUE'
          : false,
      );
      set_USE_COI(
        (REACT_APP_USE_COI as string) !== undefined
          ? (REACT_APP_USE_COI as string).toLocaleUpperCase() === 'TRUE'
          : false,
      );
      set_DICOS_VERSION(
        (REACT_APP_DICOS_VERSION as string) !== undefined && (REACT_APP_DICOS_VERSION as string).length > 0
          ? REACT_APP_DICOS_VERSION
          : 'Unknown',
      );
      set_PMR_REFRESH_RATE(
        (REACT_APP_PMR_REFRESH_RATE as string) !== undefined && (REACT_APP_PMR_REFRESH_RATE as string).length > 0
          ? parseInt(REACT_APP_PMR_REFRESH_RATE)
          : 0.5,
      );
      set_ATMS_BASE_URL(REACT_APP_ATMS_BASE_URL ? REACT_APP_ATMS_BASE_URL : 'https://atms.acc-intern-belgiantrain.be');
    });
  };

  const fetchVariablesFromEnv = () => {
    set_ACCESS_KEY_PATH('DNMBSASSIST_ACCESS_TOKEN');
    set_ID_KEY_PATH('DNMBSASSIST_ID_TOKEN');
    set_OIDC_PATH('https://idp.acc-intern-belgianrail.be/idhub/oidc');
    set_CLIENT_ID('97732079-e1e1c14fbd944ce8980f150e814db9aa');
    set_CLIENT_SECRET('');
    set_BASE_ENDPOINT('https://assist-dashboard-bff.tst-intern-belgiantrain.be');
    set_REDIRECT_URI('https://localhost:2001');
    set_INSIGHTS_KEY('26a35bd1-6f4b-40e0-b750-26f7761cf6c6');
    set_APP_VERSION('local');
    set_USE_NEW_DESIGNS(true);
    set_USE_PMR_3(true);
    set_USE_COI(false);
    set_PMR_REFRESH_RATE(0.5);
    set_ATMS_BASE_URL('https://atms.acc-intern-belgiantrain.be');
    set_DICOS_VERSION('unknown');
  };

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
      fetchVariablesFromEnv();
    } else {
      fetchVariablesFromEndpoint();
    }
  }, []);

  return <EnvironmentContext.Provider value={{variables}}>{children}</EnvironmentContext.Provider>;
};

export default EnvironmentProvider;
