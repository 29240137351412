import React from 'react';
import {FormAction, FormActionKind, FormState} from '../../formState';

import {Box, SxProps, Theme} from '@mui/system';
import {Paper, Grid, Chip} from '@mui/material';
import {ReservationStatusDto} from 'src/services/models/reservation-status-dto';
import CheckIcon from '@mui/icons-material/Check';
import {useTranslation} from 'react-i18next';

const chipStyle: SxProps<Theme> = {
  border: 'solid 1px darkgrey',
  borderRadius: '5px',
  fontWeight: 'bold',
};

const selectedChipStyle: SxProps<Theme> = {
  ...chipStyle,
  border: 'solid 1px white',
  backgroundColor: '#EFF4F9',
};

export const ReservationStatus: React.FC<{state: FormState; dispatch: React.Dispatch<FormAction>}> = ({
  state,
  dispatch,
}) => {
  const {t} = useTranslation();

  return (
    <Box sx={{padding: '0px 25px'}}>
      <Paper elevation={0} sx={{marginBottom: '15px', marginTop: '15px'}}>
        <b>{t('pmr.search.mission-status')}</b>
      </Paper>
      <Grid container gap={1}>
        {Object.values(ReservationStatusDto)
          .filter(el => {
            return (
              el !== ReservationStatusDto.Unknown &&
              el !== ReservationStatusDto.Released &&
              el !== ReservationStatusDto.Confirmed &&
              el !== ReservationStatusDto.Refused &&
              el !== ReservationStatusDto.Communication
            );
          })
          .map(el => {
            return (
              <Grid item key={el}>
                <Chip
                  variant="outlined"
                  sx={state.status.includes(el) ? {...selectedChipStyle} : {...chipStyle}}
                  onClick={() => dispatch({payload: el, type: FormActionKind.STATUS})}
                  label={el}
                  icon={state.status.includes(el) ? <CheckIcon /> : <></>}
                />
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};
