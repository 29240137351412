// @ts-nocheck
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const PmrIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg {...props} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <defs></defs>
      <title>ic_singleExchange-blue</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1-2">
          <g id="ic_singleExchange" data-name="ic singleExchange">
            <path
              class="cls-1"
              style={{fill: '#25336c'}}
              d="M5.44,14.6a5.08,5.08,0,0,1,.41-2A5.37,5.37,0,0,1,7,10.89,5.29,5.29,0,0,1,8.64,9.76a5.08,5.08,0,0,1,2-.41H12A3.91,3.91,0,0,1,8.06,5.47v0A3.8,3.8,0,0,1,9.21,2.63,3.8,3.8,0,0,1,12,1.48a3.8,3.8,0,0,1,2.79,1.15,3.8,3.8,0,0,1,1.15,2.79,3.91,3.91,0,0,1-3.89,3.93h1.26a5.08,5.08,0,0,1,2,.41,5.24,5.24,0,0,1,2.82,2.81,5.08,5.08,0,0,1,.41,2H17.25a3.93,3.93,0,0,0-3.93-3.93H10.69a3.93,3.93,0,0,0-3.94,3.92v0ZM9.38,5.42A2.58,2.58,0,0,0,12,8h0a2.58,2.58,0,0,0,1.85-.76,2.51,2.51,0,0,0,.78-1.86,2.58,2.58,0,0,0-.78-1.85,2.61,2.61,0,0,0-3.69,0l0,0A2.56,2.56,0,0,0,9.38,5.42Z"
            />
            <path
              class="cls-1"
              style={{fill: '#25336c'}}
              d="M3.18,18.21l2.5-2.09a.62.62,0,0,1,.88,0l0,0a.62.62,0,0,1,.09.86l-.09.09L4.74,18.54h5.33a.58.58,0,0,1,.66.49.48.48,0,0,1,0,.17.58.58,0,0,1-.5.65H4.74l1.85,1.52a.62.62,0,0,1,.09.86l-.09.09a.62.62,0,0,1-.88,0l0,0L3.18,20.1a1.38,1.38,0,0,1-.37-.94A1.4,1.4,0,0,1,3.18,18.21Z"
            />
            <path
              class="cls-1"
              style={{fill: '#25336c'}}
              d="M13.31,19.2a.58.58,0,0,1,.49-.66h5.46l-1.85-1.47a.62.62,0,0,1-.09-.86l.09-.09a.62.62,0,0,1,.88,0l0,0,2.5,2.09a1.4,1.4,0,0,1,.37.95,1.38,1.38,0,0,1-.37.94l-2.5,2.22a.62.62,0,0,1-.88,0l0,0a.62.62,0,0,1-.09-.86l.09-.09,1.85-1.52H14a.58.58,0,0,1-.68-.45A.45.45,0,0,1,13.31,19.2Z"
            />
            <rect class="cls-2" style={{fill: 'none'}} width="24" height="24" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PmrIcon;
