import {ReservationTypeDto} from 'src/services/models';
import {MissionSummaryDto} from 'src/services/models/mission-summary-dto';
import {highestAmountOfTravelers, isSecondAssistanceRequired} from './util';
import LightAssistanceIcon from 'src/icons/LightAssistanceIcon';
import {ContentItem} from '../MissionItem.styles';
import AdultIcon from 'src/icons/AdultIcon';
import ChildIcon from 'src/icons/ChildIcon';
import SeniorIcon from 'src/icons/SeniorIcon';

const GetTravelers: React.FC<{mission: MissionSummaryDto; index: number}> = ({mission, index}) => {
  const travelers = highestAmountOfTravelers(
    mission.traveler?.adults,
    mission.traveler?.children,
    mission.traveler?.seniors,
  )[index];

  return (
    <>
      {travelers.variant === 'ADULTS' ? (
        <AdultIcon />
      ) : travelers.variant === 'CHILDREN' ? (
        <ChildIcon />
      ) : (
        <SeniorIcon />
      )}
      <ContentItem sx={{fontSize: '11.2px'}} color="#333333">
        {travelers.amount}
      </ContentItem>
    </>
  );
};

export const ExtraAssistance: React.FC<{mission: MissionSummaryDto}> = ({mission}) => {
  const areAssistancesDefined =
    mission.traveler?.fullAssistances !== undefined && mission.traveler.lightAssistances !== undefined;

  const areDisabledsDefined = mission.traveler?.disableds !== undefined;

  const highestAmountOfTravelersForIndex = (index: number) =>
    highestAmountOfTravelers(mission.traveler?.adults, mission.traveler?.children, mission.traveler?.seniors)[index];

  if (
    mission.reservationType === ReservationTypeDto.Disabled &&
    isSecondAssistanceRequired(mission.traveler?.fullAssistances, mission.traveler?.lightAssistances)
  ) {
    return (
      <>
        <LightAssistanceIcon style={{width: '24px', height: '24px'}} />
        <ContentItem>{mission.traveler?.lightAssistances}</ContentItem>
      </>
    );
  }

  if (
    mission.reservationType === ReservationTypeDto.Group &&
    areAssistancesDefined &&
    //@ts-ignore
    mission.traveler.fullAssistances > 0 &&
    //@ts-ignore
    mission.traveler.lightAssistances === 0
  ) {
    return <GetTravelers mission={mission} index={0} />;
  }

  if (
    mission.reservationType === ReservationTypeDto.Group &&
    isSecondAssistanceRequired(mission.traveler?.fullAssistances, mission.traveler?.lightAssistances)
  ) {
    return (
      <>
        <LightAssistanceIcon style={{width: '24px', height: '24px'}} />
        <ContentItem sx={{fontSize: '11.2px'}} color="#333333">
          {mission.traveler?.lightAssistances && mission.traveler.lightAssistances}
        </ContentItem>
      </>
    );
  }

  if (
    mission.reservationType === ReservationTypeDto.Group &&
    areAssistancesDefined &&
    //@ts-ignore
    mission.traveler.fullAssistances === 0 &&
    //@ts-ignore
    mission.traveler.lightAssistances > 0
  ) {
    return <GetTravelers mission={mission} index={0} />;
  }

  if (
    mission.reservationType === ReservationTypeDto.Group &&
    areDisabledsDefined &&
    //@ts-ignore
    mission.traveler.disableds === 0 &&
    highestAmountOfTravelersForIndex(1).amount === 0
  ) {
    return <></>;
  }

  if (
    mission.reservationType === ReservationTypeDto.Group &&
    areDisabledsDefined &&
    //@ts-ignore
    mission.traveler.disableds === 0 &&
    highestAmountOfTravelersForIndex(1).amount > 0
  ) {
    return <GetTravelers mission={mission} index={1} />;
  }

  return <></>;
};
