import { Alert, Snackbar } from '@mui/material';
import * as React from 'react';




export const Succes: React.FC<{open: boolean, close: React.Dispatch<boolean>}> = ({open, close}) => {
    return(
        <Snackbar 
        data-testid='succes-snackbar'
        open={open} 
        autoHideDuration={2000} 
        onClose={() => close(false)}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        >
            <Alert 
                severity="success" 
                sx={{ width: '100%' }}
            >
                Succes
            </Alert>
        </Snackbar>
    )
}