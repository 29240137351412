import axios, { AxiosError } from "axios";
import { DeleteMissionDetailDto } from "src/services/models/delete-mission-detail-dto";


const deleteMissionFromBackend = async (baseUrl: string, deleteMissionData: DeleteMissionDetailDto) =>  {
    const fullUrl = `${baseUrl}/api/missions/delete`
    try {
        const res = await axios.post(fullUrl, {
            ...deleteMissionData
        })
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error as AxiosError)
    }
}

export default deleteMissionFromBackend