import {Component} from 'react';
import {withAITracking} from '@microsoft/applicationinsights-react-js';
import {useNavigate, useLocation, useParams, NavigateFunction, Location, Params} from 'react-router-dom';
import {ai} from './TelemetryService';

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component<{
  history: {navigate: NavigateFunction; location: Location<any>; params: Readonly<Params<string>>};
  after: () => void;
  instrumentationKey: string;
}> {
  state = {
    initialized: false,
  };

  componentDidMount() {
    const {history} = this.props;
    const {initialized} = this.state;
    const AppInsightsInstrumentationKey = this.props.instrumentationKey;
    if (!initialized && Boolean(AppInsightsInstrumentationKey) && Boolean(history)) {
      ai.initialize(AppInsightsInstrumentationKey, history);
      this.setState({initialized: true});
    }

    this.props.after();
  }

  render() {
    const {children} = this.props;
    return <>{children}</>;
  }
}

// Create a functional wrapper component
const TelemetryProviderWrapper = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const history = {
    navigate,
    location,
    params,
  };

  return <TelemetryProvider {...props} history={history} />;
};

export default withAITracking(ai.reactPlugin, TelemetryProviderWrapper);
