import TrainIcon from 'src/components/icons/Train';
import DoorIcon from 'src/components/images/ic_door-grey.svg';
import SeatIcon from 'src/components/images/ic_seat.svg';
import PerronIcon from 'src/components/images/ic_perron-gray.svg';

import {CardItemContent, ContentItem} from '../MissionItem.styles';

type Props = {
  coachInfo: string | number | undefined;
  seatInfo: string | number | undefined;
  doorInfo: string | number | undefined;
  trackInfo: string | number | undefined;
};
export default function JourneyDetails({coachInfo, seatInfo, doorInfo, trackInfo}: Props) {
  return (
    <CardItemContent direction="row" itemCentered contentCentered margin={4} spacing={0.5}>
      {coachInfo && (
        <>
          <TrainIcon />
          <ContentItem color="#333333" size={0.7}>
            {`n°${coachInfo}`}
          </ContentItem>
        </>
      )}
      {seatInfo && (
        <>
          <ContentItem size={1} color="#333333" weight={400}>
            I
          </ContentItem>
          <img width={'20px'} height={'20px'} src={SeatIcon} />
          <ContentItem color="#333333" size={0.7}>
            {seatInfo}
          </ContentItem>
        </>
      )}

      {doorInfo && (
        <>
          <ContentItem size={1} color="#333333" weight={400}>
            I
          </ContentItem>
          <img width={'20px'} height={'20px'} src={DoorIcon} />
          <ContentItem color="#333333" size={0.7}>
            {doorInfo}
          </ContentItem>
        </>
      )}

      {trackInfo && (
        <>
          <ContentItem size={1} color="#333333" weight={400}>
            I
          </ContentItem>
          <img width={'20px'} height={'20px'} src={PerronIcon} />
          <ContentItem color="#333333" size={0.7}>
            {trackInfo}
          </ContentItem>
        </>
      )}
    </CardItemContent>
  );
}
