import React from 'react';
import {CreateTaxiMissionStore} from '../CreateTaxiMission';
import {Box, Button, FormControl, FormLabel, IconButton, Input, MenuItem, Select, TextField} from '@mui/material';
import {CacheContext} from 'src/context/cache/cache.store';
import {DisabilityDto, LocalizedTextDto} from 'src/services/models';
import {useTranslation} from 'react-i18next';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';
import {TaxiCreateTaxiMissionActionType} from '../createTaxiMission.reducer';
import {CloseOutlined} from '@mui/icons-material';

export const Disabilities = ({store}: {store: CreateTaxiMissionStore}) => {
  const {i18n, t} = useTranslation();
  const {disabilities} = React.useContext(CacheContext);

  return (
    <Box>
      {store.creatTaxiMissionState.assistances.map((el, ind) => {
        return (
          <FormControl
            sx={{
              display: 'grid',
              gridTemplateColumns: ind === 0 ? 'auto 1fr' : 'auto 1fr auto',
              gap: '15px',
              marginTop: '15px',
            }}
          >
            <TextField
              value={el.quantity}
              type="number"
              name="Quantity"
              placeholder={t('placeholders.quantity')}
              onChange={e => {
                if (parseInt(e.target.value) <= 0) {
                  return;
                }
                store.createTaxiMissionDispatch({
                  type: TaxiCreateTaxiMissionActionType.ASSISTANCE_QUANT,
                  payload: {amount: parseInt(e.target.value), index: ind},
                });
              }}
            />
            <Select
              value={el.typeId}
              onChange={e =>
                store.createTaxiMissionDispatch({
                  type: TaxiCreateTaxiMissionActionType.ASSISTANCE_TYPE,
                  payload: {id: e.target.value as string, index: ind},
                })
              }
            >
              {(disabilities as DisabilityDto[])
                .filter(el => el.reservationType === 'Disabled')
                .map(d => (
                  <MenuItem value={d.id || ''}>
                    {handleTranslationObject(i18n, d.description as LocalizedTextDto[])}
                  </MenuItem>
                ))}
            </Select>
            {ind !== 0 && (
              <IconButton
                onClick={() =>
                  store.createTaxiMissionDispatch({type: TaxiCreateTaxiMissionActionType.DEL_ASSISTANCES, payload: ind})
                }
              >
                <CloseOutlined />
              </IconButton>
            )}
          </FormControl>
        );
      })}
      <Button
        sx={{marginTop: '15px'}}
        variant="contained"
        onClick={() =>
          store.createTaxiMissionDispatch({
            type: TaxiCreateTaxiMissionActionType.ADD_ASSISTANCES,
            payload: {
              quantity: 0,
              typeId: (disabilities as DisabilityDto[]).filter(el => el.reservationType === 'Disabled')[0].id,
            },
          })
        }
      >
        ADD ASSISTANCE
      </Button>
    </Box>
  );
};
