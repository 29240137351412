// @ts-nocheck
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';

export const Container = styled(Box)({
  display: 'flex',
  gap: '5px',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

export const ContainerText = styled(Typography)({
  fontSize: '0.875rem',
  fontWeight: 600,
  color: '#25336C',
});

export const Border = styled(Divider)({
  borderColor: '#D7D7D7',
});
