import {Box} from '@mui/system';
import React from 'react';
import {HorizontalLine, StepButton} from '../common';
import {CUSTOM_EVENTS} from 'src/scenes/pmr/util';

export const AlternativeRouteStepper = () => {
  const heightRef = React.useRef<HTMLDivElement>(null);
  const [lineHeight, setLineHeight] = React.useState('0px');

  React.useEffect(() => {
    function handleLineHeight() {
      if (heightRef && heightRef.current) {
        setLineHeight((heightRef.current.clientHeight - 34).toString() + 'px');
      }
    }

    handleLineHeight();

    window.addEventListener('resize', handleLineHeight);
    window.addEventListener(CUSTOM_EVENTS.force_recalculation_line_height, handleLineHeight);

    return () => {
      window.removeEventListener('resize', handleLineHeight);
      window.removeEventListener(CUSTOM_EVENTS.force_recalculation_line_height, handleLineHeight);
    };
  }, []);

  return (
    <Box sx={{display: 'grid', gridTemplateRows: '40px 1fr 40px', position: 'relative'}} ref={heightRef}>
      <StepButton alternate={true} />
      <Box></Box>
      <StepButton alternate={true} />
      <HorizontalLine height={lineHeight} left="12px" top="17px" dashed={true} />
    </Box>
  );
};
