import {AgeCategoryDto, DisabilityDto, DistrictDto, ReasonDto, StationDto} from 'src/services/models';
import {cachePaths} from './cachePaths';
import {TaxiDto} from 'src/services/models/taxi-dto';

export const setToLocal = (res: {data: any}[]) => {
  localStorage.setItem(cachePaths.reasons.path, JSON.stringify(res[0].data));
  localStorage.setItem(cachePaths.districts.path, JSON.stringify(res[1].data));
  localStorage.setItem(cachePaths.stations.path, JSON.stringify(res[2].data));
  localStorage.setItem(cachePaths.disabilities.path, JSON.stringify(res[3].data));
  localStorage.setItem(cachePaths.age_categories.path, JSON.stringify(res[4].data));
  localStorage.setItem(cachePaths.reasons_reservations.path, JSON.stringify(res[5].data));
  localStorage.setItem(cachePaths.taxi_companies.path, JSON.stringify(res[6].data));
};

export const getCachedData = (
  setReasons: React.Dispatch<React.SetStateAction<ReasonDto[]>>,
  setDistricts: React.Dispatch<React.SetStateAction<DistrictDto[]>>,
  setStations: React.Dispatch<React.SetStateAction<StationDto[]>>,
  setDisabilities: React.Dispatch<React.SetStateAction<DisabilityDto[]>>,
  setAgeCategories: React.Dispatch<React.SetStateAction<AgeCategoryDto[]>>,
  setReservationRefusalReasons: React.Dispatch<React.SetStateAction<ReasonDto[]>>,
  setTaxiCompanies: React.Dispatch<React.SetStateAction<TaxiDto[]>>,
) => {
  setReasons(JSON.parse(localStorage.getItem(cachePaths.reasons.path) || JSON.stringify([])));
  setDistricts(JSON.parse(localStorage.getItem(cachePaths.districts.path) || JSON.stringify([])));
  setStations(JSON.parse(localStorage.getItem(cachePaths.stations.path) || JSON.stringify([])));
  setDisabilities(JSON.parse(localStorage.getItem(cachePaths.disabilities.path) || JSON.stringify([])));
  setAgeCategories(JSON.parse(localStorage.getItem(cachePaths.age_categories.path) || JSON.stringify([])));
  setReservationRefusalReasons(
    JSON.parse(localStorage.getItem(cachePaths.reasons_reservations.path) || JSON.stringify([])),
  );
  setTaxiCompanies(JSON.parse(localStorage.getItem(cachePaths.taxi_companies.path) || JSON.stringify([])));
};
