import React from 'react';

const AgentIcon: React.FC<React.SVGProps<SVGPathElement>> = props => {
  return (
    <svg id="ic_agent" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_8901" data-name="Group 8901">
        <path
          id="Path_5715"
          data-name="Path 5715"
          d="M20.57,2A1.37,1.37,0,0,1,22,3.43V20.57A1.37,1.37,0,0,1,20.57,22H3.43a1.42,1.42,0,0,1-1-.4,1.36,1.36,0,0,1-.42-1V3.43a1.36,1.36,0,0,1,.42-1,1.42,1.42,0,0,1,1-.4Zm0,1.43H3.43V20.57H4.86V19.14a7.67,7.67,0,0,1,.31-2.21,4.8,4.8,0,0,1,1-1.83,4.71,4.71,0,0,1,1.77-1.22,6.78,6.78,0,0,1,2.63-.45h2.86a6.92,6.92,0,0,1,2.65.45,4.48,4.48,0,0,1,1.77,1.22,5,5,0,0,1,1,1.83,7.67,7.67,0,0,1,.31,2.21v1.43h1.43ZM17.71,19.14c0-2.85-1.42-4.28-4.28-4.28H10.57c-2.86,0-4.28,1.43-4.28,4.28v1.43H17.71Zm-1.42-10a4.27,4.27,0,1,1-1.254-3.033A4.27,4.27,0,0,1,16.29,9.14ZM12,12a2.81,2.81,0,0,0,2-.83,2.73,2.73,0,0,0,.85-2,2.85,2.85,0,0,0-4.87-2,2.79,2.79,0,0,0-.85,2,2.73,2.73,0,0,0,.85,2A2.81,2.81,0,0,0,12,12Zm0,5.71h4.29v1.43H12Z"
          fill="#333"
          {...props}
        />
      </g>
      <rect id="Rectangle_1975" data-name="Rectangle 1975" width="24" height="24" fill="none" />
    </svg>
  );
};

export default AgentIcon;
