// @ts-nocheck
import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Button,
  ButtonGroup
} from '@mui/material';

import pmrBlueIcon from '../../../components/images/ic_exchange-blue.svg';
import pmrWhiteIcon from '../../../components/images/ic_exchange-white.svg';
import groboBlueIcon from '../../../components/images/ic_colleague-blue.svg';
import groboWhiteIcon from '../../../components/images/ic_colleague-white.svg';
import './ReservationModel.scss';
import {ReservationTypeDto} from 'src/services/models';
import {MissionTypeDto} from 'src/services/models/mission-type-dto';
import {MissionStatusDto} from 'src/services/models/mission-status-dto';
import {IMissionFilter} from '../MissionOverview/missions.store';
import TrainSticker from 'src/components/icons/TrainSticker';

interface ReservationFilterModalProps {
  isOpen: boolean;
  handleClose: () => void;
  selectedTab: number;
  onResetFilter: () => void;
  handleChangeFilter: (filter: IMissionFilter) => void;
  activeFilter: IMissionFilter;
}

const ReservationFilterModal: FC<ReservationFilterModalProps> = ({
  handleClose,
  isOpen,
  selectedTab,
  onResetFilter,
  handleChangeFilter,
  activeFilter,
}) => {
  const { t } = useTranslation();
  const [isButtonActive, setisButtonActive] = useState(() => {
    if (activeFilter.reservationType === ReservationTypeDto.Group) {
      return 2;
    } else if (activeFilter.reservationType === ReservationTypeDto.Disabled) {
      return 3;
    } else if (activeFilter.reservationType === MissionTypeDto.Stickering) return 1;
  });
  const [isCompleted, setIsCompleted] = useState<boolean>(activeFilter.completed === MissionStatusDto.Completed);
  const [isStarted, setIsStarted] = useState<boolean>(activeFilter.started === MissionStatusDto.Started);
  const [isCancelled, setIsCancelled] = useState<boolean>(activeFilter.cancelled === MissionStatusDto.Stopped);
  const [isFullAssistance, setIsFullAssistance] = useState<boolean>(activeFilter.fullAssistance);
  const [isLightAssistance, setIsLightAssistance] = useState<boolean>(activeFilter.lightAssistance);

  const handleButtonActive = (reservationType: ReservationTypeDto | MissionTypeDto | undefined) => {
    if (reservationType === ReservationTypeDto.Group) {
      setisButtonActive(3);
    }
    if (reservationType === ReservationTypeDto.Disabled) {
      setisButtonActive(2);
    }
    if (reservationType === MissionTypeDto.Stickering) {
      setisButtonActive(4);
    }
    if (reservationType === undefined) {
      setisButtonActive(1);
    }
  };

  const handleSumbit = () => {
    let reserv = undefined;
    if (isButtonActive === 2) {
      reserv = ReservationTypeDto.Disabled;
    } else if (isButtonActive === 3) {
      reserv = ReservationTypeDto.Group;
    } else if (isButtonActive === 4) {
      reserv = MissionTypeDto.Stickering;
    }

    const formatFilter: IMissionFilter = {
      completed: isCompleted ? MissionStatusDto.Completed : undefined,
      reservationType: reserv,
      stickerMissions: undefined,
      started: isStarted ? MissionStatusDto.Started : undefined,
      cancelled: isCancelled ? MissionStatusDto.Stopped : undefined,
      fullAssistance: isFullAssistance,
      lightAssistance: isLightAssistance
    };
    handleChangeFilter(formatFilter);
  };

  React.useEffect(() => {
    handleButtonActive(activeFilter.reservationType);
    setIsCompleted(activeFilter.completed === MissionStatusDto.Completed);
    setIsStarted(activeFilter.started === MissionStatusDto.Started);
    setIsCancelled(activeFilter.cancelled === MissionStatusDto.Stopped);
    setIsFullAssistance(activeFilter.fullAssistance);
    setIsLightAssistance(activeFilter.lightAssistance);
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{
        width: '100%',
        minWidth: '600px',
      }}
    >
      <DialogTitle sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #d7d7d7',
      }}>
        <Typography sx={{
          color: '#333',
          fontSize: '24px',
          fontFamily: 'Arial',
          fontWeight: 600,
        }}>{t('filter-section.filter-header-title')}</Typography>
        <Button sx={{
          textTransform: 'capitalize',
          color: '#25336c',
          fontSize: '16px',
          fontFamily: 'Arial',
          }}
          onClick={onResetFilter}
        >
          {t('filter-section.filter-reset')}
        </Button>
      </DialogTitle>
      <DialogContent
        sx={{
          width: '100%',
          minWidth: '600px',
          marginTop: '40px',
          padding: '20px 0'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center'  }}>
          <ButtonGroup>
            <Button
              sx={{ textTransform: 'capitalize' }}
              className={
                isButtonActive === 1
                  ? 'filter-active-panel'
                  : 'filter-main-panel'
              }
              onClick={() => handleButtonActive(undefined)}
            >
              {t('filter-section.all-mission')}
            </Button>
            <Button
              className={isButtonActive === 3 ? 'filter-active-panel' : 'filter-main-panel'}
              onClick={() => handleButtonActive(ReservationTypeDto.Group)}
            >
              <img src={isButtonActive === 3 ? groboWhiteIcon : groboBlueIcon} className="img-style" alt="GROBO" />
            </Button>
            <Button
              className={
                isButtonActive === 2
                  ? 'filter-active-panel'
                  : 'filter-main-panel'
              }
              onClick={() => handleButtonActive(ReservationTypeDto.Disabled)}
            >
              <img src={isButtonActive === 2 ? pmrWhiteIcon : pmrBlueIcon} className="img-style" alt="PMR" />
            </Button>
            <Button
              className={
                isButtonActive === 4
                  ? 'filter-active-sticker'
                  : 'filter-main-panel'
              }
              onClick={() => handleButtonActive(MissionTypeDto.Stickering)}
            >
              <TrainSticker />
            </Button>
          </ButtonGroup>
        </Box>
        <Box sx={{ padding: '0 40px', borderBottom: '1px solid #d7d7d7', marginTop: '30px' }}>
          <Typography sx={{ fontWeight: 500, fontSize: '16px', marginBottom: '10px', color: '#333' }}>
            {isButtonActive === 1 && t('filter-section.cat-label.cat-all')}
            {isButtonActive === 2 && t('filter-section.cat-label.cat-pmr')}
            {isButtonActive === 3 && t('filter-section.cat-label.cat-grobo')}
            {isButtonActive === 4 && t('filter.sticker')}
          </Typography>
        </Box>
        <Box sx={{ padding: '10px 40px' }}>
          <Box className="assign-panel">
            <Box className="child-assign-panel">
              <span>
                <input
                  type="checkbox"
                  className="checkbox-filter"
                  checked={!isCompleted}
                  onChange={() => setIsCompleted(prev => !prev)}
                />
              </span>
              <span style={{color: 'black', paddingLeft: '15px'}}>{t('filter.completed')}</span>
            </Box>
          </Box>
          <Box className="assign-panel">
            <Box className="child-assign-panel">
              <span>
                <input
                  type="checkbox"
                  className="checkbox-filter"
                  checked={!isStarted}
                  onChange={() => setIsStarted(prev => !prev)}
                />
              </span>
              <span style={{color: 'black', paddingLeft: '15px'}}>{t('filter.started')}</span>
            </Box>
          </Box>
          <Box className="assign-panel">
            <Box className="child-assign-panel">
              <span>
                <input
                  type="checkbox"
                  className="checkbox-filter"
                  checked={!isCancelled}
                  onChange={() => setIsCancelled(prev => !prev)}
                />
              </span>
              <span style={{color: 'black', paddingLeft: '15px'}}>{t('filter.cancelled')}</span>
            </Box>
          </Box>
          <Box className="assign-panel">
            <Box className="child-assign-panel">
              <span>
                <input
                  type="checkbox"
                  className="checkbox-filter"
                  checked={isFullAssistance}
                  onChange={() => {
                    setIsFullAssistance(prev => !prev);
                  }}
                />
              </span>
              <span style={{color: 'black', paddingLeft: '15px'}}>{t('filter.pbm-full-assistance')}</span>
            </Box>
          </Box>
          <Box className="assign-panel">
            <Box className="child-assign-panel">
              <span>
                <input
                  type="checkbox"
                  className="checkbox-filter"
                  checked={isLightAssistance}
                  onChange={() => {
                    setIsLightAssistance(prev => !prev);
                  }}
                />
              </span>
              <span style={{color: 'black', paddingLeft: '15px'}}>{t('filter.pbm-light-assistance')}</span>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{
        borderTop: '1px solid #d7d7d7',
        padding: '16px 24px',
      }}>
        <Button sx={{ marginRight: '12px', textTransform: 'capitalize', fontSize: '16px', fontWeight: 400 }} onClick={handleClose}>
          {t('filter-section.filter-cancel')}
        </Button>
        <Button
          onClick={handleSumbit}
          variant='contained'
          sx={{ textTransform: 'capitalize', fontSize: '16px', fontWeight: 400 }}
        >
          {t('filter-section.filter-choose')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReservationFilterModal;
