import React from 'react';
import {Box, CircularProgress} from '@mui/material';
import {ChangeRouteContext} from '../ChangeRoute.store';
import {OriginalRouteStep} from '../OriginalRouteView/OriginalRouteStep';
import {originalRouteStepMapper} from '../util/OriginalRouteStepMap';
import {DisabledOriginalJourneyDto} from 'src/services/models/disabled-original-journey-dto';
import {calculateDurationOfTime} from '../util/CalculateDurationOfRoute';
import {AlternativeRouteStepper} from '../AlternativeRouteView/AlternativeRouteStepper';
import {AlternativeRouteContent} from '../AlternativeRouteView/AlternativeRouteContent';
import {TravelResponseDto} from 'src/services/models/travel-response-dto';
import {getAlternativeRoutes} from '../data';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {NetworkContext} from 'src/context/network/network.store';
import {AxiosError} from 'axios';
import {ModelError} from 'src/services/models';
import {NotCompleteRoute} from './NewProposalNotCompleteRoute';
import {TransportTypeDto} from 'src/services/models/transport-type-dto';
import {TaxiProposal} from '../NewRoutePlanner/TaxiProposal';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {getTimeZoneOffset} from '../util/getTimeZoneOffset';
import {SettingsContext} from 'src/context/settings/settings.store';
import {useLocation, useParams} from 'react-router-dom';

const NewProposal = () => {
  const {
    selectedOriginalRoute,
    selectedNewProposal,
    handleDeletionOfPartOfProposal,
    manualRouteCreation,
    setOpenNewRoutePlanner,
    variantSelected,
    setTripStatus,
    tripStatus,
  } = React.useContext(ChangeRouteContext);
  const {variables} = React.useContext(EnvironmentContext);
  const {validateNetworkCall} = React.useContext(NetworkContext);
  const {
    RESERVATIONS_FULL_ALTERNATIVE_ROUTE_MAXIMUM_TRANSFER_TIME,
    RESERVATIONS_FULL_ALTERNATIVE_ROUTE_MINIMUM_TRANSFER_TIME,
    RESERVATIONS_LIGHT_ALTERNATIVE_ROUTE_MAXIMUM_TRANSFER_TIME,
    RESERVATIONS_LIGHT_ALTERNATIVE_ROUTE_MINIMUM_TRANSFER_TIME,
  } = React.useContext(SettingsContext);

  const [isFetchingContinuation, setIsFetchingContinuation] = React.useState(false);
  const [alternativeRoutes, setAlternativeRoutes] = React.useState<TravelResponseDto | undefined>(undefined);
  const [moreResultsTokenForNewRoute, setMoreResultsTokenForNewRoute] = React.useState<string>('');
  const [tokens, setTokens] = React.useState({next: '', prev: ''});
  const [isFetchingManual, setIsFetchingManual] = React.useState(false);
  const [refetch, setRefetch] = React.useState(false);

  const locations = useLocation();

  const assistanceType = () => {
    const queryStringWithoutQuestionMark = locations.search.substring(1);
    // Split the query string into key-value pairs
    const keyValuePairs = queryStringWithoutQuestionMark.split('&');
    // Initialize an empty array to store the values
    const values: string[] = [];
    // Loop through each key-value pair
    keyValuePairs.forEach((pair: string) => {
      // Split the pair into key and value
      const [key, value] = pair.split('=');
      // Decode the URI component to handle special characters properly
      const decodedValue = decodeURIComponent(value);
      // Push the value into the array
      values.push(decodedValue);
    });
    return values[2];
  };

  const {t} = useTranslation();

  const fetchNextOrPrevForProposal = (variant: 'next' | 'prev') => {
    const token = variant === 'next' ? tokens.next : tokens.prev;
    if (isFetchingManual) {
      handleCustomFetch(token);
    } else {
      setMoreResultsTokenForNewRoute(token || '');
      setRefetch(prev => !prev);
    }
  };

  const minTransfer =
    assistanceType() === 'Full'
      ? RESERVATIONS_FULL_ALTERNATIVE_ROUTE_MINIMUM_TRANSFER_TIME
      : RESERVATIONS_LIGHT_ALTERNATIVE_ROUTE_MINIMUM_TRANSFER_TIME;

  const maxTransfer =
    assistanceType() === 'Full'
      ? RESERVATIONS_FULL_ALTERNATIVE_ROUTE_MAXIMUM_TRANSFER_TIME
      : RESERVATIONS_LIGHT_ALTERNATIVE_ROUTE_MAXIMUM_TRANSFER_TIME;

  const inOrOut =
    tripStatus.active === 'inbound' ? selectedNewProposal?.inboundProposal : selectedNewProposal?.outBoundProposal;

  const isRouteComplete =
    inOrOut &&
    selectedOriginalRoute &&
    inOrOut.journeys &&
    inOrOut.journeys[inOrOut.journeys.length - 1].arrivalUicCode ===
      selectedOriginalRoute[selectedOriginalRoute.length - 1].arrivalUicCode;

  React.useEffect(() => {
    const fetchContinuatingTravelsForNewProposal = async () => {
      setIsFetchingManual(false);
      setIsFetchingContinuation(true);
      if (inOrOut && inOrOut.journeys && selectedOriginalRoute) {
        await getAlternativeRoutes(
          variables.BASE_ENDPOINT,
          inOrOut?.journeys[inOrOut.journeys?.length - 1].arrivalUicCode || '',
          selectedOriginalRoute[selectedOriginalRoute.length - 1].arrivalUicCode || '',
          inOrOut?.journeys[inOrOut.journeys?.length - 1].arrivalTime || '',
          minTransfer,
          maxTransfer,
          moreResultsTokenForNewRoute,
        )
          .then(res => {
            if (res) {
              setAlternativeRoutes(res);
              setTokens({next: res.laterResultsToken || '', prev: res.earlierResultsToken || ''});
              setIsFetchingContinuation(false);
            }
          })
          .catch((err: AxiosError) => {
            if (err.response) {
              validateNetworkCall(err.response.status, err.response.data as ModelError);
              setIsFetchingContinuation(false);
            }
          });
      }
    };

    if (isRouteComplete) {
      setIsFetchingContinuation(false);
      setTripStatus(prev => {
        return {
          active: prev.active,
          inbound: variantSelected === 'return' ? 'ROUTE_COMPLETED' : prev.inbound,
          outbound: variantSelected === 'outbound' ? 'ROUTE_COMPLETED' : prev.outbound,
        };
      });
    } else {
      fetchContinuatingTravelsForNewProposal();
      setTripStatus(prev => {
        return {
          active: prev.active,
          inbound: variantSelected === 'return' ? 'ROUTE_INCOMPLETE' : prev.inbound,
          outbound: variantSelected === 'outbound' ? 'ROUTE_INCOMPLETE' : prev.outbound,
        };
      });
    }
  }, [isRouteComplete, selectedNewProposal, refetch]);

  const handleCustomFetch = async (token?: string) => {
    setIsFetchingManual(true);
    setOpenNewRoutePlanner(false);
    setIsFetchingContinuation(true);
    const formatMonth = manualRouteCreation.date.split('-').reverse().toString().replaceAll(',', '-');
    const date = formatMonth + 'T' + manualRouteCreation.time + `:00${getTimeZoneOffset()}`;
    await getAlternativeRoutes(
      variables.BASE_ENDPOINT,
      manualRouteCreation.departureUic,
      manualRouteCreation.arrivalUic,
      date,
      minTransfer,
      maxTransfer,
      token,
    )
      .then(res => {
        if (res) {
          setAlternativeRoutes(res);
          setTokens({next: res.laterResultsToken || '', prev: res.earlierResultsToken || ''});
          setIsFetchingContinuation(false);
        }
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          validateNetworkCall(err.response.status, err.response.data as ModelError);
          setIsFetchingContinuation(false);
        }
      });
  };

  if (
    inOrOut &&
    (tripStatus.active === 'inbound' ? selectedNewProposal?.inboundProposal : selectedNewProposal?.outBoundProposal) &&
    selectedOriginalRoute
  ) {
    return (
      <Box
        sx={{
          backgroundColor: 'white',
          padding: '25px',
          overflowY: 'scroll',
          maxHeight: 'calc(100vh - 64px)',
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
        }}
      >
        <Box sx={{width: '520px'}}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              padding: ' 0px 25px',
              backgroundColor: 'white',
            }}
          >
            <h4>{t('cr.new-route')}</h4>
            <p style={{textAlign: 'right'}}>
              {inOrOut?.journeys &&
                calculateDurationOfTime(
                  inOrOut.journeys[0].departureTime,
                  inOrOut.journeys[inOrOut.journeys?.length - 1].arrivalTime,
                )}
              , {inOrOut.journeys && inOrOut.journeys.length > 1 ? inOrOut.journeys && inOrOut.journeys.length - 1 : ''}{' '}
              {inOrOut.journeys && inOrOut.journeys.length === 1
                ? t('cr.no-transfers')
                : inOrOut.journeys && inOrOut.journeys.length === 2
                ? t('cr.transfer')
                : t('cr.transfers')}
            </p>
          </Box>
          {/*@ts-ignore */}
          {inOrOut.journeys && inOrOut.journeys[0].departureName !== selectedOriginalRoute[0].departureName && (
            <NotCompleteRoute destination={selectedOriginalRoute[0].departureName} />
          )}
          {inOrOut.journeys?.map((el, ind, arr) => (
            <Box
              key={`${el.transportId}+${ind}`}
              sx={{
                backgroundColor: 'white',
                padding: '25px',
                paddingTop: '0px',
                paddingBottom: '10px',
                marginBottom: ind === arr.length - 1 ? '10px' : '0px',
                position: 'relative',
                zIndex: 0,
              }}
            >
              {/*@ts-ignore */}
              {el.transportType === TransportTypeDto.Taxi ? (
                <TaxiProposal
                  onProposal={true}
                  //@ts-ignore
                  arrival={el.arrivalName || ''}
                  //@ts-ignore
                  departureComment={el.departureRemark}
                  //@ts-ignore
                  departureName={el.departureName || ''}
                  taxiCompany={''}
                  //@ts-ignore
                  travelTime={{timeInSeconds: parseInt(el.duration)} || ''}
                  arrivalTime={moment(el.arrivalTime || '').format('HH:mm')}
                  departureTime={moment(el.departureTime || '').format('HH:mm')}
                  withDelete={handleDeletionOfPartOfProposal}
                  ind={ind}
                  arrivalComment={el.arrivalRemark || ''}
                />
              ) : (
                <OriginalRouteStep
                  route={originalRouteStepMapper(el as DisabledOriginalJourneyDto)}
                  isSelected={false}
                  handleClick={() => {}}
                  ind={ind}
                  withHover={false}
                  withDelete={handleDeletionOfPartOfProposal}
                />
              )}
            </Box>
          ))}
          {!isRouteComplete && (
            <NotCompleteRoute destination={selectedOriginalRoute[selectedOriginalRoute.length - 1].arrivalName} />
          )}
        </Box>
        {!isRouteComplete && alternativeRoutes !== undefined && !isFetchingContinuation && (
          <Box>
            <Box sx={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
              <h4>{t('cr.alternative-route')}</h4>
            </Box>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '25px 1fr',
                width: '100%',
                minHeight: '400px',
              }}
            >
              <AlternativeRouteStepper />
              <AlternativeRouteContent
                alt={alternativeRoutes}
                sel={selectedOriginalRoute}
                fetchNextOrPrevious={fetchNextOrPrevForProposal}
                handleCustomFetchWithPremadeProposal={handleCustomFetch}
              />
            </Box>
          </Box>
        )}
        {isFetchingContinuation && (
          <Box sx={{backgroundColor: 'white', padding: '25px', display: 'grid', placeItems: 'center'}}>
            <Box>
              <CircularProgress />
            </Box>
          </Box>
        )}
      </Box>
    );
  } else {
    return null;
  }
};

export default NewProposal;
