import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Paragraph from 'antd/lib/typography/Paragraph';
import Button from '@mui/material/Button';
import {useTranslation} from 'react-i18next';

export const TimeOutDialog: React.FC<{open: boolean; close: React.Dispatch<boolean>}> = ({open, close}) => {
  const {t} = useTranslation();
  return (
    <Dialog open={open} data-testid="time-out-snackbar">
      <div style={{display: 'grid', gridTemplateRows: 'auto 1fr auto', padding: '8px'}}>
        <DialogTitle style={{padding: '8px'}}>{t('common.404')}</DialogTitle>
        <Paragraph style={{padding: '8px'}}>{t('common.check-network')}</Paragraph>
        <Button data-testid="close-button" variant="contained" onClick={() => close(false)}>
          {t('close')}
        </Button>
      </div>
    </Dialog>
  );
};
