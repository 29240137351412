import React, {useState} from 'react';
import Stack from '@mui/material/Stack';
import {MissionContext} from 'src/scenes/coordinator/MissionOverview/missions.store';
import MissionDetailsComponents from './components';
import {ReservationTypeDto} from 'src/services/models';
import AssignMission from 'src/scenes/Pages/Coordinator/components/Modal/Assign';
import DeleteMission from 'src/scenes/Pages/Coordinator/components/Modal/Delete';
import {ModernModal} from 'src/components/ModernModal/ModernModal';
import {useTranslation} from 'react-i18next';

export default function MissionDetailsNew({isAssigned}: {isAssigned: boolean}) {
  const [isAssignModal, setIsAssignModal] = useState(false);
  const [isdeleteModal, setIsDeleteModal] = useState(false);
  const {selectedMissionDetails, selectedMission} = React.useContext(MissionContext);

  const {t} = useTranslation();

  const handleDeleteConfirmation = () => {
    setIsDeleteModal(prev => !prev);
  };

  const handleAssignConfirmation = () => {
    setIsAssignModal(prev => !prev);
  };

  return selectedMissionDetails ? (
    <Stack sx={{pl: 0, pr: 4, pt: 4, pb: 9, mb: 8}} spacing={2}>
      <MissionDetailsComponents.Banner mission={selectedMissionDetails} type={selectedMission.reservationType} />
      <MissionDetailsComponents.OverviewOfTravelers
        mission={selectedMissionDetails}
        reservationType={selectedMission.reservationType as ReservationTypeDto}
        isReservation={false}
      />
      <MissionDetailsComponents.Journey
        journey={selectedMissionDetails.journey}
        status={selectedMission.status}
        type={selectedMission.missionType}
        variant={selectedMission.reservationType}
      />
      <MissionDetailsComponents.ClientInformation mission={selectedMissionDetails} />
      <MissionDetailsComponents.DeleteAssignBar
        handleAssignConfirmation={handleAssignConfirmation}
        handleDeleteConfirmation={handleDeleteConfirmation}
        isAssigned={isAssigned}
      />
      {/*
      <AssignMission
        isVisible={isAssignModal}
        canceledModal={handleAssignConfirmation}
        missioninfo={selectedMissionDetails.id}
        dateAvailblity={new Date()}
      />
      */}
      <ModernModal
        fullWidth
        sx={{zIndex: 998}}
        disableEscapeKeyDown
        maxWidth="lg"
        onClose={handleAssignConfirmation}
        open={isAssignModal}
        modalTitle={t('pmr-mission.assign-modal')}
        titleStyles={{
          paddingTop: '25px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <MissionDetailsComponents.AssignModal onClose={handleAssignConfirmation} mission={selectedMissionDetails} />
      </ModernModal>

      <DeleteMission isVisible={isdeleteModal} canceledModal={handleDeleteConfirmation} isMultiple={false} />
    </Stack>
  ) : null;
}
