// @ts-nocheck

import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function Adult(props) {
  return (
    <SvgIcon {...props}>
      <svg id="ic_adult" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
        <rect id="Rectangle_1643" data-name="Rectangle 1643" width="24" height="24" fill="none" />
        <path
          id="Path_5198"
          data-name="Path 5198"
          d="M13.5,6.42h-.67a2.58,2.58,0,1,0-1.66,0H10.5A2.59,2.59,0,0,0,7.92,9v5a1.58,1.58,0,0,0,1.5,1.57V21A1.58,1.58,0,0,0,11,22.58h2A1.58,1.58,0,0,0,14.58,21V15.57A1.58,1.58,0,0,0,16.08,14V9A2.59,2.59,0,0,0,13.5,6.42ZM12,2.58a1.41,1.41,0,1,1-1.007.413A1.42,1.42,0,0,1,12,2.58ZM13.42,15v6a.42.42,0,0,1-.42.42H11a.42.42,0,0,1-.42-.42V15a.58.58,0,0,0-.58-.58H9.5A.42.42,0,0,1,9.08,14V9A1.41,1.41,0,0,1,10.5,7.58h3A1.41,1.41,0,0,1,14.92,9v5a.42.42,0,0,1-.42.42H14A.58.58,0,0,0,13.42,15Z"
        />
      </svg>
    </SvgIcon>
  );
}
