import { Autocomplete, Box, Checkbox, Input, TextField } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DateAndTimeInput } from 'src/components';

import { DepartureAndArrival } from "../types";

interface ItineraryAutocompleteProps extends DepartureAndArrival {
    type: 'Arrival' | 'Departure';
  }
  
export const ItineraryAutocomplete: React.FC<ItineraryAutocompleteProps> = ({
    train,
    departureItinerary,
    arrivalItinerary,
    handleItineraryChange,
    index,
    type,
    loading,
    hasInCorrectTrainNumber,
    handleAssistanceChange,
    withAssistance,
}) => {
    const {t} = useTranslation()

    const label = type === 'Arrival' 
        ? t('reservation.form.arrival-station-placeholder')
        : t('reservation.form.departure-station-placeholder');

    const options = train 
        ? train.itinerary.map(itin => itin.name)
            .filter(option => {
            if (type === 'Arrival') {
                return departureItinerary ? departureItinerary.name !== option : true
            } else {
                return arrivalItinerary ? arrivalItinerary.name !== option : true
            }
            })
        : [];

    const value = type === 'Arrival' ? arrivalItinerary?.name : departureItinerary?.name;

    const time = type === 'Arrival' ? arrivalItinerary?.arrival : departureItinerary?.departure

    const handleChange = (value: string) => {
        handleItineraryChange(value, type);
    }

    return (
        <div>
            <Autocomplete
                options={options}
                value={value ?? ''}
                renderInput={(args) => <TextField label={label} color={value && value.length > 0 ? 'success' : 'error'} {...args} />}
                //@ts-ignore
                onChange={(value) => handleChange(value.target.innerHTML)}
                disabled={train === undefined || loading || (type === 'Departure' && index > 0) || hasInCorrectTrainNumber}

            />
            <DateAndTimeInput isTime={true} disabled={true}  value={value ? time?.toString() : ''} />
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'auto 1fr'
                }}
            >
                <Checkbox
                    checked={withAssistance}
                    onClick={() => handleAssistanceChange(withAssistance, type)}
                    disabled={(type === 'Departure' && index === 0)}
                />
                <p style={{lineHeight: "50px", margin: '0'}}>{t('create-grobo-mission.assistance')}</p>
            </Box>
        </div>
    )
}
  