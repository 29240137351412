import {MissionTravelerDto} from 'src/services/models/mission-traveler-dto';

export const findHeaviestAssistanceType = (full = 0, light = 0): {amount: number; variant: 'FULL' | 'LIGHT'} => {
  if (full > 0) {
    return {amount: full, variant: 'FULL'};
  }

  return {amount: light, variant: 'LIGHT'};
};

type AgeGroups = 'ADULTS' | 'SENIORS' | 'CHILDREN';

export const highestAmountOfTravelers = (
  adults = 0,
  children = 0,
  seniors = 0,
): Array<{amount: number; variant: AgeGroups}> => {
  const highestAgeCat = [
    {variant: 'ADULTS' as AgeGroups, amount: adults},
    {variant: 'CHILDREN' as AgeGroups, amount: children},
    {variant: 'SENIORS' as AgeGroups, amount: seniors},
  ].sort((a, b) => b.amount - a.amount);
  return highestAgeCat;
};

export const getSumOfAssistances = (full = 0, light = 0): string => {
  const sum = full + light;
  if (sum === 0) {
    return '';
  }

  return sum.toString();
};

export const getSumOfAgeCategories = (traveler: MissionTravelerDto | undefined): string => {
  if (traveler === undefined) {
    return '';
  }

  const {adults = 0, children = 0, seniors = 0} = traveler;
  const sum = adults + children + seniors;
  if (sum > 0) {
    return sum.toString();
  }

  return '';
};

export const isSecondAssistanceRequired = (full = 0, light = 0): boolean => {
  if (full > 0 && light > 0) return true;
  return false;
};
