// @ts-nocheck
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const PlusIcon = (props: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>) => {
  return (
    <SvgIcon {...props}>
      <svg id="ic_add" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
        <g id="Group_7612" data-name="Group 7612">
          <path
            id="Path_5068"
            data-name="Path 5068"
            d="M22,11.29v1.42a.63.63,0,0,1-.71.72H13.43v7.86a.63.63,0,0,1-.72.71H11.29a.76.76,0,0,1-.5-.2.69.69,0,0,1-.22-.51V13.43H2.71a.75.75,0,0,1-.49-.2A.67.67,0,0,1,2,12.71V11.29a.67.67,0,0,1,.22-.52.75.75,0,0,1,.49-.2h7.86V2.71a.69.69,0,0,1,.22-.51.76.76,0,0,1,.5-.2h1.42a.63.63,0,0,1,.72.71v7.86h7.86a.63.63,0,0,1,.71.72Z"
          />
        </g>
        <rect id="Rectangle_1572" data-name="Rectangle 1572" width="24" height="24" fill="none" />
      </svg>
    </SvgIcon>
  );
};
