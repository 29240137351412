import {DatePicker} from 'antd';
import './DateAndTimePicker.scss';
import moment from 'moment';

const DateAndTimePicker = ({
  name,
  change,
  value,
  isTime = false,
  disabled = false,
}: {
  name: string;
  change: (key: any) => (value: any) => void;
  value: string;
  isTime?: boolean;
  disabled?: boolean;
}) => {
  const handleOnChange = (_: any, dateString: any) => {
    change(name)(dateString);
  };

  return (
    <DatePicker
      disabled={disabled}
      size="large"
      className="picker"
      showTime={isTime}
      format=""
      value={moment(value) || ''}
      onChange={handleOnChange}
    />
  );
};
export default DateAndTimePicker;
