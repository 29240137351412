//@ts-nocheck
import {Box} from '@mui/system';
import React from 'react';
import {Alert, Button, Collapse} from '@mui/material';
import GearIcon from 'src/components/images/ic_configure.svg';
import {ArrowBack, ArrowForward, InfoOutlined} from '@mui/icons-material';
import {NewRoutePlanner} from '../NewRoutePlanner/NewRoutePlanner';
import {ChangeRouteContext} from '../ChangeRoute.store';
import {DisabledOriginalJourneyDto} from 'src/services/models/disabled-original-journey-dto';
import {TravelResponseDto} from 'src/services/models/travel-response-dto';
import {AlternativeTravelDto} from 'src/services/models/alternative-travel-dto';
import moment from 'moment';
import {FORCE_RECALCULATION_LINE_HEIGHT} from 'src/scenes/pmr/util';
import AlternativeRouteProposalStepper from './AlternativeRouteProposalStepper';

import {ArrowDown, ArrowUp, TransferIcon} from './AlternativeRouteIcons';
import {ProposalSelectDrawer} from './AlternativeRouteProposal';

import {mapDisabledJourneysToTravels} from '../util/mapDisabledJourneysToTravels';
import {t} from 'i18next';
import {useTranslation} from 'react-i18next';

export const AlternativeRouteContent = ({
  alt,
  sel,
  fetchNextOrPrevious,
  handleCustomFetchWithPremadeProposal,
}: {
  alt: TravelResponseDto;
  sel: DisabledOriginalJourneyDto[];
  fetchNextOrPrevious: (variant: 'next' | 'prev') => void;
  handleCustomFetchWithPremadeProposal?: () => void;
}) => {
  const {tripStatus, selectedNewProposal} = React.useContext(ChangeRouteContext);
  const {t} = useTranslation();
  return (
    <Box sx={{display: 'grid', gridTemplateRows: 'minmax(40px, auto) 1fr minmax(40px, auto)', gap: '25px'}}>
      <Box sx={{display: 'grid', alignContent: 'center'}}>
        <b style={{paddingLeft: '15px'}}>
          {/*@ts-ignore*/}
          {!(tripStatus.active === 'inbound'
            ? selectedNewProposal?.inboundProposal
            : selectedNewProposal?.outBoundProposal)
            ? sel[0].departureName
            : alt?.alternatives[0]?.journeys[0]?.departureName || ''}
        </b>
      </Box>
      <Box sx={{paddingLeft: '25px'}}>
        <Box>
          <ManualCreationOfAlternativeRoute />
          {alt.alternatives &&
            alt.alternatives.map((el, ind) => (
              <AlternativeRouteItem key={JSON.stringify(el) + ind.toString()} isFirst={ind === 0} alt={el} />
            ))}
        </Box>
        <Box sx={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
          <Box sx={{display: 'flex', width: '100%'}}>
            <Button
              disabled={alt.earlierResultsToken === null || alt.earlierResultsToken === undefined}
              onClick={() => fetchNextOrPrevious('prev')}
              startIcon={<ArrowBack />}
            >
              {t('earlier')}
            </Button>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row-reverse', width: '100%'}}>
            <Button
              disabled={alt.laterResultsToken === null || alt.laterResultsToken === undefined}
              onClick={() => fetchNextOrPrevious('next')}
              endIcon={<ArrowForward />}
            >
              {t('later-on')}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box sx={{display: 'grid', alignContent: 'center'}}>
        <b style={{paddingLeft: '15px'}}>{sel[sel.length - 1].arrivalName}</b>
      </Box>
      <NewRoutePlanner handleCustomFetchWithPremadeProposal={handleCustomFetchWithPremadeProposal} />
    </Box>
  );
};

const ManualCreationOfAlternativeRoute = () => {
  const {setOpenNewRoutePlanner} = React.useContext(ChangeRouteContext);
  const {t} = useTranslation();
  return (
    <Box
      sx={{
        height: '54px',
        backgroundColor: '#EFF4F9',
        borderRadius: '5px',
        padding: '25px 15px',
        display: 'grid',
        alignContent: 'center',
        marginBottom: '10px',
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
      }}
    >
      <h6 style={{margin: '0'}}>{t('cr.alternative-trains')}</h6>
      <Box>
        <p style={{display: 'inline-block', marginRight: '15px', marginBottom: '0px', lineHeight: '54px'}}>
          {t('cr.choose-one')}{' '}
        </p>
        <Button
          onClick={() => {
            setOpenNewRoutePlanner(true);
          }}
          variant="outlined"
          sx={{color: 'black', borderColor: 'black', backgroundColor: 'white'}}
          startIcon={<img src={GearIcon} alt="edit" />}
        >
          {t('cr.recreate-manually')}
        </Button>
      </Box>
    </Box>
  );
};

const AlternativeRouteItem = ({isFirst = false, alt}: {isFirst?: boolean; alt: AlternativeTravelDto}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const [showProposalDrawer, setShowProposalDrawer] = React.useState(false);

  const [alternativeTravel, setAlternativeTravel] = React.useState(alt);

  const {selectedOriginalRoute, isIndividualSelected, selectedIndividualProposal, selectedNewProposal, tripStatus} =
    React.useContext(ChangeRouteContext);
  const {t} = useTranslation();

  const handleCloseProposalDrawer = () => {
    setShowProposalDrawer(false);
  };

  const handleSelectThisProposal = () => {
    if (
      tripStatus.active === 'inbound' ? selectedNewProposal?.inboundProposal : selectedNewProposal?.outBoundProposal
    ) {
      let copyOfCurrentNewProposal =
        tripStatus.active === 'inbound'
          ? selectedNewProposal?.inboundProposal?.journeys
          : selectedNewProposal?.outBoundProposal?.journeys;
      copyOfCurrentNewProposal = [...(copyOfCurrentNewProposal || []), ...(alt.journeys || [])];
      const travel: AlternativeTravelDto = {
        duration: '',
        journeys: copyOfCurrentNewProposal,
      };
      setAlternativeTravel(travel);
      setShowProposalDrawer(true);
      return;
    }

    if (isIndividualSelected && selectedIndividualProposal !== 0 && selectedIndividualProposal !== null) {
      if (
        selectedOriginalRoute &&
        !(tripStatus.active === 'inbound'
          ? selectedNewProposal?.inboundProposal
          : selectedNewProposal?.outBoundProposal)
      ) {
        const sliceOfPreviousRoutes = selectedOriginalRoute.slice(0, selectedIndividualProposal);
        const mapOfPreviousRoutes = mapDisabledJourneysToTravels(sliceOfPreviousRoutes);
        const travel: AlternativeTravelDto = {
          duration: '',
          journeys: [...mapOfPreviousRoutes, ...(alt.journeys || [])],
        };
        setAlternativeTravel(travel);
      }
    }
    //Logic will change based on existing proposal

    setShowProposalDrawer(true);
  };

  //Check if this alternativ route is the original route
  const checkIfIsOriginal = () => {
    const ALT_dep = alt.journeys[0].departureTime;
    const ALT_arr = alt.journeys[alt.journeys?.length - 1].arrivalTime;

    const OR_dep = selectedOriginalRoute[0].departureTime;
    const OR_arr = selectedOriginalRoute[selectedOriginalRoute?.length - 1].arrivalTime;

    return ALT_dep === OR_dep && ALT_arr === OR_arr;
  };

  return (
    <Box sx={{border: 'solid 1px #ECECEC', borderRadius: '8px', padding: '20px 15px', marginBottom: '15px'}}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          borderRadius: '4px',
          gap: '20px',
          borderBottom: isExpanded ? 'dashed 1px #ECECEC' : 'none',
          paddingBottom: isExpanded ? '15px' : '0px',
        }}
      >
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <span style={{color: '#25336C', fontSize: '18px', fontWeight: 'bold'}}>
            {alt.journeys && moment(alt.journeys[0].departureTime).format('HH:mm')} —{' '}
            {alt.journeys && moment(alt.journeys[alt.journeys.length - 1].arrivalTime).format('HH:mm')}
          </span>
          <svg
            style={{marginLeft: '15px', marginRight: '15px'}}
            width="2"
            height="16"
            viewBox="0 0 2 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path opacity="0.554" d="M1 0V16" stroke="#25336C" />
          </svg>
          <p style={{margin: '0px 15px 0px 0px'}}>
            {t('cr.towards')} <b>{alt.journeys && alt.journeys[alt.journeys?.length - 1].direction}</b>
          </p>
          <p style={{margin: '0'}}>
            {alt.journeys && alt.journeys.length > 1 && <TransferIcon />}{' '}
            {alt.journeys && alt.journeys.length > 1 && alt.journeys.length - 1}{' '}
            {alt.journeys && alt.journeys.length === 1
              ? t('cr.no-transfers')
              : alt.journeys && alt.journeys.length === 2
              ? t('cr.transfer')
              : t('cr.transfers')}
          </p>
        </Box>
        <Box sx={{display: 'grid', gridTemplateColumns: 'auto 40px', gap: '15px', alignItems: 'center'}}>
          <p style={{margin: '0'}}>
            {alt.duration?.split(':').map((el, ind, arr) => {
              return (
                ind !== arr.length - 1 && (
                  <b key={el + ind.toString()}>
                    {el}
                    {ind === 0 ? 'h' : 'min'}{' '}
                  </b>
                )
              );
            })}
          </p>
          <span
            style={{cursor: 'pointer', textAlign: 'center'}}
            onClick={() => {
              setIsExpanded(prev => !prev);
            }}
          >
            {isExpanded ? <ArrowUp /> : <ArrowDown />}
          </span>
        </Box>
      </Box>
      {checkIfIsOriginal() && (
        <Alert sx={{marginTop: '10px'}} severity="warning" iconMapping={{warning: <InfoOutlined />}}>
          {t('cr.route-same')}
        </Alert>
      )}
      <Collapse
        timeout={0}
        addEndListener={() => window.dispatchEvent(FORCE_RECALCULATION_LINE_HEIGHT)}
        in={isExpanded}
      >
        <Box sx={{paddingTop: '15px', display: 'grid', gridTemplateColumns: '1fr auto'}}>
          <AlternativeRouteProposalStepper travel={alt} />
          <Button
            onClick={() => handleSelectThisProposal()}
            sx={{alignSelf: 'end', height: '48px'}}
            variant="contained"
          >
            {t('cr.select-this-route')}
          </Button>
        </Box>
      </Collapse>
      <ProposalSelectDrawer
        proposal={alternativeTravel}
        openDrawer={showProposalDrawer}
        onCloseDrawer={handleCloseProposalDrawer}
      />
    </Box>
  );
};
