import {ButtonLabel} from './ButtonLabel';
import {NewMissionButton} from './NewMissionButton';
import {BadgeItem} from './BadgeItem';

const MenuComponents = {
  ButtonLabel: ButtonLabel,
  NewMissionButton: NewMissionButton,
  BadgeItem: BadgeItem,
};

export default MenuComponents;
