import {Stack} from '@mui/material';
import React from 'react';
import MissionDetailsComponents from 'src/scenes/coordinator/MissionDetailsNew/components';
import {DisabilityDto, ReservationTypeDto} from 'src/services/models';
import {MissionDetailDto} from 'src/services/models/mission-detail-dto';
import {ReservationDto} from 'src/services/models/reservation-dto';
import {Travel} from './Travel';
import {MissionTypeDto} from 'src/services/models/mission-type-dto';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

export const Details: React.FC<{
  reservation: ReservationDto;
  reservationType: ReservationTypeDto | undefined;
  variant: ReservationTypeDto | undefined;
  disabilities: DisabilityDto | undefined;
}> = ({reservation, reservationType, variant}) => {
  const {t} = useTranslation();

  return (
    <Stack sx={{pl: 4, pr: 4, pt: 4, pb: 9, mb: 8, overflowY: 'scroll'}} spacing={4}>
      <MissionDetailsComponents.OverviewOfTravelers
        mission={{} as MissionDetailDto}
        reservationType={reservation.type as ReservationTypeDto}
        isReservation={true}
        reservation={reservation}
      />
      {reservation.travels?.map((travel, ind) => {
        return (
          <React.Fragment key={ind}>
            <h5>
              {t('trip-details-v2.details.departure')}: {moment(travel.travelDate).format('DD/MM/YYYY') || ''}
            </h5>
            <Travel
              journey={travel.journeys === null || travel.journeys === undefined ? undefined : travel.journeys}
              status={reservation.status}
              type={reservationType}
              ind={ind}
              variant={variant}
              reservation={reservation}
            />
          </React.Fragment>
        );
      })}
    </Stack>
  );
};
