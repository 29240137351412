// @ts-nocheck
import {handleDataLocalization} from 'src/utils/handleDataLocalization';

const setTranslationLanguage = (dataObject, keytoSearch, language) => {
  return handleDataLocalization(dataObject, keytoSearch, language);
  // if (dataObject === null || dataObject === undefined) return;
  // const pattern = `${keytoSearch}${language}`;
  // const arrayData = Object.entries(dataObject);
  // const arrayValue = arrayData.map(item => item.reduce((key, value) => `${[key]}:${[value]} `));
  // const valueItem = arrayValue
  //   .filter(item => item.includes(pattern))
  //   .map(value => value.split(':').pop())
  //   .pop();
  // return valueItem;
};

export default setTranslationLanguage;
