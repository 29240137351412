import type {i18n} from 'i18next';
import {DistrictDto} from 'src/services/models';

export const getDistrictFromName = (_i18n: i18n, districtName: string, cachedDistricts: DistrictDto[]) => {
  let id: string | null | undefined = '';

  cachedDistricts.forEach(el => {
    el.name?.forEach(name => {
      if (name.text === districtName) {
        id = el.id;
      }
    });
  });

  if (id !== null && id !== undefined) {
    return id;
  }
  return 'prm-1';
};
