import React from 'react';
import {AxiosError} from 'axios';
import {useParams, useLocation, Navigate} from 'react-router-dom';
import {ReservationsContext} from 'src/context/reservations/reservations.store';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {NetworkContext} from 'src/context/network/network.store';
import {ReservationDto} from 'src/services/models/reservation-dto';
import {
  ChangeRouteReducerActions,
  ChangeRouteState,
  changeRouteReducer,
  initialChangeRouteReducerState,
} from './reducer';
import {getAlternativeRoutes, getOriginaleRoute, saveAlternativeRoute} from './data';
import {DisabledOriginalTravelsDto} from 'src/services/models/disabled-original-travels-dto';
import {DisabledOriginalJourneyDto} from 'src/services/models/disabled-original-journey-dto';
import {TravelResponseDto} from 'src/services/models/travel-response-dto';
import {ModelError, ReservationTypeDto} from 'src/services/models';
import {OriginalRouteStepMap, originalRouteStepMapper} from './util/OriginalRouteStepMap';
import {AlternativeTravelDto} from 'src/services/models/alternative-travel-dto';
import {ReplaceTravelsActionDto} from 'src/services/models/replace-travels-action-dto';
import {TransportTypeDto} from 'src/services/models/transport-type-dto';
import {SettingsContext} from 'src/context/settings/settings.store';

import type {TravelStatus} from './JourneyView/JourneyView';
import {NewJourneyDto} from 'src/services/models/new-journey-dto';
import {getTimeZoneOffset} from './util/getTimeZoneOffset';
type IChangeRouteContext = {
  reservations: ReservationDto[];
  selectedOriginalRoute: DisabledOriginalJourneyDto[] | null;
  setSelectedOriginalRoute: React.Dispatch<DisabledOriginalJourneyDto[] | null>;
  alternativeRoutes: TravelResponseDto | null;
  fetchAlternativeRoutes: (s: DisabledOriginalJourneyDto[]) => void;
  openNewRoutePlanner: boolean;
  setOpenNewRoutePlanner: React.Dispatch<boolean>;
  manualRouteCreation: ChangeRouteState;
  manualRouteCreationDispatch: React.Dispatch<ChangeRouteReducerActions>;
  fetchManualRoute: () => void;
  originalRouteData: DisabledOriginalTravelsDto | null;
  showCorrectTrips: (variant: 'outbound' | 'return') => void;
  isFetchingAlternativeRoutes: boolean;
  fetchNextOrPrevious: (variant: 'next' | 'prev') => void;
  isIndividualSelected: boolean;
  setIsIndividualSelected: React.Dispatch<React.SetStateAction<boolean>>;
  fetchSingleProposal: (route: OriginalRouteStepMap) => Promise<void>;
  handleSelectionOfNewRoute: (route: AlternativeTravelDto) => void;
  selectedNewProposal:
    | {outBoundProposal: AlternativeTravelDto | undefined; inboundProposal: AlternativeTravelDto | undefined}
    | undefined;
  setSelectedNewProposal: React.Dispatch<
    React.SetStateAction<
      | undefined
      | {outBoundProposal: AlternativeTravelDto | undefined; inboundProposal: AlternativeTravelDto | undefined}
    >
  >;
  handleDeletionOfPartOfProposal: (ind: number) => void;
  selectedIndividualProposal: number | null;
  setSelectedIndividualProposal: React.Dispatch<React.SetStateAction<number | null>>;
  isFetchingManualRoutes: boolean;
  saveRoute: (isDraft: boolean) => void;
  variantSelected: undefined | 'outbound' | 'return';
  tripStatus: {inbound: TravelStatus; outbound: TravelStatus; active: 'inbound' | 'outbound' | undefined};
  setTripStatus: React.Dispatch<
    React.SetStateAction<{
      inbound: TravelStatus;
      outbound: TravelStatus;
      active: 'inbound' | 'outbound' | undefined;
    }>
  >;
};

export const ChangeRouteContext = React.createContext<IChangeRouteContext>({
  reservations: [],
  selectedOriginalRoute: null,
  setSelectedOriginalRoute: (() => {}) as React.Dispatch<DisabledOriginalJourneyDto[] | null>,
  alternativeRoutes: null,
  fetchAlternativeRoutes: (s: DisabledOriginalJourneyDto[]) => {},
  openNewRoutePlanner: false,
  setOpenNewRoutePlanner: (() => {}) as React.Dispatch<boolean>,
  manualRouteCreation: {} as ChangeRouteState,
  manualRouteCreationDispatch: (() => {}) as React.Dispatch<ChangeRouteReducerActions>,
  fetchManualRoute: (() => {}) as () => void,
  originalRouteData: null,
  showCorrectTrips: () => {},
  isFetchingAlternativeRoutes: false,
  fetchNextOrPrevious: () => {},
  isIndividualSelected: false,
  setIsIndividualSelected: () => {},
  fetchSingleProposal: async (route: OriginalRouteStepMap) => {},
  handleSelectionOfNewRoute: () => {},
  selectedNewProposal: undefined,
  setSelectedNewProposal: () => {},
  handleDeletionOfPartOfProposal: (ind: number) => {},
  selectedIndividualProposal: null,
  setSelectedIndividualProposal: () => {},
  isFetchingManualRoutes: false,
  saveRoute: () => {},
  variantSelected: undefined,
  tripStatus: {inbound: 'NO_CHANGES', outbound: 'NO_CHANGES', active: undefined},
  setTripStatus: () => {},
});

const ChangeRouteProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const {variables} = React.useContext(EnvironmentContext);
  const {id} = useParams<{id: string}>();
  const locations = useLocation();
  const {reservations} = React.useContext(ReservationsContext);
  const {validateNetworkCall} = React.useContext(NetworkContext);
  const {prevRoute, setIsMenuOpen} = React.useContext(SettingsContext);

  const [selectedOriginalRoute, setSelectedOriginalRoute] = React.useState<DisabledOriginalJourneyDto[] | null>(null);
  const [originalRouteData, setOriginalRouteData] = React.useState<DisabledOriginalTravelsDto | null>(null);

  const [alternativeRoutes, setAlternativeRoutes] = React.useState<TravelResponseDto | null>(null);

  //Show the new route
  const [openNewRoutePlanner, setOpenNewRoutePlanner] = React.useState<boolean>(false);

  //Custom route
  const [manualRouteCreation, manualRouteCreationDispatch] = React.useReducer(
    changeRouteReducer,
    initialChangeRouteReducerState,
  );

  //LoadingState
  const [isFetchingAlternativeRoutes, setIsFetchingAlternativeRoutes] = React.useState(false);

  //const individual proposal
  const [isIndividualSelected, setIsIndividualSelected] = React.useState(false);
  const [selectedIndividualProposal, setSelectedIndividualProposal] = React.useState<number | null>(null);

  //selected alternative proposal
  const [selectedNewProposal, setSelectedNewProposal] = React.useState<
    {outBoundProposal: AlternativeTravelDto | undefined; inboundProposal: AlternativeTravelDto | undefined} | undefined
  >(undefined);

  //searching for custom routes
  const [isFetchingManualRoutes, setIsFetchingManualRoutes] = React.useState(false);
  const [moreResultTokens, setMoreResultTokens] = React.useState({next: '', prev: ''});

  //variant
  const [variantSelected, setVariantSelected] = React.useState<undefined | 'outbound' | 'return'>(undefined);

  const [doRedirect, setDoRedirect] = React.useState(false);

  //Staus of trip
  const [tripStatus, setTripStatus] = React.useState<{
    inbound: TravelStatus;
    outbound: TravelStatus;
    active: 'inbound' | 'outbound' | undefined;
  }>({
    inbound: 'NO_CHANGES',
    outbound: 'NO_CHANGES',
    active: undefined,
  });

  const assistanceType = () => {
    const queryStringWithoutQuestionMark = locations.search.substring(1);
    // Split the query string into key-value pairs
    const keyValuePairs = queryStringWithoutQuestionMark.split('&');
    // Initialize an empty array to store the values
    const values: string[] = [];
    // Loop through each key-value pair
    keyValuePairs.forEach((pair: string) => {
      // Split the pair into key and value
      const [key, value] = pair.split('=');
      // Decode the URI component to handle special characters properly
      const decodedValue = decodeURIComponent(value);
      // Push the value into the array
      values.push(decodedValue);
    });
    return values[2];
  };

  const {
    RESERVATIONS_FULL_ALTERNATIVE_ROUTE_MAXIMUM_TRANSFER_TIME,
    RESERVATIONS_FULL_ALTERNATIVE_ROUTE_MINIMUM_TRANSFER_TIME,
    RESERVATIONS_LIGHT_ALTERNATIVE_ROUTE_MAXIMUM_TRANSFER_TIME,
    RESERVATIONS_LIGHT_ALTERNATIVE_ROUTE_MINIMUM_TRANSFER_TIME,
  } = React.useContext(SettingsContext);

  const minTransfer =
    assistanceType() === 'Full'
      ? RESERVATIONS_FULL_ALTERNATIVE_ROUTE_MINIMUM_TRANSFER_TIME
      : RESERVATIONS_LIGHT_ALTERNATIVE_ROUTE_MINIMUM_TRANSFER_TIME;

  const maxTransfer =
    assistanceType() === 'Full'
      ? RESERVATIONS_FULL_ALTERNATIVE_ROUTE_MAXIMUM_TRANSFER_TIME
      : RESERVATIONS_LIGHT_ALTERNATIVE_ROUTE_MAXIMUM_TRANSFER_TIME;

  const fetchAlternativeRoutes = async (selectedRoute: DisabledOriginalJourneyDto[], moreResultsToken?: string) => {
    setIsFetchingManualRoutes(false);
    setIsIndividualSelected(false);
    setIsFetchingAlternativeRoutes(true);
    const first = selectedRoute[0];
    const last = selectedRoute[selectedRoute.length - 1];

    const departurUic = first?.departureUicCode || '';
    const departingAt = first?.departureTime || '';
    const arrivalUic = last?.arrivalUicCode || '';

    await getAlternativeRoutes(
      variables.BASE_ENDPOINT,
      departurUic,
      arrivalUic,
      departingAt,
      minTransfer,
      maxTransfer,
      moreResultsToken,
    )
      .then(res => {
        if (res) {
          setAlternativeRoutes(res);
          setMoreResultTokens({next: res.laterResultsToken || '', prev: res.earlierResultsToken || ''});
          setIsFetchingAlternativeRoutes(false);
        }
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          validateNetworkCall(err.response.status, err.response.data as ModelError);
          setIsFetchingAlternativeRoutes(false);
        }
      });
  };

  const fetchSingleProposal = async (route: OriginalRouteStepMap, moreResultToken?: string) => {
    setIsFetchingManualRoutes(false);
    setIsFetchingAlternativeRoutes(true);

    await getAlternativeRoutes(
      variables.BASE_ENDPOINT,
      route.departureStation.uic,
      route.arrivalStation.uic,
      route.departureDate,
      minTransfer,
      maxTransfer,
      moreResultToken,
    )
      .then(res => {
        if (res) {
          setAlternativeRoutes(res);
          setMoreResultTokens({next: res.laterResultsToken || '', prev: res.earlierResultsToken || ''});
          setIsFetchingAlternativeRoutes(false);
        }
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          validateNetworkCall(err.response.status, err.response.data as ModelError);
          setIsFetchingAlternativeRoutes(false);
        }
      });
  };

  const fetchNextOrPrevious = (variant: 'next' | 'prev') => {
    //HANDLE STATES OVER HERE
    let routeDataForNextOrPrev = selectedOriginalRoute;

    let token = undefined;
    token = variant === 'next' ? moreResultTokens.next : moreResultTokens.prev;

    if (isFetchingManualRoutes) {
      fetchManualRoute(token);
      return;
    }

    if (isIndividualSelected) {
      const individual = selectedOriginalRoute && selectedOriginalRoute[selectedIndividualProposal || 0];
      if (individual) {
        if (token !== null) {
          fetchSingleProposal(originalRouteStepMapper(individual), token);
        }
      }
    } else {
      fetchAlternativeRoutes(routeDataForNextOrPrev || [], token as string);
    }
  };

  const fetchManualRoute = async (token?: string) => {
    setIsFetchingManualRoutes(true);
    setIsFetchingAlternativeRoutes(true);
    const formatMonth = manualRouteCreation.date.split('-').reverse().toString().replaceAll(',', '-');
    const date = formatMonth + 'T' + manualRouteCreation.time + `:00${getTimeZoneOffset()}`;
    setOpenNewRoutePlanner(false);
    await getAlternativeRoutes(
      variables.BASE_ENDPOINT,
      manualRouteCreation.departureUic,
      manualRouteCreation.arrivalUic,
      date,
      minTransfer,
      maxTransfer,
      token,
    )
      .then(res => {
        if (res) {
          setAlternativeRoutes(res);
          setMoreResultTokens({next: res.laterResultsToken || '', prev: res.earlierResultsToken || ''});
          setIsFetchingAlternativeRoutes(false);
        }
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          validateNetworkCall(err.response.status, err.response.data as ModelError);
          setIsFetchingAlternativeRoutes(false);
        }
      });
  };

  const fetchOriginalRoute = async (id: string, minTransfer: number, maxTransfer: number) => {
    await getOriginaleRoute(variables.BASE_ENDPOINT, id).then(res => {
      setOriginalRouteData(res);
      let draftOut: AlternativeTravelDto | undefined = undefined;
      let draftReturn: AlternativeTravelDto | undefined = undefined;

      if (res.draftReturnJourneys && res.draftReturnJourneys.length > 0) {
        draftReturn = {
          duration: '',
          journeys: res.draftReturnJourneys as NewJourneyDto[],
        };
      }

      if (res.draftOutboundJourneys && res.draftOutboundJourneys.length > 0) {
        draftOut = {
          duration: '',
          journeys: res.draftOutboundJourneys as NewJourneyDto[],
        };
        setSelectedOriginalRoute(res.outboundJourneys as DisabledOriginalJourneyDto[]);
      }

      if (
        (res.draftOutboundJourneys && res.draftOutboundJourneys.length > 0) ||
        (res.draftReturnJourneys && res.draftReturnJourneys.length > 0)
      ) {
        setSelectedNewProposal({inboundProposal: draftReturn, outBoundProposal: draftOut});
        setTripStatus({active: 'outbound', inbound: 'ROUTE_COMPLETED', outbound: 'ROUTE_COMPLETED'});
        setVariantSelected('outbound');
        if (res.outboundJourneys) {
          fetchAlternativeRoutes(res.outboundJourneys);
        } else if (res.returnJourneys) {
          fetchAlternativeRoutes(res.returnJourneys);
        }
      }
    });
  };

  const showCorrectTrips = (variant: 'outbound' | 'return') => {
    setVariantSelected(variant);
    const getOriginalData =
      variant === 'outbound' ? originalRouteData?.outboundJourneys : originalRouteData?.returnJourneys;
    fetchAlternativeRoutes(getOriginalData || []);
    setSelectedOriginalRoute(getOriginalData || []);
    setTripStatus(prev => ({...prev, active: variant === 'outbound' ? 'outbound' : 'inbound'}));
  };

  const handleSelectionOfNewRoute = (proposal: AlternativeTravelDto) => {
    const active =
      tripStatus.active === 'inbound' ? selectedNewProposal?.inboundProposal : selectedNewProposal?.outBoundProposal;
    setSelectedNewProposal(prev => ({
      inboundProposal: tripStatus.active === 'inbound' ? proposal : prev?.inboundProposal,
      outBoundProposal: tripStatus.active === 'outbound' ? proposal : prev?.outBoundProposal,
    }));
  };

  const handleDeletionOfPartOfProposal = (ind: number) => {
    if (ind === 0) {
      setSelectedNewProposal(prev => ({
        inboundProposal: tripStatus.active === 'inbound' ? undefined : prev?.inboundProposal,
        outBoundProposal: tripStatus.active === 'outbound' ? undefined : prev?.outBoundProposal,
      }));
      fetchAlternativeRoutes(selectedOriginalRoute || [], undefined);
      setTripStatus(prev => ({
        active: prev.active,
        inbound: prev.active === 'inbound' ? 'ROUTE_INCOMPLETE' : prev.inbound,
        outbound: prev.active === 'outbound' ? 'ROUTE_INCOMPLETE' : prev.outbound,
      }));
    } else {
      const copyOfSelectedProposal =
        tripStatus.active === 'inbound'
          ? selectedNewProposal?.inboundProposal?.journeys
          : selectedNewProposal?.outBoundProposal?.journeys;
      copyOfSelectedProposal?.splice(ind, copyOfSelectedProposal.length);
      const newProposal: AlternativeTravelDto = {
        duration:
          tripStatus.active === 'inbound'
            ? selectedNewProposal?.inboundProposal?.duration
            : selectedNewProposal?.outBoundProposal?.duration,
        journeys: copyOfSelectedProposal,
      };
      setSelectedNewProposal(prev => ({
        inboundProposal: tripStatus.active === 'inbound' ? newProposal : prev?.inboundProposal,
        outBoundProposal: tripStatus.active === 'outbound' ? newProposal : prev?.outBoundProposal,
      }));
    }
  };

  const saveRoute = async (isDraft = false) => {
    setIsFetchingAlternativeRoutes(true);
    const queryStringWithoutQuestionMark = locations.search.substring(1);
    // Split the query string into key-value pairs
    const keyValuePairs = queryStringWithoutQuestionMark.split('&');
    // Initialize an empty array to store the values
    const values: string[] = [];
    // Loop through each key-value pair
    keyValuePairs.forEach((pair: string) => {
      // Split the pair into key and value
      const [key, value] = pair.split('=');
      // Decode the URI component to handle special characters properly
      const decodedValue = decodeURIComponent(value);
      // Push the value into the array
      values.push(decodedValue);
    });
    const formatData: ReplaceTravelsActionDto = {
      isDraft: isDraft,
      reasonId: values[0] !== 'undefined' ? values[0] : undefined,
      comment: values[1],
      reservationId: id,
      reservationType: ReservationTypeDto.Disabled,
      //@ts-ignore
      newOutboundJourneys:
        selectedNewProposal?.outBoundProposal !== undefined
          ? selectedNewProposal?.outBoundProposal?.journeys?.map(el => {
              return {
                arrivalRemark: el.arrivalRemark || '',
                arrivalTime: el.arrivalTime,
                arrivalUicCode: el.arrivalUicCode,
                departureRemark: el.departureRemark || '',
                departureTime: el.departureTime,
                departureUicCode: el.departureUicCode,
                transportId: el.transportId,
                //@ts-ignore
                transportType: el.transportType || TransportTypeDto.Train,
              };
            })
          : [],
      //@ts-ignore
      newReturnJourneys:
        selectedNewProposal?.inboundProposal !== undefined
          ? selectedNewProposal?.inboundProposal?.journeys?.map(el => {
              return {
                arrivalRemark: el.arrivalRemark || '',
                arrivalTime: el.arrivalTime,
                arrivalUicCode: el.arrivalUicCode,
                departureRemark: el.departureRemark || '',
                departureTime: el.departureTime,
                departureUicCode: el.departureUicCode,
                transportId: el.transportId,
                //@ts-ignore

                transportType: el.transportType || TransportTypeDto.Train,
              };
            })
          : [],
    };
    await saveAlternativeRoute(variables.BASE_ENDPOINT, formatData).then(r => {
      setIsMenuOpen(true);
      setDoRedirect(true);
    });
  };

  React.useEffect(() => {
    if (id) {
      fetchOriginalRoute(id, minTransfer, maxTransfer);
    }
  }, [id]);

  return (
    <ChangeRouteContext.Provider
      value={{
        reservations,
        selectedOriginalRoute,
        setSelectedOriginalRoute,
        alternativeRoutes,
        fetchAlternativeRoutes,
        openNewRoutePlanner,
        setOpenNewRoutePlanner,
        manualRouteCreation,
        manualRouteCreationDispatch,
        fetchManualRoute,
        originalRouteData,
        showCorrectTrips,
        isFetchingAlternativeRoutes,
        fetchNextOrPrevious,
        isIndividualSelected,
        setIsIndividualSelected,
        fetchSingleProposal,
        handleSelectionOfNewRoute,
        selectedNewProposal,
        setSelectedNewProposal,
        handleDeletionOfPartOfProposal,
        selectedIndividualProposal,
        setSelectedIndividualProposal,
        isFetchingManualRoutes,
        saveRoute,
        variantSelected,
        tripStatus,
        setTripStatus,
      }}
    >
      {children}
      {doRedirect && <Navigate to={prevRoute} />}
    </ChangeRouteContext.Provider>
  );
};

export default ChangeRouteProvider;
