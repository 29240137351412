import React from 'react';
import {Fab, IconButton, Box} from '@mui/material';
import {PlusIcon} from 'src/components/icons';
import {NewMissionButtonContainer} from '../Menu.styles';
import {useRect} from './useRect';
import {useLocation, useNavigate} from 'react-router-dom';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {routes} from 'src/constants/routes';
import {SettingsContext} from 'src/context/settings/settings.store';

export const NewMissionButton: React.FC<{disabled: boolean}> = ({disabled}) => {
  // Remove conditional location hook
  const location = useLocation();
  const plusRef = React.useRef(null);
  const rect = useRect(plusRef);

  // Memoize dimensions to prevent unnecessary re-renders
  const dimensions = React.useMemo(() => {
    return {
      top: rect.top - rect.height,
      left: rect.left + rect.width + 20,
    };
  }, [rect.top, rect.height, rect.left, rect.width]);

  const [isFabVisible, setIsFabVisible] = React.useState(false);
  const {variables} = React.useContext(EnvironmentContext);
  const navigate = useNavigate();
  const {COI_FEATURE_FLAG} = React.useContext(SettingsContext);

  // Use location.pathname as a stable dependency
  React.useEffect(() => {
    // Reset fab visibility when location changes
    setIsFabVisible(false);
  }, [location.pathname]);

  const relocate = React.useCallback(
    (isPmr: boolean, isCoi: boolean) => {
      setIsFabVisible(false);

      if (isCoi) {
        navigate(routes.coi.create_incident);
        return;
      }

      if (isPmr) {
        navigate(variables.USE_PMR_3 ? '/reservation/last-minute-reservation-pmr' : '/last-minute-reservation-pmr');
        return;
      }

      navigate(variables.USE_PMR_3 ? '/reservation/last-minute-reservation-grobo' : '/last-minute-reservation-grobo');
    },
    [navigate, variables.USE_PMR_3],
  );

  return (
    <>
      <IconButton
        disabled={disabled}
        ref={plusRef}
        data-testid="new-mission-button"
        sx={NewMissionButtonContainer}
        onClick={() => setIsFabVisible(prev => !prev)}
      >
        <PlusIcon color="white" />
      </IconButton>
      <Box
        sx={{
          position: 'absolute',
          top: dimensions.top,
          left: dimensions.left,
          display: 'grid',
          gridTemplateRows: '1fr 1fr',
          gap: '10px',
          width: `${isFabVisible ? 'auto' : 0}`,
          visibility: `${isFabVisible ? 'visible' : 'hidden'}`,
        }}
      >
        <Fab variant="extended" sx={{backgroundColor: '#FFD046', width: '150px'}} onClick={() => relocate(true, false)}>
          PMR
        </Fab>
        <Fab variant="extended" sx={{backgroundColor: '#00A8A9'}} onClick={() => relocate(false, false)}>
          GROUP
        </Fab>
        {COI_FEATURE_FLAG && (
          <Fab variant="extended" sx={{backgroundColor: '#ff7b00'}} onClick={() => relocate(false, true)}>
            INCIDENT
          </Fab>
        )}
      </Box>
    </>
  );
};
