import {IconButton, Menu, MenuItem, Radio, ListItemIcon, ListItemText, InputAdornment} from '@mui/material';
import React, {useState} from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import {MessageFilter} from './coiChatView';
import {useTranslation} from 'react-i18next';

export const ChatFilter: React.FC<{
  selectedOption: MessageFilter;
  onFilterChange: (option: MessageFilter) => void;
}> = ({selectedOption, onFilterChange}) => {
  const {t} = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = (option?: MessageFilter) => {
    setAnchorEl(null);
    if (option) {
      onFilterChange(option);
    }
  };

  return (
    <InputAdornment position="end">
      <IconButton onClick={handleFilterClick}>
        <FilterListIcon sx={{color: selectedOption === MessageFilter.AUTO ? 'orange' : '#1374b9'}} />
      </IconButton>
      <span style={{marginLeft: 2, marginRight: 2, color: '#1374b9', cursor: 'pointer'}} onClick={handleFilterClick}>
        {t('filter.name')}
      </span>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleFilterClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => handleFilterClose(MessageFilter.ALL)}>
          <ListItemIcon>
            <Radio
              checked={selectedOption === MessageFilter.ALL}
              onChange={() => handleFilterClose(MessageFilter.ALL)}
              value={MessageFilter.ALL}
            />
          </ListItemIcon>
          <ListItemText primary={MessageFilter.ALL} />
        </MenuItem>
        <MenuItem onClick={() => handleFilterClose(MessageFilter.AUTO)}>
          <ListItemIcon>
            <Radio
              checked={selectedOption === MessageFilter.AUTO}
              onChange={() => handleFilterClose(MessageFilter.AUTO)}
              value={MessageFilter.AUTO}
            />
          </ListItemIcon>
          <ListItemText primary={MessageFilter.AUTO} />
        </MenuItem>
        <MenuItem onClick={() => handleFilterClose(MessageFilter.MANUAL)}>
          <ListItemIcon>
            <Radio
              checked={selectedOption === MessageFilter.MANUAL}
              onChange={() => handleFilterClose(MessageFilter.MANUAL)}
              value={MessageFilter.MANUAL}
            />
          </ListItemIcon>
          <ListItemText primary={MessageFilter.MANUAL} />
        </MenuItem>
      </Menu>
    </InputAdornment>
  );
};
