import React from 'react';
import {discardDraftReservation, fetchReservations, fetchSearchReservations, refuseReservation} from './data';
import {EnvironmentContext} from '../environment/environment.store';
import {NetworkContext} from '../network/network.store';
import {AxiosError} from 'axios';
import {ModelError, ReservationTypeDto} from 'src/services/models';
import {DisabledReservationDto} from 'src/services/models/disabled-reservation-dto';
import {ReservationStatusDto} from 'src/services/models/reservation-status-dto';
import {localStorageKeys} from 'src/utils/localStorageKeys';
import {FormState, initialFormState} from 'src/scenes/pmr/formState';
import {RefuseReservationActionDetailDto} from 'src/services/models/refuse-reservation-action-detail-dto';
import {RefusedStationDto} from 'src/services/models/refused-station-dto';
import {finalizedHideIfCompliedToSelection} from 'src/scenes/pmr/util';
import {useDistrictContext} from '../district/district.store';
import { AuthContext, AuthPermissions } from '../authentication/store.auth';

type IReservationContext = {
  reservations: DisabledReservationDto[];
  isFetchingProposalReservations: boolean;
  isFetchingPendingReservations: boolean;
  focusedReservation: number | null;
  setFocusedReservation: React.Dispatch<number | null>;
  isDeclineViewVisible: boolean;
  setIsDeclineViewVisible: React.Dispatch<boolean>;
  cancelRefusalModal: boolean;
  setCancelRefusalModal: React.Dispatch<boolean>;
  reservationsInProgress: DisabledReservationDto[];
  initReservationsStore: () => Promise<void>;
  searchedReservations: DisabledReservationDto[];
  searchReservations: (form?: FormState) => Promise<void>;
  isSearching: boolean;
  refuseReservations: (toRefuse: RefuseReservationActionDetailDto) => Promise<void>;
  refusalData: RefuseReservationActionDetailDto;
  setRefusalData: React.Dispatch<React.SetStateAction<RefuseReservationActionDetailDto>>;
  fetchProposalReservations: (district: string) => Promise<void>;
  fetchInProgressReservations: (district: string) => Promise<void>;
  setIsFetchingProposalReservations: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFetchingPendingReservations: React.Dispatch<React.SetStateAction<boolean>>;
  handleLengthCalc: {revLength: number; revProgLength: number};
  discardDraft: (reservationId: string) => Promise<void>;
  resetReservationsStore: () => void;
};

export const ReservationsContext = React.createContext<IReservationContext>({
  reservations: [],
  isFetchingProposalReservations: true,
  isFetchingPendingReservations: true,
  focusedReservation: null,
  setFocusedReservation: (() => {}) as React.Dispatch<number | null>,
  isDeclineViewVisible: false,
  setIsDeclineViewVisible: (() => {}) as React.Dispatch<boolean>,
  cancelRefusalModal: false,
  setCancelRefusalModal: (() => {}) as React.Dispatch<boolean>,
  reservationsInProgress: [],
  initReservationsStore: async () => {},
  searchedReservations: [],
  searchReservations: async () => {},
  isSearching: false,
  refuseReservations: async () => {},
  refusalData: {},
  setRefusalData: (() => {}) as React.Dispatch<React.SetStateAction<RefuseReservationActionDetailDto>>,
  fetchProposalReservations: async (_district: string) => {},
  fetchInProgressReservations: async (_district: string) => {},
  setIsFetchingProposalReservations: () => {},
  setIsFetchingPendingReservations: () => {},
  handleLengthCalc: {revLength: 0, revProgLength: 0},
  discardDraft: async () => {},
  resetReservationsStore: () => {},
});

const ReservationsProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const {permissions} = React.useContext(AuthContext);
  const [reservations, setReservations] = React.useState<DisabledReservationDto[]>([]);
  const [reservationsInProgress, setReservationsInProgress] = React.useState<DisabledReservationDto[]>([]);
  const [isFetchingProposalReservations, setIsFetchingProposalReservations] = React.useState(true);
  const [isFetchingPendingReservations, setIsFetchingPendingReservations] = React.useState(true);
  const [focusedReservation, setFocusedReservation] = React.useState<number | null>(null);
  const [isDeclineViewVisible, setIsDeclineViewVisible] = React.useState(false);
  const [cancelRefusalModal, setCancelRefusalModal] = React.useState(false);

  //Search
  const [searchedReservations, setSearchedReservations] = React.useState<DisabledReservationDto[]>([]);
  const [isSearching, setIsSearching] = React.useState<boolean>(false);
  const [prevSearchState, setPrevSearchState] = React.useState<FormState>(initialFormState);

  //Recalc
  const [forceRecalc, setForceRecalc] = React.useState(false);

  //Refusal
  const [refusalData, setRefusalData] = React.useState<RefuseReservationActionDetailDto>({});

  //URL param
  const isSearch = window.location.pathname.includes('/search');
  const isValidation = window.location.pathname.includes('/validation-in-progress');

  //DIstrictContext
  const {selectedDistrict, selectedStations} = useDistrictContext();

  React.useEffect(() => {
    const reservationsToRefuse = isSearch ? searchedReservations : isValidation ? reservationsInProgress : reservations;
    if (focusedReservation !== null) {
      const affectedStations: RefusedStationDto[] = [];
      const r: RefuseReservationActionDetailDto = {
        affectedStations: affectedStations,
        comment: '',
        reasonId: undefined,
        reservationId: reservationsToRefuse[focusedReservation]?.id,
        reservationType: ReservationTypeDto.Disabled,
      };
      setRefusalData(r);
    } else {
      setRefusalData({});
    }
  }, [focusedReservation]);

  const {validateNetworkCall} = React.useContext(NetworkContext);

  //Env
  const {variables} = React.useContext(EnvironmentContext);

  const handleLengthCalc = React.useMemo(() => {
    const revLength = reservations
      .map(e => finalizedHideIfCompliedToSelection(<p></p>, e))
      .filter(el => el !== null).length;
    const revProgLength = reservationsInProgress
      .map(e => finalizedHideIfCompliedToSelection(<p></p>, e))
      .filter(el => el !== null).length;

    const res = {
      revLength: revLength,
      revProgLength: revProgLength,
    };

    return res;
  }, [reservations, reservationsInProgress, forceRecalc]);

  const fetchProposalReservations = async (district: string) => {
    setFocusedReservation(null);
    setIsFetchingProposalReservations(true);
    setIsDeclineViewVisible(false);
    await fetchReservations(variables.BASE_ENDPOINT, [ReservationStatusDto.Proposal], district)
      .then(res => {
        const proposal = res.filter(el => el.status === ReservationStatusDto.Proposal);
        setReservations(proposal);
        setIsFetchingProposalReservations(false);
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          validateNetworkCall(err.response.status, err.response.data as ModelError);
          setIsFetchingProposalReservations(false);
        }
      });
    setForceRecalc(prev => !prev);
  };

  const fetchInProgressReservations = async (district: string) => {
    setFocusedReservation(null);
    setIsFetchingPendingReservations(true);
    setIsDeclineViewVisible(false);
    await fetchReservations(variables.BASE_ENDPOINT, [ReservationStatusDto.Pending], district)
      .then(res => {
        const progress = res.filter(el => el.status === ReservationStatusDto.Pending);
        setReservationsInProgress(progress);
        setIsFetchingPendingReservations(false);
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          validateNetworkCall(err.response.status, err.response.data as ModelError);
          setIsFetchingPendingReservations(false);
        }
      });
    setForceRecalc(prev => !prev);
  };

  const discardDraft = async (reservationId: string) => {
    setFocusedReservation(null);
    setIsFetchingPendingReservations(true);
    setIsDeclineViewVisible(false);
    await discardDraftReservation(variables.BASE_ENDPOINT, reservationId).catch((err: AxiosError) => {
      if (err.response) {
        validateNetworkCall(err.response.status, err.response.data as ModelError);
        setIsFetchingPendingReservations(false);
      }
    });
  };

  const initReservationsStore = async () => {
    setFocusedReservation(null);
    setIsDeclineViewVisible(false);
    const district = localStorage.getItem(localStorageKeys.SELECTED_DISTRICT) || '';
    await Promise.all([fetchProposalReservations(district), fetchInProgressReservations(district)]);
  };

  const resetReservationsStore = () => {
    setReservations(_prev => []);
    setReservationsInProgress(_prev => []);
    setForceRecalc(prev => !prev);
  };

  const searchReservations = async (form?: FormState) => {
    setFocusedReservation(null);
    setIsDeclineViewVisible(false);
    setPrevSearchState(form ? form : prevSearchState);
    setSearchedReservations([]);
    const district = localStorage.getItem(localStorageKeys.SELECTED_DISTRICT) || '';
    setIsSearching(true);
    await fetchSearchReservations(form ? form : prevSearchState, variables.BASE_ENDPOINT, district, permissions.includes(AuthPermissions.FullPmrAssistance))
      .then(res => {
        setSearchedReservations(res);
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          validateNetworkCall(err.response.status, err.response.data as ModelError);
        }
      });
    setIsSearching(false);
  };

  const refuseReservations = async (toRefuse: RefuseReservationActionDetailDto) => {
    const district = localStorage.getItem(localStorageKeys.SELECTED_DISTRICT) || '';
    setIsDeclineViewVisible(false);
    setIsSearching(true);
    setFocusedReservation(null);
    setSearchedReservations([]);
    await refuseReservation(variables.BASE_ENDPOINT, toRefuse)
      .then(_res => {
        if (isSearch) {
          searchReservations();
        } else {
          if (window.location.pathname.includes('overview')) {
            fetchProposalReservations(district);
          } else if (window.location.pathname.includes('validation-in-progress')) {
            fetchInProgressReservations(district);
          } else {
            initReservationsStore();
          }
          setIsSearching(false);
        }
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          validateNetworkCall(err.response.status, err.response.data as ModelError);
        }
      });
    setIsSearching(false);
  };
  const value = React.useMemo(
    () => ({
      reservations,
      isFetchingProposalReservations,
      isFetchingPendingReservations,
      reservationsInProgress,
      focusedReservation,
      setFocusedReservation,
      isDeclineViewVisible,
      setIsDeclineViewVisible,
      cancelRefusalModal,
      setCancelRefusalModal,
      initReservationsStore,
      searchedReservations,
      searchReservations,
      isSearching,
      refuseReservations,
      refusalData,
      setRefusalData,
      fetchInProgressReservations,
      fetchProposalReservations,
      setIsFetchingProposalReservations,
      setIsFetchingPendingReservations,
      handleLengthCalc,
      discardDraft,
      resetReservationsStore,
    }),
    [
      reservations,
      isFetchingProposalReservations,
      isFetchingPendingReservations,
      focusedReservation,
      isDeclineViewVisible,
      cancelRefusalModal,
      reservationsInProgress,
      isSearching,
      searchedReservations,
      refusalData,
      setRefusalData,
      handleLengthCalc,
    ],
  );

  React.useEffect(() => {
    variables.USE_PMR_3 && initReservationsStore();
  }, []);

  if (variables.USE_PMR_3) {
    return <ReservationsContext.Provider value={value}>{children}</ReservationsContext.Provider>;
  } else {
    return <>{children}</>;
  }
};

export default ReservationsProvider;
