import React from 'react';

const CalendarIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({...props}) => (
  <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 1.33333H11.3333V0H10V1.33333H3.33333V0H2V1.33333H1.33333C0.6 1.33333 0 1.93333 0 2.66667V13.3333C0 14.0667 0.6 14.6667 1.33333 14.6667H12C12.7333 14.6667 13.3333 14.0667 13.3333 13.3333V2.66667C13.3333 1.93333 12.7333 1.33333 12 1.33333ZM12 13.3333H1.33333V6H12V13.3333ZM12 4.66667H1.33333V2.66667H12V4.66667Z"
      fill="black"
      style={{
        fillOpacity: '0.87',
      }}
    />
  </svg>
);
export default CalendarIcon;
