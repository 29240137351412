import React from 'react';
import {SvgIcon, Grid, IconButton} from '@mui/material';
import NoteIcon from 'src/icons/NoteIcon';
import {useTranslation} from 'react-i18next';
import {MissionDetailDto} from 'src/services/models/mission-detail-dto';
import {RemarkDialog} from './RemarkDialog';
import NoteIconNew from 'src/icons/NoteIconNew';
import {ReservationDto} from 'src/services/models/reservation-dto';

export const TravelerInformationBanner: React.FC<{mission: MissionDetailDto | ReservationDto}> = ({mission}) => {
  const {t} = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  return (
    <>
      <Grid item xs={8} mb={'16px'}>
        <h4>{t('mission-details-v2.traveler.overview')}</h4>
      </Grid>
      <Grid item xs={4} display={'flex'} justifyContent={'flex-end'}>
        <IconButton
          disabled={!mission.remarks || mission.remarks.length === 0}
          onClick={() => setIsDialogOpen(mission.remarks && mission.remarks.length > 0 ? true : false)}
          sx={{width: '40px', height: '32px'}}
        >
          {mission.remarks && mission.remarks?.length > 0 ? (
            <NoteIconNew width={'26px'} height={'26px'} />
          ) : (
            <NoteIcon opacity={0.5} />
          )}
        </IconButton>
      </Grid>
      <RemarkDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        remarks={mission.remarks || []}
        fullWidth={true}
        maxWidth={'lg'}
        close={() => setIsDialogOpen(false)}
      />
    </>
  );
};
