import {SxProps, Theme} from '@mui/system';

export const MenuContainer: SxProps<Theme> = {
  height: 'calc(100vh - 64px)',
  display: 'grid',
  gridColumn: '1/2',
  gridRow: '2/3',
  top: '64px',
  zIndex: 1,
  backgroundColor: '#FFFFFF',
};

export const TopLevelContainer: SxProps<Theme> = {
  boxShadow: '2px 0px 2px #0000001A',
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 64px)',
  justifyContent: 'space-between',
  alignItems: 'center',
  overflowY: 'auto',
  padding: '20px 0'
};

export const SubLevelContainer: SxProps<Theme> = {
  boxShadow: '2px 0px 4px #0000001A',
  border: '1px solid #E5E5E5',
  borderRadius: '0px 8px 8px 0px',
  display: 'grid',
  placeItems: 'center',
  transitionDuration: '0.8s',
};

export const NewMissionButtonContainer: SxProps = {
  backgroundColor: '#0069B4',
  height: '56px',
  width: '56px',
  borderRadius: '16px',
  marginBottom: '20px'
};

export const SubLevelButton: SxProps = {
  justifyContent: 'flex-start',
  fontWeight: 'normal',
  textAlign: 'left',
  textTransform: 'capitalize',
  padding: '15px',
  borderRadius: '24px',
  color: 'black',
  display: 'grid',
  gridTemplateColumns: '1fr 20px',
};
