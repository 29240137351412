// @ts-nocheck

import moment from 'moment';
import i18next from 'i18next';
import {handleDataLocalization} from 'src/utils/handleDataLocalization';
import {AppType} from 'src/utils/AppType';
import {MissionType} from 'src/utils/MissionType';

const formatDate = dateObj => {
  if (dateObj) {
    const ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(dateObj);
    const mo = new Intl.DateTimeFormat('en', {month: 'numeric'}).format(dateObj);
    const da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(dateObj);
    return `${ye}-${mo}-${da}`;
  }
  return undefined;
};

const formatType = 'DD/MM/YYYY';
const dateFormat = date => (date ? moment(date).format(formatType) : '');

const stringFormatDate = (date, locale) => {
  if (date === null || date === undefined) {
    return '';
  }
  const dateOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  return new Date(date).toLocaleDateString(locale, dateOptions);
};

const formatTime = date => {
  if (date === undefined || date === null || date.length === 0) {
    return undefined;
  }
  const dateObj = new Date(date);
  const hour = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const hourFormatted = hour <= 9 ? `0${hour}` : hour;
  const minutesFormatted = minutes <= 9 ? `0${minutes}` : minutes;
  return `${hourFormatted}:${minutesFormatted}`;
};

const convertTimeToMinutes = data => {
  if (data === undefined || data === null) {
    return 0;
  }
  const splittedTime = data.split(':');
  let minutes = 0;
  if (splittedTime[0] != 0) {
    minutes = parseInt(splittedTime[0], 10) * 60;
  }
  if (splittedTime[1] != 0) {
    minutes += parseInt(splittedTime[1], 10);
  }
  return minutes;
};

const getLoggedInUser = () => (localStorage.getItem('userId') ? localStorage.getItem('userId') : '');

const isStickerMission = (applicationType, missionType) =>
  applicationType === AppType.Grobo && missionType === MissionType.StickerMission;

const searchDataInList = (dataList, value) => {
  if (value.length >= 2) {
    const missionData = [...dataList];
    const searchedData = missionData.filter(({reservation, journey, applicationType, missionType}) => {
      if (isStickerMission(applicationType, missionType))
        return handleDataLocalization(
          journey?.firstDepartureStation?.firstDepartureStationDetail,
          'name',
          i18next.language,
        )
          ?.toLowerCase()
          .includes(value);
      return (
        reservation.client.firstName.toLowerCase().includes(value) ||
        reservation.client.lastName.toLowerCase().includes(value) ||
        reservation.client.phone.toLowerCase().includes(value) ||
        reservation.client.mail.toLowerCase().includes(value) ||
        journey.trainNumber.toLowerCase().includes(value) ||
        handleDataLocalization(journey.departureStationName, 'name', i18next.language)?.toLowerCase().includes(value) ||
        handleDataLocalization(journey.arrivalStationName, 'name', i18next.language)?.toLowerCase().includes(value)
      );
    });
    if (value.length >= 2) {
      return searchedData;
    }
  }
  return dataList;
};

const toggleCheckbox = remove => {
  const x = document.getElementsByClassName('checkbox-style');
  for (let i = 0; i < x.length; i += 1) {
    if (remove) {
      x[i].classList.add('checkbox-hide');
    } else {
      x[i].classList.remove('checkbox-hide');
    }
  }

  const z = document.getElementsByClassName('multiple-accepter');
  const y = document.querySelector('.coordinator-popup');
  if (y) {
    if (remove) {
      y.classList.remove('coordinator-popup-hide');
    } else {
      y.classList.add('coordinator-popup-hide');
    }
  }
  for (let j = 0; j < z.length; j += 1) {
    if (remove) {
      z[j].classList.remove('multiple-accepter-hide');
    } else {
      z[j].classList.add('multiple-accepter-hide');
    }
  }
};

const cleanObject = obj => {
  for (let propName in obj) {
    if (!obj[propName]) {
      delete obj[propName];
    }
  }
  return obj;
};

const compareDate = (a, b) => {
  if (!a?.journey?.departureDate) {
    return 0;
  }
  if (moment(a?.journey?.departureDate).isAfter(moment(b?.journey?.departureDate))) {
    return 1;
  } else {
    return -1;
  }
};
const compareName = (a, b) => {
  if (
    a?.journey?.firstDepartureStation?.firstDepartureStationDetail?.nameEn >
    b?.journey?.firstDepartureStation?.firstDepartureStationDetail?.nameEn
  ) {
    return 1;
  }
  if (
    a?.journey?.firstDepartureStation?.firstDepartureStationDetail?.nameEn <
    b?.journey?.firstDepartureStation?.firstDepartureStationDetail?.nameEn
  ) {
    return -1;
  }
  return 0;
};
const comapreTime = (a, b) => {
  if (!a.journey.departureDate) {
    return 0;
  }
  if (
    moment(a.journey.departureStationTimingInformation.plannedDepartureDate).isAfter(
      moment(b.journey.departureStationTimingInformation.plannedDepartureDate),
    )
  ) {
    return 1;
  } else {
    return -1;
  }
};

const groupCount = data =>
  data && data.length > 0 ? data.map(x => Number(x.quantity)).reduce((total, number) => total + number, 0) : 0;

export {
  formatDate,
  stringFormatDate,
  formatTime,
  convertTimeToMinutes,
  getLoggedInUser,
  searchDataInList,
  toggleCheckbox,
  cleanObject,
  groupCount,
  dateFormat,
  formatType,
  compareDate,
  compareName,
  comapreTime,
};
