import React from 'react';

const ClockIcon = () => (
  <svg
    id="ic_time_picker"
    data-name="ic_time picker"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="Group_7622" data-name="Group 7622">
      <path
        id="Path_5168"
        data-name="Path 5168"
        d="M12,2a9.76,9.76,0,0,1,3.89.78,10.12,10.12,0,0,1,5.33,5.33A9.76,9.76,0,0,1,22,12a9.76,9.76,0,0,1-.78,3.89,10.12,10.12,0,0,1-5.33,5.33A9.76,9.76,0,0,1,12,22a9.91,9.91,0,0,1-7.09-2.93,10.21,10.21,0,0,1-2.13-3.18A9.76,9.76,0,0,1,2,12a9.76,9.76,0,0,1,.78-3.89A10.21,10.21,0,0,1,4.91,4.93,9.91,9.91,0,0,1,12,2Zm0,18.75a8.69,8.69,0,0,0,6.19-2.56A8.734,8.734,0,0,0,15.4,3.93,8.765,8.765,0,0,0,3.93,15.42a8.9,8.9,0,0,0,4.65,4.65,8.66,8.66,0,0,0,3.42.68ZM17.63,12a.61.61,0,0,1,.42.18.6.6,0,0,1,0,.89.61.61,0,0,1-.42.18H10.75V7.63A.56.56,0,0,1,11.38,7a.61.61,0,0,1,.42.18.56.56,0,0,1,.2.45V12Z"
        fill="#333"
      />
    </g>
    <rect id="Rectangle_1633" data-name="Rectangle 1633" width="24" height="24" fill="none" />
  </svg>
);

export default ClockIcon;
