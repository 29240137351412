import {Banner} from './Banner';
import {Form} from './Form';
import {RefusalModal} from './modal';

const Refusal = {
  Banner: Banner,
  Form: Form,
  Modal: RefusalModal,
};

export default Refusal;
