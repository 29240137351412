import axios from 'axios';

const environment = axios
  .create({
    baseURL: '/',
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .get('variables.json')
  .catch(err => (process.env.NODE_ENV === 'development' ? console.log('Loaded mock variables') : console.error(err)));

export default environment;
