import axios, { AxiosError } from "axios";
import { SettingsDto } from "src/services/models/settings-dto";

export const getSettings = async (BASE_URL:string) => {
    try {
        const res = await axios.get<SettingsDto>(`${BASE_URL}/api/settings`)
        return Promise.resolve(res.data)
    } catch (error) {
        return Promise.reject(error as AxiosError)
    }
}