import {Banner} from './Banner/Banner';
import {OverviewOfTravelers} from './OverviewOfTravelers/OverviewOfTravelers';
import {ClientInformation} from './ClientInformations';
import {Journey} from './Journey/Journey';
import {DeleteAssignBar} from './DeleteAssignBar';
import {AssignModal} from './AssignModal/AssignModal';

const MissionDetailsComponents = {
  Banner: Banner,
  OverviewOfTravelers: OverviewOfTravelers,
  ClientInformation: ClientInformation,
  Journey: Journey,
  DeleteAssignBar: DeleteAssignBar,
  AssignModal: AssignModal,
};

export default MissionDetailsComponents;
