// @ts-nocheck
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function PrintStickerIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
        <g id="ico_print" transform="translate(-773 -739)">
          <rect
            id="Rectangle_1738"
            data-name="Rectangle 1738"
            width="24"
            height="24"
            rx="4"
            transform="translate(773 739)"
            fill="#2b7c2b"
          />
          <g id="ic_print" transform="translate(775.326 741.326)">
            <path
              id="Tracé_5691"
              data-name="Tracé 5691"
              d="M20.465,5.954a1.226,1.226,0,0,1,1.209,1.209v6.046a1.145,1.145,0,0,1-.359.869,1.2,1.2,0,0,1-.85.34H18.046v6.047a1.143,1.143,0,0,1-.359.869,1.2,1.2,0,0,1-.85.34H7.163a1.164,1.164,0,0,1-1.209-1.209V14.418H3.535a1.165,1.165,0,0,1-1.209-1.209V7.163a1.194,1.194,0,0,1,.341-.85,1.142,1.142,0,0,1,.868-.359H5.954V3.535a1.2,1.2,0,0,1,.34-.85,1.145,1.145,0,0,1,.869-.359h9.674a1.163,1.163,0,0,1,.85.359,1.161,1.161,0,0,1,.359.85V5.954Zm0,1.209H3.535v6.046H5.954V12a1.2,1.2,0,0,1,.34-.85,1.142,1.142,0,0,1,.869-.359h9.674A1.226,1.226,0,0,1,18.046,12v1.209h2.419ZM7.163,5.954h9.674V3.535H7.163Zm9.674,8.464V12H7.163v8.465h9.674Zm-7.255,0h4.837v1.21H9.582Zm0,2.419h4.837v1.209H9.582Zm6.65-7.256a.534.534,0,0,1-.6-.6.608.608,0,0,1,.17-.416.557.557,0,0,1,.434-.189h2.419a.557.557,0,0,1,.434.189.613.613,0,0,1,.17.416.534.534,0,0,1-.6.6Z"
              transform="translate(-2.326 -2.326)"
              fill="#fff"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
