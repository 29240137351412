import {Box} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Circular} from 'src/components/coordinator/MissionItem/StatusItem';
import {DisabledReservationDto} from 'src/services/models/disabled-reservation-dto';
import {ReservationStatusDto} from 'src/services/models/reservation-status-dto';

export const ReservationClientAndStatus = ({reservation}: {reservation: DisabledReservationDto}) => {
  const {t} = useTranslation();

  return (
    <Box
      sx={{
        border: 'solid 1px #ECECEC',
        marginLeft: '0px',
        marginRight: '0px',
        borderRadius: '5px',
        padding: '15px',
        display: 'grid',
        gridTemplateColumns: '1fr auto',
      }}
    >
      <Box>
        {reservation.client?.firstName && reservation.client.lastName && (
          <h6>
            {reservation.client?.firstName} {reservation.client?.lastName}
          </h6>
        )}
        {reservation.client?.phoneNumber && (
          <a href={`dica:${reservation.client?.phoneNumber?.replace('+', '000')}`} className="button-parent"></a>
        )}
        {reservation.client?.language && (
          <p style={{margin: 0}}>
            {t('mission-details-v2.client-information.language')}: {reservation.client?.language}
          </p>
        )}
        {reservation.client?.email && <p style={{margin: 0}}>{reservation.client?.email}</p>}
      </Box>
      <Box>{reservation.status && <StatusItem status={reservation.status} />}</Box>
    </Box>
  );
};

const Colors = {
  New: 'rgba(37, 51, 108, 1)',
  Refused: 'rgba(51, 51, 51, 1)',
  Approved: 'rgba(51, 51, 51, 1)',
  Pending: 'rgba(194, 119, 16, 1)',
  Proposal: 'rgba(194, 119, 16, 1)',
  Finalized: 'rgba(51, 51, 51, 1)',
};

const StatusItem: React.FC<{status: ReservationStatusDto}> = ({status}) => {
  const {t} = useTranslation();

  const getColor = (status: ReservationStatusDto): string => {
    switch (status) {
      case ReservationStatusDto.New:
        return Colors.New;
      case ReservationStatusDto.Refused:
        return Colors.Refused;
      case ReservationStatusDto.Approved:
        return Colors.Approved;
      case ReservationStatusDto.Pending:
        return Colors.Pending;
      case ReservationStatusDto.Proposal:
        return Colors.Proposal;
      case ReservationStatusDto.Finalized:
        return Colors.Finalized;
      default:
        return Colors.Finalized;
    }
  };

  return (
    <Box>
      <Circular color={getColor(status)} />
      <span
        style={{
          color: getColor(status),
          fontSize: '12px',
          fontWeight: 'bold',
          marginLeft: '5px',
        }}
      >
        {status}
      </span>
    </Box>
  );
};
