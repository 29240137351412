import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';

import AlarmIcon from '@mui/icons-material/Alarm';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl';

import {useTranslation} from 'react-i18next';

import DialogModal from 'src/components/DialogModal/DialogModal';

import confirmationIcon from 'src/components/images/ic_confirmation-blue.svg';

import IconButton from './components/IconButton';

import {AuthContext, IPhoneId} from 'src/context/authentication/store.auth';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {
  checkCoordinatorIn,
  checkCoordinatorOut,
  checkinCallData,
  getCoordinatorCheckinStatus,
  getCoordinatorRoles,
} from './checkIn.service';

import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from '@mui/material';
import {AxiosError} from 'axios';
import {CacheContext} from 'src/context/cache/cache.store';
import {NetworkContext} from 'src/context/network/network.store';
import {DistrictDto, ModelError} from 'src/services/models';
import {CoordinatorRoles} from 'src/services/models/coordinator-roles-dto';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';
import {localStorageKeys} from 'src/utils/localStorageKeys';
import checkInIconRed from '../../../components/images/ic_checkin_red.svg';
import {MissionContext} from '../MissionOverview/missions.store';
import './CheckIn.scss';
import ButtonLoading from 'src/components/buttons/ButtonLoading/ButtonLoading';

export default function CheckIn() {
  const {t, i18n} = useTranslation();
  const {clearMissions} = React.useContext(MissionContext);
  const [isUserCheckedIn, setIsUserCheckedIn] = React.useState(false);

  const [isOpen, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isFetchingUser, setIsFetchingUser] = React.useState(true);

  const [coordinatorRoles, setCoordinatorRoles] = React.useState([] as Array<CoordinatorRoles>);
  const [telephoneNumber, setTelephoneNumber] = React.useState(() => {
    const activePhoneNumberWithId = localStorage.getItem(localStorageKeys.PHONE_NUMBER_WITH_ID);

    if (activePhoneNumberWithId === null) {
      return '';
    } else {
      const parsed = JSON.parse(activePhoneNumberWithId) as IPhoneId;
      return parsed?.phoneNumber;
    }
  });
  const [district, setDistrict] = React.useState('');
  const [service, setService] = React.useState('');
  const [checkInData, setCheckinData] = React.useState<checkinCallData>({} as checkinCallData);

  //Errors
  const [districtError, setDistrictError] = React.useState({hasError: true, text: t('check-in.errors.district')});
  const [serviceError, setServiceError] = React.useState({hasError: true, text: t('check-in.errors.service')});
  const [phoneError, setPhoneError] = React.useState(() => {
    const activePhoneNumberWithId = localStorage.getItem(localStorageKeys.PHONE_NUMBER_WITH_ID);
    const parseActivePhoneNumberWithId: {userId: string; phoneNumber: string} =
      activePhoneNumberWithId && JSON.parse(activePhoneNumberWithId);

    if (parseActivePhoneNumberWithId?.phoneNumber && parseActivePhoneNumberWithId.phoneNumber.length === 0) {
      return {hasError: true, text: t('check-in.errors.phone')};
    } else {
      return {hasError: false, text: ''};
    }
  });

  const {variables} = React.useContext(EnvironmentContext);
  const {validUser, userData} = React.useContext(AuthContext);
  const {districts} = React.useContext(CacheContext);
  const {validateNetworkCall} = React.useContext(NetworkContext);

  const syncCheckInState = React.useCallback(
    (checkedIn: boolean, districtId?: string, roleId?: string, phoneNumber?: string) => {
      // Update local storage with sync data
      localStorage.setItem(
        'cross_tab_checkin_sync',
        JSON.stringify({
          checkedIn,
          districtId,
          roleId,
          phoneNumber,
          timestamp: Date.now(),
        }),
      );
    },
    [],
  );

  React.useEffect(() => {
    const withAsync = async () => {
      clearMissions();
      let roles: Array<CoordinatorRoles> = [] as Array<CoordinatorRoles>;
      //Get roles
      await getCoordinatorRoles(variables.BASE_ENDPOINT).then(res => {
        if (res) {
          setCoordinatorRoles(res);
          roles = res;
          //@ts-ignore
          setCheckinData({roleId: res[0].id, districtId: districts[0].id, phoneNumber: telephoneNumber || ''});
        }
      });

      //Get status of user
      await getCoordinatorCheckinStatus(variables.BASE_ENDPOINT)
        .then(async res => {
          if (res && res.isCheckedIn && !(districts as DistrictDto[]).some(d => d.id === res?.districtId)) {
            return await handleOnCheckOut(res.districtId);
          }
          if (res && res.isCheckedIn) {
            setIsUserCheckedIn(res.isCheckedIn);
            setTelephoneNumber(res.phoneNumber);
            setPhoneError(prev => ({...prev, hasError: false, text: ''}));
            //@ts-ignore
            const res_districts = handleTranslationObject(i18n, districts.find(el => el.id === res.districtId).name);
            setDistrict(res_districts);
            setDistrictError(prev => ({...prev, hasError: false, text: ''}));
            setCheckinData(prev => ({...prev, districtId: res.districtId, phoneNumber: res.phoneNumber}));
            if (roles) {
              const activeRole: CoordinatorRoles | undefined = roles.find(el => el.id === res.role.id);
              localStorage.setItem('user_role', activeRole?.id || '');
              if (activeRole) {
                setService(handleTranslationObject(i18n, activeRole.name));
                setServiceError(prev => ({...prev, hasError: false, text: ''}));
              } else {
                setService('Cols');
                setServiceError(prev => ({...prev, hasError: false, text: ''}));
              }
            }
          }
        })
        .finally(() => setIsFetchingUser(false));
    };

    withAsync();
  }, []);

  React.useEffect(() => {
    if (districts && checkInData?.districtId) {
      //@ts-ignore
      const getCurrentDistrictObject = districts.find(el => el.id === checkInData.districtId);
      if (getCurrentDistrictObject) {
        const res = handleTranslationObject(i18n, getCurrentDistrictObject.name);
        setDistrict(res);
      } else {
        setDistrict('UNKNOWN DISTRICT');
      }
    }
  }, [t]);

  const handleCheckinResult = (check: 'LOGIN' | 'LOGOUT') => {
    check === 'LOGIN' ? setIsUserCheckedIn(true) : setIsUserCheckedIn(false);
  };

  const handleCheckinDataChange = (key: 'DISTRICT' | 'ROLE' | 'PHONE', value: string) => {
    switch (key) {
      case 'DISTRICT':
        setCheckinData(prev => ({...prev, districtId: value}));
        setDistrictError(prev => ({hasError: false, text: ''}));
        //@ts-ignore
        const res_districtid = handleTranslationObject(i18n, districts.find(el => el.id === value).name);
        setDistrict(res_districtid);
        break;
      case 'ROLE':
        setCheckinData(prev => ({...prev, roleId: value}));
        setServiceError(_prev => ({hasError: false, text: ''}));
        //@ts-ignore
        const res_role = handleTranslationObject(i18n, coordinatorRoles.find(el => el.id === value).name);
        setService(res_role);
        break;
      case 'PHONE':
        setCheckinData(prev => ({...prev, phoneNumber: value}));
        if (value.length > 0) {
          setPhoneError(_prev => ({hasError: false, text: ''}));
        } else {
          setPhoneError(_prev => ({hasError: true, text: t('check-in.errors.phone')}));
        }
        setTelephoneNumber(value);
        break;
      default:
        break;
    }
  };

  function handleClick() {
    setLoading(true);
    setOpen(true);
  }

  const handleOnCheckIn = async () => {
    setLoading(true);
    await checkCoordinatorIn(variables.BASE_ENDPOINT, checkInData)
      .then(res => {
        if (res) {
          setLoading(false);
          const activePhoneNumberWithId = localStorage.getItem(localStorageKeys.PHONE_NUMBER_WITH_ID);
          if (activePhoneNumberWithId !== null) {
            const parsed = JSON.parse(activePhoneNumberWithId) as IPhoneId;
            parsed.phoneNumber = telephoneNumber;
            localStorage.setItem(localStorageKeys.PHONE_NUMBER_WITH_ID, JSON.stringify(parsed));
          }
          setIsUserCheckedIn(true);
          // Sync check-in state across tabs
          //syncCheckInState(true, checkInData.districtId, checkInData.roleId, telephoneNumber);
          localStorage.setItem('user_role', checkInData.roleId);
          handleCheckinResult('LOGIN');
        }
      })
      .catch((err: AxiosError) => {
        setLoading(false);
        if (err.response) {
          validateNetworkCall(err.response.status, err.response.data as ModelError);
        }
      });
  };

  const handleOnCheckOut = async (withDistrictId?: string) => {
    setLoading(true);
    await checkCoordinatorOut(variables.BASE_ENDPOINT, {
      districtId: withDistrictId ? withDistrictId : checkInData.districtId,
    })
      .then(res => {
        if (res) {
          // Sync check-out state across tabs
          //syncCheckInState(false);
          setIsUserCheckedIn(false);

          localStorage.removeItem('user_role');
          setLoading(false);
          handleCheckinResult('LOGOUT');
        }
      })
      .catch(err => {
        setLoading(false);
        if (err.response) {
          validateNetworkCall(err.response.status, err.response.data as ModelError);
        }
      });
  };

  const confirmationIconText = (
    <>
      <img src={confirmationIcon} alt="confirmation" /> <span>{t('create-mission.confirmation')}</span>
    </>
  );

  return (
    <Box
      style={{backgroundColor: '#EFF4F9', width: '100%', height: 'calc(100vh - 64px)', padding: 20, overflowY: 'auto'}}
    >
      <Typography
        style={{fontSize: 24, color: '#333333', fontWeight: 700, paddingTop: 15, paddingBottom: 10, paddingLeft: 10}}
      >
        {t('check-in.title')}
      </Typography>
      <Typography style={{fontSize: 16, color: '#333333', fontWeight: 700, paddingBottom: 10, paddingLeft: 10}}>
        {t('check-in.role-coordinator')} - {userData.sub}
      </Typography>
      <Box style={{display: 'grid', placeItems: 'center', marginTop: 30, paddingBottom: 30}}>
        <Box style={{width: 376}}>
          {isFetchingUser && (
            <Paper elevation={3} style={{backgroundColor: '#ffffff', width: 376, padding: 20}}>
              <LinearProgress />
            </Paper>
          )}
          {!isFetchingUser && (
            <Paper elevation={3} style={{backgroundColor: '#ffffff', width: 376, padding: 20}}>
              {true && (
                <FormControl fullWidth style={{marginTop: 20, marginBottom: 20}} size="small">
                  <Autocomplete
                    disablePortal
                    disableClearable
                    //@ts-ignore
                    options={districts.map(el => ({value: el.id, label: handleTranslationObject(i18n, el.name)}))}
                    renderInput={args => (
                      <TextField
                        {...args}
                        label={t('check-in.district-placeholder')}
                        error={districtError.hasError}
                        helperText={districtError.text}
                      />
                    )}
                    //@ts-ignore
                    onChange={(_e, v) => handleCheckinDataChange('DISTRICT', v.value)}
                    value={district}
                    defaultValue={district}
                    disabled={loading || isUserCheckedIn}
                  />
                </FormControl>
              )}
              <FormControl fullWidth style={{marginTop: 20, marginBottom: 20}} size="small">
                <Autocomplete
                  disablePortal
                  disableClearable
                  //@ts-ignore
                  options={
                    coordinatorRoles.length > 0
                      ? coordinatorRoles.map(el => ({value: el.id, label: handleTranslationObject(i18n, el.name)}))
                      : [{value: '', label: ''}]
                  }
                  renderInput={args => (
                    <TextField
                      {...args}
                      label={t('check-in.service-placeholder')}
                      error={serviceError.hasError}
                      helperText={serviceError.text}
                    />
                  )}
                  //@ts-ignore
                  onChange={(_e, v: {value: string; label: string}) => handleCheckinDataChange('ROLE', v.value)}
                  value={service}
                  //@ts-ignore
                  getOptionSelected={(option, value) => {
                    //nothing that is put in here will cause the warning to go away
                    if (value === '') {
                      return true;
                    } else if (value === option) {
                      return true;
                    }
                  }}
                  defaultValue={service}
                  disabled={loading || isUserCheckedIn}
                />
              </FormControl>
              <TextField
                fullWidth
                InputLabelProps={{shrink: true}}
                style={{marginTop: 20, marginBottom: 20}}
                id="phone-number"
                label={t('check-in.gsm-placeholder')}
                variant="outlined"
                size="small"
                value={telephoneNumber}
                onChange={e => handleCheckinDataChange('PHONE', e.target.value)}
                error={phoneError.hasError}
                helperText={phoneError.text}
                disabled={loading || isUserCheckedIn}
              />
              {validUser && !isUserCheckedIn ? (
                <LoadingButton
                  style={{
                    backgroundColor: `${
                      districtError.hasError ||
                      serviceError.hasError ||
                      phoneError.hasError ||
                      loading ||
                      isUserCheckedIn
                        ? 'grey'
                        : '#0069B4'
                    }`,
                    color: '#ffffff',
                    width: '100%',
                    height: 48,
                    marginTop: 20,
                    cursor: `${
                      districtError.hasError ||
                      serviceError.hasError ||
                      phoneError.hasError ||
                      loading ||
                      isUserCheckedIn
                        ? 'not-allowed'
                        : 'pointer'
                    }`,
                  }}
                  onClick={handleClick}
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<AlarmIcon style={{color: '#ffffff'}} />}
                  variant="contained"
                  disabled={
                    districtError.hasError ||
                    serviceError.hasError ||
                    phoneError.hasError ||
                    loading ||
                    isUserCheckedIn ||
                    telephoneNumber.length === 0
                  }
                >
                  {isUserCheckedIn ? 'Update' : 'Check-in'}
                </LoadingButton>
              ) : null}
              {/*User is already checked in*/}
              {isUserCheckedIn && (
                <IconButton
                  text={loading ? 'Loading...' : 'Check-out'}
                  icon={checkInIconRed}
                  onClick={() => handleOnCheckOut()}
                  className="checkin-btn-check-out"
                  color="white"
                />
              )}
            </Paper>
          )}
        </Box>
      </Box>
      <Dialog
        open={isOpen}
        onClose={() => {
          setOpen(false);
          setLoading(false);
        }}
        keepMounted={false}
        disableAutoFocus
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid #D7D7D7',
            gap: '10px',
          }}
        >
          <img src={confirmationIcon} alt="confirmation" /> <span>{t('create-mission.confirmation')}</span>
        </DialogTitle>
        <DialogContent sx={{paddingTop: '20px !important', width: '500px'}}>
          <p style={{textAlign: 'start'}}>
            {t('check-in.modal.p1')} <b>{userData.sub}</b>
          </p>
          <p style={{textAlign: 'start'}}>
            {t('check-in.modal.p2')} {/*@ts-ignore*/}
            <b>{handleTranslationObject(i18n, districts?.find(el => el.id === checkInData?.districtId)?.name)}</b>
          </p>

          <p style={{textAlign: 'start'}}>
            {' '}
            {/*@ts-ignore*/}
            <b>{handleTranslationObject(i18n, coordinatorRoles?.find(el => el.id === checkInData?.roleId)?.name)}</b>
          </p>
          <p style={{textAlign: 'start'}}> {t('check-in.modal.p5')}</p>
        </DialogContent>
        <DialogActions sx={{padding: '20px 24px'}}>
          <ButtonLoading
            onClick={() => {
              setOpen(false);
              setLoading(false);
              handleOnCheckIn();
            }}
            fixed={false}
            loadingData={false}
            icon={false}
          >
            {t('check-in.btn-yes')}
          </ButtonLoading>
          <button
            type="button"
            className="btn btn-common btn-without-bg"
            onClick={() => {
              setOpen(false);
              setLoading(false);
            }}
          >
            {t('check-in.btn-no')}
          </button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
