import {Box, Button, Grid, MenuItem, Paper, TextField} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import {ReservationsContext} from 'src/context/reservations/reservations.store';
import {SubPmrRoutes} from '../../Pmr';
import {CacheContext} from 'src/context/cache/cache.store';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';

export const Form: React.FC<{id: string}> = ({id}) => {
  const {refusalData, setRefusalData, refuseReservations} = React.useContext(ReservationsContext);
  const {reservationRefusalReasons} = React.useContext(CacheContext);
  const {i18n, t} = useTranslation();

  const [refuseComment, setRefuseComment] = React.useState(refusalData.comment || '');

  const handleRefusalDataComment = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRefuseComment(e.target.value);
  };

  const handleRefusalReason = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newRefusalData = refusalData;
    newRefusalData.reasonId = e.target.value;
    setRefusalData(() => ({...newRefusalData}));
  };

  const handleRefusal = () => {
    const newRefuslaData = refusalData;
    newRefuslaData.comment = refuseComment;
    newRefuslaData.reservationId = id;
    setRefusalData(() => ({...newRefuslaData}));
    refuseReservations(refusalData);
  };

  const isSubmittable = (): boolean => {
    const {affectedStations, reasonId} = refusalData;

    if (affectedStations?.length === 0) {
      return false;
    }

    if (!reasonId) {
      return false;
    }

    return true;
  };

  return (
    <Paper
      elevation={1}
      sx={{
        border: 'solid 1px #D7D7D7',
        padding: '18px 15px',
      }}
    >
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField fullWidth select onChange={handleRefusalReason} label={t('pmr.change-res-reason')}>
              {reservationRefusalReasons
                .sort((a, b) => {
                  const first = handleTranslationObject(i18n, a.description || []).toLocaleUpperCase();
                  const last = handleTranslationObject(i18n, b.description || []).toLocaleUpperCase();
                  return first < last ? -1 : 1;
                })
                .map(el => {
                  return (
                    <MenuItem key={el.id} value={el.id || ''}>
                      {handleTranslationObject(i18n, el.description || [])}
                    </MenuItem>
                  );
                })}
            </TextField>
          </Grid>
          <Grid item xs={7}>
            <TextField
              fullWidth
              placeholder={t('pmr.add-note')}
              value={refuseComment}
              onChange={handleRefusalDataComment}
            ></TextField>
          </Grid>
          <Grid item xs={1} sx={{display: 'grid', placeItems: 'center'}}>
            <Button
              disabled={!isSubmittable()}
              onClick={handleRefusal}
              fullWidth
              variant="outlined"
              color="error"
              startIcon={<CloseIcon />}
            >
              {t('pmr.refuse')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
