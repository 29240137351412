import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

import {createTheme} from '@mui/system';

import {ListIcon, CollapseItem, BadgeItem, BadgeItemSelected} from './MenuItem.styles';

type ListMenuItemProps = {
  handleOnClick: (...args: any) => void;
  index: number | string;
  selectedIndex: number | string;
  icon?: React.ReactNode;
  text: string;
  isCollapsed?: boolean;
  badgeNumber?: string | number;
};

export default function ListMenuItem({
  handleOnClick,
  index,
  selectedIndex,
  icon,
  text,
  isCollapsed = false,
  badgeNumber,
}: ListMenuItemProps) {
  const theme = createTheme();

  return (
    <>
      {isCollapsed ? (
        <CollapseItem selected={selectedIndex === index} onClick={handleOnClick}>
          <ListItemText
            primary={text}
            primaryTypographyProps={{
              color: selectedIndex === index ? '#0069B4' : '#373737',
              fontSize: '0.9rem',
              fontWeight: 500,
              paddingLeft: theme.spacing(6),
            }}
          />
          {selectedIndex === index ? (
            <BadgeItemSelected badgeContent={badgeNumber} />
          ) : (
            <BadgeItem badgeContent={badgeNumber} />
          )}
        </CollapseItem>
      ) : (
        <ListItemButton autoFocus selected={selectedIndex === index} onClick={handleOnClick}>
          {icon && <ListIcon>{icon}</ListIcon>}
          <ListItemText
            primary={text}
            primaryTypographyProps={{
              color: selectedIndex === index ? '#0069B4' : '#373737',
              fontSize: '0.9rem',
              fontWeight: 500,
            }}
          />
        </ListItemButton>
      )}
    </>
  );
}
