// @ts-nocheck
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function Manager(props) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
        <g
          data-name="Groupe 2"
          style={{
            mixBlendMode: 'normal',
            isolation: 'isolate',
          }}
        >
          <path
            data-name="Trac\xE9 5109"
            d="M20.161 18.602q1.836.82 1.836 3.4h-20a3.33 3.33 0 0 1 1.875-3.359 2.606 2.606 0 0 1-.449-.742 2.338 2.338 0 0 1-.176-.9 2.5 2.5 0 0 1 4.277-1.757 2.431 2.431 0 0 1 .723 1.757 2.253 2.253 0 0 1-.586 1.6 3 3 0 0 1 1.25 1.016 2.9 2.9 0 0 1 1.211-.977 2.606 2.606 0 0 1-.449-.742 2.338 2.338 0 0 1-.176-.9 2.5 2.5 0 0 1 4.277-1.757 2.431 2.431 0 0 1 .723 1.757 2.253 2.253 0 0 1-.586 1.6 3 3 0 0 1 1.25 1.016 2.9 2.9 0 0 1 1.211-.977 2.606 2.606 0 0 1-.449-.742 2.338 2.338 0 0 1-.176-.9 2.5 2.5 0 0 1 4.277-1.757 2.431 2.431 0 0 1 .723 1.757 2.253 2.253 0 0 1-.586 1.607ZM8.247 20.75q0-.937-.762-1.093a8.635 8.635 0 0 0-1.738-.157 7.849 7.849 0 0 0-1.719.176.965.965 0 0 0-.781 1.074ZM4.497 17a1.183 1.183 0 0 0 .371.9 1.235 1.235 0 0 0 .879.351 1.2 1.2 0 0 0 1.25-1.25 1.235 1.235 0 0 0-.352-.879 1.184 1.184 0 0 0-.9-.371 1.267 1.267 0 0 0-1.25 1.25Zm12.5-3.75H5.747V12a6.466 6.466 0 0 1 .234-1.933 2.48 2.48 0 0 1 .7-1.133 2.446 2.446 0 0 1 1.172-.547 8.7 8.7 0 0 1 1.641-.137h3.75a7.558 7.558 0 0 1 1.64.157 2.6 2.6 0 0 1 1.172.566 2.475 2.475 0 0 1 .7 1.152A6.307 6.307 0 0 1 16.991 12Zm-1.25-2.5a.827.827 0 0 0-.391-.7 2.576 2.576 0 0 0-.859-.39 4.524 4.524 0 0 0-1.25-.157h-3.75a4.519 4.519 0 0 0-1.25.157 2.791 2.791 0 0 0-.879.39.73.73 0 0 0-.371.7V12h8.75Zm-1.25-5.625a3.042 3.042 0 0 1-.9 2.207 3.125 3.125 0 1 1 0-4.414 3.043 3.043 0 0 1 .903 2.207ZM11.372 7a1.868 1.868 0 1 0-1.328-.547A1.807 1.807 0 0 0 11.375 7Zm3.125 13.75q0-.937-.762-1.093a8.635 8.635 0 0 0-1.738-.157 7.849 7.849 0 0 0-1.719.176.965.965 0 0 0-.781 1.074ZM10.747 17a1.183 1.183 0 0 0 .371.9 1.235 1.235 0 0 0 .879.351 1.2 1.2 0 0 0 1.25-1.25 1.235 1.235 0 0 0-.352-.879 1.184 1.184 0 0 0-.9-.371 1.267 1.267 0 0 0-1.25 1.25Zm10 3.75q0-.937-.762-1.093a8.635 8.635 0 0 0-1.738-.157 7.849 7.849 0 0 0-1.719.176.965.965 0 0 0-.781 1.074ZM16.997 17a1.183 1.183 0 0 0 .371.9 1.235 1.235 0 0 0 .879.351 1.2 1.2 0 0 0 1.25-1.25 1.235 1.235 0 0 0-.352-.879 1.184 1.184 0 0 0-.9-.371 1.267 1.267 0 0 0-1.25 1.25Z"
            fill="#25336c"
          />
        </g>
        <path data-name="Rectangle 1605" fill="none" d="M0 0h24v24H0z" />
      </svg>
    </SvgIcon>
  );
}
