import React from 'react'


const ElectricWheelchairIcon = () => (
    <svg id="ic_wheelchair_electric" data-name="ic_wheelchair electric" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path id="Path_5212" data-name="Path 5212" d="M0,0H24V24H0Z" fill="none"/>
        <path id="Path_5213" data-name="Path 5213" d="M17.53,12.66h-1l1.29-2.84a2.5,2.5,0,0,0-.17-2.41,1.94,1.94,0,0,0-.26-.31h0a2.64,2.64,0,1,0-2.64-2.64,2.58,2.58,0,0,0,.7,1.77H10.38A2.61,2.61,0,0,0,8,7.78L7.35,9.56a.54.54,0,0,0,.37.73l1.9.53a.57.57,0,0,0,.66-.32l.5-1.18h1l-1.45,3.27a2.44,2.44,0,0,0-.1.24v-1.1a.55.55,0,0,0-.55-.55,5.5,5.5,0,1,0,5.5,5.5.56.56,0,0,0-.21-.44H17v4.39a.56.56,0,0,0,.55.56h2a.56.56,0,0,0,.56-.56V15.19a2.54,2.54,0,0,0-2.58-2.53Zm0,2.47H12.61a1.43,1.43,0,0,1-1.2-.67,1.48,1.48,0,0,1-.12-1.41L13.1,9a.53.53,0,0,0,0-.52.56.56,0,0,0-.47-.26H10.41a.53.53,0,0,0-.51.34L9.44,9.61,8.6,9.38,9,8.21a1.45,1.45,0,0,1,1.34-.86h5.14A1.47,1.47,0,0,1,16.75,8a1.38,1.38,0,0,1,.09,1.35L15.19,13a.56.56,0,0,0,0,.53.55.55,0,0,0,.47.25h1.83A1.43,1.43,0,0,1,19,15.19v4.88h-.87V15.69a.56.56,0,0,0-.6-.56ZM12,16.68a2.41,2.41,0,1,1-2.41-2.41.54.54,0,0,0,.45-.24,2.69,2.69,0,0,0,.39,1,2.54,2.54,0,0,0,1.8,1.16.56.56,0,0,0-.23.49ZM9,13.2a3.52,3.52,0,1,0,4,4h1a4.39,4.39,0,1,1-4.91-4.91Zm6.84-8.74a1.53,1.53,0,1,1,.725,1.428A1.53,1.53,0,0,1,15.84,4.46Z" fill="#333"/>
    </svg>
)

export default ElectricWheelchairIcon