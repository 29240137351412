import {Client} from './Client';
import {DepartureArrivalStationForm} from './DepartureArrivalStation';
import {Disabilities} from './Disablities';
import {PickupDropoff} from './PickupDropoff';
import {SelectTaxiCompany} from './SelectTaxiCompany';

export const CreateTaxiMissionFormComponents = {
  Company: SelectTaxiCompany,
  DepartureArrival: DepartureArrivalStationForm,
  PickupDropoff: PickupDropoff,
  Client: Client,
  Disabilities: Disabilities,
};
