export const routes = {
  pmr: {
    overview: 'pmr/overview',
    in_progress: 'pmr/validation-in-progress',
    search: 'pmr/search',
    changeRoute: `pmr/change-route/:id`,
  },
  grobo: {
    group: 'grobo/group-reservations',
    print: 'grobo/print-stickers',
  },
  missions: {
    overview: 'missions/mission-overview',
    assign: 'missions/mission-assign',
  },
  coi: {
    overview: 'coi/overview',
    follow_up: 'coi/follow-up',
    create_incident: 'coi/create-incident',
  },
  checkin: {
    my: 'check-in/my-check-in',
    overview: 'check-in/overview',
  },
  settings: {
    support: 'settings/support',
    legend: 'settings/legend',
    language: 'settings/language',
  },
  details: {
    stickering: 'details/stickering',
    group: '/details/group',
  },
  taxi: {
    create: 'taxi/create-taxi-mission',
  },
};
