import axios, { AxiosError } from "axios";
import { MissionActionResultDto } from "src/services/models/mission-action-result-dto";
import { AssignMissionsDto } from "src/services/models/assign-missions-dto";



const assignMission = async (baseUrl: string, data: AssignMissionsDto): Promise<MissionActionResultDto | AxiosError> =>  {
    const fullUrl = `${baseUrl}/api/missions/assign`
    try {
        const res = await axios.post<MissionActionResultDto>(fullUrl, {
            ...data
        })
        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error as AxiosError)
    }
}

export default assignMission