import React from 'react'

const ChildIcon = () => (
    <svg id="ic_child" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <rect id="Rectangle_1981" data-name="Rectangle 1981" width="24" height="24" fill="none"/>
        <path id="Path_5725" data-name="Path 5725" d="M12,7.87a2.19,2.19,0,1,1,2.18-2.19A2.19,2.19,0,0,1,12,7.87ZM12,4.5a1.19,1.19,0,1,0,1.18,1.18A1.19,1.19,0,0,0,12,4.5Z" fill="#333"/>
        <path id="Path_5726" data-name="Path 5726" d="M14.52,20.5H12.84a.5.5,0,0,1-.5-.5V15.45h-.69V20a.5.5,0,0,1-.5.5H9.47A.5.5,0,0,1,9,20V10C6.61,9.19,5.6,7.63,5.6,4.84a.51.51,0,0,1,.5-.5H7.79a.51.51,0,0,1,.5.5c0,2,.82,2.86,2.86,2.87h1.7c2,0,2.86-.84,2.86-2.87a.51.51,0,0,1,.5-.5H17.9a.51.51,0,0,1,.5.5c0,2.79-1,4.36-3.38,5.18V20A.5.5,0,0,1,14.52,20.5Zm-1.18-1H14V9.65a.5.5,0,0,1,.4-.49,3.66,3.66,0,0,0,3-3.82h-.7c-.16,2.26-1.42,3.36-3.84,3.37H11.14C8.72,8.7,7.46,7.6,7.3,5.34H6.61a3.65,3.65,0,0,0,3,3.83.5.5,0,0,1,.36.48V19.5h.68V15a.5.5,0,0,1,.5-.5h1.69a.5.5,0,0,1,.5.5Z" fill="#333"/>
    </svg>
)

export default ChildIcon