import React from 'react';

const TrainIcon = () => {
  return (
    <svg id="ic_train" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        id="Path_5169"
        data-name="Path 5169"
        d="M7.2,18.25,4.7,22H3.25L6,18a2.38,2.38,0,0,0,1,.23ZM7,17a1.19,1.19,0,0,1-.9-.37,1.26,1.26,0,0,1-.35-.88V7a4.85,4.85,0,0,1,.39-1.95A5,5,0,0,1,8.8,2.39,4.85,4.85,0,0,1,10.75,2h2.5a4.86,4.86,0,0,1,1.93.39,5.09,5.09,0,0,1,1.61,1.07,5.24,5.24,0,0,1,1.07,1.59,4.85,4.85,0,0,1,.39,2V15.8a1.26,1.26,0,0,1-.35.88A1.19,1.19,0,0,1,17,17Zm10,3.75L17.82,22H6.18L7,20.75Zm-10-5H17V7a3.72,3.72,0,0,0-3.75-3.75h-2.5A3.72,3.72,0,0,0,7,7Zm1.64,2.46h6.64l.86,1.25H7.82ZM13.25,4.5A2.45,2.45,0,0,1,15,5.22,2.41,2.41,0,0,1,15.75,7v3.75H8.25V7A2.41,2.41,0,0,1,9,5.22a2.45,2.45,0,0,1,1.76-.72ZM9.5,12a1.26,1.26,0,0,1,.9,2.13,1.28,1.28,0,0,1-1.8,0A1.24,1.24,0,0,1,9.5,12Zm5.31-5a1.54,1.54,0,0,0-1.56-1.56h-2.5A1.54,1.54,0,0,0,9.19,7V9.81h5.62Zm-.31,5a1.26,1.26,0,0,1,.9,2.13,1.28,1.28,0,0,1-1.8,0A1.24,1.24,0,0,1,14.5,12Zm6.25,10H19.3l-2.46-3.75H17A2.44,2.44,0,0,0,18.09,18Z"
        fill="#333"
      />
      <rect id="Rectangle_1634" data-name="Rectangle 1634" width="24" height="24" fill="none" />
    </svg>
  );
};

export default TrainIcon;
