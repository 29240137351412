import React from 'react';
import {Box, FormControl, TextField, InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import {CacheContext} from 'src/context/cache/cache.store';
import {CreateReservationDto} from 'src/services/models';
import {useTranslation} from 'react-i18next';

type TaxiCompanyProps = {
  reservation: CreateReservationDto;
  setReservation: React.Dispatch<React.SetStateAction<CreateReservationDto>>;
  index: number;
};
export const SelectTaxiCompany: React.FC<TaxiCompanyProps> = ({reservation, setReservation, index}) => {
  const {taxiCompanies} = React.useContext(CacheContext);

  const {t} = useTranslation();
  const handleChange = async (e: SelectChangeEvent<string>) => {
    const selectedTaxi = taxiCompanies.find(t => t.id === e.target.value);
    if (selectedTaxi !== undefined) {
      const prevState = reservation;
      prevState.travel.journeys[index].transportId = selectedTaxi.id || '';
      setReservation(_prev => {
        return {...prevState};
      });
    }
  };
  return (
    <Box sx={{display: 'grid', gridTemplateRows: '1fr 1fr', gap: '15px'}}>
      <FormControl fullWidth>
        <InputLabel>{t('taxi-company')}</InputLabel>
        <Select
          error={reservation.travel.journeys[index].transportId === undefined}
          required
          labelId="Taxi Company"
          id="demo-simple-select"
          disabled={index < reservation.travel.journeys.length - 1}
          //@ts-ignore
          value={reservation.travel.journeys[index].transportId ? reservation.travel.journeys[index].transportId : ''}
          label={t('taxi-company')}
          onChange={handleChange}
        >
          {taxiCompanies.map(t => (
            <MenuItem key={t.id as string} value={t.id as string}>
              {t.company}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <TextField
          placeholder={t('placeholders.phone-number')}
          variant="outlined"
          value={taxiCompanies.find(t => t.id === reservation.travel.journeys[index].transportId)?.phoneNumber}
          disabled
        />
      </FormControl>
    </Box>
  );
};
