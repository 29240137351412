import {CircularProgress, Container} from '@mui/material';

export const PmrLoader = () => {
  return (
    <Container
      sx={{
        height: 'calc(100vh - 120px)',
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <CircularProgress />
    </Container>
  );
};
