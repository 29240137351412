import React from 'react';

import ManagerIcon from 'src/components/icons/Manager';
import Exchange from 'src/components/icons/Exchange';
import TrainStickerIcon from 'src/components/icons/TrainSticker';
import {MissionDetailDto} from 'src/services/models/mission-detail-dto';

import {CardItemContent, ContentItem} from '../MissionItem.styles';
import {colors} from 'src/utils/hexCode';

type Props = {
  dataItem: MissionDetailDto;
  isGrobo: boolean;
  margin: number;
  hasSticker: boolean;
};

const ReservationDetails: React.FC<Props> = props => {
  const disableds = () => {
    if (props.dataItem.traveler !== undefined) {
      return props.dataItem.traveler.disableds?.length || 0;
    }
    return 0;
  };
  const groboCount = () => {
    if (props.dataItem.traveler !== undefined) {
      const adults = props.dataItem.traveler.adults || 0;
      const children = props.dataItem.traveler.children || 0;
      const seniors = props.dataItem.traveler.seniors || 0;

      return adults + children + seniors;
    }
    return 0;
  };

  return (
    <CardItemContent direction="row" margin={props.margin} spacing={0.2}>
      {disableds() > 0 && (
        <>
          <Exchange color={colors.nbms_dark_blue} />
          <ContentItem color={colors.nbms_dark_blue}>{disableds()}</ContentItem>
        </>
      )}
      {props.isGrobo && groboCount() > 0 && (
        <>
          <ManagerIcon color={colors.nbms_dark_blue} />
          <ContentItem color={colors.nbms_dark_blue}>{groboCount()}</ContentItem>
          {props.hasSticker && <TrainStickerIcon color={colors.mission_overview.succesfull} />}
        </>
      )}
    </CardItemContent>
  );
};

export default ReservationDetails;
