// @ts-nocheck
import React from 'react';
import {FormControls} from './components/FormControls';
import {Layout, Typography} from 'antd';
import {useTranslation} from 'react-i18next';
import OverViewCheckinProvider from './OverviewCheckin.store';
import {FormList} from './components/FormList';
import {MissionContext} from '../MissionOverview/missions.store';
import {Box} from '@mui/material';

const {Title} = Typography;
const {Content} = Layout;

export function OverviewCheckIn() {
  const {clearMissions} = React.useContext(MissionContext);

  React.useEffect(() => {
    clearMissions();
  }, []);

  const {t} = useTranslation();

  return (
    <OverViewCheckinProvider>
      <Box
        sx={{
          maxHeight: '100vh',
          paddingLeft: '0px',
          paddingRight: '0px',
          overflow: 'scroll !important',
          overflowX: 'hidden',
          minHeight: '100vh',
        }}
      >
        <Layout
          style={{
            backgroundColor: '#fff',
            flexGrow: 1,
            width: '100%',
            justifyContent: 'center',
            padding: '50px 50px',
          }}
        >
          <Content style={{marginBottom: 50}}>
            <Title level={5}>{t('check-in.overview.check-in-time')}</Title>
            <FormControls />
            <FormList />
          </Content>
        </Layout>
      </Box>
    </OverViewCheckinProvider>
  );
}
