// @ts-nocheck
import * as React from 'react';


export const LanguageContext = React.createContext({langKey: 'Fr'})


const LanguageProvider = ({children}) => {

    const [langKey, setLangKey] = React.useState()

    const intializer =  () => {
        let lang = localStorage.getItem('i18nextLng');
        let localLang = localStorage.getItem('language')
        if (!lang || !localLang) {
          localStorage.setItem('i18nextLng', 'Fr');
          localStorage.setItem('language', 'Fr');
          setLangKey('Fr')
        } else {
          const properLang = lang.charAt(0).toUpperCase() + lang.slice(1);
          localStorage.setItem('i18nextLng', properLang);
          localStorage.setItem('language', properLang);
        }
    }



    React.useEffect(() => {
        intializer()
    }, [])

    return <LanguageContext.Provider value={{langKey, setLangKey}}>{children}</LanguageContext.Provider>

}

export default LanguageProvider