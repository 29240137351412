//@ts-nocheck
import React from 'react';
import {JourneyItem} from 'src/scenes/coordinator/MissionDetailsNew/components/Journey/JourneyItem';
import {getTime} from 'src/scenes/coordinator/MissionDetailsNew/components/Journey/util';
import {ReservationTypeDto} from 'src/services/models';
import {MissionTypeDto} from 'src/services/models/mission-type-dto';

import {ReservationJourneyDto} from 'src/services/models/reservation-journey-dto';
import {TransportTypeDto} from 'src/services/models/transport-type-dto';

export const JourneyItemGroup: React.FC<{
  reservation: ReservationJourneyDto | undefined;
  journ: ReservationJourneyDto;
  variant: ReservationTypeDto | undefined;
  journey: ReservationJourneyDto[];
  ind: number;
  type: MissionTypeDto | ReservationTypeDto | undefined;
  hasWheelChairDep: boolean;
  hasWheelChairArr: boolean;
  hasFinished: boolean | undefined;
}> = ({journ, variant, journey, ind, type, hasWheelChairDep, hasFinished, reservation, hasWheelChairArr}) => {
  const getLocation = () => journ.coachNumber || '';
  return (
    <>
      <JourneyItem
        isExtended={false}
        hasStickerFinished={false}
        isSticker={false}
        isEndOfLine={false}
        isFullStop={true}
        location={getLocation()}
        requiresAssistance={journ.withDepartureAssistance}
        stationName={journ.departureName || ''}
        time={getTime(journ.departureTime)}
        trainNumber={journ.trainNumber}
        wheelchair={hasWheelChairDep}
        isCompleteOverview={true}
        isGrobo={variant === ReservationTypeDto.Group || type === MissionTypeDto.Stickering}
        door={journ.doorNumber}
        isTaxi={journ.transportType === TransportTypeDto.Taxi}
      />
      <JourneyItem
        isExtended={false}
        hasStickerFinished={false}
        isSticker={false}
        isEndOfLine={journey.length - 1 === ind}
        isFullStop={journey.length - 1 === ind}
        location={getLocation()}
        requiresAssistance={journ.withArrivalAssistance}
        stationName={journ.arrivalName || ''}
        time={getTime(journ.arrivalTime)}
        trainNumber={''}
        wheelchair={hasWheelChairArr}
        isCompleteOverview={true}
        isGrobo={variant === ReservationTypeDto.Group || type === MissionTypeDto.Stickering}
        door={journ.doorNumber}
      />
    </>
  );
};
