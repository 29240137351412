import * as React from 'react';
import {Autocomplete} from '@mui/material';
import {Alert, Box, Button, Container, IconButton, Input, Select} from '@mui/material';
import TextField from '@mui/material/TextField';

import {CacheContext} from 'src/context/cache/cache.store';
import {CreateMissionAssistance} from '../types';
import {CreateGroupDto, DisabilityDto, LocalizedTextDto, ReasonDto, ReservationTypeDto} from 'src/services/models';
import {useTranslation} from 'react-i18next';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';
import {getDisabilityFromId} from 'src/utils/getDisabilityFromId';
import {Close} from '@mui/icons-material';

export const Assistance: React.FC<CreateMissionAssistance> = ({reservation, setReservation, variant}) => {
  const {disabilities}: {disabilities: ReasonDto[] | DisabilityDto[]} = React.useContext(CacheContext) as {
    disabilities: ReasonDto[];
  };
  const {t, i18n} = useTranslation();

  //Errros
  const [minimumOfOneAssistanceInPmr, setMinimumOfOneAssistanceInPmr] = React.useState(
    variant === ReservationTypeDto.Disabled ? t('create-prm-mission.error-min-assistance') : '',
  );

  const getDisabilities = () => {
    return disabilities
      .filter(el => el.reservationType === variant)
      .map(el => {
        if (el.description) {
          return {label: handleTranslationObject(i18n, el.description), value: el.id};
        }
      });
  };

  const handleDisabilitieIdChange = (value: {label: string; value: string}, index: number) => {
    if (variant === ReservationTypeDto.Group && value === null) {
      const prevState = reservation;
      if (prevState && prevState.travel?.traveler?.disableds) {
        prevState.travel.traveler.disableds[index].typeId = null;
      }
      setReservation(_prev => {
        return {...prevState};
      });
    } else {
      const prevState = reservation;
      if (prevState && prevState.travel?.traveler?.disableds) {
        prevState.travel.traveler.disableds[index].typeId = value.value;
      }

      setReservation(_prev => {
        return {...prevState};
      });
    }
  };

  const handleDisabilitieAmountChange = (value: number, index: number) => {
    const prevState = reservation;
    if (prevState && prevState.travel?.traveler?.disableds) {
      prevState.travel.traveler.disableds[index].quantity = value;
    }

    setReservation(_prev => {
      return {...prevState};
    });
  };

  const getValue = (disabled: CreateGroupDto) => {
    const dis = getDisabilityFromId(disabilities as DisabilityDto[], disabled.typeId ? disabled.typeId : '');
    if (dis) {
      return {
        label: handleTranslationObject(i18n, dis.description as LocalizedTextDto[]),
        value: disabled.typeId ? disabled.typeId : '',
      };
    }
  };

  const handleAddDisability = () => {
    const prevState = reservation;
    if (prevState) {
      prevState.travel?.traveler?.disableds?.push({} as CreateGroupDto);
    }

    setReservation(_prev => {
      return {...prevState};
    });
  };

  const handleRemoveDisability = (index: number) => {
    const prevState = reservation;
    if (prevState) {
      prevState.travel?.traveler?.disableds?.splice(index, 1);
    }

    setReservation(_prev => {
      return {...prevState};
    });
  };

  React.useEffect(() => {
    //Check for validity of component only in PMR
    if (reservation.type === ReservationTypeDto.Disabled) {
      //Minmum 1 assistance amount
      let totalAmoutOfAssistance = 0;
      reservation?.travel?.traveler?.disableds?.forEach(el => {
        if (el.quantity) {
          totalAmoutOfAssistance = totalAmoutOfAssistance + el.quantity;
        }
      });

      //CHeck if every assistance has an amount
      const hasAllAmountsBeenSet = reservation?.travel?.traveler?.disableds?.every(
        el => el.quantity && el.quantity > 0,
      );

      //All assistances have to have an id
      const hasAllAssistancesBeenSet = reservation?.travel?.traveler?.disableds?.every(
        el => el.typeId && el.typeId.length > 0,
      );

      //Verify both
      if (totalAmoutOfAssistance > 0 && hasAllAssistancesBeenSet && hasAllAmountsBeenSet) {
        setMinimumOfOneAssistanceInPmr('');
      } else {
        setMinimumOfOneAssistanceInPmr(t('create-prm-mission.error-min-assistance'));
      }
    }
  }, [reservation]);

  return (
    <Container maxWidth={false}>
      {reservation?.travel?.traveler?.disableds?.map((disabled, ind) => {
        return (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: `200px 1fr ${ind > 0 ? '40px' : ''}`,
              gap: '15px',
              marginBottom: '15px',
            }}
          >
            <TextField
              type="number"
              InputProps={{
                inputProps: {min: 0},
              }}
              label={t('mission-details.show-pmr-data.label-quantity')}
              value={disabled.quantity}
              onChange={event => handleDisabilitieAmountChange(parseInt(event.target.value), ind)}
              error={
                variant === ReservationTypeDto.Disabled && (disabled.quantity === 0 || disabled.quantity === undefined)
              }
              color={
                variant === ReservationTypeDto.Disabled && (disabled.quantity === 0 || disabled.quantity === undefined)
                  ? 'error'
                  : 'success'
              }
            />
            <Autocomplete
              disableClearable={variant === ReservationTypeDto.Disabled}
              options={getDisabilities()}
              value={getValue(disabled)}
              renderInput={args => (
                <TextField
                  error={variant === ReservationTypeDto.Disabled && disabled.typeId === undefined}
                  label={t('mission-details.show-pmr-data.label-type-person')}
                  {...args}
                />
              )}
              //@ts-ignore
              onChange={(_event, value) => handleDisabilitieIdChange(value, ind)}
              color={variant === ReservationTypeDto.Disabled && disabled.typeId === undefined ? 'error' : 'success'}
            />
            {ind > 0 && (
              <IconButton onClick={() => handleRemoveDisability(ind)}>
                <Close />
              </IconButton>
            )}
          </Box>
        );
      })}
      {variant === ReservationTypeDto.Disabled &&
      minimumOfOneAssistanceInPmr.length > 0 &&
      reservation?.travel?.traveler?.disableds?.length === 1 ? (
        <Alert severity="error">{minimumOfOneAssistanceInPmr}</Alert>
      ) : null}
      <Box
        sx={{
          marginTop: '15px',
        }}
      >
        <Button
          onClick={() => handleAddDisability()}
          variant="contained"
          disabled={variant === ReservationTypeDto.Disabled && minimumOfOneAssistanceInPmr.length > 0}
        >
          {t('reservation.add')}
        </Button>
      </Box>
    </Container>
  );
};
