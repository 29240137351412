import React from 'react';
import {useTranslation} from 'react-i18next';

export const CastBanner = () => {
  const {t} = useTranslation();

  return (
    <h4 style={{display: 'block', width: '100%', marginBottom: '15px'}}>{t('mission-details-v2.traveler.cast')}</h4>
  );
};
